
import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Text from '../../common/Text/Text'
import CloseIcon from '../../icons/CloseIcon'
import CaretLeftRightIcon from '../../icons/CaretLeftRightIcon'
import SearchFiltersCategories from './SearchFiltersCategories'
import SearchFiltersTopics from './SearchFiltersTopics'
import { buildSearchQueryObj } from '../../../utilities/searchPageHelpers'
import { getFilters } from '../../../services/api'

import './SearchFilters.scss'

const searchFiltersTransitionTime = 500

const SearchFilters = (props) => {
  const {
    keywords,
    onRequestClose,
    onApplyFiltersClick,
    initialAllFilters,
    initialActiveFilters
  } = props
  const [activeFilters, setActiveFilters] = useState(initialActiveFilters)
  const [allFilters, setAllFilters] = useState(initialAllFilters)
  const [isLoadingCounts, setIsLoadingCounts] = useState(false)
  // reference to the current selected L1 category
  const [currentCategory, setCurrentCategory] = useState(null)

  const totalNumOfActiveFilters = useMemo(() => {
    return Object.keys(activeFilters).reduce((total, key) => {
      return total + activeFilters[key].length
    }, 0)
  }, [activeFilters])

  /**
   * Reloads all filters with updated counts
   * @param {object} filterParams - filter parameters to use for constraining the counts;
   *                                e.g., { Programs: ['Medicare']}
   */
  async function reloadFilterCounts (filterParams = {}) {
    setIsLoadingCounts(true)

    try {
      const params = buildSearchQueryObj({
        keywords,
        filters: filterParams
      }, initialAllFilters)

      const { data: updatedAllFilters } = await getFilters(params)

      setAllFilters(updatedAllFilters)
      setIsLoadingCounts(false)
    } catch (e) {
      console.error('error reloading filters', e)
      setIsLoadingCounts(false)
    }
  }

  // whenever allFilters changes, currentCategory needs to be refreshed from
  // allFilters to display the updated counts
  useEffect(() => {
    setCurrentCategory(currentCategory => {
      const currentCategoryLabel = currentCategory ? currentCategory.label : null
      // retrieve updated category from allFilters for updated topic counts
      return allFilters.find(category => category.label === currentCategoryLabel) || null
    })
  }, [allFilters])

  function handleCategoryClick (category) {
    setCurrentCategory(category)
    // reload filters upon entering into category; since the filters API endpoint only ANDs
    // the filter params, we need to leave out the current cateogry filters so those counts are
    // excluded from the ANDing (ie, only other category filters should be ANDed relative
    // to the current category items)
    reloadFilterCounts({
      ...activeFilters,
      [category.label]: []
    })
  }

  function clearCurrentCategory () {
    setCurrentCategory(null)
  }

  /**
   * handler used for CategoryTopics, which updates the current category filters
   * @param {string[]} updatedCategoryFilters
   */
  function handleUpdateFiltersForCategory (updatedCategoryFilters) {
    setActiveFilters(existingFilters => ({
      ...existingFilters,
      [currentCategory.label]: updatedCategoryFilters
    }))
  }

  /**
   * handler used for the footer for clearing filters for all categories
   */
  function handleClearAllFiltersClick () {
    const emptyFilters = Object.keys(activeFilters).reduce((obj, key) => ({
      ...obj,
      [key]: []
    }), {})
    setActiveFilters(emptyFilters)
    // reload filter counts now that filters are empty
    reloadFilterCounts(emptyFilters)
  }

  /**
   * handler for clearing filters of an L1 category
   * @param {object} category
   */
  function handleClearCategoryFiltersClick (category) {
    setActiveFilters(existingFilters => ({
      ...existingFilters,
      [category.label]: []
    }))
  }

  /**
   * handler used for the footer for applying current state's active filters for all categories;
   * parent is responsible for closing the modal after handling this callback
   */
  function handleApplyFiltersClick () {
    onApplyFiltersClick(activeFilters)
  }

  const isShowingCateogries = !!(currentCategory) === false

  return (
    <div className='SearchFilters'>
      <div className='SearchFilters__header'>
        <TransitionGroup className='SearchFilters__headerInner'>
          <CSSTransition
            key={isShowingCateogries}
            timeout={searchFiltersTransitionTime}
            classNames='fadeTransition'
          >
            <div>
              {!currentCategory && (
                <Text
                  className='SearchFilters__headerText'
                  typeFace='03 H3/Desktop/White'
                >Filters
                </Text>
              )}
              {currentCategory && (
                <div className='SearchFilters__headerNav' onClick={e => clearCurrentCategory(e)}>
                  <CaretLeftRightIcon direction='left' color='#fff' />
                  <Text typeFace='Small Text/White' domElement='span'>All Filter Categories</Text>
                </div>
              )}
            </div>
          </CSSTransition>
        </TransitionGroup>

        <CloseIcon onClick={onRequestClose} size={24} />
      </div>
      <div className='SearchFilters__body'>
        <TransitionGroup className='SearchFilters__bodyInner'>
          <CSSTransition
            key={isShowingCateogries}
            timeout={searchFiltersTransitionTime}
            classNames={`${isShowingCateogries ? 'categoryTransition' : 'topicTransition'}`}
          >
            <div>
              {isShowingCateogries && (
                <SearchFiltersCategories
                  categories={allFilters}
                  onCategoryClick={e => handleCategoryClick(e)}
                  onClearCategoryFiltersClick={e => handleClearCategoryFiltersClick(e)}
                  activeFilters={activeFilters}
                />
              )}
              {!isShowingCateogries && (
                <SearchFiltersTopics
                  currentCategory={currentCategory}
                  activeFiltersForCategory={activeFilters[currentCategory.label]}
                  onUpdateFilters={e => handleUpdateFiltersForCategory(e)}
                  isLoadingCounts={isLoadingCounts}
                />
              )}
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
      <div className='SearchFilters__footer'>
        {totalNumOfActiveFilters > 0 && (
          <Button
            className='clear-btn'
            variant='link'
            onClick={e => handleClearAllFiltersClick(e)}
          >Clear all
          </Button>
        )}
        <Button
          className='apply-btn'
          onClick={e => handleApplyFiltersClick(e)}
        >Apply
        </Button>
      </div>
    </div>
  )
}

SearchFilters.propTypes = {
  keywords: PropTypes.string,
  onRequestClose: PropTypes.func.isRequired,
  onApplyFiltersClick: PropTypes.func.isRequired,
  initialAllFilters: PropTypes.arrayOf(PropTypes.object),
  initialActiveFilters: PropTypes.object
}

const SearchFiltersModal = (props) => {
  const {
    isOpen,
    onRequestClose,
    ...otherProps
  } = props

  return (
    <Modal
      className='SearchFiltersModal'
      size='lg'
      show={isOpen}
      onHide={onRequestClose}
      scrollable
    >
      <Modal.Body className='SearchFiltersModal__body'>
        <SearchFilters
          {...otherProps}
          onRequestClose={onRequestClose}
        />
      </Modal.Body>
    </Modal>
  )
}

SearchFiltersModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired
}

export { SearchFilters }

export default SearchFiltersModal
