import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as EditIcon } from '../../../assets/fontawesome/custom/edit.svg'
import { ReactComponent as WarningIcon } from '../../../assets/fontawesome/solid/triangle-exclamation.svg'

import './NotificationBanner.scss'

const NotificationBanner = ({ actionItemHandler = null, estimatedFileSize = '', maxGBSetting = '' }) => {
  return (
    <div className={actionItemHandler ? 'NotificationBanner bottomMargin' : 'NotificationBanner topMargin'}>
      <div className='leftColorSection' />
      <div className='mainTextSection'>Your selections have exceeded the suggested limit of {maxGBSetting} GB. Your download may be impacted.</div>
      <div className='infoActionSection'>
        {actionItemHandler && <button className='editSelectionLink' onClick={actionItemHandler}><EditIcon /> Edit download selections</button>}
        {estimatedFileSize && <><WarningIcon className='warningIcon' /> Estimated file size: {estimatedFileSize}</>}

      </div>

    </div>
  )
}

NotificationBanner.propTypes = {
  actionItemHandler: PropTypes.func,
  estimatedFileSize: PropTypes.number,
  maxGBSetting: PropTypes.string
}

export { NotificationBanner }
