import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import './Checkbox.scss'

const Checkbox = (props) => {
  // avoid warnings from passing dark to input
  const {
    containerClassName,
    dark: isDark,
    ...otherProps
  } = props

  return (
    <label className={cx('Checkbox', containerClassName)}>
      <input {...otherProps} type='checkbox' />
      <span className={cx({
        Checkbox__custom: true,
        'Checkbox__custom--dark-mode': isDark
      })}
      />
    </label>
  )
}

Checkbox.defaultProps = {
  dark: false
}

Checkbox.propTypes = {
  dark: PropTypes.bool,
  containerClassName: PropTypes.string
}

export default Checkbox
