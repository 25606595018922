import { useState, useEffect } from 'react'
import debounce from 'lodash/debounce'

export const DEBOUNCE_TIME = 100

export default function useWindow () {
  const [windowProps, setWindowProps] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  useEffect(() => {
    // debounce to prevent rapid rerendering
    const debouncedSetWindowProps = debounce(() => {
      setWindowProps({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }, DEBOUNCE_TIME)

    window.addEventListener('resize', debouncedSetWindowProps)

    return () => {
      window.removeEventListener('resize', debouncedSetWindowProps)
    }
  }, [])

  return windowProps
}
