import queryString from 'query-string'
import omit from 'lodash/omit'
import dayjs from 'dayjs'
import get from 'lodash/get'
import { isValuePresent } from '../datasetHelpers'
import { DEFAULT_OFFSET, DEFAULT_SIZE } from '../../stores/interactiveToolsStore'

/**
 *
 * @param {object} params
 * @param {string} params.groupName
 * @param {string} params.conjunction
 * @param {string} params.memberOf
 */
export function createFilterGroupQueryString (params) {
  const { groupName, conjunction, memberOf } = params
  const conjuctionPart = `filter[${encodeURIComponent(groupName)}][group][conjunction]=${encodeURIComponent(conjunction)}`
  const groupPart = memberOf ? `filter[${encodeURIComponent(groupName)}][group][memberOf]=${encodeURIComponent(memberOf)}` : null
  // some filters may not have a value part, so ensure it is filtered out if not defined
  const joined = [conjuctionPart, groupPart].filter(part => !!(part)).join('&')
  return joined
}

/**
 *
 * @param {object} params
 * @param {string} params.filterName
 * @param {string} params.column
 * @param {string} params.operator
 * @param {string} params.value
 * @param {string} params.memberOf
 */
export function createFilterConditionQueryString (params) {
  const { filterName, column, operator, value, memberOf } = params

  const columnPart = `filter[${encodeURIComponent(filterName)}][condition][path]=${encodeURIComponent(column)}`
  const comparatorPart = `filter[${encodeURIComponent(filterName)}][condition][operator]=${encodeURIComponent(operator)}`
  const valuePart = value
    ? Array.isArray(value)
      ? value.map(v => `filter[${encodeURIComponent(filterName)}][condition][value][]=${encodeURIComponent(v)}`).join('&')
      : `filter[${encodeURIComponent(filterName)}][condition][value]=${encodeURIComponent(value)}`
    : null
  const groupPart = memberOf ? `filter[${encodeURIComponent(filterName)}][condition][memberOf]=${encodeURIComponent(memberOf)}` : null
  // some filters may not have all parts, so ensure they are filtered out if not defined
  const joined = [columnPart, comparatorPart, valuePart, groupPart].filter(part => !!(part)).join('&')
  return joined
}

/**
 * convert form date value to properly formatted BE API value
 * @param {string} dateValue
 */
export function convertFormDateValueToApiValue (dateValue) {
  const date = dayjs(dateValue)

  return date.isValid()
    ? date.format('YYYY-MM-DD')
    : null
}

/**
 *
 * @param {string} qString - query string (e.g., '?state=Massachusetts&offset=10')
 * @returns {object} parsed query param object for lookup tool page
 */
export const parseQueryStringForLookupToolSearch = (qString = '') => {
  const queryParams = queryString.parse(qString, { arrayFormat: 'none' })
  const size = Number(queryParams.size || DEFAULT_SIZE)
  const offset = Number(queryParams.offset || DEFAULT_OFFSET)
  const formValues = omit(queryParams, 'offset', 'size', 'sort_by', 'sort_order')

  return {
    offset,
    size,
    formValues
  }
}

const queryStringFormatters = {
  size: size => `size=${encodeURIComponent(size)}`,
  offset: offset => `offset=${encodeURIComponent(offset)}`,
  formValues: (formValues) => Object.keys(formValues).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(formValues[key])}`).join('&')
}

/**
 *
 * @param {object} params
 * @param {string} params.keyword
 * @param {object} params.filter
 * @param {object[]} params.filter.groups
 * @param {object[]} params.filter.conditions
 * @param {number} params.offset
 * @param {number} params.size
 * @param {string[]} params.sort
 * @param {string} params.groupBy
 * @param {bool} params.distinct
 * @param {string[]} params.columns
 * @param {string} params.table - specifies a subtable of the data source
 * @param {string} params.source - a tool may have multiple data sources, this specifies which one to use
 * @returns {string}
 */
export function generateDataApiQueryString (params) {
  const {
    keyword,
    filter = {},
    offset,
    size,
    sort,
    groupBy,
    distinct,
    columns,
    table,
    source
  } = params
  const { groups = [], conditions = [] } = filter

  const groupQueryStrings = groups.map(group => createFilterGroupQueryString(group))
  const filterQueryStrings = conditions.map(condition => createFilterConditionQueryString(condition))
  const keywordsQueryString = isValuePresent(keyword) ? `keyword=${encodeURIComponent(keyword)}` : null
  const sizeQueryString = isValuePresent(size) ? `size=${size}` : null
  const sortQueryString = Array.isArray(sort) && sort.length ? `sort=${sort.map(col => encodeURIComponent(col)).join(',')}` : null
  const offsetQueryString = isValuePresent(offset) ? `offset=${offset}` : null
  const groupByQueryString = isValuePresent(groupBy) ? `group_by[]=${encodeURIComponent(groupBy)}` : null
  const columnsQueryString = Array.isArray(columns) && columns.length ? `column=${columns.map(col => encodeURIComponent(col)).join(',')}` : null
  const distinctQueryString = distinct ? 'distinct=1' : null
  const tableQueryString = isValuePresent(table) ? `_table=${encodeURIComponent(table)}` : null
  const sourceQueryString = isValuePresent(source) ? `_source=${encodeURIComponent(source)}` : null

  const queryStrings = [
    keywordsQueryString,
    ...groupQueryStrings,
    ...filterQueryStrings,
    sizeQueryString,
    sortQueryString,
    offsetQueryString,
    groupByQueryString,
    distinctQueryString,
    tableQueryString,
    sourceQueryString,
    columnsQueryString
  ].filter(qString => !!(qString))

  return queryStrings.join('&')
}

/**
 * convert lookup tool search state params to a parseable frontend URL
 * @param {object} options - standard search options
 * @param {number} options.size - size
 * @param {number} options.offset - pagination
 * @param {object} options.formValues
 */
export const buildLookupToolUiQueryString = (options = {}) => {
  const optionKeys = [
    'size',
    'offset',
    'formValues'
  ]

  const queryStringComponents = optionKeys.reduce((qsComponents, optionKey) => {
    const optionValue = get(options, optionKey)
    return isValuePresent(optionValue)
      ? [...qsComponents, queryStringFormatters[optionKey](optionValue)]
      : qsComponents
  }, [])

  return `?${queryStringComponents.join('&')}`
}

/**
 * formValues dates are stored as strings, so this will convert to a date object if available,
 * otherwise return null
 * @param {string} dateString
 */
export function parseFormValueDate (dateString) {
  return dateString
    ? new Date(dateString)
    : null
}
