import queryString from 'query-string'
import isEmpty from 'lodash/isEmpty'
import omitBy from 'lodash/omitBy'
import config from '../config'

const {
  searchSortOptions
} = config

/**
 *
 * @param {object} payload
 * @param {string} payload.keywords
 * @param {object} payload.filters
 * @param {number} payload.offset
 * @param {string} payload.sort
 * @returns {string} query string representing search parameters for SearchPage
 */
const encodeSearchPageQueryString = (payload = {}) => {
  const {
    filters,
    keywords,
    offset,
    sort
  } = payload

  const searchQueryObj = {
    ...keywords && { keywords: keywords },
    ...offset && { offset: Number(offset) },
    ...sort && { sort: sort }
  }

  const withoutEmptyFilters = filters ? omitBy(filters, isEmpty) : null

  if (withoutEmptyFilters) {
    Object.keys(withoutEmptyFilters).forEach(filterKey => {
      searchQueryObj[`filter_${filterKey}`] = withoutEmptyFilters[filterKey]
    })
  }

  const qString = queryString.stringify(searchQueryObj, {
    arrayFormat: 'comma',
    encode: true
  })

  return `?${qString}`
}

export const buildFilterParamsObject = (activeFilters, allFilters) => {
  const filtersParamObj = allFilters.reduce((prev, curr) => {
    const queryKey = curr.query_parameter
    const filterLabel = curr.label
    const filterList = activeFilters[filterLabel].map(filter => {
      return encodeURIComponent(filter)
    })

    if (isEmpty(filterList)) return prev
    const filterKey = `filter[${queryKey}][]`

    return {
      ...prev,
      [filterKey]: filterList
    }
  }, {})

  return filtersParamObj
}

export const buildSortParams = (sort = '') => {
  const options = new Map(searchSortOptions[sort])
  const params = {}

  options.forEach((value, key) => {
    params[key] = value
  })

  return params
}

/**
 * @param {object} params
 * @param {string} params.keywords values to search for
 * @param {string} params.sort
 * @param {object} params.filters
 * @param {object} params.offset
 * @param {object} params.size
 * @param {object[]} allFilters
 */
export const buildSearchQueryObj = (params, allFilters) => {
  const {
    keywords = '',
    filters,
    sort,
    offset,
    size
  } = params

  const sortParams = sort ? buildSortParams(sort) : {}
  const filterParams = buildFilterParamsObject(filters, allFilters)
  return {
    ...(!isEmpty(keywords.trim()) ? { keyword: encodeURIComponent(keywords.trim()) } : {}),
    ...sortParams,
    ...filterParams,
    offset: offset,
    size: size
  }
}

/**
 *
 * @param {string} qString
 * @returns {object} query parameters object for SearchPage
 */
const decodeSearchPageQueryString = (qString = '') => {
  const queryParams = queryString.parse(qString, { arrayFormat: 'comma' })

  const {
    keywords = '',
    offset = 0,
    sort = ''
  } = queryParams

  const filters = Object.keys(queryParams).reduce((filterObj, paramKey) => {
    if (paramKey.includes('filter_')) {
      const filterKey = paramKey.substring(
        paramKey.lastIndexOf('_') + 1,
        paramKey.length
      )

      // queryString.parse will not put single values in an array, so we do it manually
      filterObj[filterKey] = !Array.isArray(queryParams[paramKey])
        ? [queryParams[paramKey]]
        : queryParams[paramKey]
    }

    return filterObj
  }, {})

  return {
    filters: filters,
    // since we are using queryString.parse with 'comma' as array format, it will convert
    // a keywords query param containing commas to an array; this is not desired, so we will
    // enforce a string
    keywords: String(keywords),
    offset: Number(offset),
    sort
  }
}

/**
 *
 * @param {number|string} x
 * @param {number|string} decimalLength - [Optional] Length of decimals to pad with '0' character. E.g. value of 2 and x=10.5 would return 10.50
 * @returns {string}
 */
const numberWithCommas = (x, decimalLength) => {
  const type = typeof x

  if (['string', 'number'].includes(type) === false) {
    console.error('numberWithCommas(): invalid input value:', x)
    return ''
  }

  const components = x.toString().split('.')
  const integer = components[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const decimal = components[1] ? `.${decimalLength > 0 ? components[1].substring(0, decimalLength).padEnd(decimalLength, '0') : components[1]}` : ''
  return `${integer}${decimal}`
}

/**
 * Helper function for calculating conditionally adjusted offset; this is necessary when
 * updated size/limit does not divide evenly into current offset.
 * In these situations, the function will round down to closest page
 * to prevent erroneous page calculation (current page calc is (offset + limit) / limit)
 *
 * Example:
 * page calculation: 150 + 100 / 100 = 250/100 = 2.5
 * rounding down in this example (Math.floor(150/100)) yields 1;
 * then multiply by size to get adjustedOffset (1 * 100) = 100
 * @param {number} updatedSize updated page size/limit
 * @param {number} currentOffset current offset
 * @returns {number} adjusted (if necessary) offset
 */
const getAdjustedOffset = (updatedSize, currentOffset) => {
  const updatedOffset = currentOffset % updatedSize
    ? Math.floor(currentOffset / updatedSize) * updatedSize
    : currentOffset

  return updatedOffset
}

function updateFormValuesSearchState (formValues, interactiveToolsStore) {
  interactiveToolsStore.update(state => {
    // setting 'formValues' ensures that upon page load, 'formValues' are in sync with URL params
    state.search.formValues = formValues
    // setting 'cachedFormValues' ensures that the form values are stored upon form submission, and
    // unlike 'formValues', they are not updated as the user changes inputs
    state.search.cachedFormValues = formValues
  })
}

export {
  encodeSearchPageQueryString,
  decodeSearchPageQueryString,
  numberWithCommas,
  getAdjustedOffset,
  updateFormValuesSearchState
}
