/*
This utility accepts a dataset version date and returns a formatted date for display
  Datasets store version date as YYYY-MM-DD strings
  These version dates correspond to an update frequency format:
    1) Daily - displayed as MM/DD/YYYY?
    2) Monthly - displayed as 'Monthname YYYY'
    3) Quarterly - displayed as 'Quarter YYYY'
    4) Weekly - displayed as MMMM D, YYYY
    3) Annually - displayed as 'YYYY'
*/
import dayjs from 'dayjs'

export const weeklyDateFormat = 'MMMM D, YYYY'

const monthDictionary = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  10: 'October',
  11: 'November',
  12: 'December'
}

const quarterDictionary = {
  '01': 'Q1',
  '02': 'Q1',
  '03': 'Q1',
  '04': 'Q2',
  '05': 'Q2',
  '06': 'Q2',
  '07': 'Q3',
  '08': 'Q3',
  '09': 'Q3',
  10: 'Q4',
  11: 'Q4',
  12: 'Q4'
}

export default function formatDatasetVersionDate (frequency, date) {
  if (!frequency || !date) throw new TypeError('Params missing')
  switch (frequency) {
    case 'Daily':
      return getDaily(date)
    case 'Monthly':
      return getMonthly(date)
    case 'Quarterly':
      return getQuarterly(date)
    case 'Weekly':
      return getWeekly(date)
    case 'Annually':
      return getAnnually(date)
    case 'Semiannually':
      return getMonthly(date)
    case 'Three times a year':
      return getMonthly(date)
    case 'Bimonthly':
      return getMonthly(date)
    case 'Semimonthly':
      return getDaily(date)
    case 'Three times a month':
      return getDaily(date)
    case 'Biweekly':
      return getDaily(date)
    case 'Semiweekly':
      return getDaily(date)
    case 'Three times a week':
      return getDaily(date)
    case 'Periodically':
      return getDaily(date)
    default:
      throw new TypeError('Unknown frequency param passed')
  }
}

function getDaily (date) {
  const [year, month, day] = date.split('-')
  // calendar day should be displayed as int, e.g, 01 should display as '1'
  const dayInt = parseInt(day, 10)
  return `${monthDictionary[month]} ${dayInt}, ${year}`
}

function getMonthly (date) {
  const dateParts = date.split('-')
  const month = dateParts[1]

  return `${monthDictionary[month]} ${dateParts[0]}`
}

function getQuarterly (date) {
  const dateParts = date.split('-')
  const month = dateParts[1]

  return `${quarterDictionary[month]} ${dateParts[0]}`
}

function getWeekly (date) {
  return dayjs(date).format(weeklyDateFormat)
}

function getAnnually (date) {
  return date.split('-')[0]
}
