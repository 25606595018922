import React from 'react'
import useDisplayContext from '../../hooks/useDisplayContext'

export default (Component) => {
  return (props) => {
    const display = useDisplayContext()

    return (
      <Component
        {...props}
        {...display}
      />
    )
  }
}
