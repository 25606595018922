import { getLookupToolData } from '../../services/api'
import { convertDataToObjectArray } from '../../services/formatters/lookupTool'

// column names
export const PROVIDER_NAME_COLUMN = 'Rndrng_Prvdr_Org_Name'
export const STATE_COLUMN = 'Rndrng_Prvdr_State_Abrvtn'
export const CCN_COLUMN = 'Rndrng_Prvdr_CCN'
export const CITY_COLUMN = 'Rndrng_Prvdr_City'
export const ZIP_COLUMN = 'Rndrng_Prvdr_Zip5'

// input name values
export const INPATIENT_PROVIDER_NAME = 'name'
export const INPATIENT_STATE = 'state'
export const INPATIENT_CCN = 'ccn'
export const INPATIENT_CITY = 'city'

export const INPATIENT_SUBTABLE = 'lookup'

const formSearchConfig = {
  [INPATIENT_PROVIDER_NAME]: {
    filterName: INPATIENT_PROVIDER_NAME,
    operator: 'CONTAINS',
    column: PROVIDER_NAME_COLUMN
  },
  [INPATIENT_STATE]: {
    filterName: INPATIENT_STATE,
    operator: '=',
    column: STATE_COLUMN
  },
  [INPATIENT_CITY]: {
    filterName: INPATIENT_CITY,
    operator: '=',
    column: CITY_COLUMN
  },
  [INPATIENT_CCN]: {
    filterName: INPATIENT_CCN,
    operator: '=',
    column: CCN_COLUMN
  }
}

const typeaheadSearchConfig = {
  [INPATIENT_PROVIDER_NAME]: {
    ...formSearchConfig[INPATIENT_PROVIDER_NAME],
    operator: 'STARTS_WITH'
  }
}

/**
 *
 * @param {object} data
 * @param {object} data.formValues
 * @param {object} data.fitlerConfig
 */
export function getToolSearchApiParams (data) {
  const {
    formValues,
    filterConfig = formSearchConfig
  } = data

  const filter = {
    conditions: []
  }

  Object.keys(formValues).forEach(formKey => {
    const baseCondition = { ...filterConfig[formKey] }
    filter.conditions.push({
      ...baseCondition,
      value: formValues[formKey]
    })
  })

  return {
    filter,
    sort: [PROVIDER_NAME_COLUMN, STATE_COLUMN],
    columns: [
      PROVIDER_NAME_COLUMN,
      STATE_COLUMN,
      CCN_COLUMN,
      CITY_COLUMN,
      ZIP_COLUMN
    ],
    table: INPATIENT_SUBTABLE
  }
}

export async function getTypeaheadData (uuid, { value, size }) {
  try {
    const data = {
      formValues: { [INPATIENT_PROVIDER_NAME]: value },
      filterConfig: typeaheadSearchConfig
    }

    const apiParams = getToolSearchApiParams(data)

    const res = await getLookupToolData(uuid, { ...apiParams, size })
    const results = convertDataToObjectArray(res)
    const formatted = results.map(result => {
      return {
        result,
        listItem: {
          name: result[PROVIDER_NAME_COLUMN],
          location: `${result[CITY_COLUMN]}, ${result[STATE_COLUMN]}`
        },
        value: {
          [INPATIENT_PROVIDER_NAME]: result[PROVIDER_NAME_COLUMN],
          [INPATIENT_STATE]: result[STATE_COLUMN],
          [INPATIENT_CCN]: result[CCN_COLUMN],
          [INPATIENT_CITY]: result[CITY_COLUMN]
        }
      }
    })

    return formatted
  } catch (e) {
    // check
    console.error('Error fetching typeahead data', e)
    return []
  }
}
