import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import SortMenu from './SortMenu'
import config from '../../../config'
import SortIcon from '../../icons/SortIcon'

import './Sort.scss'

function Sort ({ selected, onClick, className, show, onToggle }) {
  // grab sort keys
  const sortOptions = Object.keys(config.searchSortOptions)

  function handleClick (e) {
    // prevent default link action and propagation by default
    e.preventDefault()
    e.stopPropagation()
    onClick(e)
  }

  return (
    <Dropdown
      drop='down'
      show={show}
      onToggle={onToggle}
      className={cx('Search__sort', className)}
    >
      <Dropdown.Toggle as={SortButton} id='Search__sort-button' />

      <Dropdown.Menu
        as={SortMenu}
        className='Search__sort-menu'
        flip={false}
      >
        {
          sortOptions.map((value, index) => {
            return (
              <div className='Search__sort-link' key={index}>
                <Dropdown.Item
                  key={index}
                  active={value === selected}
                  data-sort={value}
                  onClick={e => handleClick(e)}
                >
                  {value}
                </Dropdown.Item>
                {
                  index !== sortOptions.length - 1 && <Dropdown.Divider />
                }
              </div>
            )
          })
        }
      </Dropdown.Menu>
    </Dropdown>
  )
}

Sort.propTypes = {
  selected: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  show: PropTypes.bool,
  onToggle: PropTypes.func
}

const SortButton = forwardRef((props, ref) => {
  const isExpanded = props['aria-expanded']
  const handleClick = (e) => {
    e.preventDefault()

    props.onClick(e)
  }

  return (
    <Button
      onClick={handleClick}
      className={isExpanded ? 'active' : null}
      ref={ref}
    >
      <SortIcon />
      <span className='Search__sort-label'>
        Sort
      </span>
    </Button>
  )
})

SortButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  'aria-expanded': PropTypes.bool
}

export default Sort
