import React from 'react'
import PropTypes from 'prop-types'
import Container from 'react-bootstrap/Container'
import VisualizationTools from './VisualizationTools'

import './VisualizationToolsList.scss'

function VisualizationToolsList ({ visualizationTools: lists, visualizationToolsHeader }) {
  return (
    <div className='VisualizationToolsList__wrapper'>
      <Container>
        <h2>{visualizationToolsHeader}</h2>
        <ul className='VisualizationToolsList'>
          {
            lists.map((list, i) => (
              <VisualizationTools
                key={i}
                listPosition={i}
                list={list}
              />
            ))
          }
        </ul>
      </Container>
    </div>
  )
}

VisualizationToolsList.propTypes = {
  visualizationTools: PropTypes.array.isRequired,
  visualizationToolsHeader: PropTypes.string.isRequired
}

export default VisualizationToolsList
