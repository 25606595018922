import { formatDataSources } from './lookupTool'

/**
 *
 * @param {object} moduleInclude - the un-deserialized raw include of the dynamic module
 * @returns object with extracted properties
 */
export function formatDynamicModule (moduleInclude, dataSources) {
  const formattedDataSources = formatDataSources(dataSources)
  return {
    id: moduleInclude.id,
    type: moduleInclude.type,
    name: moduleInclude.attributes.title,
    moduleIdentifier: moduleInclude.attributes.machine_name,
    dataSourceConfig: JSON.parse(moduleInclude.attributes.field_data_source_json),
    config: JSON.parse(moduleInclude.attributes.field_module_config_json),
    dataSources: formattedDataSources
  }
}
