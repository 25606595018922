import React, { Component, createRef } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import memoizeOne from 'memoize-one'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import Container from 'react-bootstrap/Container'
import Typeahead, { TypeaheadOverlay } from '../../common/Typeahead/Typeahead'
import ContactBanner from '../../common/ContactBanner/ContactBanner'
import Pagination from '../../common/Pagination/Pagination'
import SearchFilters from '../../search/SearchFilters/SearchFilters'
import SearchPageFiltersDisplay from '../../search/SearchPageFiltersDisplay/SearchPageFiltersDisplay'
import SearchPageResult from '../../search/SearchPageResult/SearchPageResult'
import SearchPageResultLoading from '../../search/SearchPageResultLoading/SearchPageResultLoading'
import config from '../../../config'
import {
  getPopularSearchTerms,
  getSearchResults,
  getTrendingStats,
  getFilters,
  getSearchMetaData
} from '../../../services/api'
import Text from '../../common/Text/Text'
import MetaHead from '../../layout/MetaHead/MetaHead'
import compose from '../../../hocs/compose'
// top level error handling
import withBoundary from '../../../hocs/withBoundary/withBoundary'
import Footer from '../../layout/Footer/Footer'
import {
  buildSearchQueryObj,
  decodeSearchPageQueryString,
  encodeSearchPageQueryString
} from '../../../utilities/searchPageHelpers'
import withDisplayContext from '../../../hocs/withDisplayContext/withDisplayContext'
import GovBanner from '../../common/GovBanner/GovBanner'
import Header from '../../../components/layout/Header/Header.js'

import './SearchPage.scss'
import log from '../../../log'
import ErrorPage from '../ErrorPage/ErrorPage'

// attach a key to meta title
const uuid = require('uuid/v4')

const {
  searchLimit,
  defaultSearchSortValue,
  metaOrganizationName,
  placeholders
} = config

const { searchTypeahead, searchTypeaheadMobile } = placeholders

const memoizedDecodeQueryString = memoizeOne(decodeSearchPageQueryString)

// TODO: check mock for page title
const pageTitle = 'Search Data.CMS.gov'

class SearchPage extends Component {
  constructor (props) {
    super(props)

    // set initial state
    this.state = {
      allFilters: [],
      loadingInitialData: true,
      loadingResults: true,
      isFilterModalOpen: false,
      error: null,
      data: [],
      popularSearchStats: [],
      searchResultTags: {},
      meta: {
        total: 0
      },
      metaInfo: {}
    }

    this.overlayRef = createRef()
  }

  get queryParams () {
    const { location } = this.props
    const search = get(location, 'search', '')
    const queryParams = memoizedDecodeQueryString(search)

    return queryParams
  }

  get offset () {
    return this.queryParams.offset || 0
  }

  get limit () {
    return this.queryParams.limit || searchLimit
  }

  get sort () {
    return this.queryParams.sort
  }

  get searchValue () {
    return this.queryParams.keywords
  }

  get activeFilters () {
    const { allFilters } = this.state

    const allFiltersObj = allFilters.reduce((prev, current) => {
      const newKey = { [current.label]: [] }

      return {
        ...prev,
        ...newKey
      }
    }, {})

    return {
      ...allFiltersObj,
      ...this.queryParams.filters
    }
  }

  get isNothingLoading () {
    const {
      loadingInitialData,
      loadingResults
    } = this.state

    return (!loadingInitialData && !loadingResults)
  }

  get isAnythingLoading () {
    const {
      loadingInitialData,
      loadingResults
    } = this.state

    return (loadingInitialData || loadingResults)
  }

  componentDidMount () {
    this.loadInitialData()
  }

  componentDidUpdate (prevProps) {
    const { location: currentLocation } = this.props
    const { location: previousLocation } = prevProps

    if (currentLocation !== previousLocation) {
      this.fetchSearchResults()
    }
  }

  loadInitialData = async () => {
    this.setState({
      loadingInitialData: true
    })

    Promise.all([
      this.fetchFilters(),
      this.fetchTrendingStats(),
      this.fetchPopularSearchTerms(),
      this.fetchContact()
    ])
      .then(() => {
        this.setState({
          loadingInitialData: false
        })

        this.fetchSearchResults()
      })
  }

  fetchPopularSearchTerms = async () => {
    try {
      const popularSearchStats = await getPopularSearchTerms({})

      this.setState({
        errorPopular: null,
        popularSearchStats
      })
    } catch (error) {
      log.error(error)
      this.setState({
        errorPopular: error,
        popularSearchStats: []
      })
    }
  }

  fetchTrendingStats = async () => {
    try {
      const searchResultTags = await getTrendingStats()

      this.setState({
        errorTrending: null,
        searchResultTags
      })
    } catch (error) {
      log.error(error)
      this.setState({
        errorTrending: error,
        stats: {}
      })
    }
  }

  // fetch the available filter lists
  fetchFilters = async () => {
    try {
      const { data: allFilters } = await getFilters()

      this.setState({
        allFilters
      })
    } catch (error) {
      this.setState({
        error,
        data: [],
        meta: {}
      })
    }

    log.debug('Fetching filters')
  }

  fetchContact = async () => {
    try {
      const metaInfo = await getSearchMetaData()

      this.setState({
        metaInfo
      })
    } catch (error) {
      this.setState({
        error,
        data: [],
        meta: {}
      })
    }
  }

  handleRequestSearch = (searchValue) => {
    const { history } = this.props

    // retain sort and filters;
    const queryString = encodeSearchPageQueryString({
      sort: (!this.queryParams.sort && searchValue) ? defaultSearchSortValue : this.queryParams.sort,
      filters: this.queryParams.filters,
      keywords: searchValue
    })

    history.push({
      search: queryString
    })
  }

  fetchSearchResults = async () => {
    const {
      loadingResults,
      typeahead,
      allFilters
    } = this.state

    if (!loadingResults || typeahead) {
      this.setState({
        loadingResults: true
      })
    }

    try {
      const params = buildSearchQueryObj({
        keywords: this.searchValue,
        sort: this.sort,
        filters: this.activeFilters,
        offset: this.offset,
        size: this.limit
      }, allFilters)

      const res = await getSearchResults(params)
      const { data, meta } = res.data

      this.setState({
        loadingResults: false,
        data,
        meta: {
          total: Number(meta.count)
        }
      })
    } catch (error) {
      log.error('fetchSearchResults error', error)
      this.setState({
        loadingResults: false,
        error,
        data: [],
        meta: {}
      })
    }
  }

  handleFilterButtonClick = () => {
    this.setState({ isFilterModalOpen: true })
  }

  handleCloseFilterModal = () => {
    this.setState({ isFilterModalOpen: false })
  }

  // user wants to remove all active filters
  handleClearAllFilters = (e) => {
    const { history } = this.props

    // retain sort and keywords value; reset offset and filters
    const queryString = encodeSearchPageQueryString({
      keywords: this.searchValue,
      sort: this.queryParams.sort
    })

    history.push({
      search: queryString
    })
  }

  // the user has chosen to apply temporary filters to active
  handleApplyFiltersClick = (updatedFilters = {}) => {
    const { history } = this.props

    // retain sort and keywords value; reset offset
    const queryString = encodeSearchPageQueryString({
      sort: this.queryParams.sort,
      keywords: this.searchValue,
      filters: updatedFilters
    })

    history.push({
      search: queryString
    })

    // close modal after applying filters
    this.setState({ isFilterModalOpen: false })
  }

  // user clicked to dismiss an active filter from the active filter bar
  // parent is unknown in this context so remove then conduct new search
  handleDismissFilterClick = (filter) => {
    const { history } = this.props
    const newFilters = {}

    Object.keys(this.activeFilters).forEach(parentFilter => {
      newFilters[parentFilter] = this.activeFilters[parentFilter].filter(f => f !== filter)
    })

    // retain sort and keywords value; reset offset
    const queryString = encodeSearchPageQueryString({
      keywords: this.searchValue,
      sort: this.queryParams.sort,
      filters: newFilters
    })

    history.push({
      search: queryString
    })
  }

  handleSortClick = ({ target }) => {
    const { history } = this.props
    const sort = target.getAttribute('data-sort')

    // retain all existing params except sort
    const queryString = encodeSearchPageQueryString({
      ...this.queryParams,
      sort: sort
    })

    history.push({
      search: queryString
    })
  }

  goToPage = (newOffset) => {
    const { history } = this.props

    // retain all existing params except offset
    const queryString = encodeSearchPageQueryString({
      ...this.queryParams,
      offset: newOffset
    })

    history.push({
      search: queryString
    })

    // scroll to top immediately after navigation
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  renderTypeaheadOverlay = ({ isTypeaheadOpen }) => {
    if (!this.overlayRef.current) { return null }

    return createPortal(
      <TypeaheadOverlay active={isTypeaheadOpen} />,
      this.overlayRef.current
    )
  }

  render () {
    const {
      data,
      allFilters,
      isFilterModalOpen,
      loadingResults,
      loadingInitialData,
      popularSearchStats,
      searchResultTags,
      meta: {
        total
      },
      metaInfo,
      error
    } = this.state

    const { history, isMobile } = this.props

    const {
      metaTags,
      ...contactInfo
    } = metaInfo

    if (error) {
      return <ErrorPage error={error} />
    }

    const { handleHeaderOpen, handleHeaderClose } = this.props

    return (
      <div>
        <div className='SearchPage pageAnimation'>
          <GovBanner id='QA_govBanner' />
          <Header
            history={history}
            onOpen={handleHeaderOpen}
            onClose={handleHeaderClose}
          />
          <MetaHead metaTags={metaTags}>
            <title key={uuid()}>{`${pageTitle}${metaOrganizationName}`}</title>
          </MetaHead>
          <SearchFilters
            isOpen={isFilterModalOpen}
            keywords={this.searchValue}
            onRequestClose={this.handleCloseFilterModal}
            onApplyFiltersClick={this.handleApplyFiltersClick}
            initialAllFilters={allFilters}
            initialActiveFilters={this.activeFilters}
          />
          <div className='SearchPage__search-input-container'>
            <Container>
              <Typeahead
              // use key to remount Typeahead, which will set initialSearchValue
              // to the current filter query param whenever the route changes
                key={this.searchValue}
                autoFocus
                label='Search'
                placeholder={isMobile ? searchTypeaheadMobile : searchTypeahead}
                onRequestSearch={this.handleRequestSearch}
                initialSearchValue={this.searchValue}
                renderOverlay={this.renderTypeaheadOverlay}
                event={{ grouping: 'Search', category: 'Search Typeahead' }}
              />
            </Container>
          </div>
          <div className='SearchPage__body' ref={this.overlayRef}>
            {/* Results Header + Filters */}
            <SearchPageFiltersDisplay
              isLoadingInitialData={loadingInitialData}
              isLoading={loadingResults}
              activeFilters={this.activeFilters}
              numOfResults={data.length}
              numOfTotalResults={total}
              sort={this.sort}
              offset={this.offset}
              searchValue={this.searchValue}
              onFilterButtonClick={this.handleFilterButtonClick}
              onSortClick={this.handleSortClick}
              onClearAllFilters={this.handleClearAllFilters}
              onDismissFilterClick={this.handleDismissFilterClick}
            />
            {/* Results Container */}
            {!loadingResults && !isEmpty(data) && (
              <Container className='SearchPage__results-container'>
                {
                  data.map((result, i) => (
                    <SearchPageResult
                      key={i}
                      result={{
                        ...result,
                        subTitle: get(searchResultTags, result.title, null)
                      }}
                    />
                  ))
                }
              </Container>
            )}
            {/* Loading Results Container */}
            {this.isAnythingLoading && (
              <Container className='SearchPage__results-container'>
                {Array(this.limit).fill(true).map((v, i) => (
                  <SearchPageResultLoading key={i} />
                ))}
              </Container>
            )}
            {/* Not Found Container */}
            {this.isNothingLoading && isEmpty(data) && (
              <Container className='SearchPage__no-results-container'>
                <span className='SearchPage__no-results-msg'>Sorry, no matches found.</span>
                <span className='SearchPage__no-results-tips-msg'>Here are some search tips:</span>
                <ul className='SearchPage__no-results-tips'>
                  <li>Make sure all words are spelled correctly.</li>
                  <li>Try one or two keywords for a wider search.</li>
                </ul>
                {!isEmpty(popularSearchStats) && (
                  <div className='SearchPage__no-results-popular-container'>
                    <Text className='SearchPage__no-results-popular-msg' typeFace='05 H5/Black/Regular/L'>Popular search terms</Text>
                    {popularSearchStats.map((text, i) => (
                      <Text
                        className='SearchPage__no-results-popular-item'
                        typeFace='Body/18pt/Black/L'
                        key={i}
                        onClick={() => {
                          history.push({
                            search: encodeSearchPageQueryString({ keywords: text })
                          })
                        }}
                      >{text}
                      </Text>
                    ))}
                  </div>
                )}
              </Container>
            )}
            {/* Results Footer Container */}
            {this.isNothingLoading && !isEmpty(data) && total > this.limit && (
              <Container className='SearchPage__results-footer'>
                <Pagination
                  limit={this.limit}
                  offset={this.offset}
                  total={total}
                  goToPage={this.goToPage}
                />
              </Container>
            )}
            {/* Contact Banner */}
            <ContactBanner
              {...contactInfo}
              subjectLine='General Question'
            />
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

SearchPage.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  handleHeaderOpen: PropTypes.func,
  handleHeaderClose: PropTypes.func
}

export default compose(withBoundary, withDisplayContext)(SearchPage)

export {
  SearchPage
}
