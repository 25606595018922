import config from '../config'

const {
  changelogParam,
  interactiveDatasetParam,
  toolsPath,
  apiDocsParam,
  previewParam
} = config

/**
 * Simple operation to get an array of url params from a url path
 * @param {string} pathname
 */
export function getPathnameParams (pathname) {
  // remove public url path for slug identification
  const subpath = process.env.PUBLIC_URL
    ? pathname.replace(process.env.PUBLIC_URL, '')
    : pathname

  // use portion before query string; remove the first empty string from the .split('/') operation
  return subpath.split('?')[0].split('/').slice(1)
}

/**
 * This is a helper function used to remove portions of a pathname that are managed by the frontend;
 * e.g., if there is a dataset located at /chronic-conditions/chronic-conditions, the frontend creates a
 * dynamic route at /chronic-conditions/chronic-conditions/data.  Since the BE has no knowledge of this
 * /data route, we want to strip out that portion of the pathname when we are identifying what lives
 * at /chronic-conditions/chronic-conditions via the BE /slug endpoint
 * @param {string} pathname
 */
export function getFormattedPathname (pathname) {
  const params = getPathnameParams(pathname)

  // for now there is only one tool detail param, but starting this array in case more are added in the future
  const availableToolDetailParams = ['provider']

  const paramsToRemove = [
    previewParam,
    interactiveDatasetParam,
    changelogParam,
    apiDocsParam,
    ...availableToolDetailParams
  ]

  const filteredParams = paramsToRemove.reduce((updatedParams, paramToRemove) => {
    const index = updatedParams.findIndex(param => param === paramToRemove)

    // Remove preview prefix.
    if (index === 0 && updatedParams[0] === previewParam) {
      return updatedParams.slice(1)
    }

    // if parameter is present, remove it and all parameters after it;
    // make exception if the parameter is the root of the path (e.g., /api-docs)
    return index > 0
      ? updatedParams.slice(0, index)
      : updatedParams
  }, [...params])

  return `/${filteredParams.join('/')}`
}

export const isPreviewUrl = () => {
  return window.location.pathname.startsWith(`/${previewParam}/`)
}

export function getHomePagePath () {
  return process.env.PUBLIC_URL ? `${process.env.PUBLIC_URL}` : '/'
}

export function getSearchPagePath () {
  return process.env.PUBLIC_URL ? `${process.env.PUBLIC_URL}/search` : '/search'
}

export function getToolsPagePath () {
  return process.env.PUBLIC_URL ? `${process.env.PUBLIC_URL}${toolsPath}` : toolsPath
}

/**
 * If useLocation result has a path with query string params in it,
 * Remove those from the path name and put them in location.search instead
 *  * @param {object} location The result of useLocation() call
 */
export function removeQueryStringFromLocationPath (location) {
  const index = location?.pathname?.indexOf('?')
  if (index > 0) {
    location.search = location.search || location.pathname.substring(index)
    location.pathname = location.pathname.substring(0, index)
  }
  return location
}
