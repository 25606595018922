import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SearchFilterCloseIcon from '../../icons/SearchFilterCloseIcon'

import './SearchFiltersBar.scss'

function SearchFiltersBar (props) {
  const {
    activeFilters,
    onDismissFilterClick,
    onClearAllFilters
  } = props

  // flatten activeFilters for render
  const concatFilters = Object.keys(activeFilters).reduce((prev, curr) => {
    return [
      ...prev,
      ...activeFilters[curr]
    ]
  }, [])

  const isActive = !isEmpty(concatFilters)

  return (
    <Container className='SearchFiltersBar'>
      {isActive && (
        <Row className='SearchFiltersBarActive'>
          <Col lg={10} className='SearchFiltersBarActive-filters'>
            {concatFilters.map((filter, i) => (
              <Badge
                key={i}
                pill
                className='SearchFiltersBarActive-filter'
                onClick={() => onDismissFilterClick(filter)}
              >
                <span className='SearchFiltersBarActive-filter button-text'>{filter}</span>
                <SearchFilterCloseIcon />
              </Badge>
            ))}
          </Col>
          <Col className='SearchFiltersBarActive-clear' lg={2}>
            <Button
              className='SearchFiltersBarActive-button'
              variant='link'
              aria-label='Remove keyword filter from search results'
              onClick={onClearAllFilters}
            >
              Clear Filters
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  )
}

SearchFiltersBar.propTypes = {
  activeFilters: PropTypes.object.isRequired,
  onClearAllFilters: PropTypes.func.isRequired,
  onDismissFilterClick: PropTypes.func.isRequired
}

SearchFiltersBar.defaultProps = {
  activeFilters: {}
}

export default SearchFiltersBar
