import axios from 'axios'
import log from '../../log'
import simpleCacheBust from '../../utilities/simpleCacheBust'
import config from '../../config'

const {
  apiSite,
  useCacheBuster
} = config

/**
 * Get information about a provided url/path/slug
 * @param {String} path the slug to lookup
 * @param {Object} [opts] options object
 * @param {*} [opts.cancelToken] axios cancel token
 */
export const getSlug = async (path, {
  cancelToken
} = {}) => {
  try {
    const { data } = await axios({
      operationId: 'getSlug',
      method: 'get',
      baseURL: apiSite,
      url: '/data-api/v1/slug',
      params: {
        path,
        ...(useCacheBuster.getSlug ? {
          cacheBuster: simpleCacheBust()
        } : {})
      },
      cancelToken: cancelToken
    })
    return data
  } catch (error) {
    log.error('getSlug: request error', error)
    throw (error)
  }
}
