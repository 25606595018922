import axios from 'axios'
import { formatCategoryPageData } from '../formatters/browsebycategory'
import log from '../../log'
import config from '../../config'
const {
  apiSite
} = config

/**
 * Get data for homepage
 * @param {*} [opts.cancelToken] axios cancel token
 */
export const getBrowseByCategoryMetadata = async ({ cancelToken } = {}) => {
  try {
    const res = await axios({
      operationId: 'getBrowseByCategoryMetadata',
      method: 'GET',
      baseURL: apiSite,
      jsonAPI: true,
      url: '/jsonapi/node/browse_by_category',
      cancelToken: cancelToken
    })
    return formatCategoryPageData(res.data[0])
  } catch (error) {
    log.error('getBrowseByCategoryMetadata: request error', error)
    throw (error)
  }
}
