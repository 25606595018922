import React, { useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import Container from 'react-bootstrap/Container'
import Typeahead from '../../common/Typeahead/Typeahead'
import siteStore from '../../../stores/siteStore'
import useDisplayContext from '../../../hooks/useDisplayContext'
import config from '../../../config'

import './QuickSearch.scss'

const {
  placeholders: {
    searchTypeahead,
    searchTypeaheadMobile
  }
} = config

const QuickSearch = (props) => {
  const {
    isOpen,
    headerDomNode,
    onRequestClose,
    onRequestSearch,
    govBannerHeight
  } = props

  const [top, setTop] = useState(0)
  const { showAlert, alertHeight } = siteStore.useState(({ showAlert, alertHeight }) => ({ showAlert, alertHeight }))
  const { isMobile } = useDisplayContext()

  useLayoutEffect(() => {
    if (headerDomNode && isOpen) {
      setTop(headerDomNode.getBoundingClientRect().height + govBannerHeight)
    }
    // need alert state props as dep's to properly stick to header when alerts show/hide
  }, [showAlert, alertHeight, headerDomNode, isMobile, isOpen, govBannerHeight])

  useLayoutEffect(() => {
    // need to dynamically modify modal backdrop style after opening to stick to bottom of header
    if (isOpen) {
      const backdropEl = document.querySelector('.QuickSearchModal__backdrop')
      backdropEl.style.top = `${top}px`
    }
  }, [isOpen, top])

  return (
    <Modal
      className='QuickSearchModal'
      dialogClassName='QuickSearchModal'
      backdropClassName='QuickSearchModal__backdrop'
      show={isOpen}
      onHide={onRequestClose}
      style={{ top: `${top}px` }}
    >
      <div className='QuickSearch'>
        <Container>
          <Typeahead
            className='QuickSearch__typeahead'
            autoFocus
            label='Search'
            initialSearchValue=''
            placeholder={isMobile ? searchTypeaheadMobile : searchTypeahead}
            onRequestSearch={onRequestSearch}
            event={{ grouping: 'Search', category: 'Search Typeahead' }}
          />
        </Container>
      </div>
    </Modal>
  )
}

QuickSearch.propTypes = {
  isOpen: PropTypes.bool,
  headerDomNode: PropTypes.object,
  onRequestClose: PropTypes.func.isRequired,
  onRequestSearch: PropTypes.func.isRequired,
  govBannerHeight: PropTypes.number
}

export default QuickSearch
