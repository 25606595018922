import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import LinkHandler from '../../common/LinkHandler/LinkHandler'
import Text from '../../common/Text/Text'
import { truncateWithElipses } from '../../../utilities/displayUtilities'

import './BasicSearchResult.scss'

const BasicSearchResult = (props) => {
  const {
    className,
    description,
    imgSrc,
    imgAlt,
    btnText,
    slug,
    title,
    type
  } = props

  return (
    <div className={cx('SearchResult animated fadeIn', className)}>
      {/* Img */}
      {imgSrc && <div className='SearchResult__img-container'><img src={imgSrc} alt={imgAlt} /></div>}
      {/* Info */}
      <div className='SearchResult__info-container'>
        {type && <p className='SearchResult__info-container__sub-title'>{type}</p>}
        <LinkHandler
          className='SearchResult__info-container__title-link'
          href={slug}
        >
          <Text
            domElement='h2'
            className='SearchResult__info-container__title'
            typeFace='05 H5/Black/Regular/L'
          >
            {title}
          </Text>
        </LinkHandler>
        <p className='SearchResult__desc'>{truncateWithElipses(description, 250)}</p>
      </div>
      {/* Link Button */}
      <div className='SearchResult__read-more-container'>
        <LinkHandler
          className='SearchResult__read-more-btn'
          href={slug}
          label={btnText}
          variant='primary'
        />
      </div>
    </div>
  )
}

BasicSearchResult.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string,
  btnText: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string
}

export default BasicSearchResult
