/*
 * High level function to convert raw JSON:API homepage data to POJO
 */
import { formatMetaTags } from './global'

export const formatCategoryPageData = (data) => {
  const {
    title = '',
    fieldCategoryPageHeader = '',
    metatagNormalized = []
  } = data
  return {
    pageTitle: title,
    pageHeader: fieldCategoryPageHeader,
    metaTags: formatMetaTags(metatagNormalized)
  }
}
