import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import { ReactComponent as XIcon } from '../../../assets/fontawesome/custom/x-icon.svg'
import { ReactComponent as StarIcon } from '../../../assets/fontawesome/custom/stars.svg'
import { getWhatsNewSettings, getWhatsNewEntries } from '../../../services/apiDomains/whatsNew'
import RichTextField from '../../common/RichTextField/RichTextField'
import dayjs from 'dayjs'
import { trackWhatsNewEvent } from '../../../utilities/analyticsEventHelpers'

import './WhatsNewSideDrawer.scss'

export const WhatsNewSideDrawer = (props) => {
  const { onHide, show } = props

  const defaultFilters = [
    { display: 'All', value: 'All', selected: true },
    { display: 'Data Updates', value: 'Data Updates' },
    { display: 'New Products', value: 'New Products' },
    { display: 'Enhancements', value: 'Enhancements' }
  ]

  const [whatsNewSettings, setWhatsNewSettings] = useState(null)
  const [whatsNewList, setWhatsNewList] = useState(null)
  const [filters, setFilters] = useState(defaultFilters)

  useEffect(() => {
    const fetchData = async () => {
      if (!whatsNewSettings) {
        const newSettings = await getWhatsNewSettings()
        setWhatsNewSettings(newSettings)
        const whatsNewListData = await getWhatsNewEntries(newSettings?.field_display_update_count)
        setWhatsNewList(whatsNewListData)
      }
    }
    fetchData()
  }, [])

  const selectFilter = (selectedFilter) => {
    const updatedFilters = filters.map((filter) => {
      return {
        ...filter,
        selected: filter.value === selectedFilter.value
      }
    })
    setFilters(updatedFilters)

    trackWhatsNewEvent('Side Drawer', selectedFilter.display, window.utag)
  }
  const getSelectedFilter = () => {
    return filters?.find(filter => filter.selected)
  }

  const renderLink = (entry) => {
    const linkHandler = (event, url, newTab) => {
      event.preventDefault()

      trackWhatsNewEvent('Side Drawer', url, window.utag)

      if (newTab) {
        window.open(url, '_blank')
      } else {
        window.location.href = url
      }
    }

    switch (entry?.fieldWhatsnewUpdateOption) {
      case 'internal_link':
        return (entry?.fieldRefWhatsnewInternalLink?.path?.alias &&
          <a onClick={(e) => linkHandler(e, entry?.fieldRefWhatsnewInternalLink?.path?.alias, false)} target='_self'>{entry?.fieldRefWhatsnewLinkLabel?.name}</a>)

      case 'external_link':
        return (entry?.fieldWhatsnewExternalLink?.uri &&
          <a onClick={(e) => linkHandler(e, entry?.fieldWhatsnewExternalLink?.uri, true)} target='_blank' rel='noreferrer'>{entry?.fieldRefWhatsnewLinkLabel?.name}</a>)

      default:
        return <></>
    }
  }
  const selectedFilter = getSelectedFilter()
  return (
    <Modal
      className='WhatsNewSideDrawer' size='xl'
      show={show}
      onHide={onHide}

    >
      <div className='WhatsNewtitle'>
        <div className='WhatsNewtitle__left'>
          <div><StarIcon /></div>
          <div>What's New</div>
        </div>
        <div className='WhatsNewtitle__right'>
          <button onClick={onHide}>
            <XIcon />
          </button>
        </div>
      </div>

      <div className='WhatsNewBody'>
        <div className='WhatsNewBody__sortGroup'>
          {filters?.map((filter) => (
            <div
              key={filter?.value}
              className={`WhatsNewBody__sortGroup__ContentTypeTab ${filter?.selected ? 'selected' : ''}`}
              onClick={() => { selectFilter(filter) }}
            >
              {filter?.display}
            </div>
          ))}
        </div>
        {whatsNewList?.map((entry) => (
          (selectedFilter?.value === 'All' || selectedFilter?.value === entry?.fieldRefWhatsnewFilters?.name) &&
            <div key={entry?.id} className='WhatsNewBody__entry'>

              {entry?.fieldWhatsnewSpotlight && (
                <>
                  {/* <img src={whatsNewSettings?.spotLightUrl} alt='Spotlight Image' /> */}
                  <div className='SpotLightTitle'>SPOTLIGHT</div>
                </>
              )}

              <div className='WhatsNewBody__entry__content'>
                <div className='WhatsNewBody__entry__content__type'>{entry?.fieldRefWhatsnewUpdateType?.name}</div>
                <div className='WhatsNewBody__entry__content__section'>
                  <div className='WhatsNewBody__entry__content__title'>
                    {entry?.title}
                  </div>
                  <div className='WhatsNewBody__entry__content__body'>
                    <RichTextField content={entry?.fieldWhatsnewDescription?.value} />
                  </div>
                </div>
              </div>
              <div className='WhatsNewBody__entry__date'>{dayjs(entry?.fieldWhatsnewDate).format('MMMM D, YYYY')}</div>
              <div className='WhatsNewBody__entry__link'>{renderLink(entry)}</div>
            </div>
        ))}
      </div>

    </Modal>
  )
}

WhatsNewSideDrawer.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.func
}
export default WhatsNewSideDrawer
