import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ModalCloseButton from '../../common/ModalCloseButton/ModalCloseButton'
import { numberWithCommas } from '../../../utilities/searchPageHelpers'

import './NpiListModal.scss'

const NpiListModal = ({ show, onHide, name, npis }) => {
  return (
    <Modal
      className='NpiListModal'
      size='lg'
      show={show}
      onHide={onHide}
      centered
      scrollable
    >
      <Modal.Header>
        <Modal.Title>
          <div className='eyebrow'>
            All NPIs
          </div>
          <div className='title'>{name}</div>
        </Modal.Title>
        <ModalCloseButton onClick={onHide} />
      </Modal.Header>
      <Modal.Body>
        <div className='npi-list'>
          {npis.map((npi, idx) => <div key={idx}>{npi}</div>)}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className='done-btn' onClick={onHide}>Done</Button>
      </Modal.Footer>
    </Modal>
  )
}

NpiListModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  npis: PropTypes.array.isRequired
}

export const NpiTextDisplay = (props) => {
  const {
    className,
    name,
    npis,
    currentNpi,
    as: DomElement
  } = props

  const [isOpen, setIsOpen] = useState(false)

  function handleClick (e) {
    setIsOpen(true)
  }

  return (
    <DomElement className={cx('NpiTextDisplay', className)}>
      {`${currentNpi}${npis.length > 1 ? ',' : ''} `}
      {npis.length > 1 && (
        <span onClick={e => handleClick(e)}>{`+ ${numberWithCommas(npis.length - 1)} more`}</span>
      )}
      <NpiListModal
        show={isOpen}
        onHide={() => setIsOpen(false)}
        name={name}
        npis={npis}
      />
    </DomElement>
  )
}

NpiTextDisplay.propTypes = {
  className: PropTypes.string,
  as: PropTypes.string, // dom element
  name: PropTypes.string,
  npis: PropTypes.arrayOf(PropTypes.string),
  currentNpi: PropTypes.string
}

NpiTextDisplay.defaultProps = {
  as: 'p'
}

export default NpiListModal
