import React from 'react'
import PropTypes from 'prop-types'
import times from 'lodash/times'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Text from '../Text/Text'
import ModalCloseButton from '../ModalCloseButton/ModalCloseButton'
import RichTextField from '../../common/RichTextField/RichTextField'
import cx from 'classnames'

import numOfUserAgreementLoadingRows from './UserAgreementModal.scss'

const TOP_BORDER_CLASS = 'UserAgreementModal__content-body__top'
const BOTTOM_BORDER_CLASS = 'UserAgreementModal__content-body__bottom'

class UserAgreementModal extends React.PureComponent {
  state = {
    containerRef: null,
    topBodyRef: null,
    bottomBodyRef: null,
    showTopBorder: false,
    showBottomBorder: false,
    observer: null
  }

  componentDidMount () {
    this.setupObserver()
  }

  componentDidUpdate (prevProps, prevState) {
    const { topBodyRef: oldTop, bottomBodyRef: oldBottom } = prevState
    const { topBodyRef, bottomBodyRef } = this.state

    if (topBodyRef !== oldTop && bottomBodyRef !== oldBottom) {
      this.setupObserver()
    }
  }

  componentWillUnmount () {
    const { observer } = this.state

    if (observer) {
      observer.disconnect()
    }
  }

  handleAgreeClick = () => {
    const {
      onHide,
      localStorageKey
    } = this.props

    acceptUserAgreement(localStorageKey)

    onHide({ hasAgreed: true })
  }

  handleCloseClick = () => {
    const {
      onHide
    } = this.props

    onHide({ hasAgreed: false })
  }

  setContainerRef = (el) => {
    if (el) this.setState({ containerRef: el })
  }

  setBottomBodyRef = (el) => {
    if (el) this.setState({ bottomBodyRef: el })
  }

  setTopBodyRef = (el) => {
    if (el) this.setState({ topBodyRef: el })
  }

  // attach listeners for top & bottom of modal body to dictate styling
  setupObserver () {
    const { containerRef, bottomBodyRef, topBodyRef } = this.state

    if (containerRef && topBodyRef && bottomBodyRef) {
      const observerOptions = {
        root: containerRef,
        rootMargin: '0px',
        threshold: [0, 1]
      }
      const observer = new window.IntersectionObserver(this.reportObserver, observerOptions)
      observer.observe(topBodyRef)
      observer.observe(bottomBodyRef)

      this.setState({ observer })
    }
  }

  /**
  * intersection observer handler
  *   modify state (style builds from state) when an intersection occurs, if necessary
  */
  reportObserver = (entries) => {
    entries.forEach(entry => {
      if (entry.target.className === TOP_BORDER_CLASS) {
        // if no scrolling has occurred, top is intersecting
        this.setState({
          showTopBorder: !entry.isIntersecting
        })
      }

      // only show bottom when content is longer than container & bottom border div is not intersecting
      if (entry.target.className === BOTTOM_BORDER_CLASS) {
        const { boundingClientRect, rootBounds } = entry
        const isContentVerticalOverflow = boundingClientRect.bottom > rootBounds.height
        const displayBorder = isContentVerticalOverflow && !entry.isIntersecting

        this.setState({
          showBottomBorder: displayBorder
        })
      }
    })
  }

  renderTextBody = () => {
    const { body } = this.props

    return (
      <div className='UserAgreementModal__content-body' ref={this.setBodyRef}>
        <div className={TOP_BORDER_CLASS} ref={this.setTopBodyRef} />
        <RichTextField className='UserAgreementModal__content-body__inner' content={body} />
        <div className={BOTTOM_BORDER_CLASS} ref={this.setBottomBodyRef} />
      </div>
    )
  }

  render () {
    const {
      show,
      isLoading,
      title,
      onHide
    } = this.props

    const { showTopBorder, showBottomBorder } = this.state

    return (
      <Modal
        className='UserAgreementModal'
        size='lg'
        show={show}
        onHide={this.handleCloseClick}
        centered
      >
        <Modal.Header className='UserAgreementModal__modal-header'>
          {isLoading && <div className='skeleton-box' />}
          {!isLoading && <Modal.Title><Text typeFace='02 H2/Black/L'>{title}</Text></Modal.Title>}
          <ModalCloseButton onClick={this.handleCloseClick} />
        </Modal.Header>

        <Modal.Body
          className={cx({
            'UserAgreementModal__modal-body': true,
            hasTopBorderOnly: !showBottomBorder && showTopBorder,
            hasBottomBorderOnly: !showTopBorder && showBottomBorder,
            hasBothBorders: showTopBorder && showBottomBorder
          })}
        >
          <div className='UserAgreementModal__content' ref={this.setContainerRef}>
            {isLoading
              ? <BodyLoading />
              : this.renderTextBody()}
          </div>
        </Modal.Body>

        <Modal.Footer className='UserAgreementModal__modal-footer'>
          <Button className='goBack' variant='link' onClick={onHide}>Go Back</Button>
          <Button disabled={isLoading} className='agree' variant='primary' onClick={this.handleAgreeClick}>Agree</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const BodyLoading = () => {
  return (
    <>
      {times(numOfUserAgreementLoadingRows.numOfUserAgreementLoadingRows, (num) => {
        return <div key={num} className='skeleton-box' />
      })}
    </>
  )
}

const hasUserAcceptedAgreement = (localStorageKey) => {
  return !!(window.sessionStorage.getItem(localStorageKey))
}

const acceptUserAgreement = (localStorageKey) => {
  window.sessionStorage.setItem(localStorageKey, 'accepted')
}

UserAgreementModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  localStorageKey: PropTypes.string,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  body: PropTypes.string // markup
}

UserAgreementModal.defaultProps = {
  title: '',
  body: '<div></div>'
}

export default UserAgreementModal

export { hasUserAcceptedAgreement }
