import axios from 'axios'
import camelCase from 'lodash/camelCase'
import get from 'lodash/get'
import find from 'lodash/find'
import log from '../../log'
import config from '../../config'
import simpleCacheBust from '../../utilities/simpleCacheBust'
import { buildInteractiveDataQueryString } from '../../utilities/datasetHelpers'
import { isCancellationError } from '../api'

const {
  apiSite,
  useCacheBuster
} = config

export const getInteractiveDataApiPath = (uuid) => {
  return `/data-api/v1/dataset/${uuid}/data-viewer`
}

// global data object for caching allDatasets; getDatasetsWithMetaData is heavy for BE
let preLoadedAllDatasets = null
// variable storing the single request promise to avoid multiple components triggering network requests
let allDatasetsResponsePromise = null

/**
 * Get all dataset that are assocated with a topic AND descendants of the topic;
 * dependent on getDatasetsWithMetaData
 * @param {string|string[]} topicId
 */
export async function getDatasetsAssociatedWithTopic (topicId) {
  const datasets = await getDatasetsWithMetaData()
  const datasetsOfTopic = datasets.filter(dataset => {
    return Array.isArray(topicId)
      ? topicId.includes(dataset.topic_uuid)
      : dataset.topic_uuid === topicId
  })
  return datasetsOfTopic
}

/**
 * Get all dataset that are assocated with a subtopic
 * dependent on getDatasetsWithMetaData
 * @param {string|string[]} subtopicId
 */
export async function getDatasetsAssociatedWithSubtopic (subtopicId) {
  const datasets = await getDatasetsWithMetaData()
  const datasetsOfTopic = datasets.filter(dataset => {
    return Array.isArray(subtopicId)
      ? subtopicId.includes(dataset.subtopic_uuid)
      : dataset.subtopic_uuid === subtopicId
  })
  return datasetsOfTopic
}

/**
 *
 * @param {object} params
 */
export const getDatasetsWithMetaData = async () => {
  try {
  // return preloaded data if available
    if (preLoadedAllDatasets) {
      return preLoadedAllDatasets
    }

    if (!allDatasetsResponsePromise) {
    // set the in-progress request if this is the first request
      allDatasetsResponsePromise = axios({
        operationId: 'getDatasetsWithMetaData',
        method: 'get',
        baseURL: apiSite,
        url: '/data-api/v1/dataset-type'
      })
    }

    return allDatasetsResponsePromise
      .then(response => {
        const datasets = get(response, 'data.data', [])
        preLoadedAllDatasets = datasets
        return datasets
      })
  } catch (error) {
    log.error('getDatasetsWithMetaData: request error', error)
    throw (error)
  }
}

/*
* Get a dataset type taxonomy's related user agreement
* @param {String} datasetTypeUuid - dataset type uuid
* @param {Object} [opts] options object
* @param {*} [opts.cancelToken] axios cancel token
*/
export const getDatasetUserAgreement = async (datasetTypeUuid, {
  cancelToken
} = {}) => {
  try {
    const res = await axios({
      operationId: 'getDatasetUserAgreement',
      jsonAPI: true,
      method: 'get',
      baseURL: apiSite,
      url: `/jsonapi/taxonomy_term/dataset/${datasetTypeUuid}?include=field_ref_license_agreement`,
      params: {
        ...(useCacheBuster.getDatasetUserAgreement ? {
          cacheBuster: simpleCacheBust()
        } : {})
      },
      cancelToken: cancelToken
    })
    return get(res, 'data.fieldRefLicenseAgreement', null)
  } catch (error) {
    log.error('getDatasetUserAgreement: request error', error)
    throw (error)
  }
}

export const getDatasetById = (uuid) => {
  const moduleFields = 'id,title,machine_name,field_module_config_json,field_data_source_json,field_pg_data_source'

  return new Promise((resolve, reject) => {
    axios(
      {
        operationId: 'getDatasetById',
        jsonAPI: true,
        method: 'GET',
        baseURL: apiSite,
        url: `/jsonapi/node/dataset/${uuid}`,
        params: {
          'fields[dynamic_module--map]': moduleFields,
          'fields[dynamic_module--bar_graph]': moduleFields,
          'fields[dynamic_module--line_graph]': moduleFields,
          'fields[dynamic_module--number_callout]': moduleFields,
          include: [
            'field_pg_data_callouts.field_ref_number_callout',
            'field_pg_data_callouts.field_pg_data_callout_wrapper.field_ref_dynamic_module',
            'field_pg_data_callouts.field_pg_data_callout_wrapper.field_ref_dynamic_module.field_pg_data_source.paragraph_type',
            'field_pg_data_callouts.field_pg_data_callout_wrapper.field_ref_dynamic_module.field_pg_data_source.field_pg_data_source',
            'field_pg_data_callouts.field_pg_data_callout_wrapper.field_ref_dynamic_module.field_pg_data_source.field_pg_data_source.paragraph_type',
            'field_para_faq',
            'field_dataset_type.field_ref_data_source',
            'field_dataset_type.field_ref_nav_topic.parent',
            'field_dataset_type.field_ref_license_agreement',
            'field_dataset_type.field_subscription_service',
            'field_dataset_type.field_ref_tool_callout.field_internal_page',
            'field_dataset_type.field_ref_tool_callout.field_internal_page.field_ref_lookup_tool_type',
            'field_dataset_type.field_ref_tool_callout.field_internal_page.field_ref_background_image',
            'field_dataset_type.field_ref_tool_callout.field_internal_page.field_ref_background_image.field_callout_image',
            'field_dataset_type.field_ref_tool_callout.field_internal_page.field_ref_background_image.field_mobile_image',
            'field_dataset_type.field_ref_nav_topic.parent.field_ref_navigational_category',
            'field_dataset_type.field_ref_nav_topic.field_ref_navigational_category',
            'field_ref_visualization.field_ref_dataset_vis_component.field_visualization_image.field_media_image',
            'field_ref_visualization.field_ref_dataset_vis_component.field_additional_visualization.field_ancillary_file.field_media_file',
            'field_ref_visualization.field_ref_dataset_vis_component.field_additional_visualization.field_ref_license_agreement',
            'field_ref_visualization.field_pg_dynamic_data_vis_comp.field_ref_dynamic_module',
            'field_ref_visualization.field_pg_dynamic_data_vis_comp.field_ref_dynamic_module.field_pg_data_source.paragraph_type',
            'field_ref_visualization.field_pg_dynamic_data_vis_comp.field_ref_dynamic_module.field_pg_data_source.field_pg_data_source',
            'field_ref_visualization.field_pg_dynamic_data_vis_comp.field_ref_dynamic_module.field_pg_data_source.field_pg_data_source.paragraph_type',
            'field_ref_visualization.field_pg_dynamic_data_vis_comp.field_ref_dynamic_module.field_pg_data_source.field_pg_data_source.field_ref_look_up_tool',
            'field_ref_visualization.field_pg_dynamic_data_vis_comp.field_ref_dynamic_module.field_pg_data_source.field_pg_data_source.field_ref_dynamic_module',
            'field_ref_visualization.field_pg_dynamic_data_vis_comp.field_ref_dynamic_module.field_pg_data_source.field_pg_data_source.field_ref_dataset_type',
            'field_ref_visualization.field_pg_dynamic_data_vis_comp.field_ref_dynamic_module.field_pg_data_source.field_pg_data_source.field_ref_dataset_version',
            'field_ref_visualization.field_pg_dynamic_data_vis_comp.field_ref_dynamic_module.field_pg_data_source.field_pg_data_source.field_ref_media_interactive',
            'field_ref_contact_email',
            'field_ref_geography',
            'field_ref_programs',
            'field_ref_provider_types',
            'field_ref_topics',
            'field_latest_change_item'
          ].join(','),
          ...(useCacheBuster.getDatasetById ? {
            cacheBuster: simpleCacheBust()
          } : {})
        }
      }
    )
      .then(({ data, rawData }) => {
        log.debug('getDatasetById before resolve', data)
        resolve([data, rawData.included])
      })
      .catch(error => {
        log.error('getDatasetById: request error ', error)
        reject(error)
      })
  })
}

export const getDownloadResources = (datasetTypeUuid) => {
  return new Promise((resolve, reject) => {
    axios(
      {
        operationId: 'getDownloadResources',
        method: 'GET',
        baseURL: apiSite,
        url: `/data-api/v1/dataset-type/${datasetTypeUuid}/resources`,
        params: {
          ...(useCacheBuster.getDownloadResources ? {
            cacheBuster: simpleCacheBust()
          } : {})
        }
      }
    )
      .then(response => {
        const resources = response.data.data.map(resource => {
          return Object.keys(resource).reduce((obj, resourceKey) => {
            obj[camelCase(resourceKey)] = resource[resourceKey]
            return obj
          }, {})
        })

        resolve(resources)
      })
      .catch(err => {
        log.error('getDownloadResources: request error ', err)
        reject(err)
      })
  })
}

export const getDatasetVersionResources = (datasetVersionUuid) => {
  return new Promise((resolve, reject) => {
    axios(
      {
        operationId: 'getDatasetVersionResources',
        method: 'GET',
        baseURL: apiSite,
        url: `/data-api/v1/dataset/${datasetVersionUuid}/resources`,
        params: {
          ...(useCacheBuster.getDatasetVersionResources ? {
            cacheBuster: simpleCacheBust()
          } : {})
        }
      }
    )
      .then(response => {
        const resources = response.data.data.map(resource => {
          return Object.keys(resource).reduce((obj, resourceKey) => {
            obj[camelCase(resourceKey)] = resource[resourceKey]
            return obj
          }, {})
        })

        resolve(resources)
      })
      .catch(err => {
        log.error('getDatasetVersionResources: request error ', err)
        reject(err)
      })
  })
}

export const getDatasetVersions = (datasetTypeName) => {
  return new Promise((resolve, reject) => {
    axios(
      {
        operationId: 'getDatasetVersions',
        method: 'GET',
        baseURL: apiSite,
        jsonAPI: true,
        url: '/jsonapi/node/dataset',
        params: {
          include: 'field_dataset_type,field_ref_primary_data_file',
          'fields[node--dataset]': 'field_dataset_version,field_ref_primary_data_file,field_last_updated_date,field_re_release_select,field_re_release_reference,field_re_release_version',
          'filter[field_dataset_type.name]': datasetTypeName,
          sort: '-field_dataset_version,-field_re_release_version',
          ...(useCacheBuster.getDatasetVersions ? {
            cacheBuster: simpleCacheBust()
          } : {})
        }
      }
    )
      .then(response => {
        const versions = response.data || []
        const versionsWithTypes = versions.map((version) => {
          if (version.fieldRefPrimaryDataFile) {
            const rawType = get(
              find(get(response, 'rawData.data', []), { id: version.id }),
              'relationships.field_ref_primary_data_file.data.type'
            )
            if (rawType) {
              version.fieldRefPrimaryDataFile.type = rawType
              version.fieldRefPrimaryDataFile.isStatic = rawType.indexOf('_static') > -1
            }
            version.fieldDatasetVersionSuffix = ''
            if (version.fieldReReleaseSelect === 'off-cycle') {
              version.fieldDatasetVersionSuffix = ' oc' + version.fieldReReleaseVersion
            } else if (version.fieldReReleaseSelect === 'amended') {
              version.fieldDatasetVersionSuffix = ' a' + version.fieldReReleaseVersion
            }
            version.fieldDatasetVersionCombined = version?.fieldDatasetVersion + version?.fieldDatasetVersionSuffix
          }
          return version
        })
        resolve(versionsWithTypes)
      })
      .catch((error) => {
        log.error('getDatasetVersions: request error ', error)
        reject(error)
      })
  })
}

export const getDatasetVersionsPages = (datasetTypeName, url = false, versions = []) => {
  const initialRequest = {
    operationId: 'getDatasetVersions',
    method: 'GET',
    baseURL: apiSite,
    jsonAPI: true,
    url: '/jsonapi/node/dataset',
    params: {
      include: 'field_dataset_type,field_ref_primary_data_file',
      'fields[node--dataset]': 'field_dataset_version,field_ref_primary_data_file,field_last_updated_date,field_re_release_select,field_re_release_reference,field_re_release_version',
      'filter[field_dataset_type.name]': datasetTypeName,
      sort: '-field_dataset_version,-field_re_release_version',
      ...(useCacheBuster.getDatasetVersions ? {
        cacheBuster: simpleCacheBust()
      } : {})
    }
  }
  const paginatedRequest = {
    operationId: 'getDatasetVersionsPages',
    method: 'GET',
    jsonAPI: true,
    url: url
  }
  return new Promise((resolve, reject) => {
    axios(url ? paginatedRequest : initialRequest)
      .then(response => {
        const versions = response.data || []
        const next = get(response, 'rawData.links.next.href', false)
        const versionsWithTypes = versions.map((version) => {
          if (version.fieldRefPrimaryDataFile) {
            const rawType = get(
              find(get(response, 'rawData.data', []), { id: version.id }),
              'relationships.field_ref_primary_data_file.data.type'
            )
            if (rawType) {
              version.fieldRefPrimaryDataFile.type = rawType
              version.fieldRefPrimaryDataFile.isStatic = rawType.indexOf('_static') > -1
            }
            version.fieldDatasetVersionSuffix = ''
            if (version.fieldReReleaseSelect === 'off-cycle') {
              version.fieldDatasetVersionSuffix = ' oc' + version.fieldReReleaseVersion
            } else if (version.fieldReReleaseSelect === 'amended') {
              version.fieldDatasetVersionSuffix = ' a' + version.fieldReReleaseVersion
            }
            version.fieldDatasetVersionCombined = version?.fieldDatasetVersion + version?.fieldDatasetVersionSuffix
          }
          return version
        })
        resolve({ versionsWithTypes, next })
      })
      .catch((error) => {
        log.error('getDatasetVersions: request error ', error)
        reject(error)
      })
  })
}

// export const getInteractiveDataApiPath = (uuid) => axiosPayloadGenerator.getInteractiveDataApiPath(uuid)

export const getInteractiveData = async (uuid, options = {}, cancelToken) => {
  // build queryString manually vs axios to overcome encoding issues
  const queryString = buildInteractiveDataQueryString(options)
  return new Promise((resolve, reject) => {
    axios({
      operationId: 'getInteractiveData',
      method: 'GET',
      baseURL: apiSite,
      url: `${getInteractiveDataApiPath(uuid)}${queryString}`,
      params: {
        ...(useCacheBuster.getInteractiveData ? { cacheBuster: simpleCacheBust() } : {})
      },
      cancelToken
    })
      .then(response => {
        resolve(response.data)
      })
      .catch((error) => {
        if (!isCancellationError(error)) {
          log.error('getInteractiveData: request error ', error)
        }
        reject(error)
      })
  })
}

export const getInteractiveDataStats = async (uuid, options = {}, cancelToken) => {
  // build queryString manually vs axios to overcome encoding issues
  const queryString = buildInteractiveDataQueryString(options)
  return new Promise((resolve, reject) => {
    axios(
      {
        operationId: 'getInteractiveDataStats',
        method: 'GET',
        baseURL: apiSite,
        url: `${getInteractiveDataApiPath(uuid)}/stats${queryString}`,
        params: {
          ...(useCacheBuster.getInteractiveDataStats ? { cacheBuster: simpleCacheBust() } : {})
        },
        cancelToken
      }
    )
      .then(response => {
        resolve(response.data)
      })
      .catch((error) => {
        if (!isCancellationError(error)) {
          log.error('getInteractiveDataStats: request error ', error)
        }
        reject(error)
      })
  })
}

export const logDatasetView = async (datasetTypeUuid) => {
  return new Promise((resolve, reject) => {
    axios(
      {
        operationId: 'logDatasetView',
        method: 'GET',
        baseURL: apiSite,
        url: `/data-api/v1/tracker/dataset/dataset_views/${datasetTypeUuid}`
      }
    )
      .then(response => {
        resolve(response.data)
      })
      .catch((error) => {
        log.error('logDatasetView: request error ', error)
        reject(error)
      })
  })
}

export const logDatasetDownload = async (datasetTypeUuid) => {
  return new Promise((resolve, reject) => {
    axios(
      {
        operationId: 'logDatasetDownload',
        method: 'GET',
        baseURL: apiSite,
        url: `/data-api/v1/tracker/dataset/dataset_downloads/${datasetTypeUuid}`
      }
    )
      .then(response => {
        resolve(response.data)
      })
      .catch((error) => {
        log.error('logDatasetDownload: request error ', error)
        reject(error)
      })
  })
}

export const downloadRequest = async (datasets, files) => {
  if (!files || !datasets) {
    console.error('Received empty datasets or files, precenting call to download files.')
    return
  }

  return new Promise((resolve, reject) => {
    axios(
      {
        operationId: 'downloadRequest',
        method: 'GET',
        baseURL: apiSite,
        url: `/data-api/v1/download-request?datasets=${datasets}&files=${files}`
      }
    )
      .then(response => {
        resolve(response.data)
      })
      .catch((error) => {
        log.error('downloadRequest: request error ', error)
        reject(error)
      })
  })
}

export const logDatasetDownloadError = async (datasetName, errorIdentifier, datasets, files, selectedOption, totalFileSize) => {
  let trimmedUrl = `/data-api/v1/download-request-error/${datasetName}/${errorIdentifier}?selectedOption=${selectedOption}${totalFileSize ? '&totalFileSize=' + totalFileSize : ''}&datasets=${datasets}&files=${files}`
  trimmedUrl = trimmedUrl.slice(0, 8000)

  return new Promise((resolve, reject) => {
    axios(
      {
        operationId: 'logDatasetDownload',
        method: 'GET',
        baseURL: apiSite,
        url: trimmedUrl
      }
    )
      .then(response => {
        resolve(response.data)
      })
      // Purposefully supress this guarenteed 404
      .catch(() => {})
  })
}
