import { getLookupToolData } from '../../services/api'
import { convertDataToObjectArray } from '../../services/formatters/lookupTool'

// column names
export const FIRST_NAME_COLUMN = 'Rndrng_Prvdr_First_Name'
export const LAST_NAME_COLUMN = 'Rndrng_Prvdr_Last_Org_Name'
export const STATE_COLUMN = 'Rndrng_Prvdr_State_Abrvtn'
export const NPI_COLUMN = 'Rndrng_NPI'
export const CITY_COLUMN = 'Rndrng_Prvdr_City'
export const ZIP_COLUMN = 'Rndrng_Prvdr_Zip5'
export const PROVIDER_TYPE_COLUMN = 'Rndrng_Prvdr_Type'
export const ENTITY_CODE_COLUMN = 'Rndrng_Prvdr_Ent_Cd'
export const STREET_LINE1_COLUMN = 'Rndrng_Prvdr_St1'
export const STREET_LINE2_COLUMN = 'Rndrng_Prvdr_St2'

// input name values
export const PHYSICIAN_FIRST_NAME = 'firstName'
export const PHYSICIAN_LAST_NAME = 'lastName'
export const PHYSICIAN_STATE = 'state'
export const PHYSICIAN_NPI = 'npi'
export const PHYSICIAN_CITY = 'city'

export const PHYSICIAN_SUBTABLE = 'lookup'

const formSearchConfig = {
  [PHYSICIAN_FIRST_NAME]: {
    filterName: PHYSICIAN_FIRST_NAME,
    operator: 'CONTAINS',
    column: FIRST_NAME_COLUMN
  },
  [PHYSICIAN_LAST_NAME]: {
    filterName: PHYSICIAN_LAST_NAME,
    operator: 'CONTAINS',
    column: LAST_NAME_COLUMN
  },
  [PHYSICIAN_STATE]: {
    filterName: PHYSICIAN_STATE,
    operator: '=',
    column: STATE_COLUMN
  },
  [PHYSICIAN_CITY]: {
    filterName: PHYSICIAN_CITY,
    operator: '=',
    column: CITY_COLUMN
  },
  [PHYSICIAN_NPI]: {
    filterName: PHYSICIAN_NPI,
    operator: '=',
    column: NPI_COLUMN
  }
}

const typeaheadSearchConfig = {
  [PHYSICIAN_LAST_NAME]: {
    ...formSearchConfig[PHYSICIAN_LAST_NAME],
    operator: 'STARTS_WITH'
  }
}

/**
 *
 * @param {object} data
 * @param {object} data.formValues
 * @param {object} data.fitlerConfig
 */
export function getToolSearchApiParams (data) {
  const {
    formValues,
    filterConfig = formSearchConfig
  } = data

  const filter = {
    conditions: []
  }

  Object.keys(formValues).forEach(formKey => {
    const baseCondition = { ...filterConfig[formKey] }
    filter.conditions.push({
      ...baseCondition,
      value: formValues[formKey]
    })
  })

  return {
    filter,
    sort: [LAST_NAME_COLUMN, FIRST_NAME_COLUMN, STATE_COLUMN],
    columns: [
      FIRST_NAME_COLUMN,
      LAST_NAME_COLUMN,
      STATE_COLUMN,
      NPI_COLUMN,
      CITY_COLUMN,
      ZIP_COLUMN,
      PROVIDER_TYPE_COLUMN,
      ENTITY_CODE_COLUMN,
      STREET_LINE1_COLUMN,
      STREET_LINE2_COLUMN
    ],
    table: PHYSICIAN_SUBTABLE
  }
}

export async function getTypeaheadData (uuid, { value, size }) {
  try {
    const data = {
      formValues: { [PHYSICIAN_LAST_NAME]: value },
      filterConfig: typeaheadSearchConfig
    }

    const apiParams = getToolSearchApiParams(data)

    const res = await getLookupToolData(uuid, { ...apiParams, size })
    const results = convertDataToObjectArray(res)
    const formatted = results.map(result => {
      return {
        result,
        listItem: {
          name: result[FIRST_NAME_COLUMN]
            ? `${result[FIRST_NAME_COLUMN]} ${result[LAST_NAME_COLUMN]}`
            : result[LAST_NAME_COLUMN],
          location: `${result[CITY_COLUMN]}, ${result[STATE_COLUMN]}`
        },
        value: {
          [PHYSICIAN_FIRST_NAME]: result[FIRST_NAME_COLUMN],
          [PHYSICIAN_LAST_NAME]: result[LAST_NAME_COLUMN],
          [PHYSICIAN_STATE]: result[STATE_COLUMN],
          [PHYSICIAN_CITY]: result[CITY_COLUMN],
          [PHYSICIAN_NPI]: result[NPI_COLUMN]
        }
      }
    })

    return formatted
  } catch (e) {
    console.error('Error fetching typeahead data', e)
    return []
  }
}
