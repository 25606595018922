import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'
import UrlPattern from 'url-pattern'
import config from './config'
import log from './log'

// global
import getNavigationMenus from './mock/global/getNavigationMenus.json'
import getAllNavigationalTopics from './mock/global/getAllNavigationalTopics.json'
import getApiPageMetaData from './mock/global/getApiPageMetaData.json'
import getApiDocsInfo from './mock/global/getApiDocsInfo.json'
import getSitemapPageData from './mock/global/getSitemapPageData.json'
import getAlert from './mock/global/getAlert.json'

// dataset
import * as getSlug from './mock/common/getSlug'
import getDatasetById from './mock/dataset/getDatasetById.json'
import getDatasetVersions from './mock/dataset/getDatasetVersions.json'
import getDatasetsWithMetaData from './mock/dataset/getDatasetsWithMetaData.json'
import getDownloadResources from './mock/dataset/getDownloadResources.json'
import getDatasetVersionResources from './mock/dataset/getDatasetVersionResources.json'
import getInteractiveDataOffset0 from './mock/dataset/getInteractiveData.offset-0.json'
import getInteractiveDataOffset10 from './mock/dataset/getInteractiveData.offset-10.json'
import getInteractiveDataStats from './mock/dataset/getInteractiveDataStats.json'
import logDatasetView from './mock/dataset/logDatasetView.json'
import logDatasetDownload from './mock/dataset/logDatasetDownload.json'

// search
import getSearchResults from './mock/search/getSearchResults.json'
import getFilters from './mock/search/getFilters.json'
import getPopularSearchTerms from './mock/search/getPopularSearchTerms.json'
import getSearchMetaData from './mock/search/getSearchMetaData.json'
import getTrendingStats from './mock/search/getTrendingStats.json'

// changelog
import getChangelog from './mock/changelog/getChangelog.json'

// homepage
import getHomepage from './mock/homepage/getHomepageRawData.json'

// article
import getDataResourceById from './mock/article/getDataResourceById.json'
import getArticleDatasets from './mock/article/getArticleDatasets.json'
import getDataDictionaryById from './mock/article/getDataDictionaryById.json'
import getStoryPageById from './mock/article/getStoryPageById.json'
import getRecentCaseStudies from './mock/article/getRecentCaseStudies.json'

// topic/subtopic
import getTopicData from './mock/subtopicTopic/getTopicData.json'
import getTopicPages from './mock/subtopicTopic/getTopicPages.json'
import getSubtopicData from './mock/subtopicTopic/getSubtopicData.json'
import getChildTopicsOfCategory from './mock/subtopicTopic/getChildTopicsOfCategory.json'
import getSubtopicsOfTopic from './mock/subtopicTopic/getSubtopicsOfTopic.json'

// tools
import getLookupTools from './mock/lookupTool/getLookupTools.json'
import getLookupToolById from './mock/lookupTool/getLookupToolById.json'
import getLookupToolDetail from './mock/lookupTool/getLookupToolDetail.json'
import getLookupToolDetailStats from './mock/lookupTool/getLookupToolDetailStats.json'
import getLookupToolsGlobalListing from './mock/lookupTool/getLookupToolsGlobalListing.json'

// older mocks, should be renamed/cleaned-up/re-classified
import getContactPageData from './mock/contact/getContactPageData.json'

const {
  useMockAdapter,
  mockDelayResponse
} = config

const datasetTypeId = '86b4807a-d63a-44be-bfdf-ffd398d5e623'
const datasetVersionId = '4ca364a5-89a3-4d9b-9a62-a4817f6c1464'
const datasetPath = '/provider-summary-by-type-of-service/medicare-durable-medical-equipment-devices-supplies/medicare-durable-medical-equipment-devices-supplies-by-referring-provider-and-service'
const getDatasetVersionsParams = {
  include: 'field_dataset_type,field_ref_primary_data_file',
  'fields[node--dataset]': 'field_dataset_version,field_ref_primary_data_file',
  'filter[field_dataset_type.name]': 'Medicare Durable Medical Equipment, Devices & Supplies - by Referring Provider and Service',
  sort: '-field_dataset_version'
}

const getGlobalListingParams = {
  include: [
    'field_ref_tools_list_section.field_ref_global_tool.field_tools_visualization_url',
    'field_ref_tools_list_section.field_ref_global_tool.field_internal_page.field_ref_image.field_media_image',
    'field_ref_tools_list_section.field_ref_global_tool.field_media_image.field_media_image_1'
  ].join(',')
}

const toolId = 'ad635465-b388-493e-9820-1bc187ef9d46'

const getLookupToolByIdParams = {
  'fields[node--dataset]': 'title,field_last_updated_date',
  include: [
    'field_look_up_tool_resource_sec',
    'field_look_up_tool_resource_sec.field_lookup_tool_feat_resources',
    'field_look_up_tool_resource_sec.field_lookup_tool_feat_resources.field_internal_page.field_ref_content_type',
    'field_look_up_tool_resource_sec.field_lookup_tool_feat_resources.field_ancillary_file.field_media_file',
    'field_look_up_tool_resource_sec.field_lookup_tool_feat_resources.field_ancillary_file.field_ref_content_type',
    'field_look_up_tool_resource_sec.field_lookup_tool_addl_resources.field_internal_page.field_ref_content_type',
    'field_look_up_tool_resource_sec.field_lookup_tool_addl_resources.field_ancillary_file.field_media_file',
    'field_look_up_tool_resource_sec.field_lookup_tool_addl_resources.field_ancillary_file.field_ref_content_type',
    'field_lookup_tool_faq_sec.field_lookup_tool_ref_faq_list.field_ref_faq',
    'field_lookup_tool_datasets_sec.field_lookup_tool_rel_datasets.field_ref_dataset_type',
    'field_lookup_tool_datasets_sec.field_lookup_tool_rel_datasets.field_ref_dataset_type.field_current_dataset_version',
    'field_ref_contact_email',
    'field_ref_license_agreement'
  ].join(',')
}

const getLookupToolDetailSearchResultsString = '?filter[Rndrng_Prvdr_CCN]=010036&size=10&offset=0'
const getLookupToolDetailSearchResultsStringRefresh = '?filter[Rndrng_Prvdr_CCN]=010036&size=1&offset=0'
const getLookupToolDetailSearchStats = '?filter[Rndrng_Prvdr_CCN]=010036&size=0&offset=0'

const dataResourcePath = '/resources/medicare-durable-medical-equipment-devices-supplies-methodology'
const dataResourcePageId = 'c16fff64-bebd-4e91-9dbc-af6330f15e31'
const dataDictionaryPath = '/resources/medicare-durable-medical-equipment-devices-supplies-by-referring-provider-and-service-data-dictionary'
const dataDictionaryPageId = 'f5b7e2e7-1560-46bf-a274-a5c26bcc8e38'
const storyPagePath = '/case-study/medicare-current-beneficiary-survey-covid19-data-snapshot'
const storyPageId = 'fa1b3594-2d71-4d9e-a031-53d90a06ea31'

const topicPath = '/provider-summary-by-type-of-service'
const subtopicPath = '/provider-summary-by-type-of-service/medicare-durable-medical-equipment-devices-supplies'

if (useMockAdapter) {
  log.debug('Using Mock Adapter')
  const mock = new MockAdapter(axios, { delayResponse: mockDelayResponse })
  mock
    // Global APIs
    .onGet('/jsonapi/taxonomy_term/navigational_topics?include=field_ref_navigational_category,parent')
    .reply(200, getAllNavigationalTopics)
    .onGet('/data-api/v1/frontend/navigation-menu')
    .reply(200, getNavigationMenus)
    .onGet(new RegExp('/jsonapi/node/api_page*'))
    .reply(200, getApiPageMetaData)
    .onGet('/data-api/v1?_format=json')
    .reply(200, getApiDocsInfo)
    .onGet(new RegExp('/jsonapi/node/sitemap_page*'))
    .reply(200, getSitemapPageData)
    .onGet(new RegExp('/jsonapi/node/alert*'))
    .reply(200, getAlert)

    // Dataset Detail Page APIs
    .onGet('/data-api/v1/slug', { params: { path: datasetPath } })
    .reply(200, getSlug.dataset)
    .onGet(`/jsonapi/node/dataset/${datasetVersionId}`)
    .reply(200, getDatasetById)
    .onGet('/jsonapi/node/dataset', { params: getDatasetVersionsParams })
    .reply(200, getDatasetVersions)
    .onGet('/data-api/v1/dataset-type')
    .reply(200, getDatasetsWithMetaData)
    .onGet(`/data-api/v1/dataset-type/${datasetTypeId}/resources`)
    .reply(200, getDownloadResources)
    .onGet(`/data-api/v1/dataset/${datasetVersionId}/resources`)
    .reply(200, getDatasetVersionResources)
    .onGet(new RegExp('/data-api/v1/tracker/dataset/dataset_views/*'))
    .reply(200, logDatasetView)
    .onGet(new RegExp('/data-api/v1/tracker/dataset/dataset_downloads/*'))
    .reply(200, logDatasetDownload)

    // Dataset IDS Page APIs
    .onGet(new RegExp(`/data-api/v1/dataset/${datasetVersionId}/data-viewer\\?size=10\\&offset=0`))
    .reply(200, getInteractiveDataOffset0)
    .onGet(new RegExp(`/data-api/v1/dataset/${datasetVersionId}/data-viewer\\?size=10\\&offset=10`))
    .reply(200, getInteractiveDataOffset10)
    .onGet(`/data-api/v1/dataset/${datasetVersionId}/data-viewer/stats?`)
    .reply(200, getInteractiveDataStats)

    // Search APIs
    .onGet('/data-api/v1/search/results')
    .reply(200, getSearchResults)
    .onGet('/data-api/v1/search/filters')
    .reply(200, getFilters)
    .onGet('/data-api/v1/search/popular-terms')
    .reply(200, getPopularSearchTerms)
    .onGet('/jsonapi/node/search_page')
    .reply(200, getSearchMetaData)
    .onGet('/data-api/v1/dataset/stats/trending')
    .reply(200, getTrendingStats)

    // Homepage APIs
    .onGet('/jsonapi/node/home_page')
    .reply(200, getHomepage)

    // LookupTool APIs
    .onGet('/jsonapi/node/tool_listing_page', { params: getGlobalListingParams })
    .reply(200, getLookupToolsGlobalListing)
    .onGet('/jsonapi/node/look_up_tool')
    .reply(200, getLookupTools)
    // use inpatient
    .onGet(`/jsonapi/node/look_up_tool/${toolId}`, { params: getLookupToolByIdParams })
    .reply(200, getLookupToolById)
    // use first result "Andalusia Regional Hospital"
    .onGet(`/data-api/v1/dataset/${toolId}/data-viewer${getLookupToolDetailSearchResultsString}`)
    .reply(200, getLookupToolDetail)
    // accounting for hard refresh
    .onGet(`/data-api/v1/dataset/${toolId}/data-viewer${getLookupToolDetailSearchResultsStringRefresh}`)
    .reply(200, getLookupToolDetail)
    .onGet(`/data-api/v1/dataset/${toolId}/data-viewer/stats${getLookupToolDetailSearchStats}`)
    .reply(200, getLookupToolDetailStats)

    // Changelog APIs
    .onGet(new RegExp(`/data-api/v1/dataset-type/${datasetVersionId}/changelog`))
    .reply(200, getChangelog)

    // Sitemap APIs
    .onGet('/jsonapi/node/sitemap_page?include=field_ref_contact_email')
    .reply(200, getSitemapPageData)

    // articles
    .onGet(new RegExp('/jsonapi/node/dataset\\?include=field_dataset_type\\&filter'))
    .reply(200, getArticleDatasets)
    // article: data resource
    .onGet('/data-api/v1/slug', { params: { path: dataResourcePath } })
    .reply(200, getSlug.dataResource)
    .onGet(`/jsonapi/node/data_resource_page/${dataResourcePageId}`)
    .reply(200, getDataResourceById)
    // article: data dictionary
    .onGet('/data-api/v1/slug', { params: { path: dataDictionaryPath } })
    .reply(200, getSlug.dataDictionary)
    .onGet(`/jsonapi/node/data_dictionary_page/${dataDictionaryPageId}`)
    .reply(200, getDataDictionaryById)
    // article: story page
    .onGet('/data-api/v1/slug', { params: { path: storyPagePath } })
    .reply(200, getSlug.storyPage)
    .onGet(`/jsonapi/node/story_page/${storyPageId}`)
    .reply(200, getStoryPageById)
    .onGet('/jsonapi/node/story_page')
    .reply(200, getRecentCaseStudies)

    // topic page
    .onGet('/data-api/v1/slug', { params: { path: topicPath } })
    .reply(200, getSlug.topicPage)
    .onGet(new UrlPattern('/jsonapi/node/topic_page?filter[field_ref_topic.id]=*').regex)
    .reply(200, getTopicData)
    .onGet(new UrlPattern('/jsonapi/node/topic_page?filter[topics-filter][condition][path]=field_ref_topic.id*').regex)
    .reply(200, getTopicPages)

    // subtopic page
    .onGet('/data-api/v1/slug', { params: { path: subtopicPath } })
    .reply(200, getSlug.subtopicPage)
    .onGet(new UrlPattern('/jsonapi/node/subtopic_page?filter[field_ref_topic.id]=*').regex)
    .reply(200, getSubtopicData)

    // topic/subtopic common
    .onGet(new UrlPattern('/jsonapi/taxonomy_term/navigational_topics').regex)
    .reply(function (config) {
      const paramKeys = config.params ? Object.keys(config.params) : []
      if (paramKeys.includes('filter[field_ref_navigational_category.id]')) {
        return [200, getChildTopicsOfCategory]
      } else if (paramKeys.includes('filter[parent.id]')) {
        return [200, getSubtopicsOfTopic]
      }
    })

    // passed here, the mocks need to be renamed/cleaned-up/re-classified
    .onGet('/jsonapi/node/contact_page*')
    .reply(200, getContactPageData)
    .onGet('/error-response')
    .reply(400)
}
