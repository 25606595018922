import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import Button from 'react-bootstrap/Button'
import configData from '../../../config'
import Text from '../Text/Text'
import { getContactHref } from '../../../utilities/linkHelpers'

import defaultBg from '../../../assets/contactBanner.webp'
import mobileBg from '../../../assets/contactBannerMobile.webp'
import './ContactBanner.scss'
import ComponentShell from '../ComponentShell/ComponentShell'
import useDisplayContext from '../../../hooks/useDisplayContext'
import { trackContactClick } from '../../../utilities/analyticsEventHelpers'

const {
  contactDefaultContact,
  contactDefaultDescription,
  contactDefaultHeader,
  contactDefaultButtonLabel
} = configData

function ContactBanner (props) {
  const {
    className,
    contactLink,
    description,
    header,
    buttonLabel,
    leftColOffset,
    subjectLine = '',
    hasLinkButton,
    // for SEO convert the eyebrow to a p and make the desc the h1 on contact page ONLY
    isContactPage
  } = props

  const { isMobile } = useDisplayContext()
  const imgSrc = isMobile ? mobileBg : defaultBg

  const isContactLinkMissing = isEmpty(contactLink)
  // component used many places so dynamism lives here
  if (hasLinkButton && isContactLinkMissing) return null

  const contactHref = getContactHref(contactLink, subjectLine)

  const buttonProps = {
    target: '_blank',
    rel: 'noopener noreferrer',
    href: contactHref
  }

  return (
    <section
      className={cx('ContactBanner', className)}
      style={{ backgroundImage: `url(${imgSrc})` }}
    >
      <ComponentShell leftColOffset={leftColOffset}>
        <Text
          className='ContactBanner__heading'
          domElement={isContactPage ? 'p' : 'h2'}
          typeFace='Rubik-Regular'
        >
          {header}
        </Text>
        <Text
          className='ContactBanner__desc'
          domElement={isContactPage ? 'h1' : 'p'}
          typeFace='01 H1/White'
        >
          {description}
        </Text>
        {contactLink && (
          <Button {...buttonProps} onClick={() => trackContactClick(buttonLabel, contactHref, window.utag)}>{buttonLabel}</Button>
        )}
      </ComponentShell>
    </section>
  )
}

ContactBanner.propTypes = {
  className: PropTypes.string,
  contactLink: PropTypes.string,
  description: PropTypes.string,
  header: PropTypes.string,
  buttonLabel: PropTypes.string,
  leftColOffset: PropTypes.number,
  subjectLine: PropTypes.string,
  hasLinkButton: PropTypes.bool,
  isContactPage: PropTypes.bool
}

ContactBanner.defaultProps = {
  contactLink: contactDefaultContact,
  description: contactDefaultDescription,
  header: contactDefaultHeader,
  buttonLabel: contactDefaultButtonLabel,
  leftColOffset: 0,
  subjectLine: '',
  hasLinkButton: true,
  isContactPage: false
}

export default ContactBanner
