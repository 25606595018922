import axios from 'axios'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import config from '../../config'
import log from '../../log'
import { getContactEmail } from '../../utilities/apiHelpers'
import { formatMetaTags } from '../formatters/global'
import queryString from 'query-string'
import simpleCacheBust from '../../utilities/simpleCacheBust'

const {
  apiSite,
  useCacheBuster,
  searchTrendingDataTypes,
  mobileTypeaheadMaxRows,
  typeaheadMaxRows
} = config

/**
 * custom param serializer for search APIs
 * @param {object} params
 */
const searchParamSerializer = params => {
  return queryString.stringify(params, { encode: false })
}

/**
 * Get list of filters with properties; params by the BE to return the counts for each
 * @param {object} params
 * @param {string} params.keywords values to search for
 * @param {string} params.sort_by BE key to sort by
 * @param {...array} params.filterName (multiple allowed) array of strings
 * @param {object} params.offset offset the search result list
 * @param {object} params.size count of rows to return
 */
export const getFilters = async (params) => {
  try {
    const res = await axios({
      method: 'GET',
      operationId: 'getFilters',
      baseURL: apiSite,
      url: '/data-api/v1/search/filters',
      params: params,
      paramsSerializer: searchParamSerializer
    })
    return res.data
  } catch (error) {
    log.error('getFilters: request error', error)
    throw error
  }
}

/**
 * Get popular search terms when no results found
 * @param {object} options
 * @param {*} options.cancelToken optional axios cancellation token
 */
export const getPopularSearchTerms = async ({ cancelToken }) => {
  try {
    const res = await axios({
      method: 'get',
      operationId: 'getPopularSearchTerms',
      baseURL: apiSite,
      url: '/data-api/v1/search/popular-terms',
      cancelToken: cancelToken
    })
    const data = get(res, 'data.data', [])
    return data
  } catch (error) {
    log.error('getPopularSearchTerms: request error', error)
    throw error
  }
}

/**
 * Grab search page specific contact info
 */
export const getSearchMetaData = async () => {
  try {
    const includes = [
      'field_ref_contact_email'
    ]
    const { data } = await axios(
      {
        method: 'GET',
        operationId: 'getSearchMetaData',
        baseURL: apiSite,
        url: '/jsonapi/node/search_page',
        jsonAPI: true,
        params: {
          include: includes.join(''),
          ...(useCacheBuster.getSearchMetaData ? {
            cacheBuster: simpleCacheBust()
          } : {})
        }
      }
    )
    const rawData = get(data, '[0]')
    const contactInfo = getContactEmail(rawData)
    const metaTags = formatMetaTags(get(rawData, 'metatagNormalized'))

    return {
      ...contactInfo,
      metaTags
    }
  } catch (error) {
    log.error('getSearchMetaData: request error', error)
    throw error
  }
}

/**
 * Core search functionality
 * @param {object} params
 * @param {string} params.keywords values to search for
 * @param {string} params.sort_by BE key to sort by
 * @param {...array} params.filterName (multiple allowed) array of strings
 * @param {object} params.offset offset the search result list
 * @param {object} params.size count of rows to return
 */
export const getSearchResults = async (params) => {
  // Per WDDSE-3940, we want to sort by last updated date when no keywords or
  // sorting criteria are supplied.
  const { keyword = '' } = params
  const sortOrderExists = Object.keys(params).filter(param => param.startsWith('sort_by')).length
  if (!keyword && !sortOrderExists) {
    params['sort_by[updated]'] = 'DESC'
  } else if (!keyword && params['sort_by[search_api_relevance]']) {
    delete params['sort_by[search_api_relevance]']
    params['sort_by[updated]'] = 'DESC'
  }
  try {
    const res = await axios({
      method: 'GET',
      operationId: 'getSearchResults',
      baseURL: apiSite,
      url: '/data-api/v1/search/results',
      params: params,
      paramsSerializer: searchParamSerializer
    })
    return res
  } catch (error) {
    log.error('getSearchResults: request error', error)
    throw error
  }
}

/**
 * Fetch trending stats from back-end to deduce most viewed, most downloaded, etc
 */
export const getTrendingStats = async () => {
  try {
    const { data } = await axios(
      {
        method: 'GET',
        operationId: 'getTrendingStats',
        baseURL: apiSite,
        url: '/data-api/v1/dataset/stats/trending',
        params: {
          ...(useCacheBuster.fetchTrendingStats ? {
            cacheBuster: simpleCacheBust()
          } : {})
        }
      }
    )

    const searchResultTags = {}

    // sort so searchResult tags get overriden with the highest priority
    const sortedSearchTrendingDataTypes = sortBy(searchTrendingDataTypes, ({ priority }) => {
      return priority
    })

    sortedSearchTrendingDataTypes.forEach(({
      key,
      label
    }) => {
      const trendingData = get(data, ['data', key], {})
      const trendingDataNames = Object.values(trendingData).map(({ name }) => name)
      trendingDataNames.forEach((name) => {
        searchResultTags[name] = label
      })
    })

    log.debug('fetchTrendingStats: data', searchResultTags)

    return searchResultTags
  } catch (error) {
    log.error('getTrendingStats: request error', error)
    throw error
  }
}

/**
 * Get typeahead information
 * @param {string} title typeahead search value
 * @param {boolean} isMobile flag to control num of returned results
 * @param {Object} [opts] options object
 * @param {*} [opts.cancelToken] axios cancel token
 */
export const getTypeahead = async (title = '', isMobile = false, {
  cancelToken
} = {}) => {
  const rowCount = isMobile ? mobileTypeaheadMaxRows : typeaheadMaxRows
  try {
    const res = await axios({
      method: 'GET',
      operationId: 'getSearchResults',
      baseURL: apiSite,
      url: '/data-api/v1/search/results',
      params: {
        keyword: encodeURIComponent(title),
        size: rowCount,
        offset: 0,
        'sort_by[search_api_relevance]': 'DESC'
      },
      paramsSerializer: searchParamSerializer,
      cancelToken: cancelToken
    })
    const formattedData = (get(res, 'data.data', [])) || []

    log.debug('getTypeahead: formatted data', formattedData)
    return formattedData
  } catch (error) {
    log.error('getTypeahead: request error', error)
    throw error
  }
}
