import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import config from '../config'
import uuid from 'uuid/v4'
import formatDatasetVersionDate from './datasetVersionFormatter'
import generateFilterQueryString from './queryGenerator'
import { formatStaticVisualizations, formatDynamicVisualizations } from '../services/formatters/subtopicTopic'
import { formatDynamicModule } from '../services/formatters/dynamicModule'
import cloneDeep from 'lodash/cloneDeep'
import { retainAccessToken } from '../utilities/linkHelpers'
import { featureIsEnabled, getFeatureSetting } from '../stores/siteStore/siteStore'

const { apiSite, filterFormConjunctions, filterFormComparators } = config

export const DATASET_PAGE_REFERRER = 'dataset'

/**
 * Extract VisualizationList component data from API data
 * @param {object} data
 * @param {object} data.fieldRefVisualization
 * @param {object} data.fieldRefVisualization.fieldRefDatasetVisComponent - where static visualizations are
 * @param {object} data.fieldRefVisualization.fieldPgDynamicDataVisComp - where dynamic visualizations are
 * @param {object[]} included - the raw included objects from JSON API
 */
export function formatVisualizationList (data, included) {
  const isStaticVisualizations = !!get(data, 'fieldRefVisualization.fieldRefDatasetVisComponent')

  // top level visualization struc
  const root = get(data, 'fieldRefVisualization')

  if (isStaticVisualizations) {
    const rawVisualizations = get(data, 'fieldRefVisualization.fieldRefDatasetVisComponent', [])
    const formattedVisualizations = formatStaticVisualizations(rawVisualizations, included)

    return {
      isStatic: true,
      header: get(root, 'fieldVisualizationHeader1'),
      subheader: get(root, 'fieldVisualizationDesc1'),
      visualizations: formattedVisualizations
    }
  } else {
    const rawVisualizations = get(data, 'fieldRefVisualization.fieldPgDynamicDataVisComp', [])
    const formattedVisualizations = formatDynamicVisualizations(rawVisualizations, included)

    return {
      isStatic: false,
      header: get(root, 'fieldVisualizationHeader1'),
      subheader: get(root, 'fieldVisualizationDesc1'),
      visualizations: formattedVisualizations
    }
  }
}

export function formatDynamicCallouts (rawCallouts, included) {
  const formatted = rawCallouts
    .filter(callout => !!callout.fieldRefDynamicModule)
    .map(callout => {
      const { fieldRefDynamicModule } = callout
      const moduleInclude = included.find(incl => incl.id === fieldRefDynamicModule.id)
      return {
        id: callout.id,
        description: callout.fieldDataCalloutDescription,
        module: formatDynamicModule(moduleInclude, cloneDeep(fieldRefDynamicModule.fieldPgDataSource))
      }
    })
  return formatted
}

/**
 * Gets the oldest-date version from an array of versions
 * @param {object[]} versions - backend version objects
 * @returns {object}
 */
export function getOldestDatasetVersion (versions = []) {
  const sortedDatasetVersions = getSortedDatasetVersions(versions, 'asc')
  return sortedDatasetVersions[0]
}

/**
 * Gets the latest-date version from an array of versions
 * @param {object[]} versions - backend version objects
 * @returns {object}
 */
export function getNewestDatasetVersion (versions = []) {
  const sortedDatasetVersions = getSortedDatasetVersions(versions, 'desc')
  return sortedDatasetVersions[0]
}

/**
 * Remove from an array of versions the versions that are later
 * than a given version
 * @param {object[]} versions - array of versions to filter
 * @param {object} versionToCompare - version used to compare the list of versions against
 * @param {object} options
 * @param {boolean} options.includeEqual - include or exclude equal date values
 * @returns {object[]} - filtered versions
 */
export function excludeLaterDatasetVersions (versions = [], versionToCompare, { includeEqual }) {
  const versionToCompareTime = new Date(getDatasetVersionValue(versionToCompare)).getTime()

  return versions.filter(version => {
    const versionTime = new Date(getDatasetVersionValue(version)).getTime()
    return includeEqual ? versionTime <= versionToCompareTime : versionTime < versionToCompareTime
  })
}

/**
 * Remove from an array of versions the versions that are earler
 * than a given version
 * @param {object[]} versions - array of versions to filter
 * @param {object} versionToCompare - version used to compare the list of versions against
 * @param {object} options
 * @param {boolean} options.includeEqual - include or exclude equal date values
 * @returns {object[]} - filtered versions
 */
export function excludeEarlierDatasetVersions (versions, versionToCompare, { includeEqual }) {
  const versionToCompareTime = new Date(getDatasetVersionValue(versionToCompare)).getTime()

  return versions.filter(version => {
    const versionTime = new Date(getDatasetVersionValue(version)).getTime()
    return includeEqual ? versionTime >= versionToCompareTime : versionTime > versionToCompareTime
  })
}

/**
 * Remove versions without a primary data file
 * @param {object[]} versions
 */
export function excludeNonInteractiveDatasetVersions (versions) {
  return versions.filter(version => isDatasetVersionInteractive(version))
}

export function isDatasetVersionInteractive (version) {
  const dataFile = getDatasetVersionPrimaryDataFile(version) || {}
  return dataFile && dataFile.isStatic === false
}

/**
 * Finds the version in an array of versions that matches a url-version string
 * @param {string} versionUrlString - string encoded via urlEncodeFormattedVersionLabel
 * @param {object[]} versions - dataset version objects from backend
 * @param {string} frequency - dataset frequency from backend
 * @returns {object}
 */
export function getCurrentVersionFromUrlString (versionUrlString, versions) {
  const uiFormattedVersionUrlString = versionUrlString.replace(/[-,]/g, ' ')
  const currentVersion = versions.find(version => {
    return !!(version?.fieldDatasetVersionCombined?.replace(',', '').toLowerCase() === uiFormattedVersionUrlString.toLowerCase())
  })

  return currentVersion
}

/**
 * Used to format the UI label (generated from datasetVersionFormatter) for a URL param
 * @param {string} formattedVersionLabel
 * @returns {string}
 */
export function urlEncodeFormattedVersionLabel (formattedVersionLabel) {
  // replace spaces, commas, and slashes with hyphens
  return formattedVersionLabel.replace(/[, ]+|\//g, '-').toLowerCase()
}

export function getVersionLabelUrl (version, frequency) {
  let formattedVersionLabel = formatDatasetVersionDate(frequency, getDatasetVersionValue(version))
  formattedVersionLabel = version?.fieldDatasetVersionSuffix ? formattedVersionLabel + version?.fieldDatasetVersionSuffix : formattedVersionLabel
  return urlEncodeFormattedVersionLabel(formattedVersionLabel)
}

/**
 * Used to sort versions by version date
 * @param {Object[]} versions - version objects
 * @param {string} direction - 'asc' or 'desc'
 * @returns {Object[]}
 */
export function getSortedDatasetVersions (versions = [], direction = 'desc') {
  return direction === 'desc' ? versions : versions.reverse()
}

/**
 * Get download url for dataset
 * @param {string} datasetTypeUuid
 * @param {object} options
 * @param {string} options.startDate string value of dataset version
 * @param {string} options.endDate string value of dataset version
 * @param {string} options.resources resource types
 * @returns {string}
 */
export const getDatasetDownloadLink = (datasetTypeUuid, options = {}) => {
  const {
    startDate,
    endDate,
    resources = []
  } = options

  const resourceParams = resources.length === 0 ? '&types[]=' : resources.map(resourceType => {
    return `&types[]=${resourceType}`
  }).join('')

  return `${apiSite}/data-api/v1/dataset-type/${datasetTypeUuid}/resources/download?start_date=${startDate}&end_date=${endDate}${resourceParams}`
}

/**
 * Parse top level data structure for dataset's full navigational info
 * @param {object} navTopicData fieldDatasetType.fieldRefNavTopic
 */
export const getNavigationalHierarchy = (navTopicData) => {
  const result = {
    category: '',
    subtopic: {},
    topic: {}
  }

  const parentObject = get(navTopicData, 'parent[0]')
  // first check for parent to determine subtopic existence
  const hasSubtopic = !isEmpty(parentObject)

  if (hasSubtopic) {
    result.category = get(navTopicData, 'parent[0].fieldRefNavigationalCategory.name')
    result.subtopic = { ..._extractNavInfo(navTopicData) }
    result.topic = { ..._extractNavInfo(parentObject) }
  } else {
    result.category = get(navTopicData, 'fieldRefNavigationalCategory.name')
    result.topic = { ..._extractNavInfo(navTopicData) }
  }

  return result
}

/**
 * Reusable utility to access nav properties from navObject
 * @param {object} navObject
 * @param {string} navObject.name
 * @param {string} navObject.path.alias //slug
 */
const _extractNavInfo = (navObject) => {
  return {
    name: get(navObject, 'name', ''),
    slug: get(navObject, 'path.alias')
  }
}

/**
 * Parse the allDatasets array for other related topic & subtopic datasets
 * @param {string} current dataset to filter from results
 * @param {object} topicHierarchy of current page, consisting of topic & subtopic
 * @param {string} topicHierarchy.topic
 * @param {string} topicHierarchy.subtopic
 * @param {array} datasets data to parse
 */
export const parseRelatedDatasets = (current, topicHierarchy, datasets) => {
  const { topic, subtopic } = topicHierarchy

  return datasets.reduce((prev, curr) => {
    if (curr.name === current) return prev

    if (curr.subtopic === subtopic.name) prev.subtopics.push(curr)
    if (curr.topic === topic.name) prev.topics.push(curr)

    return prev
  }, {
    subtopics: [],
    topics: []
  })
}

/* Putting these here to have a central repository of commonly get'd dataset properties */
export const getContactLink = (dataset) => get(dataset, 'fieldRefContactEmail.fieldContactEmail')
export const getDatasetFrequency = (dataset) => get(dataset, 'fieldDatasetType.fieldUpdateFrequency')
export const getDatasetName = (dataset) => get(dataset, 'fieldDatasetType.name')
export const getDatasetDescription = (dataset) => get(dataset, 'fieldDescription')
export const getDatasetDataSource = (dataset) => get(dataset, 'fieldDatasetType.fieldRefDataSource.name')
export const getDatasetUuid = (dataset) => get(dataset, 'fieldDatasetType.id')
export const getDatasetUrlPath = (dataset) => get(dataset, 'fieldDatasetType.path.alias')
export const getDatasetParentUrlPath = (dataset) => get(dataset, 'fieldDatasetType.fieldRefNavTopic.path.alias')
export const getDatasetResourcesHeader = (dataset) => get(dataset, 'fieldResourcesHeader')
export const getDatasetResourcesDescription = (dataset) => get(dataset, 'fieldResourcesDescription.value')
export const getDatasetCreationDate = (dataset) => get(dataset, 'fieldCreatedDate')
export const getDatasetLastUpdatedDate = (dataset) => get(dataset, 'fieldLastUpdatedDate')
export const getDatasetVisualization = (dataset) => get(dataset, 'fieldRefVisualization')
export const getDatasetLicenceAgreement = (dataset) => get(dataset, 'fieldDatasetType.fieldRefLicenseAgreement')
export const getAllTags = (dataset) => {
  const allTags = {
    Geography: get(dataset, 'fieldRefGeography', []),
    Program: get(dataset, 'fieldRefPrograms', []),
    Providers: get(dataset, 'fieldRefProviderTypes', []),
    Topic: get(dataset, 'fieldRefTopics', [])
  }

  return allTags
}

export const getDatasetVersionValue = (version) => get(version, 'fieldDatasetVersion')
export const getDatasetVersionPrimaryDataFile = (version) => get(version, 'fieldRefPrimaryDataFile')

export const hasVisualization = (dataset) => {
  const visualization = getDatasetVisualization(dataset)
  return !isEmpty(visualization)
}

// return a new filter group data structure for interactive dataset
// each group is a keyed entry with an array
export const newAdvancedFilterGroup = () => ({
  [uuid()]: {
    conjunction: { ...filterFormConjunctions[0] },
    conditions: [newBasicFilter()]
  }
})

// return a new filter condition data structure for interactive dataset
export const newBasicFilter = () => ({
  key: uuid(),
  column: null,
  comparator: null,
  filterValue: []
})

/**
 * Remove unnecessary information from filters object.
 * @param {object} filters The filters object.
 */
export const optimizeFiltersForIdsQueryString = (filters) => {
  // Abort if there are no conditions.
  if (!filters?.list?.length) {
    return {
      rootConjunction: { ...filterFormConjunctions[0] },
      list: []
    }
  }

  const optimizedFilters = {
    list: filters.list.map(item => {
      const conj = item?.conjunction ? {
        conjunction: {
          value: item.conjunction.value
        }
      } : {}

      return {
        ...conj,
        conditions: item.conditions.map(cond => {
          return {
            column: {
              value: cond.column.value
            },
            comparator: {
              value: cond.comparator.value
            },
            filterValue: cond.filterValue
          }
        })
      }
    }),
    rootConjunction: {
      value: filters.rootConjunction.value
    }
  }

  return optimizedFilters
}

/**
 * @param {object} filters An object containing necessary information to build
 *   basic or advanced filters, as returned by optimizeFiltersForIdsQueryString.
 * @returns {object} The basic or advanced filters as needed by <FiltersForm />.
 */
export const convertFilterParamsToFormState = (filters) => {
  // Abort if there are no conditions.
  if (!filters?.list?.length) {
    return {}
  }

  const isAdvanced = (filters.list[0]?.conjunction !== undefined)
  const formType = isAdvanced ? 'advanced' : 'basic'
  const formState = {
    activeFilter: formType,
    appliedTab: formType,
    rootConjunction: {
      label: filterFormConjunctions.find(item => item.value === filters.rootConjunction.value).label,
      value: filters.rootConjunction.value
    }
  }

  if (formType === 'basic') {
    formState.basic = filters.list[0].conditions.map(item => {
      return {
        column: {
          label: item.column.value,
          value: item.column.value
        },
        comparator: {
          label: filterFormComparators.find(op => op.value === item.comparator.value).label,
          value: item.comparator.value
        },
        filterValue: item.filterValue,
        key: uuid()
      }
    })
  } else {
    formState.advanced = filters.list.reduce((prev, curr) => {
      prev[uuid()] = {
        conditions: curr.conditions.map(item => {
          return {
            column: {
              // Currently, human-friendly column names (label field) and column API names (value field)
              // are the same, but this might change in the future.
              label: item.column.value,
              value: item.column.value
            },
            comparator: {
              label: filterFormComparators.find(op => op.value === item.comparator.value).label,
              value: item.comparator.value
            },
            filterValue: item.filterValue,
            key: uuid()
          }
        }),
        conjunction: {
          label: filterFormConjunctions.find(op => op.value === curr.conjunction.value).label,
          value: curr.conjunction.value
        }
      }
      return prev
    }, {})
  }

  return formState
}

/**
 * @param {object} filters An object containing filters form state.
 * @returns {boolean} True is both basic and advanced filters are empty.
 */
export const areFiltersEmpty = (filters) => {
  const basicFilters = filters.basic ?? []
  // If basic filters aren't empty, we can return TRUE.
  // There's no need to check advance filters.
  if (!areBasicFiltersEmpty(basicFilters)) {
    return false
  }

  // If basic filters are empty, then the return value
  // will depend exclusively on the advanced filters.
  const advFilters = filters.advanced ?? {}
  return areAdvancedFiltersEmpty(advFilters)
}

/**
 * @param {array} basicFilters List of basic filters.
 * @returns {boolean} True if there is no user input.
 */
export const areBasicFiltersEmpty = (basicFilters) => {
  // Abort if basicFilters isn't an array.
  if (!Array.isArray(basicFilters)) {
    return true
  }

  if (basicFilters.length > 1) {
    return false
  }

  const column = basicFilters[0]?.column?.value ?? ''
  const comparator = basicFilters[0]?.comparator?.value ?? ''
  const filterValue = basicFilters[0]?.filterValue?.length ?? 0

  return column === '' && comparator === '' && filterValue === 0
}

/**
 * @param {object} advancedFilters Object containing filters grouped by object keys.
 * @returns {boolean} True if the filters don't contain any user input.
 */
export const areAdvancedFiltersEmpty = (advancedFilters) => {
  const groupKeys = Object.keys(advancedFilters)

  // If there are no filter groups, assume the form is empty.
  if (groupKeys.length === 0) {
    return true
  }

  // If there are more than 1 filter groups, the form is not empty.
  if (groupKeys.length > 1) {
    return false
  }

  const firstGroup = advancedFilters[groupKeys[0]]
  const conditions = firstGroup?.conditions ?? []

  return areBasicFiltersEmpty(conditions)
}

/**
 * @param {object} searchParams Search parameters used to filter the data.
 * @param {string[]} columnNames List of columns names from a dataset file.
 * @returns {boolean}
 */
export const areColumnNamesInSearchParamsValid = (searchParams, columnNames) => {
  if (isEmpty(columnNames)) {
    throw new Error('No column names were provided.')
  }

  const uniqueColumnNames = new Set([...columnNames])
  const columnsInParams = new Set()

  // If sort-by value exists, it has to be a valid column name.
  if (typeof searchParams?.sort?.sortBy === 'string') {
    columnsInParams.add(searchParams.sort.sortBy)
  }

  if (Array.isArray(searchParams?.columns)) {
    searchParams.columns.forEach(column => columnsInParams.add(column))
  }

  if (Array.isArray(searchParams?.filters?.list)) {
    searchParams.filters.list.forEach(group => {
      group.conditions.forEach(condition => {
        columnsInParams.add(condition.column.value)
      })
    })
  }

  return new Set([...uniqueColumnNames, ...columnsInParams]).size === uniqueColumnNames.size
}

const queryStringFormatters = {
  limit: limit => `size=${encodeURIComponent(limit)}`,
  offset: offset => `offset=${encodeURIComponent(offset)}`,
  keywords: keywords => `keyword=${encodeURIComponent(keywords)}`,
  sort: ({ sortBy, sortOrder }) => {
    const sortOrderPrefix = sortOrder === 'DESC' ? '-' : ''
    return `sort=${sortOrderPrefix}${encodeURIComponent(sortBy)}`
  },
  columns: columns => `column=${columns.map(column => encodeURIComponent(column)).join(',')}`,
  filters: filters => `${generateFilterQueryString(filters)}`,
  format: format => `_format=${encodeURIComponent(format)}`,
  source: source => `_source=${encodeURIComponent(source)}`
}

/**
 * Helper function to build plain text params for getInteractiveData
 *
 * Due to data structure axios was incorrectly encoding pieces of data
 * when the params option was used. BE has no problem consuming empty
 * params so this is a naive solution.
 *
 * @param {object} options - standard search options
 * @param {number} options.limit - size
 * @param {number} options.offset - pagination
 * @param {string} options.keywords - search box term
 * @param {object} options.sort
 * @param {string} options.sort.sortBy - column header to sort by
 * @param {string} options.sort.sortOrder - one of ['ASC', 'DESC']
 * @param {object[]} options.filters
 * @param {string[]} options.columns - toggle columns
 * @param {string} options.format
 */
export const buildInteractiveDataQueryString = (options = {}) => {
  const optionKeys = [
    'limit',
    'offset',
    'keywords',
    'sort',
    'filters',
    'columns',
    'format',
    'source'
  ]

  const queryStringComponents = optionKeys.reduce((qsComponents, optionKey) => {
    const optionValue = get(options, optionKey)
    let isPresent

    // some checks for presence of parameter require looking inside an object
    switch (optionKey) {
      case 'sort':
        isPresent = isValuePresent(get(optionValue, 'sortBy')) && isValuePresent(get(optionValue, 'sortOrder'))
        break
      case 'filters':
        isPresent = isValuePresent(get(optionValue, 'list'))
        break
      default:
        isPresent = isValuePresent(optionValue, optionKey)
    }

    return isPresent
      ? [...qsComponents, queryStringFormatters[optionKey](optionValue)]
      : qsComponents
  }, [])

  return `?${queryStringComponents.join('&')}`
}

export function isValuePresent (value) {
  if (typeof value === 'undefined' || value === null) {
    return false
  }

  // consider numbers and non-empty values to indicate presence of value
  return (
    typeof value === 'number' ||
    !isEmpty(value)
  )
}

function isDatasetPageReferrer (history) {
  const referrer = get(history, 'location.state.pageReferrer')
  return referrer === DATASET_PAGE_REFERRER
}

/**
 *
 * @param {object} history
 * @param {function} history.goBack
 * @param {function} history.replace
 * @param {string} datasetOverviewUrl
 */
export function backToDatasetOverview (history = {}, datasetOverviewUrl = '') {
  if (isDatasetPageReferrer(history)) {
    history.goBack()
  } else {
    datasetOverviewUrl = retainAccessToken(datasetOverviewUrl)
    history.replace(datasetOverviewUrl)
  }
}

export function convertLocalToUTCDate (date) {
  if (!date) {
    return date
  }
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  return date
}

export function getEndDateForFrequencyPeriod (date, frequency) {
  if (date == null) { return date }

  let returnDate = new Date()
  const quarterlyCheckpoints = [3, 6, 9, 12]
  let newMonth = -1
  let newYear = date.getFullYear()
  switch (frequency) {
    case 'Quarterly':
      for (let i = 0; i < quarterlyCheckpoints.length; i++) {
        const month = quarterlyCheckpoints[i]
        const selectedMonth = date.getMonth() + 1
        if (selectedMonth <= month) {
          // Fits within this quarter, so go to first of next quarter
          newMonth = month
          if (newMonth > 12) {
            newMonth = 1
            newYear += 1
          }
          returnDate = new Date(newYear, newMonth, 1, 12, 0, 0, 0)
          break
        }
      }
      // After going to the first day of the next quarter, subtract a day so it is the last day of the prev quarter
      returnDate.setDate(returnDate.getDate() - 1)
      return returnDate
    case 'Annually':
      newYear += 1
      returnDate = new Date(newYear, 0, 1, 0, 0, 0, 0)
      // After going to the first day of the next year, subtract a day so it is the last day of the prev year
      returnDate.setDate(returnDate.getDate() - 1)
      return returnDate
    case 'Monthly':
    case 'Bimonthly':
    case 'Three times a year':
    case 'Semiannually':
      newMonth = date.getMonth() + 1
      if (newMonth > 12) {
        newMonth = 1
        newYear += 1
      }
      returnDate = new Date(newYear, newMonth, 1, 0, 0, 0, 0)
      // After going to the first day of the next month, subtract a day so it is the last day of the prev month
      returnDate.setDate(returnDate.getDate() - 1)
      return returnDate
    default:
      return date
  }
}

export function getDatasetVersionText (label) {
  // if custom tooltips are enabled grab that instead
  const enabled = featureIsEnabled('datasetVersionTooltips')
  if (enabled) {
    const tooltipList = getFeatureSetting('datasetVersionTooltips', 'tooltipList')
    const versionObj = tooltipList[label]
    if (versionObj?.label && versionObj?.tooltip) {
      return { display: true, ...versionObj }
    }
  }
  switch (label) {
    case 'off-cycle':
      return { display: true, label: 'Off-cycle', tooltip: 'This dataset version is published off-cycle to its regular frequency schedule.' }
    case 'amended':
      return { display: true, label: 'Amended', tooltip: 'This dataset is an update to the original release.' }
    case 'retired':
      return { display: true, label: 'Retired', tooltip: 'This dataset is retired and will no longer be updated.' }
    default:
      return { display: false }
  }
}

export function getTooltipBasedOnFrequency (frequency) {
  if (!frequency) { return '' }

  // if custom tooltips are enabled grab that instead
  const enabled = featureIsEnabled('frequenciesTooltips')
  if (enabled) {
    const tooltipList = getFeatureSetting('frequenciesTooltips', 'tooltipList')
    const tooltip = tooltipList[frequency]
    if (tooltip) {
      return tooltip
    }
  }

  switch (frequency.toLowerCase()) {
    case 'daily':
      return 'This dataset is typically updated once a day.'
    case 'weekly':
      return 'This dataset is typically updated once a week.'
    case 'monthly':
      return 'This dataset is typically updated once a month.'
    case 'quarterly':
      return 'This dataset is typically updated four times every 12 months.'
    case 'annually':
      return 'This dataset is typically updated once every 12 months.'
    case 'semiannually':
      return 'This dataset is typically updated twice every 12 months.'
    case 'three times a year':
      return 'This dataset is typically updated three times every 12 months.'
    case 'bimonthly':
      return 'This dataset is typically updated every 2 months.'
    case 'semimonthly':
      return 'This dataset is typically updated twice a month.'
    case 'three times a month':
      return 'This dataset is typically updated three times a month.'
    case 'biweekly':
      return 'This dataset is typically updated every 2 weeks.'
    case 'semiweekly':
      return 'This dataset is typically updated twice a week.'
    case 'three times a week':
      return 'This dataset is typically updated three times a week.'
    case 'periodically':
      return 'This dataset is updated periodically (not on a set schedule).'
    default:
      return `This dataset is updated on a ${frequency.toLowerCase()} basis.`
  }
}
