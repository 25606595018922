
import React from 'react'
import PropTypes from 'prop-types'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const ComponentShell = (props) => {
  const {
    leftColOffset,
    children
  } = props

  return (
    <Container>
      <Row>
        <Col lg={{ offset: leftColOffset }}>
          {children}
        </Col>
      </Row>
    </Container>
  )
}

ComponentShell.propTypes = {
  leftColOffset: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.string]))
  ])
}

ComponentShell.defaultProps = {
  leftColOffset: 0
}

export default ComponentShell
