import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { ReactComponent as FilterIcon } from '../../../assets/fontawesome/solid/bars-filter.svg'
import Sort from '../Sort/Sort'
import { numberWithCommas } from '../../../utilities/searchPageHelpers'
import SearchFiltersBar from '../SearchFiltersBar/SearchFiltersBar'
import useDisplayContext from '../../../hooks/useDisplayContext'

import './SearchPageFiltersDisplay.scss'

const SearchPageFiltersDisplay = (props) => {
  const {
    isLoadingInitialData,
    isLoading,
    activeFilters,
    numOfResults,
    numOfTotalResults,
    sort,
    offset,
    searchValue,
    onFilterButtonClick,
    onSortClick,
    onDismissFilterClick,
    onClearAllFilters
  } = props

  const { isMobile, isDesktop } = useDisplayContext()

  const activeFiltersCount = Object.keys(activeFilters).reduce((count, key) => {
    return count + activeFilters[key].length
  }, 0)

  function renderResultsHeader () {
    return (
      <div className={cx('SearchPage__results-header', { 'no-results': numOfTotalResults < 1 })}>
        {(isLoadingInitialData || isLoading)
          ? <div className='SearchPage__results-header-results-text-loading skeleton-box' />
          : (
            <ResultsText
              numOfResults={numOfResults}
              offset={offset}
              total={numOfTotalResults}
              searchValue={searchValue}
            />
          )}
      </div>
    )
  }

  return (
    <div className='SearchPageFiltersDisplay'>
      <Container className='SearchPageFiltersDisplay__container'>
        {isDesktop && renderResultsHeader()}
        <div className='SearchPage__results-buttons'>
          {!isLoadingInitialData && (
            <>
              <Button
                className={cx('filter', { active: activeFiltersCount > 0 })}
                onClick={onFilterButtonClick}
              >
                <FilterIcon className='FilterIcon' />
                <span>Filter</span>
                <span className='count'>{activeFiltersCount}</span>
              </Button>
              <Sort
                selected={sort}
                onClick={onSortClick}
              />
            </>
          )}
        </div>

      </Container>
      <SearchFiltersBar
        activeFilters={activeFilters}
        onClearAllFilters={onClearAllFilters}
        onDismissFilterClick={onDismissFilterClick}
      />
      {isMobile && (
        <Container>
          {renderResultsHeader()}
        </Container>
      )}
    </div>
  )
}

SearchPageFiltersDisplay.propTypes = {
  isLoadingInitialData: PropTypes.bool,
  isLoading: PropTypes.bool,
  activeFilters: PropTypes.object.isRequired,
  numOfResults: PropTypes.number,
  numOfTotalResults: PropTypes.number,
  sort: PropTypes.string,
  offset: PropTypes.number.isRequired,
  searchValue: PropTypes.string,
  onSortClick: PropTypes.func.isRequired,
  onFilterButtonClick: PropTypes.func.isRequired,
  onDismissFilterClick: PropTypes.func.isRequired,
  onClearAllFilters: PropTypes.func.isRequired
}

const ResultsText = ({ numOfResults, offset, total }) => {
  if (numOfResults === 0) {
    return (
      <span className='SearchPage__results-text'>Showing 0 results</span>
    )
  } else {
    const from = offset + 1
    const to = offset + numOfResults
    return (
      // `Showing 1-10 of 10,434 results`
      <span className='SearchPage__results-text'>Showing <span>{from}-{to}</span> of <span>{numberWithCommas(total)}</span> results </span>
    )
  }
}

ResultsText.propTypes = {
  numOfResults: PropTypes.number,
  offset: PropTypes.number,
  total: PropTypes.number
}

export { ResultsText }

export default SearchPageFiltersDisplay
