import React from 'react'
import get from 'lodash/get'
import UserAgreementModal, { hasUserAcceptedAgreement } from '../../components/common/UserAgreementModal/UserAgreementModal'

const initialState = {
  isLoading: false,
  resolvePromise: null,
  localStorageKey: null,
  licenseAgreement: {},
  isOpen: false
}

function withUserAgreementModal (WrappedComponent, displayName) {
  class WithUserAgreementModal extends React.PureComponent {
    state = { ...initialState }

    /**
     * This method can either accept a licenseAgreement object or load a license agreement based on a uuid (currently, only uuid of dataset types)
     *
     * @param {string} uuid - uuid of entity that has optional license agreement associated with it
     * @param {object} data
     * @param {object} data.licenseAgreement - license agreement object (with title and body keys) that is associated with the entity
     * @returns {function} - the resolve promise function that returns the agreement result
     */
    getUserAgreementStatus = (uuid, data = {}) => {
      const {
        licenseAgreement
      } = data

      return new Promise(resolve => {
        if (!licenseAgreement || hasUserAcceptedAgreement(uuid)) {
          // there is no selected license agreement for the entity in question; resolve promise to notify caller
          // that the the agreement status is accepted; do the same if already accepted
          resolve({ hasAgreed: true })
        } else {
          // open modal to prompt user, which will set status and resolve promise upon choosing action
          this.setState({
            licenseAgreement,
            resolvePromise: resolve,
            isOpen: true,
            localStorageKey: uuid
          })
        }
      })
    }

    // when UserAgreementModal closes from one of the footer action buttons, it will
    // provide a payload letting the parent component know what the user chose
    handleHideUserAgreementModal = (payload = {}) => {
      const { resolvePromise } = this.state
      const { hasAgreed } = payload

      resolvePromise({ hasAgreed })

      this.setState({ ...initialState })
    }

    render () {
      const {
        isOpen,
        isLoading,
        licenseAgreement,
        localStorageKey
      } = this.state

      const title = get(licenseAgreement, 'title')
      const body = get(licenseAgreement, 'body.value')

      return (
        <>
          <WrappedComponent
            getUserAgreementStatus={this.getUserAgreementStatus}
            {...this.props}
          />
          <UserAgreementModal
            show={isOpen}
            isLoading={isLoading}
            title={title}
            body={body}
            onHide={this.handleHideUserAgreementModal}
            localStorageKey={localStorageKey}
          />
        </>
      )
    }
  }

  WithUserAgreementModal.displayName = displayName || 'WithUserAgreementModal'
  return WithUserAgreementModal
}

export default (displayName) => {
  return (Component) => withUserAgreementModal(Component, displayName)
}
