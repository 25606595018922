import React from 'react'
import PropTypes from 'prop-types'
import ModalCloseButton from '../ModalCloseButton/ModalCloseButton'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

const MobileDownloadModal = (props) => {
  const {
    show,
    onHide,
    message
  } = props

  return (
    <Modal
      className='DownloadModal DownloadModal__mobile'
      size='lg'
      show={show}
      onHide={onHide}
      centered
    >
      <Modal.Header className='DownloadModal__modal-header'>
        <Modal.Title>
          Download dataset
        </Modal.Title>
        <ModalCloseButton onClick={onHide} />
      </Modal.Header>

      <Modal.Body className='DownloadModal__modal-body'>
        {message}
      </Modal.Body>
      <Modal.Footer className='DownloadModal__modal-footer'>
        <Button variant='primary' onClick={onHide}>
          <span>Close</span>
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

MobileDownloadModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  message: PropTypes.string
}

MobileDownloadModal.defaultProps = {
  show: false,
  onHide: () => {},
  message: 'You are not able to download this dataset on a mobile device. Download this file on a desktop machine to view more information.'
}

export default MobileDownloadModal
