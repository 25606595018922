import get from 'lodash/get'
import config from '../../config'
import { extractResourceTypeData, extractCtaData } from '../../utilities/apiHelpers'
import { getIncludedUrls, getMediaImageAltText } from './global'
import { getAssetOrigin, getScaledImgUrl } from '../../utilities/linkHelpers'

const {
  articleComponentTypes: {
    componentTypeTextBlock,
    componentTypeMedia,
    componentTypeQuote,
    componentTypeFaq,
    componentTypeFaqList,
    componentTypeImageText,
    componentTypeCallouts,
    componentTypeKeyInfoList,
    componentTypeTable,
    componentTypeSingleResource
  }
} = config

/**
 * Get formatted component for React components used in Article
 * @param {object} rawComponent JSON deserialized component
 * @param {string} rawComponent.type Drupal component type identifier
 * @param {object[]} included - JSON API response included
 * @returns {object} formattedComponent
 */
export function formatComponent (rawComponent, included) {
  const BACKEND_COMPONENT_TYPE_TEXT_BLOCK = 'paragraph--basic_text_block'
  const BACKEND_COMPONENT_TYPE_MEDIA = 'paragraph--media_component'
  const BACKEND_COMPONENT_TYPE_QUOTE = 'paragraph--blockquote'
  const BACKEND_COMPONENT_TYPE_FAQ = 'paragraph--faq'
  const BACKEND_COMPONENT_TYPE_FAQ_LIST = 'paragraph--faq_list'
  const BACKEND_COMPONENT_TYPE_KEY_INFO_LIST = 'paragraph--key_info_list'
  const BACKEND_COMPONENT_TYPE_IMAGE_TEXT = 'paragraph--image_text_component'
  const BACKEND_COMPONENT_TYPE_TABLE = 'paragraph--table_component'
  const BACKEND_COMPONENT_TYPE_CALLOUTS = 'paragraph--number_callout_wrapper'
  const BACKEND_COMPONENT_TYPE_SINGLE_RESOURCE = 'paragraph--resource_component'

  const component = {
    id: get(rawComponent, 'id')
  }

  const { title: ctaTitle, uri: ctaUri } = extractCtaData(get(rawComponent, 'fieldRefCallToAction', {}))

  switch (rawComponent.type) {
    case BACKEND_COMPONENT_TYPE_TEXT_BLOCK:
      component.type = componentTypeTextBlock
      component.subtitle = get(rawComponent, 'fieldComponentSubtitle')
      component.text = get(rawComponent, 'fieldWysiwygParagraph.processed')
      component.footnotes = get(rawComponent, 'fieldFootnotes.value')
      break

    case BACKEND_COMPONENT_TYPE_MEDIA:
      component.type = componentTypeMedia
      component.heading = get(rawComponent, 'fieldComponentSubtitle')
      component.imgSrc = `${getAssetOrigin()}${get(rawComponent, 'fieldMediaImage.fieldMediaImage.uri.url')}`
      component.imgAlt = getMediaImageAltText(get(rawComponent, 'fieldMediaImage'), included)
      component.featured = !!(get(rawComponent, 'fieldImageEyebrow'))
      component.featuredText = get(rawComponent, 'fieldImageEyebrow')
      component.description = get(rawComponent, 'fieldImageCaption')
      break

    case BACKEND_COMPONENT_TYPE_QUOTE:
      component.type = componentTypeQuote
      component.quote = get(rawComponent, 'fieldQuote')
      component.speaker = get(rawComponent, 'fieldQuoteSpeaker')
      component.speakerTitle = get(rawComponent, 'fieldQuoteSpeakerTitle')
      break

    case BACKEND_COMPONENT_TYPE_FAQ:
      component.type = componentTypeFaq
      component.fieldFaqAnswer = get(rawComponent, 'fieldFaqAnswer')
      component.fieldFaqQuestion = get(rawComponent, 'fieldFaqQuestion')
      break

    // as lint suggested, wrap in own block b/c to scope var declaration
    case BACKEND_COMPONENT_TYPE_FAQ_LIST: {
      const rawFaqs = get(rawComponent, 'fieldRefFaq', [])

      component.type = componentTypeFaqList
      component.faqs = rawFaqs.map(raw => formatComponent({ ...raw, type: BACKEND_COMPONENT_TYPE_FAQ }))
      break
    }

    case BACKEND_COMPONENT_TYPE_KEY_INFO_LIST:
      component.type = componentTypeKeyInfoList
      // component.header
      component.listType = get(rawComponent, 'fieldListType') === 'ul'
        ? 'bullet'
        : 'number'
      component.listItems = (get(rawComponent, 'fieldListItem') || []).map(item => {
        return {
          itemHeader: item.fieldSectionHeader,
          itemBody: item.fieldListItemBody.value
        }
      })
      break

    case BACKEND_COMPONENT_TYPE_IMAGE_TEXT:
      component.type = componentTypeImageText
      component.imgPos = get(rawComponent, 'fieldImageTextPositioning') === 'image-left,text-right'
        ? 'left'
        : 'right'
      component.imgSrc = `${getAssetOrigin()}${get(rawComponent, 'fieldMediaImage.fieldMediaImage.uri.url')}`
      component.imgAlt = getMediaImageAltText(get(rawComponent, 'fieldMediaImage'), included)
      component.cta = { title: ctaTitle, uri: ctaUri }
      component.heading = get(rawComponent, 'fieldComponentSubtitle')
      component.desc = get(rawComponent, 'fieldWysiwygParagraph.value')
      break

    case BACKEND_COMPONENT_TYPE_TABLE:
      component.type = componentTypeTable
      component.fieldHasDedicatedFooter = get(rawComponent, 'fieldHasDedicatedFooter')
      component.footnotes = get(rawComponent, 'fieldFootnotes.value')
      component.fieldComponentSubtitle = get(rawComponent, 'fieldComponentSubtitle')
      component.fieldWysiwygTable = get(rawComponent, 'fieldWysiwygTable')
      break

    case BACKEND_COMPONENT_TYPE_CALLOUTS:
      component.type = componentTypeCallouts
      component.callouts = get(rawComponent, 'fieldRefNumberCallout')
      break

    // w/o brackets eslint squeals
    case BACKEND_COMPONENT_TYPE_SINGLE_RESOURCE: {
      const targetRawData = get(rawComponent, 'fieldRefResource')
      const formatted = extractResourceTypeData(targetRawData)

      component.type = componentTypeSingleResource
      component.contentType = formatted.type
      component.text = formatted.title
      component.linkText = formatted.link.name
      component.linkHref = formatted.link.url
      break
    }

    default:
      component.type = null
  }

  return component
}

/**
 *
 * @param {object[]} rawSections deserialized JSON API sections
 * @param {object[]} included the non-deserialized included from the response; this is used to identify the component type,
 *                     since the BE does not provide this with each component
 * @param {string} componentsKey - the property key where the components can be found in each sections
 */
export const formatArticleSections = (rawSections = [], included, componentsKey) => {
  const formattedSections = rawSections.map(rawSection => {
    const components = get(rawSection, componentsKey, [])
    let singleResourceIndex = 0

    const formattedComponents = components.map((component, i) => {
      const type = get(included.find(includedObj => includedObj.id === component.id), 'type')
      // first, format each component
      const formattedComponent = formatComponent({
        ...component,
        type: type
      }, included)

      // ensure that resource components have index as a prop for proper color highlight display;
      // only increment index when the resources are consecutively placed within a section
      if (formattedComponent.type === componentTypeSingleResource) {
        formattedComponent.index = singleResourceIndex
        singleResourceIndex++
      } else {
        singleResourceIndex = 0
      }

      return formattedComponent
    })

    return {
      id: rawSection.id,
      header: rawSection.fieldComponentTitle,
      label: rawSection.fieldInPageNavigationLink ?? rawSection.fieldComponentTitle,
      content: formattedComponents
    }
  })

  return formattedSections
}

export function formatCitations (citations = []) {
  return citations.map((citation) => {
    const resource = extractResourceTypeData(get(citation, 'fieldRefResource'))

    return {
      id: get(citation, 'id'),
      title: get(citation, 'fieldCitationTitle'),
      description: get(citation, 'fieldCitationText'),
      url: get(resource, 'link.url')
    }
  })
}

export function formatCaseStudies (caseStudies = [], included = []) {
  return caseStudies.map(caseStudy => {
    const imgSrcPartialUrl = get(caseStudy, 'fieldRefImage.fieldMediaImage.uri.url')

    return {
      title: get(caseStudy, 'title'),
      desc: get(caseStudy, 'fieldDescription'),
      imgSrc: getScaledImgUrl('medium', getIncludedUrls(included), `${imgSrcPartialUrl}`),
      storyLink: get(caseStudy, 'path.alias')
    }
  })
}

export function formatDataUsed (dataUsed = []) {
  return dataUsed.map(dataObj => {
    const { link, title } = extractResourceTypeData(dataObj.fieldRefResource)
    return {
      title,
      uri: link.url
    }
  })
}

// extract clean table data structure from API data
export function formatDefinitionTableData (data) {
  return {
    subtitle: get(data, 'fieldComponentSubtitle', ''),
    footnotes: get(data, 'fieldFootnotes.value', ''),
    data: get(data, 'fieldWysiwygTable.value', ''),
    hasFooter: get(data, 'fieldHasDedicatedFooter', false)
  }
}
