import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import DatePicker from 'react-datepicker'
import FilePicker from '../../common/FilePicker/FilePicker'
import { ReactComponent as CalendarFromIcon } from '../../../assets/fontawesome/custom/from-calendar.svg'
import { formatResourcesIntoNodes, getDateFormatByFrequency, getPlaceholderTextByFrequency } from './downloadModalHelpers'
import { toggleAllChildrenOfNodes } from '../../../utilities/displayUtilities'

import 'react-datepicker/dist/react-datepicker.css'

const DownloadModalCustomized = (props) => {
  const {
    itemsUpdated, customizedOptions, selectedStartDate, selectedEndDate, datesUpdated, resourcesByType,
    datasetFrequency, hasSingleDataset, versionBoundry, maxGB, useDisplayName, areCheckboxesDirty, setCheckboxesDirty
  } = props
  const [startDate, setStartDate] = useState(selectedStartDate)
  const [endDate, setEndDate] = useState(selectedEndDate)
  const [uniqueDateRangeKey, setUniqueDateRangeKey] = useState(0)

  const resetDates = (e) => {
    e?.preventDefault()
    setStartDate(null)
    setEndDate(null)
    toggleAllChildrenOfNodes(customizedOptions, false)
    setCheckboxesDirty(false)
  }

  useEffect(() => {
    datesUpdated(startDate, endDate)
  }, [startDate, endDate])

  useEffect(() => {
    setUniqueDateRangeKey(startDate?.getTime() + endDate?.getTime())
  }, [selectedStartDate, selectedEndDate])

  const renderDatePicker = (stateCallback, dateObject, isEndDate) => {
    const onChange = (date) => {
      stateCallback(date)
      itemsUpdated(null, 0, 0)
    }

    let maxDate = new Date(versionBoundry?.latestDate)

    // Padding endate since the range is not inclusive.
    maxDate.setDate(maxDate.getDate() + 1)

    if (!isEndDate) {
      if (selectedEndDate?.getTime() < maxDate?.getTime()) {
        maxDate = new Date(selectedEndDate)
      }
    }

    let minDate = new Date(versionBoundry?.startDate)

    // If it is quarterly, react-datepicker requires a date prior to first of the quarter otherwise it will grey out
    if (datasetFrequency === 'Quarterly') {
      minDate.setMonth(minDate.getMonth() - 2)
      minDate.setDate(1)
    }

    if (selectedStartDate?.getTime() > minDate?.getTime()) {
      if (isEndDate) {
        minDate = new Date(selectedStartDate)
      } else {
        if (selectedEndDate) {
          maxDate = new Date(selectedEndDate)
        }
      }
    }

    return (
      <>
        <DatePicker
          dateFormat={getDateFormatByFrequency(datasetFrequency)}
          selected={dateObject}
          onChange={onChange}
          className='datepickerInput form-control'
          placeholderText={getPlaceholderTextByFrequency(datasetFrequency)}
          {...datasetFrequency === 'Quarterly' ? { showQuarterYearPicker: true } : {}}
          {...datasetFrequency === 'Annually' ? { showYearPicker: true } : {}}
          {...['Monthly', 'Bimonthly', 'Three times a year', 'Semiannually'].includes(datasetFrequency) ? { showMonthYearPicker: true } : {}}
          {...isEndDate && startDate && !endDate ? { openToDate: startDate } : {}}
          {...isEndDate && startDate && !endDate ? { highlightDates: [startDate] } : {}}
          yearItemNumber={9}
          formatWeekDay={(nameOfDay) => nameOfDay.charAt(0)}
          showMonthDropdown
          showYearDropdown
          showDisabledMonthNavigation
          minDate={new Date(minDate)}
          maxDate={new Date(maxDate)}
        />
      </>
    )
  }
  const nodes = formatResourcesIntoNodes(resourcesByType, useDisplayName)
  const hasDateBeenSelected = startDate !== null && endDate !== null
  const hasCustomizedOptions = customizedOptions && customizedOptions.length > 0
  const areThereAnyNodes = (hasCustomizedOptions) || (nodes && nodes.length > 0)

  if (hasSingleDataset && !startDate && !endDate) {
    const [year, month, day] = resourcesByType?.Primary[0].datasetVersionDate?.split('-')
    const dataSetDate = new Date(year, month - 1, day)

    setStartDate(dataSetDate)
    setEndDate(dataSetDate)
  }

  return (
    <Form.Group>
      <div className='DatePickerContainer'>
        {hasSingleDataset
          ? (
            <>
              <div className='SingleDatasetDate SmallHeader'>Latest dataset</div>
              <div className='DatepickerLine'>
                <CalendarFromIcon className='calendarFromIcon' />{resourcesByType?.Primary ? resourcesByType?.Primary[0]?.datasetVersionLabel : ''}
              </div>
            </>
          )
          : (
            <>
              <div className='SmallHeader SelectDateInRange'>Select date range:</div>
              <div className='DatepickerLine'>
                {renderDatePicker(setStartDate, startDate, false)}
                <span>to</span>
                {renderDatePicker(setEndDate, endDate, true)}
                {(startDate || endDate) && <div className='resetDate'><button onClick={() => resetDates()}>Reset</button></div>}
              </div>
            </>
          )}
      </div>
      <div className='FilePickerContainer'>
        <div className='SmallHeader SelectFilesForDownload'>Select files to include in your download:</div>
        <div>
          {hasDateBeenSelected && !areThereAnyNodes && (
            <div className='InvalidSelectionWindow'><div>No data is available for the selected date range.<br />Select new dates or <button className='resetLink' onClick={() => resetDates()}>reset</button>.</div></div>
          )}
          {!hasDateBeenSelected && <div className='InvalidSelectionWindow'>Make a date selection first to see available files</div>}
          {hasDateBeenSelected && areThereAnyNodes &&
            (
              <FilePicker key={uniqueDateRangeKey} nodes={hasCustomizedOptions && areCheckboxesDirty ? customizedOptions : nodes} itemsUpdated={itemsUpdated} maxGB={maxGB} setCheckboxesDirty={setCheckboxesDirty} />
            )}
        </div>
      </div>
    </Form.Group>
  )
}

DownloadModalCustomized.propTypes = {
  itemsUpdated: PropTypes.func,
  customizedOptions: PropTypes.array,
  selectedStartDate: PropTypes.object,
  selectedEndDate: PropTypes.object,
  datesUpdated: PropTypes.func,
  resourcesByType: PropTypes.object,
  datasetFrequency: PropTypes.string,
  hasSingleDataset: PropTypes.bool,
  versionBoundry: PropTypes.object,
  maxGB: PropTypes.number,
  useDisplayName: PropTypes.bool,
  areCheckboxesDirty: PropTypes.bool,
  setCheckboxesDirty: PropTypes.func
}

export default DownloadModalCustomized
