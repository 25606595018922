import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as SortDownIcon } from '../../../assets/fontawesome/solid/sort-down.svg'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import FormGroup from 'react-bootstrap/FormGroup'
import FormLabel from 'react-bootstrap/FormLabel'
import Select from 'react-select'
import cx from 'classnames'
import Checkbox from '../Checkbox/Checkbox'
import ModalCloseButton from '../ModalCloseButton/ModalCloseButton'
import datasetVersionFormatter from '../../../utilities/datasetVersionFormatter'
import { ReactComponent as DownToLineIcon } from '../../../assets/fontawesome/solid/down-to-line.svg'
import LoadingCover from '../LoadingCover/LoadingCover'
import isEmpty from 'lodash/isEmpty'
import memoize from 'lodash/memoize'
import without from 'lodash/without'
import some from 'lodash/some'
import {
  getDatasetVersionValue,
  getNewestDatasetVersion,
  getOldestDatasetVersion,
  excludeLaterDatasetVersions,
  excludeEarlierDatasetVersions
} from '../../../utilities/datasetHelpers'
import {
  getResourcesByType,
  getResourceFileSizeByType,
  getFileExtension,
  formatBytes,
  buildVersionsSelectList,
  getResourcesForOneVersion,
  getResourcesInRangeOfVersions,
  downloadFile,
  removeDuplicateFileResources
} from './downloadModalHelpers'
import { trackDownloadModalClick } from '../../../utilities/analyticsEventHelpers'
import { logDatasetDownload } from '../../../services/api'

import './DownloadModalLegacy.scss'

// using memoize to save on repeated calculations
const getNewestVersion = memoize(getNewestDatasetVersion)
const getOldestVersion = memoize(getOldestDatasetVersion)

const getInitialState = () => ({
  latest: true,
  selectedFrom: null,
  selectedTo: null,
  selectedResources: [],
  resourceDownloads: []
})

function wait () {
  return new Promise(resolve => setTimeout(() => resolve(), 1))
}

class DownloadModalLegacy extends PureComponent {
  state = getInitialState()

  downloadRequest = null

  get fromVersionSelectValue () {
    const { selectedFrom } = this.state
    // fall back to defaults if no selections are present
    return selectedFrom || this.defaultFromVersionOption
  }

  get toVersionSelectValue () {
    const { selectedTo } = this.state
    // fall back to defaults if no selections are present
    return selectedTo || this.defaultToVersionOption
  }

  get defaultFromVersionOption () {
    const { versions, datasetFrequency } = this.props
    const oldestVersion = getOldestVersion(versions)

    return {
      value: oldestVersion ? datasetVersionFormatter(datasetFrequency, oldestVersion.fieldDatasetVersion) : null,
      label: oldestVersion ? datasetVersionFormatter(datasetFrequency, oldestVersion.fieldDatasetVersion) : null,
      id: oldestVersion ? oldestVersion.id : null,
      versionObj: oldestVersion
    }
  }

  get defaultToVersionOption () {
    const { versions, datasetFrequency } = this.props
    const newestVersion = getNewestVersion(versions)

    return {
      value: newestVersion ? datasetVersionFormatter(datasetFrequency, newestVersion.fieldDatasetVersion) : null,
      label: newestVersion ? datasetVersionFormatter(datasetFrequency, newestVersion.fieldDatasetVersion) : null,
      id: newestVersion ? newestVersion.id : null,
      versionObj: newestVersion
    }
  }

  get fromSelectOptions () {
    if (this.toVersionSelectValue.id === null) {
      return []
    }
    const { versions, datasetFrequency } = this.props
    const toVersion = versions.find(version => {
      return version.id === this.toVersionSelectValue.id
    })

    // we do not want to show later versions in the 'from' menu than what is selected for the 'to' value
    const filteredVersions = excludeLaterDatasetVersions(versions, toVersion, { includeEqual: true })
    return buildVersionsSelectList(filteredVersions, datasetFrequency, 'asc')
  }

  get toSelectOptions () {
    if (this.fromVersionSelectValue.id === null) {
      return []
    }
    const { versions, datasetFrequency } = this.props
    const fromVersion = versions.find(version => {
      return version.id === this.fromVersionSelectValue.id
    })

    // we do not want to show earlier versions in the 'to' menu than what is selected for the 'from' value
    const filteredVersions = excludeEarlierDatasetVersions(versions, fromVersion, { includeEqual: true })
    return buildVersionsSelectList(filteredVersions, datasetFrequency)
  }

  get totalFileSize () {
    const { selectedResources } = this.state

    const primaryResourceFileSize = getResourceFileSizeByType(this.relevantPrimaryFileResources).Primary || 0
    const additionalResourceFileSize = getResourceFileSizeByType(this.relevantAdditionalResources)

    const resourcesToDownloadBytes = selectedResources.reduce((sum, resourceTypeToDownload) => {
      return sum + additionalResourceFileSize[resourceTypeToDownload]
    }, primaryResourceFileSize)

    return resourcesToDownloadBytes.toFixed(0)
  }

  get totalFileSizeString () {
    return formatBytes(this.totalFileSize)
  }

  // Because we have all versions' resources at once, we will use this to get the relevant resources based on
  // the state values (latest, to/from, etc)
  get resourcesForVersionSelection () {
    const { currentVersion, downloadResources, versions } = this.props
    const { latest } = this.state

    let resourcesForVersionSelection = []

    if (latest) {
      const relevantVersion = currentVersion || getNewestVersion(versions)
      resourcesForVersionSelection = getResourcesForOneVersion(downloadResources, relevantVersion)
    } else {
      resourcesForVersionSelection = getResourcesInRangeOfVersions(downloadResources, this.fromVersionSelectValue.versionObj, this.toVersionSelectValue.versionObj)
    }

    return resourcesForVersionSelection
  }

  get relevantPrimaryFileResources () {
    return this.resourcesForVersionSelection.filter(resource => {
      const { type, fileUrl } = resource
      return (
        type === 'Primary' &&
        !!(fileUrl)
      )
    })
  }

  get relevantAdditionalResources () {
    return this.resourcesForVersionSelection.filter(resource => {
      const { showOnDownloadModal, fileUrl } = resource
      // we also need to respect the backend showOnDownloadModal flag in addition to
      // making sure there is a file to download
      return (
        showOnDownloadModal &&
        !!(fileUrl)
      )
    })
  }

  handleSetNewestVersion = value => {
    this.setState({
      selectedTo: value
    })
  }

  handleSetOldestVersion = value => {
    this.setState({
      selectedFrom: value
    })
  }

  // toggle between latest version & range selection
  setLatest = (latest) => {
    return () => {
      this.setState({ latest })
    }
  }

  // track user requested dataset resources to download
  handleCheckboxToggle = (resourceType, checked) => {
    const { selectedResources } = this.state
    if (checked) {
      this.setState({
        selectedResources: without(selectedResources, resourceType)
      })
    } else {
      this.setState({
        selectedResources: [...selectedResources, resourceType]
      })
    }
  }

  handleDownloadClick = async () => {
    const { datasetTypeUuid } = this.props
    const { selectedResources } = this.state

    logDatasetDownload(datasetTypeUuid)
    const requiredDownloads = removeDuplicateFileResources(this.resourcesForVersionSelection)
      .filter(resource => {
        return (
          !!(resource.fileUrl) &&
          ['Primary', 'License Agreement'].includes(resource.type)
        )
      })
    trackDownloadModalClick(requiredDownloads[0], selectedResources.reduce((a, b) => a + ', ' + b, ''), window.utag)
    // group resources by type before combining into single array so that duplicates
    // are accounted for only within the particular type
    const resourcesByType = getResourcesByType(this.relevantAdditionalResources)

    const resourceDownloads = selectedResources
      .reduce((downloads, resourceType) => [...downloads, ...resourcesByType[resourceType]], requiredDownloads)
      .map(resource => ({ ...resource, isDownloadComplete: false, downloadProgressBytes: 0 }))

    this.setState({
      resourceDownloads: resourceDownloads
    })

    resourceDownloads.forEach(async (resource, index) => {
      // insert a short delay in between download requests since Safari does not
      // allow all downloads to start in parallel
      await wait()
      downloadFile(resource.fileUrl, {
        fileName: resource.fileName,
        onLoadSuccess: () => {
          this.setState(state => {
            const updated = [...state.resourceDownloads]
            updated[index].isDownloadComplete = true
            return {
              ...state,
              resourceDownloads: updated
            }
          })
        },
        onLoadProgress: (e) => {
          this.setState(state => {
            const updated = [...state.resourceDownloads]
            updated[index].downloadProgressBytes = e.loaded
            return {
              ...state,
              resourceDownloads: updated
            }
          })
        },
        onLoadError: () => {
          window.alert(`Sorry, there was error downloading ${resource.fileName}`)
          // mark download complete if error so that download spinner does not stay on screen
          this.setState(state => {
            const updated = [...state.resourceDownloads]
            updated[index].isDownloadComplete = true
            return {
              ...state,
              resourceDownloads: updated
            }
          })
        }
      })
    })
  }

  handleShowModal = () => {
    this.setState(getInitialState())
  }

  handleHideModal = () => {
    const { onHide } = this.props
    this.downloadRequest = null
    onHide()
  }

  renderModalBody () {
    const {
      latest,
      selectedResources
    } = this.state

    const {
      versions,
      currentVersion,
      datasetFrequency
    } = this.props

    const commonSelectProps = {
      className: 'Download__select',
      classNamePrefix: 'Download__select',
      components: { DropdownIndicator },
      isClearable: false,
      isSearchable: false,
      isMulti: false
    }

    const newestVersion = getNewestDatasetVersion(versions)
    const relevantResources = this.relevantAdditionalResources
    const resourcesByType = getResourcesByType(relevantResources)
    const resourceByTypeFileSizes = getResourceFileSizeByType(relevantResources)
    const hasMultipleYearsOfData = versions.length > 1

    return (
      <>
        <p className='DownloadModal__description'>Use this data for offline use in other applications. You can download the latest data or select from a range of years.</p>
        <div className='DownloadModal__year-selection'>
          {/* Latest Data */}
          <div
            className={cx({
              'DownloadModal__form-section': true,
              disabled: !latest
            })}
          >
            <label>{currentVersion ? 'Selected data' : 'Latest data'}</label>
            <div
              className='DownloadModal__form-section-latest DownloadModal__form-section-inner'
              onClick={this.setLatest(true)}
            >
              <div>
                {currentVersion
                  ? datasetVersionFormatter(datasetFrequency, getDatasetVersionValue(currentVersion))
                  : datasetVersionFormatter(datasetFrequency, getDatasetVersionValue(newestVersion))}
              </div>
            </div>
          </div>
          {/* Years of Data */}
          {hasMultipleYearsOfData &&
            <>
              <span>or</span>
              <div
                className={cx({
                  'DownloadModal__form-section': true,
                  disabled: latest
                })}
              >
                <label>Data range</label>
                <div
                  className='DownloadModal__form-section-range DownloadModal__form-section-inner'
                  onClick={this.setLatest(false)}
                >
                  <FormGroup className='DownloadModal__FormGroup'>
                    <FormLabel>From</FormLabel>
                    <Select
                      {...commonSelectProps}
                      value={this.fromVersionSelectValue}
                      options={this.fromSelectOptions}
                      onChange={this.handleSetOldestVersion}
                    />
                  </FormGroup>
                  <FormGroup className='DownloadModal__FormGroup'>
                    <FormLabel>To</FormLabel>
                    <Select
                      {...commonSelectProps}
                      value={this.toVersionSelectValue}
                      options={this.toSelectOptions}
                      onChange={this.handleSetNewestVersion}
                    />
                  </FormGroup>
                </div>
              </div>
            </>}
        </div>
        <div className='DownloadModal__resource-selection'>
          <div className='DownloadModal__form-section'>
            <label>INCLUDE THESE RESOURCES</label>
            <div className='DownloadModal__form-section-resources DownloadModal__form-section-inner'>
              <ul>
                {Object.keys(resourcesByType).map((resourceType, i) => {
                  const checked = selectedResources.includes(resourceType)
                  const resourceOfTypeList = resourcesByType[resourceType]
                  const exampleResource = resourceOfTypeList[0]
                  // display indicator only if more than 1 file of type
                  const numberIndicator = resourceOfTypeList.length > 1 ? ` (${resourceOfTypeList.length})` : null

                  return (
                    <li key={i}>
                      <Checkbox checked={checked} onChange={() => this.handleCheckboxToggle(resourceType, checked)} />
                      <span className='name'>{resourceType}{numberIndicator}</span>
                      <span className='type'>{getFileExtension(exampleResource.fileName)}</span>
                      <span className='size'>{`${formatBytes(resourceByTypeFileSizes[resourceType])}`}</span>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </>
    )
  }

  renderModalFooter () {
    return (
      <Modal.Footer className='DownloadModal__modal-footer'>
        <span>For better experience, use Google Chrome or Mozilla Firefox. Use of a database or statistical software may be required for large files.</span>
        <Button variant='primary' onClick={this.handleDownloadClick}>
          <span><DownToLineIcon className='dropdownIndicator' /></span>
          <span>Download Files</span>
        </Button>
        <span>Estimated file size: <span>{this.totalFileSizeString}</span></span>
      </Modal.Footer>
    )
  }

  renderDownloadDisplay () {
    const { resourceDownloads } = this.state

    const downloadProgressBytes = resourceDownloads.reduce((sum, resource) => resource.downloadProgressBytes + sum, 0)
    const downloadTotalBytes = resourceDownloads.reduce((sum, resource) => Number(resource.fileSize) + sum, 0)
    const statusText = `Downloading (${Math.ceil((downloadProgressBytes / downloadTotalBytes) * 100)}%)...`

    return (
      <div className='loadingContainer'>
        <LoadingCover statusText={statusText} />
      </div>
    )
  }

  render () {
    const { resourceDownloads } = this.state
    const { show, versions, datasetName } = this.props

    // all sorts of weird stuff happens when this tries to render without any available versions
    if (isEmpty(versions)) {
      return null
    }

    const isDownloading = some(resourceDownloads, resource => !resource.isDownloadComplete)

    return (
      <Modal
        className='DownloadModalLegacy'
        size='lg'
        show={show}
        onShow={this.handleShowModal}
        onHide={this.handleHideModal}
        centered
      >
        <Modal.Header className='DownloadModal__modal-header'>
          <Modal.Title>
            Download {datasetName}
          </Modal.Title>
          <ModalCloseButton onClick={this.handleHideModal} />
        </Modal.Header>
        <Modal.Body className='DownloadModal__modal-body'>
          {!isDownloading && this.renderModalBody()}
          {isDownloading && (
            <div className='loadingContainer'>
              <LoadingCover statusText='Downloading...' />
            </div>
          )}
        </Modal.Body>
        {!isDownloading && this.renderModalFooter()}
        {isDownloading && this.renderDownloadDisplay()}
      </Modal>
    )
  }
}

const DropdownIndicator = (props) => {
  return (
    <span><SortDownIcon /></span>
  )
}

DownloadModalLegacy.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  versions: PropTypes.array.isRequired,
  downloadResources: PropTypes.array.isRequired,
  datasetTypeUuid: PropTypes.string,
  datasetFrequency: PropTypes.string.isRequired,
  datasetName: PropTypes.string.isRequired,
  currentVersion: PropTypes.object
}

DownloadModalLegacy.defaultProps = {
  datasetFrequency: 'Annually',
  versions: [],
  downloadResources: [],
  datasetName: ''
}

export default DownloadModalLegacy
