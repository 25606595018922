import React, { useState } from 'react'
import downloadIndicatorStore from '../../../stores/downloadIndicatorStore'
import DownloadProgressBar from './DownloadProgressBar'
import { ReactComponent as ChevronDown } from '../../../assets/fontawesome/custom/chevron-down.svg'
import { getFeatureSetting } from '../../../stores/siteStore/siteStore'

import './DownloadStatusWindow.scss'

const DownloadStatusWindow = () => {
  useState(downloadIndicatorStore.useState())
  const [statusCollapsed, setStatusCollapsed] = useState(false)

  const downloadZip = getFeatureSetting('downloadModalV2', 'downloadZip')
  const progresses = downloadIndicatorStore.getRawState()?.progresses
  const handleWindowToggle = () => {
    setStatusCollapsed(!statusCollapsed)
  }
  return (progresses && progresses?.some(p => p?.active === true) &&
    <div className={statusCollapsed ? 'DownloadStatusWindow collpased' : 'DownloadStatusWindow expanded'}>
      <div className='TopHeader' />
      <div className='StatusWindowTitleContainer'>
        <div className='DownloadStatus'>Download Status</div>
        <div className='WindowToggleButton'><button onClick={handleWindowToggle}><ChevronDown /></button></div>
      </div>
      <div className={statusCollapsed ? 'ProgressBarsContainer' : 'ProgressBarsContainer PaddedContainer'}>
        {
          progresses.map((p) => { return p.active ? <DownloadProgressBar className='DownloadProgressBar' progress={p} key={p.uuid} downloadZip={downloadZip} statusCollapsed={statusCollapsed} /> : '' })
        }
      </div>
    </div>)
}

export default DownloadStatusWindow
