import React from 'react'
import PropTypes from 'prop-types'

const LEFT = 'left'

const CaretLeftRightIcon = ({ direction, isDisabled = false, color }) => {
  const defaultColor = isDisabled ? '#aeb0b5' : '#0071bc'
  let svgTransform = ''

  if (direction === LEFT) {
    svgTransform = 'rotate(180deg)'
  }

  return (
    <div style={{ WebkitTransform: svgTransform, transform: svgTransform }} className='CaretLeftRightIcon'>
      <svg width='8px' height='12px' viewBox='0 0 8 12' version='1.1' xmlns='http://www.w3.org/2000/svg'>
        <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g id='Pagination' transform='translate(-702.000000, -9.000000)' fill={color || defaultColor}>
            <g id='Pagination_1'>
              <g id='Next' transform='translate(648.000000, 4.000000)'>
                <g id='2.-Atom/Icon/Down-Caret' transform='translate(57.750000, 11.000000) rotate(-90.000000) translate(-57.750000, -11.000000) translate(51.750000, 7.250000)'>
                  <path d='M5.6016403,7.50246467 L0.165202109,1.42517165 C-0.0550673697,1.182699 -0.0550673697,0.790615583 0.165202109,0.548142941 L0.497949619,0.181854482 C0.718219098,-0.0606181605 1.07439953,-0.0606181605 1.29466901,0.181854482 L6,5.45950369 L10.705331,0.187013474 C10.9256005,-0.0554591681 11.2817809,-0.0554591681 11.5020504,0.187013474 L11.8347979,0.553301933 C12.0550674,0.795774575 12.0550674,1.187858 11.8347979,1.43033064 L6.3983597,7.50762367 C6.17809022,7.74493732 5.82190978,7.74493732 5.6016403,7.50246467 Z' id='2.-Atom/Icon/Caret' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  )
}

CaretLeftRightIcon.propTypes = {
  direction: PropTypes.string,
  isDisabled: PropTypes.bool,
  color: PropTypes.string
}

export default CaretLeftRightIcon
