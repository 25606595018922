import log from '../log'

const defaultEventObj = {
  ga_event_category: null,
  ga_event_action: null,
  ga_event_label: null, // using for implicit values
  ga_event_value: null,
  ga_event_non_interaction: false,
  dataset_name: null,
  dataset_version: null,
  file_type: null,
  file_size: null,
  file_name: null,
  link_url: null,
  link_text: null,
  content_type: null, // need to hook up
  data_resource: null, // need to hook up, drupal resource
  grouping: null,
  search_term: null,
  search_results_num: null
}

class Analytics {
  constructor () {
    this.eventObj = defaultEventObj
  }

  setResultsCount (count) {
    this.eventObj.search_results_num = count
  }

  setPageviewData (title, path) {
    this.eventObj.page_path = path
    this.eventObj.title = title
  }

  trackPageview (pageTitle, pagePath, utag) {
    if (utag) {
      this.setPageviewData(pageTitle, pagePath)
      utag.view(this.eventObj, () => log.debug(`pageview payload - { title: '${this.eventObj.title}', page_path: '${this.eventObj.page_path}'}`))
    }
  }

  trackEvent (eventData, utag) {
    if (utag) {
      const currentEventObj = { ...this.eventObj, ...eventData }
      utag.link(currentEventObj, () => log.debug(`event payload - { ${JSON.stringify(eventData)}, title: '${this.eventObj.title}', page_path: '${this.eventObj.page_path}'}`))
    }
  }
}

export default new Analytics()
