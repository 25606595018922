import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { getAssetOrigin, getContactHref } from './linkHelpers'

// field prop names for type inspection
const ANCILLARY_FILE_PROP = 'fieldAncillaryFile'
const DATASET_TYPE_PROP = 'fieldRefDatasetType'
const EXTERNAL_LINK_PROP = 'fieldExternalLink'
const INTERNAL_LINK_PROP = 'fieldInternalPage'
const INTERNAL_LINK_REF_PROP = 'fieldStoryInternalPageRef'

const FILE_LINK_TYPE = 'file'
const DATASET_LINK_TYPE = 'dataset'
const EXTERNAL_LINK_TYPE = 'external'
const INTERNAL_LINK_TYPE = 'internal'

/**
 * Common function to extract field_ref_contact_email and associated data
 * from deserialized JSON API data
 * @param {object} data
 * @param {string} data.fieldContactHeader
 * @param {string} data.fieldContactShortDescription
 * @param {object} data.fieldRefContactEmail
 * @param {string} data.fieldRefContactEmail.fieldContactEmail
 */
export const getContactEmail = (data) => {
  const temp = {
    header: get(data, 'fieldContactHeader', undefined),
    description: get(data, 'fieldContactShortDescription', undefined),
    contactLink: get(data, 'fieldRefContactEmail.fieldContactEmail', undefined)
  }

  // defaultProp consumers will choke on null values so handle
  return Object.keys(temp).reduce((prev, curr) => {
    const safeValue = isEmpty(temp[curr]) ? undefined : temp[curr]

    return {
      ...prev,
      [curr]: safeValue
    }
  }, {})
}

function determineLinkType (data = {}) {
  if (data.hasOwnProperty(ANCILLARY_FILE_PROP)) {
    return FILE_LINK_TYPE
  } else if (data.hasOwnProperty(EXTERNAL_LINK_PROP)) {
    return EXTERNAL_LINK_TYPE
  } else if (data.hasOwnProperty(DATASET_TYPE_PROP)) {
    return DATASET_LINK_TYPE
  } else if (data.hasOwnProperty(INTERNAL_LINK_PROP) || data.hasOwnProperty(INTERNAL_LINK_REF_PROP)) {
    return INTERNAL_LINK_TYPE
  } else {
    return null
  }
}

export const extractAlertLinkData = (data) => {
  const linkType = determineLinkType(data)
  const partialFileUrl = get(data, `${ANCILLARY_FILE_PROP}.fieldMediaFile.uri.url`)
  const contactEmail = get(data, 'fieldInternalPage.fieldContactEmail')
  let title
  let uri

  switch (linkType) {
    case FILE_LINK_TYPE:
      uri = `${getAssetOrigin()}${partialFileUrl}`
      title = get(data, `${ANCILLARY_FILE_PROP}.name`)
      break
    case EXTERNAL_LINK_TYPE:
      uri = get(data, 'fieldExternalLink.uri')
      title = get(data, 'fieldCallToActionText')
      break
    case INTERNAL_LINK_TYPE:
      uri = contactEmail
        ? getContactHref(contactEmail, 'General')
        : get(data, `${INTERNAL_LINK_PROP}.path.alias`)
      title = get(data, `${INTERNAL_LINK_PROP}.title`)
      break
    case DATASET_LINK_TYPE:
      uri = get(data, `${DATASET_TYPE_PROP}.path.alias`)
      title = get(data, `${DATASET_TYPE_PROP}.name`)
      break
    default:
      uri = ''
      title = ''
      break
  }

  return {
    title,
    uri
  }
}

function getInternalPageProp (data) {
  return data.hasOwnProperty(INTERNAL_LINK_PROP) ? INTERNAL_LINK_PROP : INTERNAL_LINK_REF_PROP
}

/**
 * Helper to extract the refactored Call To Action data from API data
 * @param {object} data
 */
export const extractCtaData = (data) => {
  const linkType = determineLinkType(data)
  const title = get(data, 'fieldCallToActionText', '')
  const partialFileUrl = get(data, `${ANCILLARY_FILE_PROP}.fieldMediaFile.uri.url`)
  const targetInternalPageProp = getInternalPageProp(data)
  let uri

  switch (linkType) {
    case FILE_LINK_TYPE:
      uri = `${getAssetOrigin()}${partialFileUrl}`
      break
    case EXTERNAL_LINK_TYPE:
      uri = get(data, 'fieldExternalLink.uri')
      break
    case DATASET_LINK_TYPE:
      uri = get(data, `${DATASET_TYPE_PROP}.path.alias`)
      break
    case INTERNAL_LINK_TYPE:
      uri = get(data, `${targetInternalPageProp}.path.alias`)
      break
    default:
      uri = ''
      break
  }

  return {
    title,
    uri
  }
}

/**
* Users can add one of three types of data to resources sections
* inspect object properties to deduce resource type & extract data
* @param {object} resource
*/
export const extractResourceTypeData = (resource = {}, isTools) => {
  const linkType = determineLinkType(resource)
  const { uri, title: ctaTitle } = extractCtaData(resource)
  const targetInternalPageProp = getInternalPageProp(resource)
  const fileContentType = get(resource, `${ANCILLARY_FILE_PROP}.fieldRefContentType.name`)
  const internalPageContentType = get(resource, `${targetInternalPageProp}.fieldRefContentType.name`)
  const typeData = {
    type: '',
    title: '',
    link: {
      url: uri,
      name: ''
    }
  }

  switch (linkType) {
    case FILE_LINK_TYPE:
      typeData.type = fileContentType
      typeData.title = get(resource, `${ANCILLARY_FILE_PROP}.name`)
      typeData.link.name = `Download ${fileContentType}`
      break
    case EXTERNAL_LINK_TYPE:
      typeData.type = isTools ? 'External Link' : 'Related Link'
      // sometimes the external link resource uses fieldComponentTitle, sometimes cta title ¯\_(ツ)_/¯
      typeData.title = get(resource, 'fieldComponentTitle') || ctaTitle
      typeData.link.name = isTools ? 'View External Link' : 'View Resource'
      break
    case DATASET_LINK_TYPE:
      typeData.type = 'Dataset'
      typeData.title = get(resource, `${DATASET_TYPE_PROP}.name`)
      typeData.link.name = 'View Dataset'
      break
    case INTERNAL_LINK_TYPE:
      typeData.type = internalPageContentType
      typeData.title = get(resource, `${targetInternalPageProp}.title`)
      typeData.link.name = `View ${internalPageContentType}`
      break
    default:
      typeData.link.name = ''
  }

  return typeData
}
