import React from 'react'
import PropTypes from 'prop-types'
import Badge from 'react-bootstrap/Badge'
import CloseIcon from '../../icons/CloseIcon'

import './SearchFiltersBadge.scss'

const SearchFiltersBadge = ({ onClick, text }) => {
  return (
    <Badge
      className='SearchFiltersBadge'
      pill
      variant='primary'
      onClick={onClick}
    >
      <div>
        <span className='text'>{text}</span>
        <CloseIcon size={10} />
      </div>
    </Badge>
  )
}

SearchFiltersBadge.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.any
}

export default SearchFiltersBadge
