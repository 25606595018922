import React, { useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import Collapse from 'react-bootstrap/Collapse'
import without from 'lodash/without'
import uniq from 'lodash/uniq'
import Text from '../../common/Text/Text'
import SearchFiltersBadge from './SearchFiltersBadge'
import { ReactComponent as SortDownIcon } from '../../../assets/fontawesome/solid/sort-down.svg'
import Checkbox from '../../common/Checkbox/Checkbox'
import './SearchFiltersTopics.scss'

export const SHOW_MORE_LIMIT = 5

const SearchFiltersTopics = (props) => {
  const {
    currentCategory,
    activeFiltersForCategory = [],
    onUpdateFilters,
    isLoadingCounts
  } = props
  const topics = currentCategory.options || []
  const initialTopicExpandedStates = topics.reduce((state, topic) => ({ ...state, [topic.name]: true }), {})
  const initialShowMoreStates = topics.reduce((state, topic) => ({ ...state, [topic.name]: false }), {})
  const [topicExpandedStates, setTopicExpandedStates] = useState(initialTopicExpandedStates)
  const [showMoreStates, setShowMoreStates] = useState(initialShowMoreStates)
  const isL1OnlyDisplay = currentCategory.max_depth === 1

  function handleExpandClick (topicName) {
    setTopicExpandedStates(state => ({ ...state, [topicName]: !state[topicName] }))
  }

  function handleShowClick (topicName) {
    setShowMoreStates(state => ({ ...state, [topicName]: !state[topicName] }))
  }

  function handleChildCheckboxChange (e) {
    const checked = e.target.checked
    const name = e.target.dataset.name
    const updatedFilters = checked
      ? uniq([...activeFiltersForCategory, name])
      : without(activeFiltersForCategory, name)

    onUpdateFilters(updatedFilters)
  }

  function handleParentCheckboxChange (e, l1, validL2s) {
    const l1Name = l1.name
    const checkedL1s = validL2s.length ? [...validL2s.map(l2 => l2.name)] : [l1Name]
    const updatedFilters = e.target.checked ? uniq([...activeFiltersForCategory, ...checkedL1s]) : without(activeFiltersForCategory, ...checkedL1s)

    onUpdateFilters(updatedFilters)
  }

  /**
   * handler for removing L1 and L2s from active filters
   * @param {object} topic
   */
  function handleBadgeClick (l1) {
    const filtersToRemove = [l1.name, ...l1.children.map(l2 => l2.name)]
    const updatedFilters = without(activeFiltersForCategory, ...filtersToRemove)
    onUpdateFilters(updatedFilters)
  }

  function getTotalActiveL2s (l2s) {
    // count number of L2s that are not disabled
    return l2s.filter((l2) => l2.count > 0)
  }

  function renderL2s (l2s) {
    return l2s.map((l2, idx) => {
      const isL2Selected = activeFiltersForCategory.includes(l2.name)
      const isDisabled = !l2.count || isLoadingCounts
      return (
        <li className={cx('SearchFiltersTopics__subtopic', { disabled: isDisabled })} key={`${l2.name}-${idx}`}>
          <span>
            <Checkbox
              disabled={isDisabled}
              dark
              data-name={l2.name}
              checked={isL2Selected}
              onChange={e => handleChildCheckboxChange(e)}
            />
          </span>
          <span className='SearchFiltersTopics__subtopic_txt' key={idx}>{l2.name} {`(${l2.count || 0})`}</span>
        </li>
      )
    })
  }

  return (
    <div className={cx('SearchFiltersTopics', { l1_only: isL1OnlyDisplay })}>
      <Text typeFace='03 H3/Desktop/White' className='SearchFiltersTopics__header'>
        <span>{currentCategory.label} </span>
      </Text>
      <ul className='SearchFilters__topicList'>
        {topics.map((l1, idx) => {
          const isExpanded = topicExpandedStates[l1.name]
          const l2s = l1.children || []
          const hasL2s = !!(l2s.length)
          const numOfL2s = l2s.length

          // show more values
          const shouldDisplayShowMoreButton = numOfL2s > SHOW_MORE_LIMIT
          const isShowingMore = showMoreStates[l1.name]
          const showMoreText = isShowingMore ? 'Show less' : 'Show more'
          const staticL2s = l2s.slice(0, SHOW_MORE_LIMIT)
          const additionalL2s = shouldDisplayShowMoreButton ? l2s.slice(SHOW_MORE_LIMIT) : []

          const shouldDisplayChildren = hasL2s && !isL1OnlyDisplay
          const isL1Selected = activeFiltersForCategory.includes(l1.name)
          // to include L1 in numOfSelectedFilters
          const initialFilterCount = isL1Selected ? 1 : 0
          // total num of selected filters - L1s and L2s
          const numOfSelectedFilters = l2s.reduce((total, l2) => {
            const isSelected = activeFiltersForCategory.includes(l2.name)
            return isSelected ? total + 1 : total
          }, initialFilterCount)
          const totalActiveL2s = hasL2s ? getTotalActiveL2s(l1.children) : []
          // select all should be disabled if there are no active L1s or L2s
          const isSelectAllDisabled = hasL2s ? totalActiveL2s.length === 0 : l1.count === 0
          // total number of active - not disabled - filters
          const totalFilters = hasL2s ? totalActiveL2s.length : 1 // no children = 1 possible filter
          const isSelectAllSelected = (numOfSelectedFilters > 0 && (numOfSelectedFilters === totalFilters)) || isL1Selected
          const hasAllFiltersSelected = isSelectAllSelected && (numOfSelectedFilters === totalFilters || !hasL2s)

          return (
            <li
              className={cx('SearchFilters__topic', { underline: !hasL2s, expanded: isExpanded, disabled: isSelectAllDisabled })}
              key={idx}
            >
              <div className='SearchFiltersTopics__topicHeader'>
                <Checkbox
                  disabled={isSelectAllDisabled}
                  dark
                  checked={hasAllFiltersSelected}
                  label={l1.name}
                  onChange={(e) => handleParentCheckboxChange(e, l1, totalActiveL2s)}
                />
                <Text
                  domElement='div'
                  typeFace='05 H5/Desktop/Regular/White'
                  className='SearchFiltersTopics__topicHeaderText'
                >
                  <span onClick={() => handleExpandClick(l1.name)}>{hasL2s ? `All ${l1.name}` : `${l1.name} (${l1.count})`}</span>
                  &nbsp;
                  {(numOfSelectedFilters > 0 && !isL1OnlyDisplay) && (
                    <SearchFiltersBadge
                      text={numOfSelectedFilters}
                      onClick={(e) => {
                        // prevent triggering expand action
                        e.stopPropagation()
                        handleBadgeClick(l1)
                      }}
                    />
                  )}
                </Text>
                {shouldDisplayChildren && (
                  <span className='expand-icon' onClick={() => handleExpandClick(l1.name)}>
                    <SortDownIcon class='SortDownIcon' />
                  </span>
                )}
              </div>
              {shouldDisplayChildren && (
                <Collapse in={isExpanded}>
                  <ul className='SearchFiltersTopics__subtopics'>
                    {renderL2s(staticL2s)}
                    {isShowingMore && renderL2s(additionalL2s)}
                    {shouldDisplayShowMoreButton && (
                      <li
                        className={cx('SearchFiltersTopics__subtopic', 'showMore', { expanded: isShowingMore })}
                        onClick={() => handleShowClick(l1.name)}
                      >
                        <span className='SearchFiltersTopics__subtopic_txt'>{showMoreText}</span>
                        <span className='expand-icon'>
                          <SortDownIcon class='SortDownIcon' />
                        </span>
                      </li>
                    )}
                  </ul>
                </Collapse>
              )}

            </li>
          )
        })}
      </ul>
    </div>
  )
}

SearchFiltersTopics.propTypes = {
  isLoadingCounts: PropTypes.bool,
  currentCategory: PropTypes.shape({
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    max_depth: PropTypes.number
  }),
  activeFiltersForCategory: PropTypes.arrayOf(PropTypes.string),
  onUpdateFilters: PropTypes.func.isRequired
}

export default SearchFiltersTopics
