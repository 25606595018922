import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import uuid from 'uuid/v4'

import './LoadingCover.scss'

const LoadingCover = ({ statusText }) => (
  <div className='LoadingCover'>
    <LoadingSpinner statusText={statusText} />
  </div>
)

LoadingCover.propTypes = {
  statusText: PropTypes.string
}

const LoadingSpinner = ({ statusText, className }) => {
  const gradientId = `gradient${uuid()}`
  return (
    <div className={cx('LoadingSpinner', className)}>
      <span className='sr-only'>Loading...</span>
      <svg className='loading_icon' width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <title>Loading...</title>
        <path fillRule='evenodd' clipRule='evenodd' d='M39.9994 60.0003C51.045 60.0003 59.9994 51.046 59.9994 40.0003C59.9994 28.9546 51.045 20.0003 39.9994 20.0003C28.9537 20.0003 19.9993 28.9546 19.9993 40.0003C19.9993 51.046 28.9537 60.0003 39.9994 60.0003ZM39.9994 73.3337C58.4088 73.3337 73.3327 58.4098 73.3327 40.0003C73.3327 21.5908 58.4088 6.66699 39.9994 6.66699C21.5899 6.66699 6.66602 21.5908 6.66602 40.0003C6.66602 58.4098 21.5899 73.3337 39.9994 73.3337Z' fill='#D6D7D9' />
        <path fillRule='evenodd' clipRule='evenodd' d='M33.334 13.3337C33.334 9.65176 36.3188 6.66699 40.0007 6.66699C58.5068 6.66699 73.334 21.8741 73.334 40.4153C73.334 47.9807 70.8672 54.9902 66.6934 60.6319C64.5037 63.5919 60.329 64.2162 57.369 62.0264C54.4091 59.8367 53.7848 55.662 55.9745 52.702C58.4985 49.2904 60.0007 45.0457 60.0007 40.4153C60.0007 29.043 50.9497 20.0003 40.0007 20.0003C36.3188 20.0003 33.334 17.0156 33.334 13.3337Z' fill={`url(#${gradientId})`} />
        <defs>
          <linearGradient id={gradientId} x1='53.334' y1='6.66699' x2='53.334' y2='63.3341' gradientUnits='userSpaceOnUse'>
            <stop stopColor='#7A00C7' />
            <stop offset='1' stopColor='#0071BC' />
          </linearGradient>
        </defs>
      </svg>

      {statusText && <div className='LoadingSpinner__statusText'>{statusText}</div>}
    </div>
  )
}

LoadingSpinner.propTypes = {
  statusText: PropTypes.string,
  className: PropTypes.string
}

export {
  LoadingSpinner
}

export default LoadingCover
