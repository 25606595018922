import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getAlert } from '../../services/api'
import Text from '../common/Text/Text'
import ModalCloseButton from '../common/ModalCloseButton/ModalCloseButton'
import LinkHandler from '../common/LinkHandler/LinkHandler'
import RichTextField from '../common/RichTextField/RichTextField'
import siteStore from '../../stores/siteStore'
import isEmpty from 'lodash/isEmpty'
import './Alert.scss'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'

const Alert = React.forwardRef((props, ref) => {
  const { closeAlert } = props
  const { showAlert } = siteStore.useState(state => ({
    showAlert: state.showAlert
  }))

  const [{ isLoading, alertDetails = {} }, setState] = useState({
    isLoading: true,
    alertDetails: {}
  })

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const alert = await getAlert()
        if (isEmpty(alert)) {
          siteStore.update(state => {
            state.showAlert = false
          })
        }
        setState({
          error: null,
          isLoading: false,
          refLoaded: ref,
          alertDetails: alert
        })
      } catch (error) {
        siteStore.update(state => {
          state.showAlert = false
        })
        setState({
          error,
          isLoading: false
        })
      }
    }
    loadInitialData()
  }, [ref])

  const { title, description, uri, linkTitle } = alertDetails

  return showAlert && !isLoading
    ? (
      <div ref={ref} className='alertBackground'>
        <ModalCloseButton onClick={closeAlert} color='white' />
        <Container className='alertDetail'>
          <Col lg={9} xs={11}>
            <Text typeFace='05 H5/Desktop/Regular/White'>{title}</Text>
            <Text typeFace='Body/16pt/White/L' domElement='div'><RichTextField content={description} /></Text>
            {uri && <LinkHandler href={uri}><Text typeFace='Body/18pt/White/L'>{linkTitle}</Text></LinkHandler>}
          </Col>
        </Container>
      </div>
    )
    : null
})

Alert.propTypes = {
  closeAlert: PropTypes.func.isRequired
}

export default Alert
