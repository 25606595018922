import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import config from '../config'
import { getScaledImgUrl, getAssetOrigin } from './linkHelpers'
import { getIncludedUrls, getMediaImageAltText } from '../services/formatters/global'
const {
  topicSubtopicCaseStudyConfig,
  topicSubtopicFilters
} = config

// prep case studies for display by consuming component
export const buildCaseStudies = (caseStudies, included) => {
  return {
    header: topicSubtopicCaseStudyConfig.header,
    subheader: topicSubtopicCaseStudyConfig.subheader,
    featuredContentItems: caseStudies.filter(caseStudy => caseStudy !== null).map(caseStudy => {
      const imgSrcPartialUrl = get(caseStudy, 'fieldRefImage.fieldMediaImage.uri.url')
      return {
        title: get(caseStudy, 'title'),
        description: get(caseStudy, 'fieldDescription'),
        link: {
          label: topicSubtopicCaseStudyConfig.defaultButtonLabel,
          url: get(caseStudy, 'path.alias'),
          imgSrc: getScaledImgUrl('medium', getIncludedUrls(included), `${getAssetOrigin()}${imgSrcPartialUrl}`),
          imgAlt: getMediaImageAltText(get(caseStudy, 'fieldRefImage'), included)
        }
      }
    }),
    spotlightCard: topicSubtopicCaseStudyConfig.spotlightCard
  }
}

/**
 * Extract component-friendly data from API to build the subtopic
 *   page body text block sections
 * @param {*} data
 */
export const buildTextSections = (data) => {
  const sections = get(data, 'fieldSubtopicPageSection', [])

  return sections.map(section => {
    const subsections = get(section, 'fieldSubtopicPageComponent')

    return {
      header: get(section, 'fieldSectionHeader', ''),
      paragraphs: subsections.map(p => (
        {
          subtitle: get(p, 'fieldComponentSubtitle', ''),
          text: get(p, 'fieldWysiwygParagraph.value', ''),
          footnotes: get(p, 'fieldFootnotes.value')
        }
      ))
    }
  })
}

/*
* Run through datasets once to build lists for display:
*   Clean datasets to match consumer format
*   Filter By list depends on data + default value All subtopics
*/
export const buildDatasetLists = (datasets) => {
  const target = {
    cleanDatasets: [],
    filters: [...topicSubtopicFilters]
  }

  datasets.reduce((prev, curr) => {
    const { cleanDatasets, filters } = prev
    const {
      name,
      slug,
      last_updated: lastUpdated,
      subtopic,
      topic,
      views
    } = curr

    cleanDatasets.push({
      heading: name,
      slug,
      date: lastUpdated,
      parent: subtopic || topic,
      views
    })

    if (subtopic && !filters.includes(subtopic)) {
      filters.push(subtopic)
    }

    return {
      cleanDatasets,
      filters
    }
  }, target)

  return target
}

/**
* check history prop for data payload pageReferrer
*  if found, take action as required
*  pageReferrer is an internal page type e.g. 'DATASET'
* @param {object} history window history object
* @param {function} callback function to call is pageReferrer located
*/
export const checkPageReferrer = (history, callback) => {
  const pageReferrer = get(history, 'location.pageReferrer')

  // remove state from history to avoid pollution :skull:
  if (pageReferrer) {
    delete history.location.pageReferrer

    callback()
  }
}

/**
 * Safe scroll to the param ref location
 */
export const scrollToRef = (ref) => {
  // if target ref mounted, scroll
  if (ref.current) {
    const { offsetTop } = ref.current

    window.scroll({
      top: offsetTop,
      left: 0,
      behavior: 'smooth'
    })
  }
}

/**
 * Sort by date descending; in case of ties sort alphabetically as well
 * @param {Object} a
 * @param {string} a.date primary sort field
 * @param {string} a.heading secondary sort field
 * @param {Object} b
 * @param {string} b.date primary sort field
 * @param {string} b.heading secondary sort field
 */
export const sortLastUpdatedAlphabetically = (a, b) => {
  if (a.date === b.date) return a.heading > b.heading ? 1 : -1

  return a.date > b.date ? -1 : 1
}

/**
 * Sort by view count descending; in case of ties sort alphabetically as well
 * @param {Object} a
 * @param {number} a.views primary sort field
 * @param {string} a.heading secondary sort field
 * @param {Object} b
 * @param {number} b.views primary sort field
 * @param {string} b.heading secondary sort field
 */
export const sortTopViewedAlphabetically = (a, b) => {
  if (a.views === b.views) return a.heading > b.heading ? 1 : -1

  return a.views > b.views ? -1 : 1
}

/**
 * reusable function to sort param datasets (see TopicDatasetsCarousel)
 * @param {('Last updated' | 'Top viewed' | 'A - Z' | 'Z - A')} sortType
 * @param {Array} datasetsToSort
 */
export const sortDatasets = (sortType, datasetsToSort) => {
  const sortedDataset = cloneDeep(datasetsToSort)
  const sortFunctions = {
    'Last updated': sortLastUpdatedAlphabetically,
    'Top viewed': sortTopViewedAlphabetically,
    'A - Z': (a, b) => a.heading > b.heading ? 1 : -1,
    'Z - A': (a, b) => a.heading > b.heading ? -1 : 1
  }
  const compare = sortFunctions[sortType]

  return sortedDataset.sort((a, b) => compare(a, b))
}
