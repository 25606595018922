import React from 'react'
import PropTypes from 'prop-types'
import ExternalLinkIcon from '../../../components/icons/ExternalLinkIcon'
import LinkHandler from '../../common/LinkHandler/LinkHandler'
import RichTextField from '../../common/RichTextField/RichTextField'
import { isExternalLink } from '../../../utilities/linkHelpers'
import defaultIcon from '../../../assets/chart-icon.svg'

import './Tool.scss'

const ButtonLabel = ({ url }) => {
  const isExternal = isExternalLink(url)

  return (
    <>
      <span className='Tool__button-text'>Launch Tool</span>
      {isExternal &&
        <ExternalLinkIcon color='white' />}
    </>
  )
}

ButtonLabel.propTypes = {
  url: PropTypes.string.isRequired
}

const Tool = ({ tool }) => {
  const {
    icon,
    title,
    description,
    url,
    altText
  } = tool
  return (
    <li className='Tool'>
      <span className='Tool__icon-container'>
        <img alt={altText || title} className='Tool__icon' src={icon || defaultIcon} />
      </span>
      <h4>{title}</h4>
      <RichTextField
        className='Tool__description'
        content={description}
      />
      <LinkHandler href={url} variant='primary' className='Tool__button'>
        <ButtonLabel url={url} />
      </LinkHandler>
    </li>
  )
}

Tool.propTypes = {
  tool: PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    altText: PropTypes.string
  })
}

export default Tool
