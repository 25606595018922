/*
  Must use forwardRef method for Boostrap Dropdown ref
*/
import React, { forwardRef } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

const SortMenu = forwardRef((props, ref) => {
  const {
    children,
    style,
    className,
    'aria-labelledby': labeledBy
  } = props

  return (
    <div
      aria-labelledby={labeledBy}
      className={cx('SortMenu', className)}
      ref={ref}
      style={style}
    >
      <ul className='list-unstyled'>
        {children}
      </ul>
    </div>
  )
})

// children: array from parent
// ...rest: Bootstrap Dropdown props
SortMenu.propTypes = {
  children: PropTypes.any.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  'aria-labelledby': PropTypes.string
}

export default SortMenu
