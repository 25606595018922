import merge from 'lodash/merge'
import toLower from 'lodash/toLower'
import defaults from './config/defaults.json'
import env from './config/env.json'
import local from './config/local.json'
import development from './config/development.json'
import production from './config/production.json'
import staging from './config/staging.json'
import testing from './config/testing.json'
import imp from './config/imp.json'

import stateFipsMapping from './config/stateFipsMapping.json'
import placeholders from './config/placeholders.json'

const configData = {
  ...defaults,
  stateFipsMapping,
  placeholders
}

const reactAppEnv = toLower(process.env.REACT_APP_ENV).trim() || 'development'

/**
 * Environment descriptions
 * development, staging, production => as labeled
 *
 * local => allow local development to bypass dev ACA cookie CORS issue
 * imp => a replica of the production server with same resources and db; used for load testing
 * testing => uses axios mockAdapter to mimic API calls instead of execing them
 */
if (reactAppEnv === 'development') merge(configData, development)
if (reactAppEnv === 'staging') merge(configData, staging)
if (reactAppEnv === 'production') merge(configData, production)

if (reactAppEnv === 'local') merge(configData, local)
if (reactAppEnv === 'imp') merge(configData, imp)
if (reactAppEnv === 'testing') merge(configData, testing)

Object.keys(env).forEach((reactEnvVarName) => {
  const envVar = process.env[reactEnvVarName]
  if (envVar) {
    const assignAt = env[reactEnvVarName]
    configData[assignAt] = envVar
  }
})

export default configData
