import React from 'react'
import PropTypes from 'prop-types'

const ExternalLinkIcon = ({ color, width, height }) => {
  const fillColor = color || '#0071BC'
  const iconHeight = height || 14
  const iconWidth = width || 14
  return (
    <span className='ExternalLinkIcon SvgIcon'>
      <svg width={iconWidth} height={iconHeight} viewBox='0 0 14 14' version='1.1' xmlns='http://www.w3.org/2000/svg'>
        <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g id='XL-01-Article-Case-Study' transform='translate(-603.000000, -9410.000000)' fill={fillColor}>
            <g id='References' transform='translate(290.000000, 9336.000000)'>
              <g id='Reference'>
                <g transform='translate(0.000000, 68.000000)'>
                  <path d='M325.444444,18.4444444 L314.555556,18.4444444 L314.555556,7.55555556 L320,7.55555556 L320,6 L314.555556,6 C313.692222,6 313,6.7 313,7.55555556 L313,18.4444444 C313,19.3 313.692222,20 314.555556,20 L325.444444,20 C326.3,20 327,19.3 327,18.4444444 L327,13 L325.444444,13 L325.444444,18.4444444 Z M321.555556,6 L321.555556,7.55555556 L324.347778,7.55555556 L316.702222,15.2011111 L317.798889,16.2977778 L325.444444,8.65222222 L325.444444,11.4444444 L327,11.4444444 L327,6 L321.555556,6 Z' id='Shape' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  )
}

ExternalLinkIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
}

export default ExternalLinkIcon
