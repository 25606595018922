import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import CaretLeftRightIcon from '../../icons/CaretLeftRightIcon'
import config from '../../../config'
import { DisplayContext } from '../../App/DisplayContext'
import {
  getCurrentPage,
  getMaxPageNumber,
  getNewOffset
} from '../../../utilities/paginationHelpers'
import { numberWithCommas } from '../../../utilities/searchPageHelpers'

import './Pagination.scss'

const Pagination = (props) => {
  const {
    goToPage: gtp,
    limit,
    total,
    offset,
    paginationMaxPages = config.paginationMaxPages,
    paginationMaxPagesMobile = config.paginationMaxPagesMobile
  } = props

  const { isMobile } = useContext(DisplayContext)
  const currentPage = getCurrentPage(offset, limit)
  const maxPagesToDisplay = isMobile ? paginationMaxPagesMobile : paginationMaxPages
  const totalPageCount = getMaxPageNumber(total, limit)
  const prevDisabled = offset === 0
  const nextDisabled = currentPage >= totalPageCount
  const showEllipsisAt = isMobile ? 4 : 6
  const noPagination = totalPageCount <= maxPagesToDisplay
  const lastSet = currentPage > (totalPageCount - showEllipsisAt)

  // handling edge case, if 10 pages, 5 overlaps the lastSet
  const isFiveOfTen = currentPage * 2 === totalPageCount && currentPage === 5

  // Should the first page number render
  const showFirstPage = !(currentPage < showEllipsisAt) && !(noPagination)
  const showMinDots = showFirstPage

  // Should the last page number render
  const showLastPage = (!lastSet && !noPagination) || isFiveOfTen
  const showMaxDots = showLastPage

  let pagesToRender = maxPagesToDisplay - (showFirstPage ? 1 : 0) - (showLastPage ? 1 : 0)

  if (totalPageCount < pagesToRender) pagesToRender = totalPageCount
  let startPage = currentPage
  // one of the first 6 nums return 1
  startPage = (currentPage < showEllipsisAt || startPage === 0) ? 1
    // one of last 6 numbers return the first number of the last pagesToRender
    : (lastSet ? (totalPageCount + 1) - pagesToRender : currentPage - (isMobile ? showEllipsisAt / 4 : showEllipsisAt / 2))

  if (startPage + pagesToRender > totalPageCount && totalPageCount > pagesToRender) {
    const atLastPage = startPage === totalPageCount ? 1 : 0
    // +1, when subtracting we need to include the last value in the count, not subtract from it
    startPage = (totalPageCount + 1) - pagesToRender + atLastPage
  }

  const pageNumbers = Array(pagesToRender).fill(0).map((v, i) => { return i + startPage })
  const goToPage = (pageNumber) => {
    gtp(getNewOffset(pageNumber, limit))
  }

  const handlePrevOnClick = () => {
    goToPage(currentPage - 1)
  }

  const handleNextOnClick = () => {
    goToPage(currentPage + 1)
  }

  return (
    <div className='Pagination'>
      <button
        className='Pagination__ctrl-btn Pagination__prev'
        disabled={prevDisabled}
        onClick={handlePrevOnClick}
      >
        <div className='Pagination__ctrl-btn__label'>
          <CaretLeftRightIcon direction='left' isDisabled={prevDisabled} />
          <span className='Pagination__ctrl-btn__text'>Previous</span>
        </div>
      </button>
      <ul className='Pagination__pages'>
        {/* Show first page */}
        {showFirstPage && (
          <li
            className='Pagination__pages-page-first'
            onClick={() => goToPage(1)}
          >1
          </li>
        )}
        {/* Show dot dot min */}
        {showMinDots && (
          <li
            className='Pagination__pages-dot-dot-dot'
          >...
          </li>
        )}
        {/* Page Numbers */}
        {pageNumbers.map((pageNumber, i) => (
          <li
            key={i}
            className={`Pagination__pages-page${pageNumber === currentPage ? ' Pagination__pages-page-active' : ''}`}
            onClick={pageNumber === currentPage ? null : () => goToPage(pageNumber)}
          >{numberWithCommas(pageNumber)}
          </li>
        ))}
        {/* Show dot dot max */}
        {showMaxDots && (
          <li
            className='Pagination__pages-dot-dot-dot'
          >...
          </li>
        )}
        {/* Show last page */}
        {showLastPage && (
          <li
            className='Pagination__pages-page-last'
            onClick={() => goToPage(totalPageCount)}
          >{numberWithCommas(totalPageCount)}
          </li>
        )}
      </ul>
      <button
        className='Pagination__ctrl-btn Pagination__next'
        disabled={nextDisabled}
        onClick={handleNextOnClick}
      >
        <span className='Pagination__ctrl-btn__label'>
          <span className='Pagination__ctrl-btn__text'>Next</span>
          <CaretLeftRightIcon isDisabled={nextDisabled} />
        </span>
      </button>
    </div>
  )
}

Pagination.propTypes = {
  total: PropTypes.number,
  limit: PropTypes.number,
  offset: PropTypes.number,
  paginationMaxPages: PropTypes.number,
  paginationMaxPagesMobile: PropTypes.number,
  goToPage: PropTypes.func
}

export default Pagination
