import React, { PureComponent } from 'react'
import pick from 'lodash/pick'
import { getNavigationMenus, getAllNavigationalTopics } from '../../services/api'
import { formatAllNavigationalTopicsForNavMenu } from '../../services/formatters/global'
import { footerDefaults } from './defaults'

const globalMenuProps = {
  headerMenuLinks: 'headerMenuLinks',
  navigationalCategories: 'navigationalCategories',
  footer: 'footer'
}

// global data object for only loading this data once for all instances of this HOC
let preloadedData = null
// variable storing the single request promise to avoid multiple components triggering network requests
let inProgressResponse = null

async function _loadNavigationMenus () {
  // return preloaded data if available
  if (preloadedData) {
    return preloadedData
  }

  if (!inProgressResponse) {
    // set the in-progress request if this is the first request
    inProgressResponse = Promise.all([
      getNavigationMenus(),
      getAllNavigationalTopics()
    ])
  }

  return inProgressResponse
    .then(data => {
      preloadedData = data
      return data
    })
}

/**
 *
 * @param {object} Component
 * @param {string[]} propsToPick
 */
function withGlobalMenus (Component, propsToPick) {
  return class extends PureComponent {
    state = {
      headerMenuLinks: {},
      navigationalCategories: [],
      footer: { ...footerDefaults }
    }

    componentDidMount () {
      this.loadNavigationMenus()
    }

    loadNavigationMenus = () => {
      _loadNavigationMenus()
        .then(([menuData, allTopics]) => {
          this.setState({
            navigationalCategories: formatAllNavigationalTopicsForNavMenu(allTopics),
            headerMenuLinks: menuData.headerMenuLinks,
            footer: menuData.footer
          })
        })
    }

    render () {
      const additionalProps = propsToPick ? pick(this.state, propsToPick) : { ...this.state }

      return (
        <Component
          {...this.props}
          {...additionalProps}
        />
      )
    }
  }
}

export {
  globalMenuProps,
  _loadNavigationMenus as loadNavigationMenus
}

export default (propsToPick) => {
  return (Component) => withGlobalMenus(Component, propsToPick)
}
