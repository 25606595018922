import './polyfillHelper'
import React from 'react'
import ReactDOM from 'react-dom'
import './mockDataDriver'
import './axiosLogInterceptor'
import App from './components/App/App'
import * as serviceWorker from './serviceWorker'
import mapboxgl from 'mapbox-gl'
import config from './config'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs'
import axios from 'axios'

import 'animate.css/animate.css'
import './index.scss'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import '@trussworks/react-uswds/lib/uswds.css'
import '@trussworks/react-uswds/lib/index.css'

dayjs.extend(customParseFormat)

const { mapBoxAccessToken, defaultHeader } = config

axios.defaults.headers['X-Call-Source'] = defaultHeader

// adding this due to known issues w/ mapbox webpack loader https://github.com/mapbox/mapbox-gl-js/issues/10173
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default
mapboxgl.accessToken = mapBoxAccessToken

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
