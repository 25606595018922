import React from 'react'
import PropTypes from 'prop-types'
import RichTextField from '../../common/RichTextField/RichTextField'
import LinkHandler from '../../common/LinkHandler/LinkHandler'
import Text from '../../common/Text/Text'
import ExternalLinkIcon from '../../../components/icons/ExternalLinkIcon'
import config from '../../../config'

import './VisualizationTools.scss'

// top border cards have top color accent
const { wddseAccentColorPalette } = config

function VisualizationTools ({ list, listPosition = 0 }) {
  const { description, title, tools } = list
  const safeIndex = listPosition % wddseAccentColorPalette.length
  const borderColor = wddseAccentColorPalette[safeIndex]

  return (
    <li className='VisualizationTools' style={{ borderTop: `4px solid ${borderColor}` }}>
      <h4>{title}</h4>
      <RichTextField
        className='VisualizationTools__description'
        content={description}
      />
      <Text className='VisualizationTools__eyebrow' typeFace='Small Text/Black'>Tools</Text>
      <ul className='VisualizationTools__tool-list'>
        {
          tools.map((tool, i) => {
            const { label, url } = tool

            return (
              <li key={i}>
                <LinkHandler href={url}>
                  <>
                    <span className='VisualizationTools__tool-label'>{label}</span>
                    <ExternalLinkIcon />
                  </>
                </LinkHandler>
              </li>
            )
          })
        }
      </ul>
    </li>
  )
}

VisualizationTools.propTypes = {
  list: PropTypes.shape({
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    tools: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    }))
  }).isRequired,
  listPosition: PropTypes.number
}

export default VisualizationTools
