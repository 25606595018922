import { getLookupToolData } from '../../services/api'
import { convertDataToObjectArray } from '../../services/formatters/lookupTool'

// column names
export const FIRST_NAME_COLUMN = 'Prscrbr_First_Name'
export const LAST_NAME_COLUMN = 'Prscrbr_Last_Org_Name'
export const STATE_COLUMN = 'Prscrbr_State_Abrvtn'
export const NPI_COLUMN = 'Prscrbr_NPI'
export const CITY_COLUMN = 'Prscrbr_City'
export const PRESCRIBER_TYPE_COLUMN = 'Prscrbr_Type'

// input name values
export const PRESCRIBER_FIRST_NAME = 'firstName'
export const PRESCRIBER_LAST_NAME = 'lastName'
export const PRESCRIBER_STATE = 'state'
export const PRESCRIBER_NPI = 'npi'
export const PRESCRIBER_CITY = 'city'

export const PRESCRIBER_SUBTABLE = 'lookup'

const formSearchConfig = {
  [PRESCRIBER_FIRST_NAME]: {
    filterName: PRESCRIBER_FIRST_NAME,
    operator: 'CONTAINS',
    column: FIRST_NAME_COLUMN
  },
  [PRESCRIBER_LAST_NAME]: {
    filterName: PRESCRIBER_LAST_NAME,
    operator: 'CONTAINS',
    column: LAST_NAME_COLUMN
  },
  [PRESCRIBER_STATE]: {
    filterName: PRESCRIBER_STATE,
    operator: '=',
    column: STATE_COLUMN
  },
  [PRESCRIBER_CITY]: {
    filterName: PRESCRIBER_CITY,
    operator: '=',
    column: CITY_COLUMN
  },
  [PRESCRIBER_NPI]: {
    filterName: PRESCRIBER_NPI,
    operator: '=',
    column: NPI_COLUMN
  }
}

const typeaheadSearchConfig = {
  [PRESCRIBER_LAST_NAME]: {
    ...formSearchConfig[PRESCRIBER_LAST_NAME],
    operator: 'STARTS_WITH'
  }
}

/**
 *
 * @param {object} data
 * @param {object} data.formValues
 * @param {object} data.fitlerConfig
 */
export function getToolSearchApiParams (data) {
  const {
    formValues,
    filterConfig = formSearchConfig
  } = data

  const filter = {
    conditions: []
  }

  Object.keys(formValues).forEach(formKey => {
    const baseCondition = { ...filterConfig[formKey] }
    filter.conditions.push({
      ...baseCondition,
      value: formValues[formKey]
    })
  })

  return {
    filter,
    sort: [LAST_NAME_COLUMN, FIRST_NAME_COLUMN, STATE_COLUMN],
    columns: [
      FIRST_NAME_COLUMN,
      LAST_NAME_COLUMN,
      STATE_COLUMN,
      NPI_COLUMN,
      CITY_COLUMN,
      PRESCRIBER_TYPE_COLUMN
    ],
    table: PRESCRIBER_SUBTABLE
  }
}

export async function getTypeaheadData (uuid, { value, size }) {
  try {
    const data = {
      formValues: { [PRESCRIBER_LAST_NAME]: value },
      filterConfig: typeaheadSearchConfig
    }

    const apiParams = getToolSearchApiParams(data)

    const res = await getLookupToolData(uuid, { ...apiParams, size })
    const results = convertDataToObjectArray(res)
    const formatted = results.map(result => {
      return {
        result,
        listItem: {
          name: result[FIRST_NAME_COLUMN]
            ? `${result[FIRST_NAME_COLUMN]} ${result[LAST_NAME_COLUMN]}`
            : result[LAST_NAME_COLUMN],
          location: `${result[CITY_COLUMN]}, ${result[STATE_COLUMN]}`
        },
        value: {
          [PRESCRIBER_FIRST_NAME]: result[FIRST_NAME_COLUMN],
          [PRESCRIBER_LAST_NAME]: result[LAST_NAME_COLUMN],
          [PRESCRIBER_STATE]: result[STATE_COLUMN],
          [PRESCRIBER_CITY]: result[CITY_COLUMN],
          [PRESCRIBER_NPI]: result[NPI_COLUMN]
        }
      }
    })

    return formatted
  } catch (e) {
    // check this out
    console.error('Error fetching typeahead data', e)
    return []
  }
}
