import React from 'react'
import PropTypes from 'prop-types'
import ModalCloseIcon from '../../icons/ModalCloseIcon'

const ModalCloseButton = ({ onClick, color }) => {
  const colorStyle = color ? { opacity: 1, color: color } : {}
  return (
    <button type='button' aria-label='close modal' className='close' style={colorStyle} onClick={onClick}>
      <span aria-hidden><ModalCloseIcon /></span>
      <span className='sr-only'>Close</span>
    </button>
  )
}

ModalCloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string
}

export default ModalCloseButton
