import {
  convertFormDateValueToApiValue
} from './toolSearchUtilities'
import { getLookupToolData } from '../../services/api'
import { convertDataToObjectArray } from '../../services/formatters/lookupTool'
import dayjs from 'dayjs'
import uniq from 'lodash/uniq'
import isEmpty from 'lodash/isEmpty'

// subtable containing npi list, combined name column, and properly formatted date
export const DUE_DATE_LIST_SUBTABLE = 'due_date_list'

// data source identifiers
export const REVALIDATION_DUE_DATE_DATASOURCE = 'revalidation_due_date_list'
export const REVALIDATION_REASSIGNMENT_DATASOURCE = 'revalidation_reassignment_list'

// column names (revalidation due date)
export const ORG_NAME_COLUMN = 'Organization Name'
export const FULL_NAME_COLUMN_LAST_FIRST = 'full_name_last_first'
export const FULL_NAME_COLUMN_FIRST_LAST = 'full_name_first_last'
export const FIRST_NAME_COLUMN = 'First Name'
export const LAST_NAME_COLUMN = 'Last Name'
export const STATE_COLUMN = 'Enrollment State Code'
export const NPI_COLUMN = 'National Provider Identifier'
export const NPI_LIST_COLUMN = 'npi_list'
export const ADJUSTED_DUE_DATE_COLUMN = 'adjusted_due_date'
export const REVALIDATION_DUE_DATE_COLUMN = 'due_date'
export const ENROLLMENT_ID_COLUMN = 'Enrollment ID'
export const ENTROLLMENT_SPECIALTY_COLUMN = 'Enrollment Specialty'
export const ENROLLEMENT_TYPE_COLUMN = 'Provider Type Text'
export const INDIVIDUALS_ASSIGNED_TO_ORG_COLUMN = 'Individual Total Reassign To'
export const ORGANIZATIONS_ASSIGNED_TO_IND_COL = 'Receiving Benefits Reassignment'
export const ENTITY_TYPE_COLUMN = 'entity_type'

// column names (reassignment)
export const REASSIGNMENT_GROUP_PAC_ID_COLUMN = 'Group PAC ID'
export const REASSIGNMENT_GROUP_ENROLLMENT_ID_COLUMN = 'Group Enrollment ID'
export const REASSIGNMENT_GROUP_BUSINESS_NAME_COLUMN = 'Group Legal_Business Name'
export const REASSIGNMENT_GROUP_STATE_COLUMN = 'Group State Code'
export const REASSIGNMENT_GROUP_DUE_DATE_COLUMN = 'Group Due Date'
export const REASSIGNMENT_INDIVIDUALS_ASSIGNED_TO_ORG_COLUMN = 'Group Reassignments and Physician Assistants'
export const REASSIGNMENT_GROUP_RECORD_TYPE_COLUMN = 'Record Type'
export const REASSIGNMENT_IND_PAC_ID_COLUMN = 'Individual PAC ID'
export const REASSIGNMENT_IND_ENROLLMENT_ID_COLUMN = 'Individual Enrollment ID'
export const REASSIGNMENT_IND_NPI_COLUMN = 'Individual NPI'
export const REASSIGNMENT_IND_FIRST_NAME_COLUMN = 'Individual First Name'
export const REASSIGNMENT_IND_LAST_NAME_COLUMN = 'Individual Last Name'
export const REASSIGNMENT_IND_STATE_COLUMN = 'Individual State Code'
export const REASSIGNMENT_IND_SPECIALTY_COLUMN = 'Individual Specialty Description'
export const REASSIGNMENT_IND_DUE_DATE_COLUMN = 'Individual Due Date'
export const REASSIGNMENT_ORGANIZATIONS_ASSIGNED_TO_IND_COL = 'Individual Total Employer Associations'

// form input names
export const REVALIDATION_ORG_NAME = 'orgName'
export const REVALIDATION_FIRST_NAME = 'firstName'
export const REVALIDATION_LAST_NAME = 'lastlName'
export const REVALIDATION_START_DATE = 'startDate'
export const REVALIDATION_END_DATE = 'endDate'
export const REVALIDATION_DATE_FILTER_TYPE = 'dateFilterType'
export const REVALIDATION_STATE = 'state'
export const REVALIDATION_NPI = 'npi'

// date filter type select values
export const ALL_RECORDS_VALUE = 'allRecords'
export const ONLY_DUE_DATE_RECORDS_VALUE = 'withDueDates'
export const DATE_RANGE_VALUE = 'dateRange'

export const ENROLLMENT_ID_TYPE_FILTER = 'enrollmentIdType'

export const DEFAULT_SORT = [FULL_NAME_COLUMN_FIRST_LAST, STATE_COLUMN]

/**
 *
 * @param {string} uuid - lookup tool uuid
 * @param {object} data
 * @param {string} data.value - input value
 * @param {string} data.name - standardized input name
 * @param {number} data.size
 */
export async function getTypeaheadData (uuid, { value, name, size }) {
  try {
    let params, column

    switch (name) {
      case REVALIDATION_ORG_NAME:
        params = getOrgTypeaheadApiParams({ value, size })
        column = ORG_NAME_COLUMN
        break
      case REVALIDATION_FIRST_NAME:
        params = getFirstNameTypeaheadApiParams({ value, size })
        column = FIRST_NAME_COLUMN
        break
      case REVALIDATION_LAST_NAME:
        params = getLastNameTypeaheadApiParams({ value, size })
        column = LAST_NAME_COLUMN
        break
      default:
        throw new Error(`unsupported typeahead field: ${name}`)
    }

    const data = await getLookupToolData(uuid, params)
    const results = convertDataToObjectArray(data)
    const formatted = results.map(result => {
      const displayName = result[column]

      return {
        listItem: {
          name: displayName,
          location: null
        },
        value: {
          [name]: displayName
        }
      }
    })

    return formatted
  } catch (e) {
    console.error('Error fetching revalidation typeahead', e)
    return []
  }
}

/**
 * generate the filter params for org name search
 * @param {string} value
 * @returns
 */
function getOrgNameFilter (value) {
  const conditions = [
    { filterName: REVALIDATION_ORG_NAME, column: ORG_NAME_COLUMN, operator: 'STARTS_WITH', value },
    { filterName: 'enrollmentId', column: ENTITY_TYPE_COLUMN, operator: '=', value: 'O' }
  ]

  return {
    groups: [],
    conditions
  }
}

/**
 * generate the filter params for first name search
 * @param {string} value
 * @returns
 */
function getFirstNameFilter (value) {
  const conditions = [
    { filterName: REVALIDATION_FIRST_NAME, column: FIRST_NAME_COLUMN, operator: 'STARTS_WITH', value },
    { filterName: 'enrollmentId', column: ENTITY_TYPE_COLUMN, operator: '=', value: 'I' }
  ]

  return {
    groups: [],
    conditions
  }
}

/**
 * generate the filter params for last name search
 * @param {string} value
 * @returns
 */
function getLastNameFilter (value) {
  const conditions = [
    { filterName: REVALIDATION_LAST_NAME, column: LAST_NAME_COLUMN, operator: 'STARTS_WITH', value },
    { filterName: 'enrollmentId', column: ENTITY_TYPE_COLUMN, operator: '=', value: 'I' }
  ]

  return {
    groups: [],
    conditions
  }
}

/**
 *
 * @param {object} params
 * @param {string} params.value - the form value
 * @param {string} params.size - the number of results to return
 * @returns {string}
 */
export function getFirstNameTypeaheadApiParams (params) {
  const { value, size } = params

  return {
    filter: getFirstNameFilter(value),
    sort: [FIRST_NAME_COLUMN],
    table: DUE_DATE_LIST_SUBTABLE,
    source: REVALIDATION_DUE_DATE_DATASOURCE,
    columns: [FIRST_NAME_COLUMN],
    distinct: true,
    size
  }
}

/**
 *
 * @param {object} params
 * @param {string} params.value - the form value
 * @param {string} params.size - the number of results to return
 * @returns {string}
 */
export function getLastNameTypeaheadApiParams (params) {
  const { value, size } = params

  return {
    filter: getLastNameFilter(value),
    sort: [LAST_NAME_COLUMN],
    table: DUE_DATE_LIST_SUBTABLE,
    source: REVALIDATION_DUE_DATE_DATASOURCE,
    columns: [LAST_NAME_COLUMN],
    distinct: true,
    size
  }
}

/**
 *
 * @param {object} params
 * @param {string} params.value - the form value
 * @param {string} params.size - the number of results to return
 * @returns {string}
 */
export function getOrgTypeaheadApiParams (params) {
  const { value, size } = params

  return {
    filter: getOrgNameFilter(value),
    sort: DEFAULT_SORT,
    table: DUE_DATE_LIST_SUBTABLE,
    source: REVALIDATION_DUE_DATE_DATASOURCE,
    columns: [ORG_NAME_COLUMN],
    distinct: true,
    size
  }
}

/**
 * This method will calculate a list of possible NPIs from a single NPI
 * @param {string} id id of the lookup tool
 * @param {object} payload
 * @param {object} payload.formValues - form values; npi is the only one of note
 * @returns {object} transformed payload
 */
export async function preparePayload (id, payload) {
  const { formValues } = payload
  const npi = formValues[REVALIDATION_NPI]

  // no work necessary if NPI is not being searched for
  if (!npi) {
    return payload
  }

  const params = {
    columns: [NPI_LIST_COLUMN, NPI_COLUMN],
    keyword: npi,
    size: 5000,
    offset: 0,
    table: DUE_DATE_LIST_SUBTABLE,
    source: REVALIDATION_DUE_DATE_DATASOURCE
  }

  const response = await getLookupToolData(id, params)
  // transform response into list of NPIs
  const results = convertDataToObjectArray(response, obj => {
    return obj[NPI_COLUMN]
  })

  // return the same payload, with the exception that now NPI is a list of NPIs
  return {
    ...payload,
    formValues: {
      ...formValues,
      [REVALIDATION_NPI]: uniq(results)
    }
  }
}

/**
 *
 * @param {object} data
 * @param {object} data.formValues
 */
export function getToolSearchApiParams (data) {
  const { formValues } = data

  const filter = {
    conditions: [],
    groups: []
  }

  let keyword

  Object.keys(formValues).forEach(formKey => {
    const value = formValues[formKey]
    const { conditions, groups } = filter

    switch (formKey) {
      case REVALIDATION_ORG_NAME:
        conditions.push(...getOrgNameFilter(value).conditions)
        groups.push(...getOrgNameFilter(value).groups)
        break

      case REVALIDATION_FIRST_NAME:
        conditions.push(...getFirstNameFilter(value).conditions)
        groups.push(...getFirstNameFilter(value).groups)
        break

      case REVALIDATION_LAST_NAME:
        conditions.push(...getLastNameFilter(value).conditions)
        groups.push(...getLastNameFilter(value).groups)
        break

      case REVALIDATION_DATE_FILTER_TYPE:
        if (value === ONLY_DUE_DATE_RECORDS_VALUE) {
          conditions.push({
            filterName: REVALIDATION_DATE_FILTER_TYPE,
            column: REVALIDATION_DUE_DATE_COLUMN,
            operator: 'IS NOT NULL'
          })
        }
        break

      case REVALIDATION_START_DATE:
        conditions.push({
          filterName: REVALIDATION_START_DATE,
          column: REVALIDATION_DUE_DATE_COLUMN,
          operator: '>=',
          value: convertFormDateValueToApiValue(value)
        })
        break

      case REVALIDATION_END_DATE:
        conditions.push({
          filterName: REVALIDATION_END_DATE,
          column: REVALIDATION_DUE_DATE_COLUMN,
          operator: '<=',
          value: convertFormDateValueToApiValue(value)
        })
        break

      case REVALIDATION_STATE:
        conditions.push({
          filterName: REVALIDATION_STATE,
          column: STATE_COLUMN,
          operator: '=',
          value
        })
        break

      case REVALIDATION_NPI:
        if (isEmpty(value)) {
          conditions.push({
            filterName: REVALIDATION_NPI,
            column: NPI_COLUMN,
            operator: 'IN',
            value: 'novalue'
          })
        } else {
          conditions.push({
            filterName: REVALIDATION_NPI,
            column: NPI_COLUMN,
            operator: 'IN',
            value
          })
        }
        break

      default:
        break
    }
  })

  return {
    filter,
    sort: DEFAULT_SORT,
    table: DUE_DATE_LIST_SUBTABLE,
    source: REVALIDATION_DUE_DATE_DATASOURCE,
    keyword
  }
}

/**
 *
 * @param {object} data
 * @param {string} data.provider
 */
export async function getToolDetailStatsApiParams (data) {
  const { provider } = data
  const isIndividual = isIndividualId(provider)
  const filter = { conditions: [] }

  // find all rows that contain this individual or organization enrollment id
  filter.conditions = [{
    filterName: 'provider',
    column: isIndividual
      ? REASSIGNMENT_IND_ENROLLMENT_ID_COLUMN
      : REASSIGNMENT_GROUP_ENROLLMENT_ID_COLUMN,
    operator: '=',
    value: provider
  }]

  return {
    filter,
    source: REVALIDATION_REASSIGNMENT_DATASOURCE
  }
}

/**
 *
 * @param {string} id - lookup tool id
 * @param {object} data
 * @param {string} data.provider
 * @param {object} data.size
 * @param {object} data.offset
 */
export async function getToolDetailDataApiParams (id, data) {
  const { provider, size, offset } = data
  const isIndividual = isIndividualId(provider)
  const collectionFilter = { conditions: [] }

  // find all rows that contain this individual or organization enrollment id
  collectionFilter.conditions = [{
    filterName: 'provider',
    column: isIndividual
      ? REASSIGNMENT_IND_ENROLLMENT_ID_COLUMN
      : REASSIGNMENT_GROUP_ENROLLMENT_ID_COLUMN,
    operator: '=',
    value: provider
  }]

  const collectionsParams = {
    filter: collectionFilter,
    size,
    offset,
    sort: isIndividual
      // if it's a page for an individual, it will be displaying orgs, so sort by org name
      ? [REASSIGNMENT_GROUP_BUSINESS_NAME_COLUMN, REASSIGNMENT_GROUP_STATE_COLUMN]
      : [REASSIGNMENT_IND_LAST_NAME_COLUMN, REASSIGNMENT_IND_FIRST_NAME_COLUMN, REASSIGNMENT_IND_STATE_COLUMN],
    source: REVALIDATION_REASSIGNMENT_DATASOURCE
  }

  const response = await getLookupToolData(id, collectionsParams)
  const results = convertDataToObjectArray(response)

  const collectionColumn = isIndividual
    // for individuals, collect the organization ids they belong to
    ? REASSIGNMENT_GROUP_ENROLLMENT_ID_COLUMN
    // for orgs, collect the individuals associated with them
    : REASSIGNMENT_IND_ENROLLMENT_ID_COLUMN

  const collectedIds = results.reduce((ids, row) => [...ids, row[collectionColumn]], [])

  const filter = {
    conditions: [{
      filterName: 'enrollmentId',
      column: ENROLLMENT_ID_COLUMN,
      operator: 'IN',
      value: collectedIds
    }]
  }

  return {
    filter,
    table: DUE_DATE_LIST_SUBTABLE,
    // offset will always be 0 since we are always confined to the set of IDs for the specific page
    offset: 0,
    size: size,
    sort: DEFAULT_SORT,
    source: REVALIDATION_DUE_DATE_DATASOURCE
  }
}

export const getEnrollmentId = (row) => row[ENROLLMENT_ID_COLUMN]

export const getNpi = (row) => row[NPI_COLUMN]

export const getNpiList = (row) => (row[NPI_LIST_COLUMN] || '').split(',')

export const getState = (row) => row[STATE_COLUMN]

export const isIndividualId = (id) => id.charAt('0') === 'I'

export const getSpecialty = (row) => row[ENTROLLMENT_SPECIALTY_COLUMN]

export const getRevalidationDueDate = (row) => row[REVALIDATION_DUE_DATE_COLUMN]

export const getAdjustedDueDate = (row) => row[ADJUSTED_DUE_DATE_COLUMN]

export const isIndividual = (row) => isIndividualId(getEnrollmentId(row))

export const getNameFromRow = (row) => isIndividual(row)
  ? `${row[FIRST_NAME_COLUMN]} ${row[LAST_NAME_COLUMN]}`
  : row[ORG_NAME_COLUMN]

export const getDueDateDisplay = (row) => {
  let revalidationDueDate = getRevalidationDueDate(row)
  let adjustedDueDate = getAdjustedDueDate(row)
  revalidationDueDate = revalidationDueDate
    ? dayjs(revalidationDueDate).format('MM/DD/YYYY')
    : 'TBD'
  adjustedDueDate = adjustedDueDate
    ? dayjs(adjustedDueDate).format('MM/DD/YYYY')
    : 'TBD'
  return { revalidationDueDate, adjustedDueDate }
}

export const isDmeSupplier = (row) => row[ENROLLEMENT_TYPE_COLUMN]

export const getNumOfIndividualsAssignedToOrg = (row) => row[INDIVIDUALS_ASSIGNED_TO_ORG_COLUMN] || 0

export const getNumOfOrganizationsAssignedToIndividual = (row) => row[ORGANIZATIONS_ASSIGNED_TO_IND_COL] || 0
