import React, { createRef, useState, useEffect, Suspense } from 'react'
import PropTypes from 'prop-types'
import MetaHead from '../../layout/MetaHead/MetaHead'
import ComponentShell from '../../common/ComponentShell/ComponentShell'

// import HomepageVideos from '../../homepage/HomepageVideos/HomepageVideos'
import { getHomepage } from '../../../services/api'
// top level error handling
import withBoundary from '../../../hocs/withBoundary/withBoundary'
import compose from '../../../hocs/compose'
import Footer from '../../layout/Footer/Footer'
import withAllDatasets from '../../../hocs/withAllDatasets/withAllDatasets'
import config from '../../../config'
import { featureIsEnabled, featuresAreLoaded } from '../../../stores/siteStore/siteStore'
import cx from 'classnames'
// the inner chart.js global object for setting config
import { Chart } from 'react-chartjs-2'
// plugin to modify bubble chart labels
import ChartDataLabels from 'chartjs-plugin-datalabels'
import useDisplayContext from '../../../hooks/useDisplayContext'
import LoadingCover from '../../common/LoadingCover/LoadingCover'
import ErrorPage from '../ErrorPage/ErrorPage'
// import { GovBanner } from '@trussworks/react-uswds'
import GovBanner from '../../common/GovBanner/GovBanner'
import Header from '../../../components/layout/Header/Header.js'
import history from '../../../history'

import headerHeight from './HomePage.scss'
import HomepageHero from '../../homepage/HomepageHero/HomepageHero'
import HomePageWhatsNew from '../../homepage/HomePageWhatsNew/HomePageWhatsNew.js'

const HomepageMission = React.lazy(() => import('../../homepage/HomepageMission/HomepageMission'))
const HomepageFeaturedContent = React.lazy(() => import('../../homepage/HomepageFeaturedContent/HomepageFeaturedContent'))
const HomepageVideos = React.lazy(() => import('../../homepage/HomepageVideos/HomepageVideos'))
const HomepageCategoryCallout = React.lazy(() => import('../../homepage/HomepageCategoryCallout/HomepageCategoryCallout'))
const HomepageInteractiveTools = React.lazy(() => import('../../homepage/HomepageInteractiveTools/HomepageInteractiveTools'))
const HomepageMostViewedDatasets = React.lazy(() => import('../../homepage/HomepageMostViewedDatasets/HomepageMostViewedDatasets'))

const mobileHeaderHeight = Number((headerHeight.mobileHeaderHeight || '77px').split('px')[0])
const desktopHeaderHeight = Number((headerHeight.globalHeaderHeight || '77px').split('px')[0])

const {
  metaDefaultSiteTitle,
  metaOrganizationName
} = config

const defaultDataState = {
  pageTitle: metaDefaultSiteTitle,
  pageHeader: '',
  pageChartSections: [],
  mission: {},
  featuredTools: {},
  featuredContent: {}
}

// add refs for each page (chart) section for mission section nav links
const buildPageSectionRefs = (sections) => {
  return sections.map(section => (
    {
      name: section.header,
      sectionRef: createRef()
    }
  ))
}

const HomePage = (props) => {
  const featuresLoaded = featuresAreLoaded()
  const { allDatasets, handleHeaderOpen, handleHeaderClose } = props
  const { isMobile } = useDisplayContext()
  const [homepage, setHomepage] = useState({
    navSections: [],
    data: defaultDataState,
    isLoading: true,
    error: null
  })
  const { isLoading, navSections, data, error } = homepage
  const { metaTags, pageHeader, pageTitle } = data

  // page setup
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getHomepage()

        // calculate the nav sections
        const navSections = [
          ...buildPageSectionRefs(data.pageChartSections),
          {
            name: 'Interactive Look-up Tools',
            sectionRef: createRef()
          }
        ]

        setHomepage(prevState => (
          {
            ...prevState,
            navSections,
            data,
            isLoading: false,
            error: null
          }
        ))

        // the chart labels plugin registers itself globally to all charts on load
        // prevent this behavior and register it manually in specific chart component setup
        Chart.plugins.unregister(ChartDataLabels)
      } catch (error) {
        setHomepage(prevState => (
          {
            ...prevState,
            error: error
          }
        ))
      }
    }

    fetchData()
  }, [])

  // smooth scroll to ref
  const handleNavLinkClick = (ref) => {
    const currentScrollTop = window.pageYOffset
    const refNodeTop = ref.current.getBoundingClientRect().top

    window.scrollTo({
      top: refNodeTop + currentScrollTop - (isMobile ? mobileHeaderHeight : desktopHeaderHeight),
      behavior: 'smooth'
    })
  }

  const renderBody = () => {
    const {
      featuredContent,
      featuredTools,
      mission,
      imageUrls,
      pageChartSections,
      videos
    } = data

    if (error) {
      return <ErrorPage error={error} />
    }
    if (isLoading) {
      return <LoadingCover />
    }

    // at present interactive tools is always last ref/nav menu item
    const interactiveToolsRef = navSections.slice(-1)[0].sectionRef

    return (
      <div className={cx({ pageAnimation: true, withVideos: videos.length > 0 })}>
        <GovBanner id='QA_govBanner' />
        <Header
          history={history}
          onOpen={handleHeaderOpen}
          onClose={handleHeaderClose}
        />
        <section className='HomePage__section HomePage__section_intro HomePage__section--accented'>
          <div className='background'>
            <div>
              <div className='heroImage'>
                <div className='heroHolder'>
                  <HomepageHero pageHeader={pageHeader} isMobile={isMobile} />
                </div>
              </div>
              <div className='imageBg'>
                <ComponentShell>
                  <Suspense fallback={<LoadingCover />}>
                    <HomepageMostViewedDatasets datasets={allDatasets} />
                  </Suspense>
                </ComponentShell>
              </div>
            </div>
          </div>
        </section>
        {featuresLoaded && featureIsEnabled('whatsNew') &&
          <section className='HomePage__section HomePage__section_whats_new'>
            <Suspense fallback={<LoadingCover />}>
              <HomePageWhatsNew />
            </Suspense>
          </section>}
        <section className='HomePage__section Homepage__section--mission'>
          <Suspense fallback={<LoadingCover />}>
            <HomepageMission
              {...mission}
              imageUrls={imageUrls}
              onLinkClick={handleNavLinkClick}
              sections={navSections}
            />
          </Suspense>
        </section>
        {
          pageChartSections.map((chartSection, i) => (
            <section
              key={i}
              ref={navSections[i].sectionRef}
              className={cx({
                HomePage__section: i === 0 || i % 2 === 0,
                'HomePage__section--accented': i % 2 !== 0
              })}
            >
              <Suspense fallback={<LoadingCover />}>
                <HomepageCategoryCallout
                  {...chartSection}
                />
              </Suspense>
            </section>
          ))
        }
        <section className='HomePage__section Homepage__section--videos'>
          <Suspense fallback={<LoadingCover />}>
            <HomepageVideos videos={videos} count={1} />
          </Suspense>
        </section>
        <section className='HomePage__section HomePage__Section--no-padding HomePage__section--interactiveTools' ref={interactiveToolsRef}>
          <Suspense fallback={<LoadingCover />}>
            <HomepageInteractiveTools {...featuredTools} />
          </Suspense>
        </section>
        <section className='HomePage__section HomePage__Section--no-padding HomePage__Section--featuredContent'>
          <Suspense fallback={<LoadingCover />}>
            <HomepageFeaturedContent {...featuredContent} />
          </Suspense>
        </section>
        <Footer />
      </div>
    )
  }

  return (
    <div className='HomePage'>
      <MetaHead metaTags={metaTags}>
        <title>{`${pageTitle}${metaOrganizationName}`}</title>
      </MetaHead>
      {renderBody()}
    </div>
  )
}

HomePage.propTypes = {
  allDatasets: PropTypes.array,
  handleHeaderOpen: PropTypes.func,
  handleHeaderClose: PropTypes.func
}

export default compose(
  withBoundary,
  withAllDatasets
)(HomePage)

export { HomePage }
