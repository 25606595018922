import React from 'react'
import PropTypes from 'prop-types'
import { getDisplayFileSize } from '../../../utilities/displayUtilities'
import formatDatasetVersionDate from '../../../utilities/datasetVersionFormatter'
import {
  rollUpAdditionalResources,
  modifyIfAdditionalResource,
  orderCategories,
  SelectedOptionTypes,
  getFileSizeSumFromResources,
  EXCLUDED_TYPES
} from './downloadModalHelpers'
import { getDatasetVersionText } from '../../../utilities/datasetHelpers'
import DatasetVersionIcon from '../DatasetVersionIcon/DatasetVersionIcon'

const DownloadModalSelection = (props) => {
  const {
    selectedOptionChanged,
    selectedOption,
    resourcesByType,
    resourcesForLatestVersion,
    hasSingleDataset,
    newestVersion,
    frequency
  } = props

  const hasSuffix = newestVersion?.fieldDatasetVersionSuffix
  if (hasSuffix) {
    newestVersion.fieldDatasetVersionCombined = formatDatasetVersionDate(frequency, newestVersion?.fieldDatasetVersion) + newestVersion?.fieldDatasetVersionSuffix
  }
  const dateLabel = newestVersion?.fieldDatasetVersionCombined?.includes('-')
    ? formatDatasetVersionDate(frequency, newestVersion?.fieldDatasetVersionCombined)
    : newestVersion?.fieldDatasetVersionCombined
  let resourcesByTypeFiltered = {}
  for (const key in resourcesByType) {
    if (!EXCLUDED_TYPES.includes(key)) {
      resourcesByTypeFiltered[key] = resourcesByType[key]
    }
  }

  const optionKeyDown = (event, option) => {
    // If enter or space was pressed
    if (event.keyCode === 32 || event.key === ' ' || event.keyCode === 13 || event.key === 'Enter') {
      selectedOptionChanged(option)
    }
  }
  const filteredResourcesForLatestVersion = [...resourcesForLatestVersion.filter((resource) => !EXCLUDED_TYPES.includes(resource?.type))]

  // Now update Additional Resources for card 1 and 3
  filteredResourcesForLatestVersion.forEach(resource => {
    resource.type = modifyIfAdditionalResource(resource?.type)
  })

  resourcesByTypeFiltered = rollUpAdditionalResources(resourcesByTypeFiltered)

  var keys = Object.keys(resourcesByTypeFiltered)
  const latestDatasetFile = filteredResourcesForLatestVersion.filter((resource) => resource?.type === 'Primary')[0]

  // Grab only the counts of each resource in latest version
  let latestVersionCounts = resourcesForLatestVersion.reduce((obj, item) => {
    if (!EXCLUDED_TYPES.includes(item.type)) {
      obj[item.type] = obj[item.type] || { type: item.type, count: 0 }
      obj[item.type].count++
    }
    return obj
  }, {})
  latestVersionCounts = orderCategories(latestVersionCounts)

  const allVersionFileCounts = Object.values(resourcesByTypeFiltered)
    .reduce((sum, value) => sum + value.length, 0)
  const latestVersionKeys = Object.keys(latestVersionCounts)
  const fileSizeSum = getFileSizeSumFromResources(resourcesForLatestVersion)
  const displayFileSizeSum = getDisplayFileSize(fileSizeSum)
  const displayLatestDataFileSize = getDisplayFileSize(latestDatasetFile?.fileSize)
  const tooltip = getDatasetVersionText(newestVersion?.fieldReReleaseSelect)

  return (
    <>
      <div className='SmallHeader SelectDownloadOption'>Select your download option:</div>
      <div className='OptionsList'>
        <div tabIndex={0} onClick={() => { selectedOptionChanged(SelectedOptionTypes.Option1) }} onKeyUp={(e) => { optionKeyDown(e, SelectedOptionTypes.Option1) }} className={selectedOption === SelectedOptionTypes.Option1 ? 'selected' : ''}>
          <div className='MostPopular'>MOST POPULAR</div>
          <div className='LatestDataset'>
            <div className={`CheckboxBackground ${selectedOption === SelectedOptionTypes.Option1 ? 'CheckedCheckbox' : ''}`} />
            <div>
              <span>Latest Dataset and{hasSingleDataset ? ' All ' : ' '}Resources</span>
              <span className='FormattedDateIcon'>
                ({dateLabel})
                {(newestVersion?.fieldDatasetVersionSuffix?.length > 0) && <DatasetVersionIcon tooltip={tooltip.tooltip} icon='outline' />}
              </span>
            </div>
          </div>
          {selectedOption === SelectedOptionTypes.Option1 && (
            <div className='OptionDescription'>
              <div className='IncludesTitle'>INCLUDES {filteredResourcesForLatestVersion.length} FILES:</div>
              <div className='IncludesList'>
                <ul>
                  {latestVersionKeys.map((key) => !EXCLUDED_TYPES.includes(key) && (
                    <li key={key}>
                      {key === 'Primary' ? 'Dataset' : key} ({latestVersionCounts[key]?.count})
                    </li>
                  ))}
                </ul>
              </div>
              <div className='FileSize'>
                <div>
                  Estimated file size: {displayFileSizeSum.slice(0, -2)}<span>&nbsp;{displayFileSizeSum.slice(-2)}</span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div tabIndex={0} onClick={() => { selectedOptionChanged(SelectedOptionTypes.Option2) }} onKeyUp={(e) => { optionKeyDown(e, SelectedOptionTypes.Option2) }} className={selectedOption === SelectedOptionTypes.Option2 ? 'selected' : ''}>
          <div className='LatestDataset'>
            <div className={`CheckboxBackground ${selectedOption === SelectedOptionTypes.Option2 ? 'CheckedCheckbox' : ''}`} />
            <div>
              <span>Latest Dataset Only</span>
              <span className='FormattedDateIcon'>
                ({dateLabel})
                {(newestVersion?.fieldDatasetVersionSuffix?.length > 0) && <DatasetVersionIcon tooltip={tooltip.tooltip} icon='outline' />}
              </span>
            </div>
          </div>
          {selectedOption === SelectedOptionTypes.Option2 && (
            <div className='OptionDescription'>
              <div className='IncludesTitle'>INCLUDES 1 FILE:</div>
              <div className='IncludesList'>
                <ul>
                  <li>Dataset (1)</li>
                </ul>
              </div>
              <div className='FileSize'>
                <div>
                  Estimated file size: {displayLatestDataFileSize.slice(0, -2)}<span>&nbsp;{displayLatestDataFileSize.slice(-2)}</span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div tabIndex={0} onClick={() => { selectedOptionChanged(SelectedOptionTypes.Option3) }} onKeyUp={(e) => { optionKeyDown(e, SelectedOptionTypes.Option3) }} className={selectedOption === SelectedOptionTypes.Option3 ? 'selected' : ''}>
          <div className='LatestDataset'>
            <div className={`CheckboxBackground ${selectedOption === SelectedOptionTypes.Option3 ? 'CheckedCheckbox' : ''}`} />
            <div>
              {hasSingleDataset
                ? (<><span>Latest Dataset and/or Resources</span><span>(Select individual files)</span></>)
                : (<><span>All Datasets and/or Resources</span><span>(Select individual files by date range)</span></>)}
            </div>
          </div>
          {selectedOption === SelectedOptionTypes.Option3 && (
            <div className='OptionDescription'>
              <div className='IncludesTitle'>INCLUDES UP TO {allVersionFileCounts} FILES:</div>
              <div className='IncludesList'>
                <ul>
                  {keys.map((key) => key !== 'External Link' && (
                    <li key={key}>
                      {key === 'Primary' ? 'Dataset' : key} ({resourcesByTypeFiltered[key]?.length})
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </>)
}

DownloadModalSelection.propTypes = {
  selectedOptionChanged: PropTypes.func,
  selectedOption: PropTypes.string,
  resourcesByType: PropTypes.object,
  resourcesForLatestVersion: PropTypes.array,
  hasSingleDataset: PropTypes.bool,
  newestVersion: PropTypes.object,
  frequency: PropTypes.string
}

export default DownloadModalSelection
