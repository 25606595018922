
// https://github.com/acdlite/recompose/blob/master/src/packages/recompose/compose.js

const compose = (...hocFunctions) => {
  return hocFunctions.reduce((acc, hocFn) => {
    return (...args) => {
      return acc(hocFn(...args))
    }
  }, (arg) => { return arg })
}

export default compose
