import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import useWindow from '../../hooks/useWindow'
import { isMobileDevice } from '../../utilities/displayUtilities'
import screenBreakSizes from './DisplayContext.scss'

const breakpoints = {
  sm: parseInt((screenBreakSizes.sm || '576px').split('px')[0], 10),
  md: parseInt((screenBreakSizes.md || '768px').split('px')[0], 10),
  lg: parseInt((screenBreakSizes.lg || '992px').split('px')[0], 10),
  xl: parseInt((screenBreakSizes.xl || '1280px').split('px')[0], 10)
}
const userAgent = navigator.userAgent || ''
const _isMobileDevice = isMobileDevice(userAgent)

// defaults, which are useful for unit tests that involve components
// that use the the DisplatContent HOC or Hook in the default export
const defaultValue = {
  windowWidth: 0,
  isDesktop: true,
  // covers both tablet and phone
  isMobile: false,
  // reference to device rather than the viewport width
  isMobileDevice: _isMobileDevice
}

const DisplayContext = React.createContext(defaultValue)

const DisplayContextProvider = ({ children }) => {
  const { width, height } = useWindow()

  const calculatedProps = useMemo(() => {
    return {
      windowWidth: width,
      windowHeight: height,
      isDesktop: width > breakpoints.md,
      isTablet: width > breakpoints.sm && width < breakpoints.lg,
      // covers both tablet and phone
      isMobile: width <= breakpoints.md,
      isMobileDevice: _isMobileDevice
    }
  }, [width, height])

  return (
    <DisplayContext.Provider value={calculatedProps}>
      {children}
    </DisplayContext.Provider>
  )
}

DisplayContextProvider.propTypes = {
  children: PropTypes.any
}

export {
  defaultValue,
  DisplayContext,
  DisplayContextProvider
}
