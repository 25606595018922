import axios from 'axios'
import get from 'lodash/get'
import mapKeys from 'lodash/mapKeys'
import camelCase from 'lodash/camelCase'
import config from '../../config'
import log from '../../log'
import simpleCacheBust from '../../utilities/simpleCacheBust'
import { getContactEmail } from '../../utilities/apiHelpers'

const {
  apiSite,
  useCacheBuster
} = config

/**
 * Main data call for changelog
 * @param {string} datasetVersionId
 */
export const getChangelog = async (datasetVersionId, { cancelToken }) => {
  try {
    const res = await axios({
      operationId: 'getChangelog',
      method: 'get',
      baseURL: apiSite,
      url: `/data-api/v1/dataset-type/${datasetVersionId}/changelog`,
      cancelToken: cancelToken,
      params: {
        ...(useCacheBuster.getChangelog ? {
          cacheBuster: simpleCacheBust()
        } : {})
      }
    })

    const changelogItems = get(res, 'data.data', []).map((v) => mapKeys(v, (v, k) => camelCase(k)))

    log.debug('getChangelog: changelogItems', changelogItems)
    return changelogItems
  } catch (error) {
    log.error('getChangelog: request error', error)
    throw (error)
  }
}

/**
 * Get param dataset uuid contact info on other pages e.g. changelog
 * @param {String} id of the dataset to fetch
 */
export const getDatasetInfo = async (id) => {
  try {
    const includes = [
      'field_ref_content_type',
      'field_ref_contact_email',
      'field_dataset_type'
    ]

    const { data } = await axios({
      method: 'get',
      baseURL: apiSite,
      operationId: 'getDatasetContactInfo',
      jsonAPI: true,
      url: `/jsonapi/node/dataset/${id}`,
      params: {
        'fields[node--dataset]': 'title,field_last_updated_date,field_ref_content_type,field_ref_contact_email,field_dataset_type,field_contact_header,field_contact_short_description',
        include: includes.join(','),
        ...(useCacheBuster.getDatasetContactInfo ? {
          cacheBuster: simpleCacheBust()
        } : {})
      }
    })
    return {
      contactInfo: getContactEmail(data),
      data
    }
  } catch (error) {
    log.error('getDatasetContactInfo: request error', error)
    throw (error)
  }
}
