import React, { Component } from 'react'
import ErrorPage from '../../components/pages/ErrorPage/ErrorPage'

function withBoundary (WrappedComponent) {
  return class extends Component {
    constructor (props) {
      super(props)
      this.state = { hasError: false }
    }

    static getDerivedStateFromError () {
      // Update state so the next render will show the fallback UI.
      return { hasError: true }
    }

    render () {
      if (this.state.hasError) {
        return <ErrorPage />
      }

      return (
        <WrappedComponent {...this.props} />
      )
    }
  }
}

export default withBoundary
