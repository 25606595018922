import React from 'react'
import PropTypes from 'prop-types'
import defaultImage from '../../../assets/chart-icon-inverted.svg'
import BasicSearchResult from './BasicSearchResult'

import './LookupToolResult.scss'
import config from '../../../config'

const { apiSite } = config

function getToolImage (toolType) {
  // icons are now pulled from the api, the default is left as a fallback
  switch (toolType) {
    default:
      // placeholder/fallback image
      return defaultImage
  }
}

const LookupToolResult = ({ data }) => {
  const {
    description,
    slug,
    title,
    type,
    tool_type, // eslint-disable-line
    icon_inverted: iconInverted // eslint-disable-line
  } = data

  const imgSrc = iconInverted ? apiSite + iconInverted : getToolImage(tool_type)

  const resultProps = {
    imgSrc,
    imgAlt: title,
    description,
    slug,
    title,
    type,
    btnText: 'Launch Tool'
  }

  return <BasicSearchResult className='LookupToolResult' {...resultProps} />
}

LookupToolResult.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    type: PropTypes.string,
    tool_type: PropTypes.string.isRequired,
    icon_inverted: PropTypes.string
  })
}

export default LookupToolResult
