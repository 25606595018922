import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import config from '../../../config'
import { useLocation } from 'react-router-dom'
import Analytics from '../../../utilities/analyticsUtilities'
import { useScrollTracker } from 'react-scroll-tracker'
const { metaDefaultSiteTitle } = config

const MetaHead = ({ children, noCrawl, metaTags, error }) => {
  const [key, setKey] = useState(0)
  const location = useLocation()
  const pathname = location.search ? location.pathname + location.search : location.pathname
  let metaTitle
  if (metaTags.length) {
    const filteredMetaTags = metaTags.filter((metatag) => metatag.property === 'og:title')
    metaTitle = filteredMetaTags.length && filteredMetaTags[0].content
  }
  // this addresses issue of react-helmet-async not properly refreshing
  // when a new <title> tag is passed as children in some instances
  useEffect(() => {
    setKey(k => k + 1)
  }, [])

  // setting scroll tracking
  useScrollTracker([25, 50, 75, 100], ({ scrollY }) => {
    Analytics.trackEvent({ scroll_depth: scrollY.toString(), ga_event_category: 'Scroll', ga_event_action: 'scroll', grouping: 'Scroll' }, window.utag)
  }, [pathname])

  // tracking pageviews
  useEffect(() => {
    if (metaTitle) {
      Analytics.trackPageview(metaTitle, pathname, window.utag)
    } else if (error) {
      Analytics.trackPageview(error, pathname, window.utag)
    }
  }, [error, metaTitle, pathname])

  return (
    <HelmetProvider>
      <Helmet key={key}>
        <title>{metaDefaultSiteTitle}</title>
        {noCrawl && <meta name='robots' content='noindex,nofollow' />}
        {metaTags.map((tag, i) => <meta key={i} {...tag} />)}
        {children}
      </Helmet>
    </HelmetProvider>
  )
}

MetaHead.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  noCrawl: PropTypes.bool,
  error: PropTypes.string,
  metaTags: PropTypes.arrayOf(PropTypes.object)
}

MetaHead.defaultProps = {
  noCrawl: false,
  metaTags: []
}

export default MetaHead
