import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import dayjs from 'dayjs'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Collapse from 'react-bootstrap/Collapse'
import { ReactComponent as SortUpIcon } from '../../../assets/fontawesome/solid/sort-up.svg'
import { ReactComponent as SortDownIcon } from '../../../assets/fontawesome/solid/sort-down.svg'
import DataDictionaryIcon from '../../icons/DataDictionaryIcon'
import DownloadWithLineIcon from '../../icons/DownloadWithLineIcon'
import RichTextField from '../../common/RichTextField/RichTextField'
import compose from '../../../hocs/compose'
import withDisplayContext from '../../../hocs/withDisplayContext/withDisplayContext'
import withUserAgreementModal from '../../../hocs/withUserAgreementModal/withUserAgreementModal'
import withDatasetDownloadModal from '../../../hocs/withDatasetDownloadModal/withDatasetDownloadModal'
import LinkHandler from '../../common/LinkHandler/LinkHandler'
import Text from '../../common/Text/Text'
import { getDatasetUserAgreement } from '../../../services/api'
import { trackIntendedDownloadClick } from '../../../utilities/analyticsEventHelpers'

import './DatasetResult.scss'

/*
  compute safe string to display
*/
function getDisplaySafeString (text, isExpanded) {
  let formattedText = text
  formattedText = formattedText.replaceAll('<p>&nbsp;</p>', '')
  formattedText = formattedText.replaceAll('    ', '')
  formattedText = formattedText.replaceAll('<p></p>', '')
  formattedText = formattedText.replaceAll('<p> </p>', '')
  formattedText = formattedText.replaceAll('<p>  </p>', '')
  formattedText = formattedText.replaceAll('<p>   </p>', '')
  formattedText = formattedText.replaceAll('<p> </p>', '')
  formattedText = formattedText.replaceAll('<br/>', '')
  formattedText = formattedText.replaceAll('<br />', '')
  formattedText = formattedText.replaceAll('<br>', '')

  return isExpanded ? text : `<div class='DatasetResult__desc__Inner'>${formattedText}</div>`
}

function kFormat (num) {
  if (Math.abs(num) > 999999) {
    return {
      value: Math.sign(num) * ((Math.abs(num) / 1000000).toFixed(1)),
      suffix: 'M'
    }
  } else if (Math.abs(num) > 999) {
    return {
      value: Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)),
      suffix: 'k'
    }
  } else {
    return {
      value: Math.sign(num) * Math.abs(num),
      suffix: null
    }
  }
}

function DatasetResult ({
  data,
  getUserAgreementStatus,
  showMobileDownloadModal,
  loadAndOpenDownloadModal,
  isLoadingDatasetDownloadModal,
  isMobileDevice,
  isMobile
}) {
  const {
    uuid: datasetTypeUuid,
    title,
    summary,
    slug,
    rows,
    cols,
    // eslint-disable-next-line
    earliest_version: earliestVersion,
    version,
    frequency,
    last_updated: lastUpdated,
    featured_resource_path: featuredResourcePath,
    featured_resource_type: featuredResourceType,
    featured_related_content: featuredRelatedContent,
    interactive
  } = data

  const isStatic = !interactive
  const [isExpanded, setExpanded] = useState(false)
  const [isLoadingAgreement, setLoadingAgreement] = useState(false)

  // prettify dates
  const displayEarliestVersion = dayjs(earliestVersion).format('YYYY')
  const displayNewestVersion = dayjs(version).format('YYYY')
  const displayLastUpdated = dayjs(lastUpdated).format('MMMM DD, YYYY')

  const formattedRows = kFormat(rows) || {}
  const formattedCols = kFormat(cols) || {}
  const isLoadingForDownloadClick = !!(isLoadingAgreement || isLoadingDatasetDownloadModal)

  async function handleDownloadClick (e) {
    trackIntendedDownloadClick(e.target.innerHTML, window.utag)
    if (isMobileDevice) {
      // if mobile device, skip loading user agreement, dataset, etc and
      // show mobile notice
      showMobileDownloadModal()
      return
    }

    setLoadingAgreement(true)

    const licenseAgreement = await getDatasetUserAgreement(datasetTypeUuid)
    setLoadingAgreement(false)

    const { hasAgreed } = await getUserAgreementStatus(datasetTypeUuid, { licenseAgreement: licenseAgreement })

    if (hasAgreed) {
      loadAndOpenDownloadModal({
        slug,
        datasetName: title,
        datasetFrequency: frequency,
        datasetTypeUuid: datasetTypeUuid
      })
    }
  }

  function handleExpandClick () {
    setExpanded(!isExpanded)
  }

  function renderTitle () {
    return (
      <div className='DatasetResult__title-container'>
        <p className='DatasetResult__title-container__sub-title'>Dataset</p>
        <LinkHandler
          className='DatasetResult__title-container__title-link'
          href={slug}
        >
          <Text
            domElement='h2'
            className='DatasetResult__title-container__title'
            typeFace='05 H5/Black/Regular/L'
          >
            {title}
          </Text>
        </LinkHandler>
      </div>
    )
  }

  function renderMeta () {
    const RowsMeta = () => (
      <div className='DatasetResult__meta-column'>
        <dt>Rows</dt>
        {
          isStatic ? (
            <dd className='DatasetResult__meta-column__stat'>
              <p>N/A</p>
            </dd>
          ) : (
            <dd className='DatasetResult__meta-column__stat'>
              <p>{formattedRows.value}</p>
              {formattedRows.suffix && (
                <span className='DatasetResult__meta-column__stat-suffix'>{formattedRows.suffix}</span>
              )}
            </dd>
          )
        }
      </div>
    )

    const ColumnsMeta = () => (
      <div className='DatasetResult__meta-column'>
        <dt>Columns</dt>
        {isStatic ? (
          <dd className='DatasetResult__meta-column__stat'>
            <p>N/A</p>
          </dd>
        ) : (
          <dd>
            <span className='DatasetResult__meta-column__stat'>
              <p>{formattedCols.value}</p>
              {formattedCols.suffix && (
                <span className='DatasetResult__meta-column__stat-suffix'>{formattedCols.suffix}</span>
              )}
            </span>
          </dd>
        )}
      </div>
    )

    const LastUpdatedMeta = () => (
      <div className='DatasetResult__meta-column'>
        <dt>Page last modified</dt>
        <dd>
          <p>{displayLastUpdated}</p>
        </dd>
      </div>
    )

    const LatestDataMeta = () => (
      <div className='DatasetResult__meta-column'>
        <dt>Latest data available</dt>
        <dd>
          <p>{displayNewestVersion}</p>
        </dd>
      </div>
    )

    const EarliestDataMeta = () => (
      <div className='DatasetResult__meta-column'>
        <dt>Earliest data available</dt>
        <dd>
          <p>{displayEarliestVersion}</p>
        </dd>
      </div>
    )

    if (isMobile) {
      return (
        <dl className='DatasetResult__meta-container'>
          <div className='DatasetResult__meta-row'>
            <RowsMeta />
            {isMobile ? <ColumnsMeta /> : <LastUpdatedMeta />}
          </div>
          <div className='DatasetResult__meta-row'>
            {isMobile ? <LastUpdatedMeta /> : <ColumnsMeta />}
          </div>
        </dl>
      )
    }

    return (
      <dl className='DatasetResult__meta-container'>
        <div className='DatasetResult__meta-row'>
          <RowsMeta />
          <ColumnsMeta />
          <LastUpdatedMeta />
          <LatestDataMeta />
          <EarliestDataMeta />
        </div>
      </dl>
    )
  }

  function renderDescription () {
    return (
      <RichTextField
        className='DatasetResult__desc'
        content={getDisplaySafeString(summary, isExpanded)}
      />
    )
  }

  function renderActionQuickLinks () {
    return (
      <div className='DatasetResult__actions__quicklinks-container'>
        <p className='DatasetResult__actions__quick-links-heading'>Quick links</p>
        {featuredResourceType && (
          <LinkHandler
            className='DatasetResult__actions__quick-link'
            href={featuredResourcePath}
          >
            <DataDictionaryIcon />
            <span>{featuredResourceType}</span>
          </LinkHandler>
        )}

        <Button
          className={cx('DatasetResult__actions__quick-link', { loading: isLoadingForDownloadClick })}
          variant='link'
          onClick={e => handleDownloadClick(e)}
        >
          {isLoadingForDownloadClick && (
            <Spinner
              as='span'
              animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          )}
          {!isLoadingForDownloadClick && <DownloadWithLineIcon />}
          <span>Download</span>
        </Button>
      </div>
    )
  }

  function renderVersions () {
    return (
      <div className='DatasetResult__actions__versions'>
        <div>
          <p className='DatasetResult__actions__versions-label'>Latest data available</p>
          <p className='DatasetResult__actions__versions-version'>
            {displayNewestVersion}
          </p>
        </div>
        <div>
          <p className='DatasetResult__actions__versions-label'>Earliest data available</p>
          <p className='DatasetResult__actions__versions-version'>
            {displayEarliestVersion}
          </p>
        </div>
      </div>
    )
  }

  function renderExpandCollapseButton () {
    return (
      <div
        className={cx({
          'DatasetResult__expand-collapse': true,
          'DatasetResult__expand-collapse-expanded': isExpanded
        })}
        onClick={e => handleExpandClick(e)}
      >
        <span className='DatasetResult__expand-collapse__text'>
          {isExpanded ? 'Show Less' : 'Show More'}
        </span>
        {isExpanded ? <SortUpIcon style={{ marginTop: isExpanded ? 6 : -6 }} /> : <SortDownIcon style={{ marginTop: isExpanded ? 6 : -6 }} />}
      </div>
    )
  }

  function renderAlsoFoundIn () {
    if (!featuredRelatedContent || Array.isArray(featuredRelatedContent)) {
      return ''
    }

    return (
      <div className='DatasetResult__result-also-found-in'>
        <div>RESULTS ALSO FOUND IN:</div>
        <div>{featuredResourcePath ? <a href={featuredResourcePath}>{featuredResourceType}</a> : ''}</div>
        {featuredRelatedContent?.resources_additional
          ? featuredRelatedContent?.resources_additional.map((resource, i) => {
            return (<div className='DatasetResult__result-also-found-in-additional-result' key={i}><a href={resource.path}>{resource.type}</a></div>)
          }) : ''}
      </div>
    )
  }

  if (isMobile) {
    return (
      <div className='DatasetResult animated fadeIn'>
        {/* Title & Meta Info */}
        <div className={cx('DatasetResult__top', { expanded: isExpanded })}>
          {renderTitle()}
          {renderDescription()}
          <Collapse in={isExpanded}>
            <div>
              {renderMeta()}
              {renderVersions()}
            </div>
          </Collapse>
          {renderExpandCollapseButton()}
        </div>
        {renderAlsoFoundIn()}
        <div className={cx('DatasetResult__top', { expanded: isExpanded })}>
          {renderActionQuickLinks()}
        </div>
      </div>
    )
  }

  return (
    <div className='DatasetResult animated fadeIn'>
      {/* 'Top Border' required to preserve border-left inside card */}
      <div className={cx('DatasetResult__bottom', { expanded: isExpanded })}>
        {/* Title & Meta Info */}
        <div className={`DatasetResult__title-meta-container${isExpanded ? ' DatasetResult__title-meta-container-expanded' : ''}`}>
          {renderTitle()}
        </div>

        {/* Description */}
        {renderDescription()}

        {/* Actions */}
        <div className={cx('DatasetResult__actions', { expanded: isExpanded })}>
          {renderActionQuickLinks()}
        </div>
      </div>
      {isExpanded && renderMeta()}

      {/* Expand Collapse Button */}
      {renderExpandCollapseButton()}
      {renderAlsoFoundIn()}
    </div>
  )
}

DatasetResult.propTypes = {
  data: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    earliest_version: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    last_updated: PropTypes.string.isRequired,
    frequency: PropTypes.string,
    cols: PropTypes.string,
    rows: PropTypes.string,
    featured_resource_path: PropTypes.string,
    featured_resource_type: PropTypes.string,
    featured_related_content: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ]),
    interactive: PropTypes.bool
  }),
  isLoadingDatasetDownloadModal: PropTypes.bool,
  getUserAgreementStatus: PropTypes.func,
  loadAndOpenDownloadModal: PropTypes.func,
  showMobileDownloadModal: PropTypes.func,
  isMobileDevice: PropTypes.bool,
  isMobile: PropTypes.bool
}

export default compose(
  withDisplayContext,
  withUserAgreementModal(),
  withDatasetDownloadModal()
)(DatasetResult)

export { DatasetResult }
