import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { enableES5 } from 'immer'

// Intersection Observer polyfill
import 'intersection-observer'

// smooth scroll polyfill for IE/iOS (for { behavior: 'smooth' } scrolling)
import smoothscroll from 'smoothscroll-polyfill'

// object-fit polyfill
import objectFitImages from 'object-fit-images'

// react 16 recommended
import 'raf/polyfill'

// used by ApiDocsMobileWarning component
import 'element-closest-polyfill'

// gumshoe requires this for IE 11
import 'custom-event-polyfill'

enableES5()

// init smooth scroll polyfill
smoothscroll.polyfill()

// init global polyfill
objectFitImages()
