import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getLookupToolData, getLookupToolDataStats } from '../../services/api'
import { convertDataToObjectArray } from '../../services/formatters/lookupTool'
import Text from '../../components/common/Text/Text'
import Container from 'react-bootstrap/Container'
import { ReactComponent as ChevronDownIcon } from '../../assets/fontawesome/solid/chevron-down.svg'
import { ReactComponent as ChevronUpIcon } from '../../assets/fontawesome/solid/chevron-up.svg'
import { ReactComponent as XIcon } from '../../assets/fontawesome/solid/x.svg'
import './msStateCountyTool.scss'

// form fields
export const MS_STATE = 'state'
export const MS_COUNTY = 'county'
export const MS_REFERENCE_PERIOD = 'referencePeriod'
export const MS_HEALTH_SERVICE_AREA = 'healthServiceArea'
export const MS_METRIC = 'metric'

// colors
export const BLUE_1 = '#9BDAF1'
export const BLUE_2 = '#02BFE7'
export const BLUE_3 = '#0071BC'
export const BLUE_4 = '#024378'
export const BLUE_5 = '#112E51'

export const PURPLE_1 = '#F2D9FF'
export const PURPLE_2 = '#E2B4FA'
export const PURPLE_3 = '#9D4CD9'
export const PURPLE_4 = '#7519B2'
export const PURPLE_5 = '#4E0484'

export const GREY = '#b3b9c1'

export const NEEDS_CONTRAST = [
  'BLUE 3',
  'BLUE 4',
  'BLUE 5',
  'PURPLE 3',
  'PURPLE 4',
  'PURPLE 5'
]

export const getMetricFromLookup = (metric, config) => {
  if (metric && config && config.metricsLookUp) {
    return config.metricsLookUp[config.metricsLookUp.findIndex(m => m.value === metric)]
  } else {
    return 'N/A'
  }
}

export const numberWithConfigFormatting = (number, metric, config) => {
  const isDollar = config?.metricsFormattedAsDollars?.includes(metric)
  const isPercentage = config?.metricsFormattedAsPercentages?.includes(metric)
  return (isDollar ? '$' : '') + number + (isPercentage ? '%' : '')
}

const flattenMetric = (metric, config) => {
  let flattened = ''
  const foundMetric = getMetricFromLookup(metric, config)
  const keys = Object.keys(foundMetric)
  keys.forEach((key, i) => {
    if (key !== 'label') {
      flattened += `${foundMetric[key]}`
      if (i !== keys.length - 1) {
        flattened += ','
      }
    }
  })
  return flattened
}

const getMetricDropdown = (config) => {
  return config.metricsLookUp ? config.metricsLookUp.map(metric => { return { label: metric.label, value: metric.value } }) : []
}

export function useLoadReferencePeriodSelectList (uuid, config) {
  const [isLoading, setIsLoading] = useState(false)
  const [selectList, setSelectList] = useState([])

  useEffect(() => {
    async function load () {
      setIsLoading(true)

      if (!config || !config.columns) {
        setIsLoading(false)
        return
      }

      const params = { columns: [config.columns.REFERENCE_PERIOD_COL], distinct: true }
      const response = await getLookupToolData(uuid, params)
      const selectList = convertDataToObjectArray(response, row => {
        return { value: row[config.columns.REFERENCE_PERIOD_COL], label: row[config.columns.REFERENCE_PERIOD_COL] }
      })

      selectList.sort((a, b) => {
        const aDate = Date.parse(a.value.split(' ')[2])
        const bDate = Date.parse(b.value.split(' ')[2])
        return bDate - aDate
      })

      setSelectList(selectList)
      setIsLoading(false)
    }

    if (config && config.columns) {
      load()
    }
  }, [uuid, config])

  return {
    isLoading,
    selectList
  }
}

export function useLoadHealthServiceAreaSelectList (uuid, config) {
  const [isLoading, setIsLoading] = useState(false)
  const [selectList, setSelectList] = useState([])

  useEffect(() => {
    async function load () {
      setIsLoading(true)

      if (!config || !config.columns) {
        setIsLoading(false)
        return
      }
      const params = { columns: [config.columns.HEALTH_SERVICE_AREA_COL], distinct: true }
      const response = await getLookupToolData(uuid, params)
      const selectList = convertDataToObjectArray(response, row => {
        return { value: row[config.columns.HEALTH_SERVICE_AREA_COL], label: row[config.columns.HEALTH_SERVICE_AREA_COL] }
      })

      setSelectList(selectList)
      setIsLoading(false)
    }

    load()
  }, [uuid, config])

  return {
    isLoading,
    selectList
  }
}

export function useLoadMetricSelectList (config) {
  // using harcoded list, but keeping in hook form for consistency and to easily load from
  // server if need be
  return {
    isLoading: false,
    selectList: getMetricDropdown(config)
  }
}

/**
 * load county select list for a given state
 * @param {string} uuid - lookup tool uuid
 * @param {string} state - state abbreviation
 */
export function useLoadCountySelectList (uuid, state) {
  const [isLoading, setIsLoading] = useState(false)
  const [selectList, setSelectList] = useState([])

  useEffect(() => {
    async function load () {
      setIsLoading(true)
      const countiesList = await import('../../assets/data/county_geojson.json')
      const countySelectList = [
        {
          label: 'All counties'
        }
      ]
      const filtered = countiesList.default.features.filter(county => Number(county.properties.STATEFP) === stateAbbrToMeta[state].fips)
      filtered.sort((a, b) => {
        if (a.properties.NAME < b.properties.NAME) {
          return -1
        } else {
          return 1
        }
      })
      const countySelectListFormatted = countySelectList.concat(filtered.map(county => { return { label: county.properties.NAME, value: `${county.properties.COUNTYFP},${county.properties.NAME}` } }))
      setSelectList(countySelectListFormatted)
      setIsLoading(false)
    }

    if (state) {
      load()
    } else {
      // clear list of no state is given
      setSelectList([])
    }
  }, [uuid, state])

  return {
    isLoading,
    selectList
  }
}

export async function loadStateDisplayData (uuid, formValues, config) {
  // ensure county is ommitted
  const relevantValues = {
    [MS_STATE]: formValues[MS_STATE],
    [MS_REFERENCE_PERIOD]: formValues[MS_REFERENCE_PERIOD],
    [MS_HEALTH_SERVICE_AREA]: formValues[MS_HEALTH_SERVICE_AREA],
    [MS_METRIC]: formValues[MS_METRIC]
  }

  const apiParams = getToolSearchApiParams({
    formValues: relevantValues,
    aggregationLevel: config.AGGREGATION_LEVELS.state
  }, config)
  const response = await getLookupToolData(uuid, apiParams)
  const result = convertDataToObjectArray(response)[0] || {}

  return result
}

export async function loadStateComparisonData (uuid, formValues, config) {
  const relevantValues = {
    [MS_STATE]: formValues[MS_STATE],
    [MS_HEALTH_SERVICE_AREA]: formValues[MS_HEALTH_SERVICE_AREA],
    [MS_METRIC]: formValues[MS_METRIC]
  }

  const apiParams = getToolSearchApiParams({
    formValues: relevantValues,
    aggregationLevel: config.AGGREGATION_LEVELS.state
  }, config)

  const response = await getLookupToolData(uuid, apiParams)
  const result = convertDataToObjectArray(response) || []

  return result
}

export async function loadNationAndTerritoriesData (uuid, formValues, config) {
  const relevantValues = {
    [MS_HEALTH_SERVICE_AREA]: formValues[MS_HEALTH_SERVICE_AREA],
    [MS_METRIC]: formValues[MS_METRIC]
  }

  const apiParams = getToolSearchApiParams({
    formValues: relevantValues,
    aggregationLevel: config.AGGREGATION_LEVELS.nation
  }, config)

  const response = await getLookupToolData(uuid, apiParams)
  const result = convertDataToObjectArray(response) || []

  return result
}

export async function loadTableViewCountyData (uuid, formValues, params, config) {
  const apiParams = {
    columns: [
      MS_COUNTY,
      getMetricFromLookup(formValues.metric, config).value
    ],
    filter: {
      conditions: [
        {
          column: config.columns.AGGREGATION_LEVEL_COL,
          filterName: 'aggregationLevel',
          operator: '=',
          value: config.AGGREGATION_LEVELS.county
        },
        {
          filterName: MS_REFERENCE_PERIOD,
          operator: '=',
          column: config.columns.REFERENCE_PERIOD_COL,
          value: formValues[MS_REFERENCE_PERIOD]
        },
        {
          filterName: MS_HEALTH_SERVICE_AREA,
          operator: '=',
          column: config.columns.HEALTH_SERVICE_AREA_COL,
          value: formValues[MS_HEALTH_SERVICE_AREA]
        },
        {
          filterName: MS_STATE,
          operator: '=',
          column: MS_STATE,
          value: formValues[MS_STATE]
        }
      ]
    },
    size: params.limit,
    offset: params.offset
  }

  if (params.sort.sortBy) {
    if (params.sort.sortOrder === 'ASC') {
      apiParams.sort = [params.sort.sortBy]
    } else {
      apiParams.sort = ['-' + params.sort.sortBy]
    }
  }

  const response = await getLookupToolData(uuid, apiParams)
  const metaResponse = await getLookupToolDataStats(uuid, apiParams)
  return {
    data: convertDataToObjectArray(response) || [],
    meta: { ...response.meta, total_rows: metaResponse.total_rows, found_rows: metaResponse.found_rows }
  }
}

export async function getCountyMetaCardData (uuid, formValues, config) {
  const relevantValues = {
    [MS_STATE]: formValues[MS_STATE],
    [MS_COUNTY]: formValues[MS_COUNTY].split(',')[0],
    [MS_REFERENCE_PERIOD]: formValues[MS_REFERENCE_PERIOD],
    [MS_HEALTH_SERVICE_AREA]: formValues[MS_HEALTH_SERVICE_AREA],
    [MS_METRIC]: formValues[MS_METRIC]
  }

  const apiParams = getToolSearchApiParams({
    formValues: relevantValues,
    aggregationLevel: config.AGGREGATION_LEVELS.county
  }, config)

  const response = await getLookupToolData(uuid, apiParams)
  const result = convertDataToObjectArray(response)[0] || {}
  return result
}

export function useLoadMapDisplayData (uuid, formValues, config) {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
    async function load () {
      // ensure county is ommitted
      const relevantValues = {
        [MS_STATE]: formValues[MS_STATE],
        [MS_REFERENCE_PERIOD]: formValues[MS_REFERENCE_PERIOD],
        [MS_HEALTH_SERVICE_AREA]: formValues[MS_HEALTH_SERVICE_AREA],
        [MS_METRIC]: formValues[MS_METRIC]
      }

      const aggregationLevel = formValues[MS_STATE]
        ? config.AGGREGATION_LEVELS.county
        : config.AGGREGATION_LEVELS.state

      const apiParams = getToolSearchApiParams({
        formValues: relevantValues,
        aggregationLevel
      }, config)

      apiParams.size = '300'

      const response = await getLookupToolData(uuid, apiParams)
      const result = convertDataToObjectArray(response, data => {
        const underscored = {}
        Object.keys(data).forEach((key) => {
          underscored[key.replace(/[ -]/g, '_')] = data[key]
        })
        return underscored
      }) || []

      setData(result)
      setIsLoading(false)
    }

    if (config && config.AGGREGATION_LEVELS) {
      setIsLoading(true)
      load()
    }
  }, [
    uuid,
    formValues,
    config
  ])

  return {
    isLoading,
    data
  }
}

/**
 *
 * @param {object} data
 * @param {object} data.formValues
 * @param {object} data.aggregationLevel
 * @returns
 */
export function getToolSearchApiParams (data, config) {
  const { formValues, aggregationLevel } = data

  const aggregationFilterCondition = {
    filterName: 'aggregationLevel',
    column: config.columns.AGGREGATION_LEVEL_COL,
    operator: '=',
    value: aggregationLevel
  }

  // start with aggregation filter condition
  const filter = {
    conditions: [aggregationFilterCondition]
  }

  // add reference period value if available
  if (formValues[MS_REFERENCE_PERIOD]) {
    filter.conditions.push({
      filterName: MS_REFERENCE_PERIOD,
      column: config.columns.REFERENCE_PERIOD_COL,
      operator: '=',
      value: formValues[MS_REFERENCE_PERIOD]
    })
  }

  // add health service area value if available
  if (formValues[MS_HEALTH_SERVICE_AREA]) {
    filter.conditions.push({
      filterName: MS_HEALTH_SERVICE_AREA,
      column: config.columns.HEALTH_SERVICE_AREA_COL,
      operator: '=',
      value: formValues[MS_HEALTH_SERVICE_AREA]
    })
  }

  // add state value (FIPS abbreviation) if available
  if (formValues[MS_STATE]) {
    filter.conditions.push({
      filterName: MS_STATE,
      column: config.columns.STATE_NAME_COL,
      operator: '=',
      value: formValues[MS_STATE]
    })
  }

  // add county value (FIPS code) if available
  if (formValues[MS_COUNTY]) {
    filter.conditions.push({
      filterName: MS_COUNTY,
      column: config.columns.COUNTY_FIPS_COL,
      operator: '=',
      value: formValues[MS_COUNTY].split(',')[0]
    })
  }

  const payload = {
    filter,
    columns: [
      config.columns.AGGREGATION_LEVEL_COL,
      config.columns.STATE_NAME_COL,
      config.columns.STATE_FIPS_COL,
      config.columns.COUNTY_NAME_COL,
      config.columns.COUNTY_FIPS_COL,
      config.columns.REFERENCE_PERIOD_COL,
      config.columns.HEALTH_SERVICE_AREA_COL,
      config.columns.MORATORIUM_COL,
      flattenMetric(formValues[MS_METRIC], config)
    ]
  }

  return payload
}

export const MsMapPaint = (dataKey) => {
  return {
    'fill-color': [
      'case',
      ['==',
        [
          'concat',
          ['get', dataKey]
        ],
        'BLUE 1'
      ],
      BLUE_1,
      ['==',
        [
          'concat',
          ['get', dataKey]
        ],
        'BLUE 2'
      ],
      BLUE_2,
      ['==',
        [
          'concat',
          ['get', dataKey]
        ],
        'BLUE 3'
      ],
      BLUE_3,
      ['==',
        [
          'concat',
          ['get', dataKey]
        ],
        'BLUE 4'
      ],
      BLUE_4,
      ['==',
        [
          'concat',
          ['get', dataKey]
        ],
        'BLUE 5'
      ],
      BLUE_5,
      ['==',
        [
          'concat',
          ['get', dataKey]
        ],
        'PURPLE 1'
      ],
      PURPLE_1,
      ['==',
        [
          'concat',
          ['get', dataKey]
        ],
        'PURPLE 2'
      ],
      PURPLE_2,
      ['==',
        [
          'concat',
          ['get', dataKey]
        ],
        'PURPLE 3'
      ],
      PURPLE_3,
      ['==',
        [
          'concat',
          ['get', dataKey]
        ],
        'PURPLE 4'
      ],
      PURPLE_4,
      ['==',
        [
          'concat',
          ['get', dataKey]
        ],
        'PURPLE 5'
      ],
      PURPLE_5,
      GREY
    ],
    'fill-opacity': 1,
    'fill-outline-color': 'white'
  }
}

export const mapLegendData = [
  {
    label: 'No Moratorium'
  },
  {
    label: 'Lowest 25%',
    color: BLUE_1
  },
  {
    label: 'Second Lowest 25%',
    color: BLUE_2
  },
  {
    label: 'Third Lowest 25%',
    color: BLUE_3
  },
  {
    label: 'Top 25% Excl. Extreme Values',
    color: BLUE_4
  },
  {
    label: 'Extreme Values',
    color: BLUE_5
  },
  {
    label: 'Active Moratorium'
  },
  {
    label: 'Lowest 25%',
    color: PURPLE_1
  },
  {
    label: 'Second Lowest 25%',
    color: PURPLE_2
  },
  {
    label: 'Third Lowest 25%',
    color: PURPLE_3
  },
  {
    label: 'Top 25% Excl. Extreme Values',
    color: PURPLE_4
  },
  {
    label: 'Extreme Values',
    color: PURPLE_5
  },
  {
    label: 'Excluded from Analysis'
  },
  {
    label: 'N/A - See technical guide',
    color: GREY
  }
]

export const stateAbbrToMeta = {
  AK: { fips: 2, center: [-154.16, 62.67], zoom: 3 },
  AL: { fips: 1, center: [-86.6820605, 32.614580000000004], zoom: 5.8 },
  AR: { fips: 5, center: [-92.339, 34.816], zoom: 6.17 },
  AS: { fips: 60, center: [-169.609873, -12.76725], zoom: 5.8 },
  AZ: { fips: 4, center: [-111.850, 34.562], zoom: 5.5 },
  CA: { fips: 6, center: [-118.534, 37.077], zoom: 4.87 },
  CO: { fips: 8, center: [-105.546, 39.023], zoom: 5.89 },
  CT: { fips: 9, center: [-72.632, 41.427], zoom: 7 },
  DC: { fips: 11, center: [-77.0274, 38.9017], zoom: 10.35 },
  DE: { fips: 10, center: [-75.4171, 39.123], zoom: 7.29 },
  FL: { fips: 12, center: [-84.1, 27.6586], zoom: 5.39 },
  GA: { fips: 13, center: [-83.297, 32.555], zoom: 5.9 },
  GU: { fips: 66, center: [144.826, 13.463671], zoom: 13 },
  HI: { fips: 15, center: [-157.322, 20.438], zoom: 5 },
  IA: { fips: 19, center: [-93.439, 42.178], zoom: 5.9 },
  ID: { fips: 16, center: [-114.1432955, 45.4946015], zoom: 5 },
  IL: { fips: 17, center: [-89.544, 39.755], zoom: 5.7 },
  IN: { fips: 18, center: [-86.201, 39.907], zoom: 5.85 },
  KS: { fips: 20, center: [-98.498, 38.437], zoom: 5.9 },
  KY: { fips: 21, center: [-85.605, 37.726], zoom: 5.9 },
  LA: { fips: 22, center: [-91.430082, 30.972234], zoom: 5.9 },
  MA: { fips: 25, center: [-71.7182015, 42.062276499999996], zoom: 6.4 },
  MD: { fips: 24, center: [-77.268295, 38.81738], zoom: 6 },
  ME: { fips: 23, center: [-69.01711449999999, 45.218725], zoom: 5.9 },
  MI: { fips: 26, center: [-86.4155505, 44.943532000000005], zoom: 5.1 },
  MN: { fips: 27, center: [-93.3642175, 46.441857], zoom: 5.1 },
  MO: { fips: 29, center: [-92.4367735, 38.304661499999995], zoom: 5.9 },
  MS: { fips: 28, center: [-89.87644850000001, 32.5849975], zoom: 5.9 },
  MT: { fips: 30, center: [-110.044783, 46.6798055], zoom: 5.1 },
  NC: { fips: 37, center: [-79.890264, 35.214220499999996], zoom: 5.65 },
  ND: { fips: 38, center: [-100.3017035, 47.467870500000004], zoom: 5.9 },
  NE: { fips: 31, center: [-99.680902, 41.500853], zoom: 5.5 },
  NH: { fips: 33, center: [-71.58394050000001, 44.001233], zoom: 6.1 },
  NJ: { fips: 34, center: [-74.7267965, 40.142971], zoom: 6.5 },
  NM: { fips: 35, center: [-106.02606850000001, 34.1662665], zoom: 5.6 },
  NV: { fips: 32, center: [-117.022697, 38.502145999999996], zoom: 5.25 },
  NY: { fips: 36, center: [-75.80918299999999, 42.7559765], zoom: 5.9 },
  OH: { fips: 39, center: [-82.669426, 40.1903625], zoom: 6.1 },
  OK: { fips: 40, center: [-98.71689, 35.3093865], zoom: 5.65 },
  OR: { fips: 41, center: [-120.51473849999999, 44.131399], zoom: 5.65 },
  PA: { fips: 42, center: [-77.6047035, 40.99483], zoom: 5.9 },
  PR: { fips: 72, center: [-66.6407015, 18.1998035], zoom: 7.61 },
  RI: { fips: 44, center: [-71.491671, 41.582568499999994], zoom: 7.6 },
  SC: { fips: 45, center: [-80.9471625, 33.625001], zoom: 6.1 },
  SD: { fips: 46, center: [-100.24714349999999, 44.2125425], zoom: 5.9 },
  TN: { fips: 47, center: [-85.978599, 35.830545], zoom: 5.85 },
  TX: { fips: 48, center: [-100.08102650000001, 31.1690405], zoom: 4.85 },
  UT: { fips: 49, center: [-111.54701, 39.499767500000004], zoom: 5.9 },
  VA: { fips: 51, center: [-79.45883950000001, 38.003375], zoom: 5.9 },
  VI: { fips: 78, center: [-64.8125555, 18.0241315], zoom: 7.61 },
  VT: { fips: 50, center: [-72.4511475, 43.871756], zoom: 5.1 },
  WA: { fips: 53, center: [-120.8245815, 47.273017499999995], zoom: 5.9 },
  WI: { fips: 55, center: [-89.8467645, 44.785962999999995], zoom: 5.9 },
  WV: { fips: 54, center: [-80.181884, 38.920142], zoom: 6.1 },
  WY: { fips: 56, center: [-107.554524, 43.000325000000004], zoom: 5.9 },
  FM: { fips: 234, center: [158.24, 6.663], zoom: 6.48 },
  MH: { fips: 234, center: [167.4397, 9.191], zoom: 9.55 },
  UM: { fips: 234, cetner: [166.6446, 19.2909], zoom: 10.58 },
  MP: { fips: 234, center: [145.734, 14.986], zoom: 7.6 },
  PW: { fips: 234, center: [134.516, 7.348], zoom: 7.5 }
}

export const DisplaySelectionsContext = ({ label, healthServiceArea, metric, getMetricFromLookup, referencePeriod, config }) => {
  const metricLabel = getMetricFromLookup(metric, config).label
  return (
    <Container className='MsStateCountyToolResults_meta_details'>
      <span className='MS_selection_data_label'>{`${label}`}</span>
      <span className='context_text'>{`${healthServiceArea}`}</span>
      <span className='context_text MS_selection_pipe'> | </span>
      <span className='context_text'>{`${metricLabel}`}</span>
      {referencePeriod ? (<span className='context_text MS_selection_pipe'> | </span>) : ''}
      {referencePeriod ? (<span className='context_text'>{`${referencePeriod}`}</span>) : ''}
    </Container>
  )
}
DisplaySelectionsContext.propTypes = {
  label: PropTypes.string.isRequired,
  healthServiceArea: PropTypes.string.isRequired,
  metric: PropTypes.string.isRequired,
  getMetricFromLookup: PropTypes.func.isRequired,
  referencePeriod: PropTypes.string,
  config: PropTypes.object
}

export const MapLegendDisplay = ({ legendData, cssClass }) => {
  const [showLegend, setShowLegend] = useState(false)
  return (
    <div className={`legend_wrapper ${cssClass}`}>
      <div className='close_icon' onClick={() => setShowLegend(false)}>
        <XIcon />
      </div>
      <ul className={`${showLegend ? 'show_legend' : ''}`}>
        {legendData.map((legend, i) => {
          if (legend.color) {
            return (
              <li key={i} className='keys'>
                <div className='color_swatch' style={{ backgroundColor: legend.color }} />
                <div className='label'>{legend.label}</div>
              </li>
            )
          } else {
            return (
              <li key={i} className='header'>
                <Text typeFace='Rubik-Regular'>{legend.label}</Text>
              </li>
            )
          }
        })}
      </ul>
      <div className={`legend_toggle ${!showLegend ? 'hide_top_border' : ''}`} onClick={() => setShowLegend(!showLegend)}>
        <div>
          {showLegend && <ChevronDownIcon />}
          {!showLegend && <ChevronUpIcon />}
        </div>
        <div>
          {showLegend ? 'Close' : 'Show'} Legend
        </div>
      </div>
    </div>
  )
}

MapLegendDisplay.propTypes = {
  legendData: PropTypes.array,
  cssClass: PropTypes.string
}

export const msStateCompareChartDataTransform = (selectedMetric, selectedStates) => {
  let transformed = []
  if (selectedStates.length > 0) {
    transformed = selectedStates.map(state => {
      return {
        label: state.stateName,
        lineColor: state.lineColor,
        data: state.data.map(refPeriods => {
          if (refPeriods[selectedMetric]) {
            return parseFloat(refPeriods[selectedMetric].replace(/[^0-9.]/g, ''))
          } else {
            return NaN
          }
        })
      }
    })
  }
  return transformed
}

export const preserveDataGaps = (selectedStates, referencePeriods) => {
  return selectedStates.map(state => {
    const withDataGaps = []
    for (let i = 0, j = 0; i < referencePeriods.length; i++) {
      if (state.data[j] && state.data[j].reference_period === referencePeriods[i].value) {
        withDataGaps.push(state.data[j])
        j++
      } else {
        withDataGaps.push({})
      }
    }
    return {
      stateName: state.stateName,
      stateCode: state.stateCode,
      data: withDataGaps,
      lineColor: state.lineColor
    }
  })
}
