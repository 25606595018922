import axios from 'axios'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import urlJoin from 'url-join'
import config from '../../config'
import log from '../../log'
import {
  getContactEmail,
  extractResourceTypeData
} from '../../utilities/apiHelpers'
import { getAssetOrigin, getScaledImgUrl } from '../../utilities/linkHelpers'
import {
  formatCitations,
  formatDefinitionTableData,
  formatDataUsed,
  formatCaseStudies,
  formatArticleSections
} from '../formatters/article'
import { formatMetaTags, getIncludedUrls, getMediaImageAltText } from '../formatters/global'
import { getDatasetsWithMetaData } from '../api'
import simpleCacheBust from '../../utilities/simpleCacheBust'

const {
  apiSite,
  articleComponentTypes: {
    componentTypeCitations,
    componentTypeResources
  },
  displayContexts: {
    DATA_RESOURCE
  },
  useCacheBuster
} = config

/**
 * Get all datasets the param article has been attached to
 * @param {String} id article uuid to fetch against
 */
export const getArticleDatasets = async (id) => {
  // the three calls hit the same endpoint except for the filter name & path field
  const callParamsConfig = [
    {
      filter: 'dictionary-filter',
      fieldPath: 'field_current_dataset_version.field_dictionary.field_internal_page.id'
    },
    {
      filter: 'methodology-filter',
      fieldPath: 'field_current_dataset_version.field_methodology.field_internal_page.id'
    },
    {
      filter: 'other-resources-filter',
      fieldPath: 'field_current_dataset_version.field_resources_additional.field_internal_page.id'
    }
  ]

  try {
    const results = await Promise.all(callParamsConfig.map(config => _getArticleDataset(id, config)))

    // aggregate then ensure unique values
    const datasets = [...results[0], ...results[1], ...results[2]]
    /*
      must cross reference all datasets for last updated date of newest version
      endpoints we called above do not guarantee newest dataset version
    */
    const allDatasets = await getDatasetsWithMetaData()
    const encountered = []

    const reduced = datasets.reduce((prev, curr) => {
      const { name, slug } = curr

      if (encountered.includes(name)) return prev

      // cache new values
      encountered.push(name)
      // find good last updated value
      const latest = allDatasets.find(dataset => dataset.name === name)

      const resource = {
        type: 'Dataset',
        heading: name,
        slug,
        date: latest.last_updated
      }

      return [...prev, resource]
    }, [])

    const final = reduced.sort((a, b) => new Date(b.date) - new Date(a.date))

    log.debug('getArticleDatasets: formatted response', final)

    return final
  } catch (error) {
    log.error('getArticleDatasets: request error', error)

    throw (error)
  }
}

/**
 * Reusable function to call the dataset endpoint provided filter config
 * @param {String} id of current article
 * @param {Object} config describes how to call the endpoint
 * @param {String} config.filter the filter name in the query
 * @param {String} config.fieldPath the path of the field assoc. with the filter
 */
const _getArticleDataset = async (id, { filter, fieldPath }) => {
  /*
    raw string prevents axios from encoding '=' which breaks filter call chain
      encode submitted variables regardless
  */
  const encodedFilter = encodeURIComponent(filter)
  const encodedPath = encodeURIComponent(fieldPath)
  const nonEncodedParams = `?include=field_current_dataset_version&filter[${encodedFilter}][condition][path]=${encodedPath}&filter[${encodedFilter}][condition][operator]=IN&filter[${encodedFilter}][condition][value][]=${id}`

  const res = await axios({
    operationId: 'getArticleDatasets',
    method: 'get',
    baseURL: apiSite,
    jsonAPI: true,
    url: `/jsonapi/taxonomy_term/dataset${nonEncodedParams}`
  })

  return res.data.map(dataset => {
    const name = get(dataset, 'name')
    const slug = get(dataset, 'path.alias')

    return {
      name,
      slug
    }
  })
}

/**
 * Get data dictionary data by unique identifier
 * @param {String} id uuid of the data dictionary to get
 * @param {Object} [opts] options object
 * @param {*} [opts.cancelToken] axios cancel token
 */
export const getDataDictionaryById = async (id, {
  cancelToken
} = {}) => {
  try {
    const { data, rawData } = await axios({
      operationId: 'getDataDictionaryById',
      method: 'get',
      baseURL: apiSite,
      jsonAPI: true,
      url: `/jsonapi/node/data_dictionary_page/${id}`,
      params: {
        include: [
          'field_ref_content_type',
          'field_ref_ancillary_file.field_media_file',
          'field_ref_contact_email'
        ].join(','),
        ...(useCacheBuster.getDataResourceById ? {
          cacheBuster: simpleCacheBust()
        } : {})
      },
      cancelToken
    })

    const internalNodeId = get(data, 'drupalInternalNid', null)
    const orderedIds = rawData?.data?.relationships?.field_dictionary_terms?.data?.map((i) => {
      return i.id
    })

    const pageContentType = get(data, 'fieldRefContentType.name', null)
    const lastUpdated = get(data, 'fieldLastUpdatedDate', null)
    const title = data.title || ''
    const rawFileDownloadUrl = get(data, 'fieldRefAncillaryFile.fieldMediaFile.uri.url', null)
    const fileDownloadUrl = rawFileDownloadUrl ? urlJoin(getAssetOrigin(), rawFileDownloadUrl) : null
    const contactEmail = getContactEmail(data)

    const formattedData = {
      title,
      pageContentType,
      lastUpdated,
      fileDownloadUrl,
      sections: [{
        id: '0',
        header: '',
        content: []
      }],
      contactEmail,
      metaTags: formatMetaTags(get(data, 'metatagNormalized')),
      internalNodeId,
      orderedIds
    }

    return formattedData
  } catch (error) {
    log.error('getDataDictionaryById: request error', error)
    throw (error)
  }
}

export const getDataDictionaryTermsByNodeId = async (internalNodeId, paginatedUrl = '', {
  cancelToken
} = {}) => {
  try {
    const { data, rawData } = await axios({
      operationId: 'getDataDictionaryTermsByNodeId',
      method: 'get',
      baseURL: apiSite,
      jsonAPI: true,
      url: paginatedUrl || `/jsonapi/paragraph/dictionary_term?filter[parent_id]=${internalNodeId}`,
      params: {
        include: [
          'field_dictionary_term_table',
          'field_dict_term_opt_items'
        ].join(','),
        ...(useCacheBuster.getDataResourceById ? {
          cacheBuster: simpleCacheBust()
        } : {})
      },
      cancelToken
    })
    const nextUrl = rawData?.links?.next?.href

    let mappedTerms = data.map((term = {}) => {
      const {
        fieldDictionaryTermDefinition: definition = '',
        fieldDictionaryTermFullName: fullName = '',
        fieldDictionaryTermShortName: variableName = '',
        fieldDictionaryTermTable: rawTable,
        fieldDictTermOptItems: optItems,
        fieldDictionaryTermFootnote: footnote = '',
        id
      } = term

      const sources = optItems?.map(item => {
        const { fieldDictTermOptItemType: key, fieldDictTermOptItemValue: value } = item

        return {
          [key]: value
        }
      })
      const table = isEmpty(rawTable) ? undefined : formatDefinitionTableData(rawTable)

      return {
        type: 'dictionary',
        fullName,
        variableName,
        table,
        definition,
        footnote,
        sources,
        id
      }
    })
    const newTerms = nextUrl ? await getDataDictionaryTermsByNodeId(data.internalNodeId, nextUrl) : []

    mappedTerms = [...mappedTerms, ...newTerms]
    return mappedTerms
  } catch (error) {
    log.error('getDataDictionaryTermsByNodeId: request error', error)
    throw (error)
  }
}

export const getDataResourceById = async (id, {
  cancelToken
} = {}) => {
  try {
    const { data, rawData } = await axios({
      operationId: 'getDataResourceById',
      method: 'get',
      baseURL: apiSite,
      jsonAPI: true,
      url: `/jsonapi/node/data_resource_page/${id}`,
      params: {
        include: [
          'field_ref_content_type',
          'field_ref_ancillary_file',
          'field_ref_ancillary_file.field_media_file',
          'field_ref_data_resource_section',
          'field_ref_data_resource_section.field_data_resource_component',
          'field_ref_data_resource_section.field_data_resource_component.field_list_item',
          'field_ref_data_resource_section.field_data_resource_component.field_media_image.field_media_image',
          'field_ref_data_resource_section.field_data_resource_component.field_ref_resource.field_internal_page',
          'field_ref_data_resource_section.field_data_resource_component.field_ref_resource.field_internal_page.field_ref_content_type',
          'field_ref_data_resource_section.field_data_resource_component.field_ref_resource.field_ref_dataset_type',
          'field_ref_data_resource_section.field_data_resource_component.field_ref_resource.field_ancillary_file.field_media_file',
          'field_ref_data_resource_section.field_data_resource_component.field_ref_resource.field_ancillary_file.field_ref_content_type',
          'field_story_page_resources.field_internal_page',
          'field_story_page_resources.field_internal_page.field_ref_content_type',
          'field_story_page_resources.field_ancillary_file.field_media_file',
          'field_story_page_resources.field_ancillary_file.field_ref_content_type',
          'field_story_page_resources.field_ref_dataset_type',
          'field_ref_citations.field_ref_resource.field_internal_page',
          'field_ref_citations.field_ref_resource.field_ref_dataset_type',
          'field_ref_citations.field_ref_resource.field_ancillary_file.field_media_file',
          'field_ref_contact_email'
        ].join(','),
        ...(useCacheBuster.getDataResourceById ? {
          cacheBuster: simpleCacheBust()
        } : {})
      },
      cancelToken
    })

    const included = rawData.included
    const citations = get(data, 'fieldRefCitations')
    const rawSections = get(data, 'fieldRefDataResourceSection')
    const hasFileDownload = !!(get(data, 'fieldRefAncillaryFile'))

    const formattedSections = formatArticleSections(rawSections, included, 'fieldDataResourceComponent')

    // setup citations section so it will be picked up by nav
    if (!isEmpty(citations)) {
      formattedSections.push({
        id: 'references',
        header: 'References',
        label: 'References',
        content: [{
          type: componentTypeCitations,
          citations: formatCitations(citations)
        }]
      })
    }

    // setup resources section so it will be picked up by nav
    const formattedResources = get(data, 'fieldStoryPageResources', []).map(rawResource => extractResourceTypeData(rawResource))

    if (!isEmpty(formattedResources)) {
      formattedSections.push({
        id: 'related-resources',
        content: [
          {
            displayContext: DATA_RESOURCE,
            type: componentTypeResources,
            resources: formattedResources
          }
        ],
        header: null,
        label: 'Related Resources'
      })
    }

    const formattedData = {
      id: get(data, 'id'),
      lastUpdated: get(data, 'fieldLastUpdatedDate'),
      created: get(data, 'created'),
      title: get(data, 'title'),
      pageContentType: get(data, 'fieldRefContentType.name'),
      publishedBy: get(data, 'fieldPublishedBy'),
      sections: formattedSections,
      hasFileDownload: hasFileDownload,
      fileDownloadUrl: hasFileDownload ? `${getAssetOrigin()}${get(data, 'fieldRefAncillaryFile.fieldMediaFile.uri.url')}` : null,
      fileDownloadName: hasFileDownload ? get(data, 'fieldRefAncillaryFile.fieldMediaFile.filename') : null,
      contactEmail: getContactEmail(data),
      metaTags: formatMetaTags(get(data, 'metatagNormalized'))
    }

    log.debug('getDataResourceById: formatted data', formattedData)
    return formattedData
  } catch (error) {
    log.error('getDataResourceById: request error', error)
    throw (error)
  }
}

export const getStoryData = async (id, {
  cancelToken
} = {}) => {
  try {
    const results = await Promise.all([
      getStoryPageById(id, cancelToken),
      getRecentCaseStudies({
        cancelToken,
        idToExclude: id
      })
    ])
    const final = {
      ...results[0],
      recentCaseStudies: results[1]
    }

    log.debug('getStoryData: formatted data', final)

    return final
  } catch (error) {
    log.error('getStoryData: request error', error)
    throw (error)
  }
}

/**
 * Get the ten most recent case studies published for display
 * @param {string} idToExclude - id to exclude from results (e.g., for excluding the current story page)
 */
export const getRecentCaseStudies = async ({ cancelToken, idToExclude = '' } = {}) => {
  try {
    const RESULTS_REQUESTED = 10
    const { data, rawData } = await axios({
      method: 'get',
      operationId: 'getRecentCaseStudies',
      jsonAPI: true,
      baseURL: apiSite,
      url: '/jsonapi/node/story_page',
      params: {
        include: 'field_ref_image.field_media_image',
        'filter[field_ref_content_type.name]': 'Case Study',
        'filter[excludeId][condition][path]': 'id',
        'filter[excludeId][condition][operator]': '<>',
        'filter[excludeId][condition][value]': idToExclude,
        'page[limit]': RESULTS_REQUESTED,
        sort: '-changed',
        ...(useCacheBuster.getRecentCaseStudies ? {
          cacheBuster: simpleCacheBust()
        } : {})
      },
      cancelToken
    })

    return formatCaseStudies(data, rawData.included)
  } catch (error) {
    log.error('getRecentCaseStudies: request error', error)
    throw (error)
  }
}

/**
 * Get story page data by unique identifier
 * @param {String} id uuid of the story page to get
 * @param {Object} [opts] options object
 * @param {*} [opts.cancelToken] axios cancel token
 */
export const getStoryPageById = async (id, {
  cancelToken
} = {}) => {
  try {
    const { data, rawData } = await axios({
      operationId: 'getStoryPageById',
      method: 'get',
      baseURL: apiSite,
      jsonAPI: true,
      url: `/jsonapi/node/story_page/${id}`,
      params: {
        include: [
          'field_ref_content_type',
          'field_data_used.field_ref_resource.field_ref_dataset_type',
          'field_ref_story_page_section',
          'field_ref_story_page_section.field_story_page_component',
          'field_ref_story_page_section.field_story_page_component.field_list_item',
          'field_ref_story_page_section.field_story_page_component.field_ref_faq',
          'field_ref_story_page_section.field_story_page_component.field_media_image.field_media_image',
          'field_ref_story_page_section.field_story_page_component.field_ref_number_callout',
          'field_ref_story_page_section.field_story_page_component.field_ref_resource.field_ancillary_file.field_media_file',
          'field_ref_story_page_section.field_story_page_component.field_ref_resource.field_ancillary_file.field_ref_content_type',
          'field_ref_story_page_section.field_story_page_component.field_ref_resource.field_internal_page.field_ref_content_type',
          'field_ref_story_page_section.field_story_page_component.field_ref_call_to_action.field_internal_page',
          'field_ref_story_page_section.field_story_page_component.field_ref_call_to_action.field_ancillary_file.field_media_file',
          'field_ref_citations.field_ref_resource.field_internal_page',
          'field_ref_citations.field_ref_resource.field_ref_dataset_type',
          'field_ref_citations.field_ref_resource.field_ancillary_file.field_media_file',
          'field_ref_ancillary_file.field_media_file',
          'field_ref_image.field_media_image',
          'field_story_page_resources.field_internal_page',
          'field_story_page_resources.field_internal_page.field_ref_content_type',
          'field_story_page_resources.field_ancillary_file.field_media_file',
          'field_story_page_resources.field_ancillary_file.field_ref_content_type',
          'field_story_page_resources.field_ref_dataset_type',
          'field_ref_contact_email'
        ].join(','),
        ...(useCacheBuster.getStoryPageById ? {
          cacheBuster: simpleCacheBust()
        } : {})
      },
      cancelToken
    })
    const included = rawData.included
    const citations = get(data, 'fieldRefCitations')
    const rawSections = get(data, 'fieldRefStoryPageSection')
    const hasFileDownload = !!(get(data, 'fieldRefAncillaryFile'))

    const formattedSections = formatArticleSections(rawSections, included, 'fieldStoryPageComponent')

    // setup citations section so that it will be picked up by nav
    if (!isEmpty(citations)) {
      formattedSections.push({
        id: 'references',
        header: 'References',
        label: 'References',
        content: [{
          type: componentTypeCitations,
          citations: formatCitations(citations)
        }]
      })
    }

    const formattedResources = get(data, 'fieldStoryPageResources', []).map(rawResource => extractResourceTypeData(rawResource))

    const formattedData = {
      id: get(data, 'id'),
      lastUpdated: get(data, 'changed'),
      created: get(data, 'created'),
      title: get(data, 'title'),
      contentType: get(data, 'fieldRefContentType.name'),
      heroImageUrl: getScaledImgUrl('large', getIncludedUrls(included), get(data, 'fieldRefImage.fieldMediaImage.uri.url', '')),
      heroImageAltText: getMediaImageAltText(get(data, 'fieldRefImage'), included),
      description: get(data, 'fieldDescription'),
      publishedBy: get(data, 'fieldPublishedBy'),
      sections: formattedSections,
      included,
      resources: formattedResources,
      fileDownloadUrl: hasFileDownload ? `${getAssetOrigin()}${get(data, 'fieldRefAncillaryFile.fieldMediaFile.uri.url')}` : null,
      fileDownloadName: hasFileDownload ? get(data, 'fieldRefAncillaryFile.fieldMediaFile.filename') : null,
      contactEmail: getContactEmail(data),
      dataUsed: formatDataUsed(get(data, 'fieldDataUsed')),
      metaTags: formatMetaTags(get(data, 'metatagNormalized')),
      hideLeftNav: get(data, 'flagHideLeftNav')
    }

    return formattedData
  } catch (error) {
    log.error('getStoryPageById: request error', error)
    throw (error)
  }
}
