import React from 'react'

const SearchIcon = () => (
  <span role='img' aria-label='Search' className='SearchIcon SvgIcon'>
    <svg fill='currentColor' xmlns='http://www.w3.org/2000/svg' height='100%' width='100%' viewBox='0 0 20 20'>
      <path fill='currentColor' fillRule='evenodd' d='M14.3 12.6h-1l-.2-.3a7.4 7.4 0 1 0-.8.8l.3.3v.9l5.7 5.7 1.7-1.7-5.7-5.7zm-6.9 0a5.1 5.1 0 1 1 0-10.3 5.1 5.1 0 0 1 0 10.3z' />
    </svg>
  </span>
)

export default SearchIcon
