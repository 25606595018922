/**
 * Common helpers for pagination components
 */

/**
* Calculate the current page number based on the offset and limit
* @param {number} offset current API results position
* @param {number} limit results to show per page
*/
export const getCurrentPage = (offset, limit) => {
  return (offset + limit) / limit
}

/**
 * Calculate the last page number based on total count
 * and current limit
 * @param {number} total number of rows
 * @param {number} limit results to show per page
 */
export const getMaxPageNumber = (total, limit) => {
  return Math.ceil((total || 1) / limit)
}

/**
 * Calculate a new offset based on current page & limit
 * @param {number} page user-friendly page number value
 * @param {number} limit results to show per page
 */
export const getNewOffset = (page, limit) => {
  return limit * (page - 1)
}
