import React from 'react'

const DownloadWithLineIcon = () => (
  <div className='DownloadWithLineIcon SvgIcon'>
    <svg xmlns='http://www.w3.org/2000/svg' fill='currentColor' height='100%' width='100%' version='1' viewBox='0 0 17 20'>
      <path fill='currentColor' fillRule='evenodd' d='M16 7h-4V0H5v7H0l8 8 8-8zM0 18v2h16v-2H0z' />
    </svg>
  </div>
)

export default DownloadWithLineIcon
