import React, { PureComponent } from 'react'
import DownloadModal from '../../components/common/DownloadModal/DownloadModal'
import DownloadModalDesktopLegacy from '../../components/common/DownloadModal/DownloadModalDesktopLegacy'
import DownloadModalMobile from '../../components/common/DownloadModal/DownloadModalMobile'
import {
  getDatasetName,
  getDatasetFrequency,
  getDatasetUuid
} from '../../utilities/datasetHelpers'
import {
  getDatasetVersions,
  getDownloadResources
} from '../../services/api'
import { featureIsEnabled } from '../../stores/siteStore/siteStore'

function getInitialState () {
  return {
    isOpen: false,
    isMobileOpen: false,
    isLoading: false,
    downloadResources: [],
    data: null,
    versions: [],
    currentVersion: null
  }
}

function withDatasetDownloadModal (WrappedComponent) {
  class WithDatasetDownloadModalComponent extends PureComponent {
    constructor (props) {
      super(props)
      this.state = getInitialState()
    }

    /**
     * Currently this is used from the search page, where we only have limited data available
     * @param {object} payload
     * @param {string} payload.datasetName
     * @param {string} payload.datatsetTypeUuid - the uuid of the dataset type, NOT the version
     * @param {String} payload.datasetFrequency - the dataset frequency
     * @param {object} payload.dataset - the payload used for the dataset detail page
     */
    loadAndOpenDownloadModal = async (payload = {}) => {
      const {
        datasetName,
        datasetTypeUuid,
        datasetFrequency
      } = payload

      this.setState({
        isOpen: false,
        isLoading: true
      })

      const [downloadResources, versions] = await Promise.all([
        getDownloadResources(datasetTypeUuid),
        getDatasetVersions(datasetName)
      ])

      this.setState({
        isOpen: true,
        isLoading: false,
        datasetName,
        datasetTypeUuid,
        datasetFrequency,
        versions,
        downloadResources,
        currentVersion: null // ensure proper label displays in modal
      })
    }

    /**
     * Currently this is used from the dataset detail page and interactive dataset page
     * @param {object} payload
     * @param {object[]} payload.versions - all versions for the dataset
     * @param {object[]} payload.downloadResources - download resources for the dataset
     * @param {object} payload.currentVersion - version being viewed, e.g., the version dropdown value in the dataset detail page
     * @param {object} payload.dataset - the payload used for the dataset detail page
     */
    openDownloadModal = (payload = {}) => {
      const {
        versions,
        downloadResources,
        currentVersion,
        dataset
      } = payload

      this.setState({
        isLoading: false,
        isOpen: true,
        currentVersion,
        versions,
        downloadResources,
        datasetTypeUuid: getDatasetUuid(dataset),
        datasetFrequency: getDatasetFrequency(dataset),
        datasetName: getDatasetName(dataset)
      })
    }

    showMobileDownloadModal = () => {
      this.setState({
        isMobileOpen: true
      })
    }

    handleCloseMobileDownloadModal = () => {
      this.setState({
        isMobileOpen: false,
        ...getInitialState()
      })
    }

    closeDownloadModal = () => {
      this.setState({
        isOpen: false,
        ...getInitialState()
      })
    }

    handleCloseDownloadModal = () => {
      this.closeDownloadModal()
    }

    render () {
      const {
        isOpen,
        isMobileOpen,
        versions,
        downloadResources,
        datasetTypeUuid,
        datasetFrequency,
        datasetName,
        currentVersion,
        isLoading
      } = this.state

      const downloadMdalIsV2 = featureIsEnabled('downloadModalV2')
      const downloadModalComponent = downloadMdalIsV2
        ? <DownloadModal show={isOpen} onHide={this.handleCloseDownloadModal} versions={versions} downloadResources={downloadResources} datasetTypeUuid={datasetTypeUuid} datasetFrequency={datasetFrequency} datasetName={datasetName} currentVersion={currentVersion} />
        : <DownloadModalDesktopLegacy show={isOpen} onHide={this.handleCloseDownloadModal} versions={versions} downloadResources={downloadResources} datasetTypeUuid={datasetTypeUuid} datasetFrequency={datasetFrequency} datasetName={datasetName} currentVersion={currentVersion} />

      return (
        <>
          <WrappedComponent
            loadAndOpenDownloadModal={this.loadAndOpenDownloadModal}
            openDatasetDownloadModal={this.openDownloadModal}
            showMobileDownloadModal={this.showMobileDownloadModal}
            closeDatasetDownloadModal={this.closeDownloadModal}
            isLoadingDatasetDownloadModal={isLoading}
            {...this.props}
          />
          {downloadModalComponent}
          <DownloadModalMobile
            show={isMobileOpen}
            onHide={this.handleCloseMobileDownloadModal}
          />
        </>
      )
    }
  }

  return WithDatasetDownloadModalComponent
}

export default () => {
  return (Component) => withDatasetDownloadModal(Component)
}
