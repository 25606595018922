/*
  api.js is a facade for API call aggregation
*/
import { CancelToken } from 'axios'

// article pages
export * from './apiDomains/article'
// changelog page
export * from './apiDomains/changelog'
// cross cutting calls
export * from './apiDomains/common'
// contact page
export * from './apiDomains/contact'
// dataset pages
export * from './apiDomains/dataset'
// global calls
export * from './apiDomains/global'
// homepage
export * from './apiDomains/homepage'
// search page
export * from './apiDomains/search'
// subtopic/topic pages
export * from './apiDomains/subtopicTopic'
// lookup tool
export * from './apiDomains/lookupTool'
// browse by category
export * from './apiDomains/browsebycategory'
// dynamic modules
export * from './apiDomains/dynamicModules'

/**
 * Retrieve a new axios request cancel token
 */
export const getCancelToken = () => {
  return CancelToken.source()
}

export const isCancellationError = (e = {}) => {
  return e && e.constructor
    ? e.constructor.name === 'Cancel'
    : false
}
