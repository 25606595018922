import { Store, registerInDevtools } from 'pullstate'

export const DEFAULT_OFFSET = 0
export const DEFAULT_SIZE = 10

export const initialState = {
  // 'data' holds static info for initial page load
  data: {
    id: '',
    toolType: '',
    name: '',
    slug: '',
    description: '',
    descriptionShort: '',
    contactInfo: {},
    landingPageSections: [],
    featuredTools: [],
    dataSources: {
      default: {}
    },
    fieldIframeSection: {},
    fieldCalloutImage: '',
    fieldMediaImage2: '',
    fieldMobileImage: '',
    fieldIconImageInverted: '',
    fieldMediaImage1: ''
  },
  search: {
    isLoading: false,
    isLoadingStats: false,
    isInitialLoad: true,
    error: false,
    results: [],
    numOfFoundResults: 0,
    offset: DEFAULT_OFFSET,
    size: DEFAULT_SIZE,
    // key/value pairs for column name/form value
    formValues: {},
    cachedFormValues: {},
    scrollPosition: 0
  },
  detail: {
    title: '',
    detailMeta: []
  }
}

const interactiveToolsStore = new Store({ ...initialState })

interactiveToolsStore.createReaction(
  state => state.search.isLoading,
  (isLoading, draft, original, prevIsLoading) => {
    const scrollPosition = original.search.scrollPosition
    // assume search results have just finished loading based on this condition
    const isFinishedLoadingResults = !isLoading && prevIsLoading

    if (isFinishedLoadingResults && scrollPosition) {
      window.scrollTo({ top: scrollPosition })
      // after restoring position, clear scrollPosition
      draft.search.scrollPosition = 0
    }
  }
)

registerInDevtools({ interactiveToolsStore })

export default interactiveToolsStore
