import React from 'react'

const SortIcon = () => (
  <span className='SortIcon SvgIcon'>
    <svg xmlns='http://www.w3.org/2000/svg' height='100%' width='100%' viewBox='0 0 24 16'>
      <path fill='currentColor' fillRule='evenodd' d='M0 16h8v-2.7H0V16zM0 0v2.7h24V0H0zm0 9.3h16V6.7H0v2.6z' />
    </svg>
  </span>
)

export default SortIcon
