import { Store, registerInDevtools, useStoreState } from 'pullstate'

export const sessionStorageKey = 'showAlert'

const initialState = {
  // state needed for Alert
  alertHeight: 0,
  showAlert: !window.sessionStorage.getItem(sessionStorageKey),
  // state for government banner
  govBannerHeight: 0,
  quickSearchOpen: false,
  // state needed for frontend settings
  frontendSettings: null,
  // state for global status bar
  downloadStatusBarProps: {
    active: false
  }
}

const siteStore = new Store({ ...initialState })

registerInDevtools({ siteStore })

export const featureIsEnabled = (name) => {
  try {
    const featureFlagConfig = siteStore?.currentState?.frontendSettings?.feature_flag_configuration
    const specificFeatureSettings = featureFlagConfig?.feature_flags?.find(flag => flag.hasOwnProperty(name))?.[name]
    return specificFeatureSettings?.enabled || false
  } catch (e) {
    console.error('Error when calling getFeatureFlag.  Returning false', e)
    return false
  }
}

export const getFeatureSetting = (featureName, settingName) => {
  try {
    const featureFlagConfig = siteStore?.currentState?.frontendSettings?.feature_flag_configuration
    const specificFeatureSettings = featureFlagConfig?.feature_flags?.find(flag => flag.hasOwnProperty(featureName))?.[featureName]
    const featureSettings = specificFeatureSettings?.featureConfig
    return featureSettings?.hasOwnProperty(settingName) ? featureSettings[settingName] : ''
  } catch (e) {
    console.error('Error when calling getFeatureSetting.  Returning empty string', e)
    return ''
  }
}

export const featuresAreLoaded = () => {
  const frontendSettings = useStoreState(siteStore, s => s.frontendSettings)

  return !!frontendSettings
}

export default siteStore
