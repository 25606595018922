// rewritten from @trussworks/uswds in order to intigrate expanding into the app
// height in viewport is necessary for correctly positioning elements of the nav
// assets and css are still being provided by the package
// css is being loaded as specified by the docs
import React, { useState, useCallback } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import siteStore from '../../../stores/siteStore'

// assets
import flagImg from 'uswds/src/img/us_flag_small.png'
import dotGovIcon from 'uswds/src/img/icon-dot-gov.svg'
import httpsIcon from 'uswds/src/img/icon-https.svg'
import lockIcon from 'uswds/src/img/lock.svg'

export const getCopy = (language, tld) => {
  const lock = (
    <span className='icon-lock'>
      <img
        src={lockIcon}
        className='usa-banner__lock-image'
        alt='lock'
        title='Lock'
        role='img'
      />
    </span>
  )

  switch (language) {
    case 'english':
      return {
        header: 'An official website of the United States government',
        headerAction: 'Here’s how you know',
        tldSectionHeader: `Official websites use ${tld}`,
        tldSectionContent: (() => {
          switch (tld) {
            case '.gov':
              return (
                <>
                  A <strong>.gov</strong> website belongs to an official
                  government organization in the United States.
                </>
              )
            case '.mil':
              return (
                <>
                  A <strong>.mil</strong> website belongs to an official U.S.
                  Department of Defense organization.
                </>
              )
          }
        })(),
        httpsSectionHeader: `Secure ${tld} websites use HTTPS`,
        httpsSectionContent: (
          <>
            A <strong>lock ({lock})</strong> or <strong>https://</strong> means
            you’ve safely connected to the {tld} website. Share sensitive
            information only on official, secure websites.
          </>
        )
      }
    case 'spanish':
      return {
        header: 'Un sitio oficial del Gobierno de Estados Unidos',
        headerAction: 'Así es como usted puede verificarlo',
        tldSectionHeader: `Los sitios web oficiales usan ${tld}`,
        tldSectionContent: (() => {
          switch (tld) {
            case '.gov':
              return (
                <>
                  Un sitio web <strong>.gov</strong> pertenece a una
                  organización oficial del Gobierno de Estados Unidos.
                </>
              )
            case '.mil':
              return (
                <>
                  Un sitio web <strong>.mil</strong> pertenece a una
                  organización oficial del Departamento de Defensa de EE. UU.
                </>
              )
          }
        })(),
        httpsSectionHeader: `Los sitios web seguros ${tld} usan HTTPS`,
        httpsSectionContent: (
          <>
            Un <strong>candado ({lock})</strong> o <strong>https://</strong>{' '}
            significa que usted se conectó de forma segura a un sitio web {tld}.
            Comparta información sensible sólo en sitios web oficiales y
            seguros.
          </>
        )
      }
  }
}

export const GovBanner = ({
  tld = '.gov',
  language = 'english',
  className,
  ...sectionProps
}) => {
  const [isOpen, setOpenState] = useState(false)

  const { quickSearchOpen } = siteStore.useState(state => ({
    govBannerHeight: state.govBannerHeight,
    quickSearchOpen: state.quickSearchOpen
  }))

  const measuredRef = useCallback(node => {
    if (node !== null) {
      const { height, top } = node.getBoundingClientRect()
      siteStore.update(state => {
        state.govBannerHeight = height + top > 0 ? height + top : 0
      })
    }
  }, [isOpen, quickSearchOpen])

  const classes = classnames('usa-banner', className)

  const customBannerStyle = { margin: 0, padding: '2px 28px' }
  const customLinkContentStyle = { margin: 0, padding: '24px 32px' }

  const copy = getCopy(language, tld)

  return (
    <section ref={measuredRef} className={classes} data-testid='govBanner' {...sectionProps}>
      <div className='usa-accordion'>
        <header className='usa-banner__header'>
          <div className='usa-banner__inner' style={customBannerStyle}>
            <div className='grid-col-auto'>
              <img
                className='usa-banner__header-flag'
                src={flagImg}
                alt='U.S. flag'
              />
            </div>
            <div className='grid-col-fill tablet:grid-col-auto'>
              <p className='usa-banner__header-text'>{copy.header}</p>
              <p className='usa-banner__header-action' aria-hidden='true'>
                {copy.headerAction}
              </p>
            </div>
            <button
              type='button'
              className='usa-accordion__button usa-banner__button'
              aria-expanded={isOpen}
              aria-controls='gov-banner'
              onClick={() => {
                setOpenState((isOpen) => !isOpen)
              }}
            >
              <span className='usa-banner__button-text'>
                {copy.headerAction}
              </span>
            </button>
          </div>
        </header>
        <div
          className='usa-banner__content usa-accordion__content'
          id='gov-banner'
          hidden={!isOpen}
          style={customLinkContentStyle}
        >
          <div className='grid-row grid-gap-lg'>
            <div className='usa-banner__guidance tablet:grid-col-6'>
              <img
                className='usa-banner__icon usa-media-block__img'
                src={dotGovIcon}
                role='img'
                alt=''
                aria-hidden='true'
              />
              <div className='usa-media-block__body'>
                <p>
                  <strong>{copy.tldSectionHeader}</strong>
                  <br />
                  {copy.tldSectionContent}
                </p>
              </div>
            </div>
            <div className='usa-banner__guidance tablet:grid-col-6'>
              <img
                className='usa-banner__icon usa-media-block__img'
                src={httpsIcon}
                role='img'
                alt=''
                aria-hidden='true'
              />
              <div className='usa-media-block__body'>
                <p>
                  <strong>{copy.httpsSectionHeader}</strong>
                  <br />
                  {copy.httpsSectionContent}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

GovBanner.propTypes = {
  tld: PropTypes.string,
  language: PropTypes.string,
  className: PropTypes.string
}

export default GovBanner
