/**
 *  Query Generator responsible for building JSONAPI filters (queries)
 *
 *   JSON API filtering will provide an interface to query the interactive dataset sqlite store
 *
 *   provided a an Object [] of filter conditions
 *   return a string filter expression ready to be sent to the BE for data
 */
import config from '../config'
import isEmpty from 'lodash/isEmpty'

const { filterFormInputsDisabledByComparator: noValueNeeded } = config

/**
 * Build a new top level JSONAPI filter group
 * @param {number} groupNum group index
 * @param {Object} conjunction
 * @param {('AND' | 'OR')} conjunction.value
 * @param {string} rootGroupName
 *
 * @returns {string} well formatted group parameter
 */
const addGroup = (groupNum, conjunction, rootGroupName) => (
  [
    `filter[group-${groupNum}][group][conjunction]=${conjunction.value}&`,
    `filter[group-${groupNum}][group][memberOf]=${rootGroupName}`
  ].join('')
)

/**
 * Build a new JSONAPI filter condition
 * @param {number} conditionNum unique id
 * @param {Object} condition the column filter operation object
 * @param {Object} condition.column
 * @param {string} condition.column.value the column the filter belongs to
 * @param {Object} condition.comparator
 * @param {string} condition.comparator.value how to compare the column & filterValue
 * @param {string[]} condition.filterValue the value to query the column with
 * @param {number} groupNum if condition part of group, the parent group id
 *
 * @returns {string} well formatted condition parameter
 */
const addCondition = (conditionNum, condition, groupNum) => {
  const { column, comparator, filterValue } = condition

  // encode as needed
  const safeColumn = column.value
  const safeComparator = encodeURIComponent(comparator.value)
  const safeFilterValue = filterValue.map(item => encodeURIComponent(item))
  // certain comparators do not require a value and will not be accepted by BE if they exist, check here
  const noValueRequired = noValueNeeded.includes(comparator.value)

  // build constituent parts; if condition IS NULL/IS NOT NULL do not pass empty value part
  const columnPart = `filter[filter-${conditionNum}][condition][path]=${safeColumn}&`
  const comparatorPart = `filter[filter-${conditionNum}][condition][operator]=${safeComparator}${noValueRequired ? '' : '&'}`
  const filterValuePart = noValueRequired ? '' : getFilter(conditionNum, safeFilterValue)
  // assemble
  const conditionPart = `&${columnPart}${comparatorPart}${filterValuePart}`
  const groupPart = `&filter[filter-${conditionNum}][condition][memberOf]=group-${groupNum}`

  return `${conditionPart}${groupPart}`
}

/**
 * Helper function that generates query parameters for a given
 * list of filter values.
 *
 * @param {number} conditionNum The condition number (for namespacing purposes).
 * @param {string[]} filterValues A list of filter values.
 *
 * @returns {string} The generated query string.
 */
function getFilter (conditionNum, filterValues) {
  if (filterValues.length === 1) {
    return `filter[filter-${conditionNum}][condition][value]=${filterValues[0]}`
  }
  const queryString = filterValues.reduce((item1, item2, index) => {
    return item1 + `filter[filter-${conditionNum}][condition][value][${index}]=${item2}&`
  }, '')

  return queryString.endsWith('&') ? queryString.slice(0, -1) : queryString
}

/**
 * Provided the correct data structure this function will generate an API-ready
 * querystring filter payload. The filtering API consuming this data expects a
 * tree-like structure with a single root conjunction. Each branch of the tree may be
 * a single condition or a group. For easier composability this function nests all conditions under
 * a group (even if only one condition exists) then nests the results under a single group root-group.
 *
 * @param {Object[]} list of all the query groups
 * @param {Object} list[].group a group of conditions bound by same conjunction
 * @param {Object} list[].group.conjunction logical operator to join conditions on
 * @param {Object[]} list[].group.conditions list of all conditions in the query group
 * @param {Object} list[].group.conditions[].column the field to query
 * @param {Object} list[].group.conditions[].comparator how to compare the field and the filterValue
 * @param {string} list[].group.conditions[].filterValue the value to query against
 * @param {object} [rootConjunction] changes the top level conjunction between groups
 * @param {('AND' | 'OR')} [rootConjunction.value] changes the top level conjunction between groups
 *
 * @returns {string} a JSONAPI filter string ready to be sent to backend
 */
function generate ({ list = [], rootConjunction = { value: 'AND' } }) {
  if (isEmpty(list)) return ''

  const queryStrings = []
  const rootGroupName = 'root-group'

  // all condition groups are nested under root-group
  const rootGroupString = `filter[${rootGroupName}][group][conjunction]=${rootConjunction.value}&`

  // for each group
  list.forEach((group, groupIndex) => {
    const { conjunction = { value: 'AND' }, conditions } = group

    // all conditions exist within a group even if only one condition exists
    let groupString = addGroup(groupIndex, conjunction, rootGroupName)

    // generate each condition inside the group & append
    conditions.forEach((condition, conditionIndex) => {
      // namespace filter name with groupIndex
      const conditionNum = `${groupIndex}-${conditionIndex}`
      const conditionString = addCondition(conditionNum, condition, groupIndex)
      // add a ampersand on end if final condition in group but not the final group
      // other tooling will concat this as needed with ampersand
      const isFinalCondition = conditionIndex === conditions.length - 1
      const isFinalGroup = groupIndex === list.length - 1

      groupString = `${groupString}${conditionString}${isFinalCondition && !isFinalGroup ? '&' : ''}`
    })

    return queryStrings.push(groupString)
  })

  return `${rootGroupString}${queryStrings.join('')}`
}

export default generate
