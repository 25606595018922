import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

class ScrollToTop extends React.Component {
  componentDidUpdate (prevProps) {
    const { location, timeout = 0 } = this.props
    const { location: previousLocation } = prevProps
    if (location.pathname !== previousLocation.pathname) {
      setTimeout(() => window.scrollTo(0, 0), timeout)
    }
  }

  render () {
    return null
  }
}

ScrollToTop.propTypes = {
  location: PropTypes.object,
  timeout: PropTypes.number
}

export { ScrollToTop }

export default withRouter(ScrollToTop)
