import isEmpty from 'lodash/isEmpty'
import UrlPattern from 'url-pattern'
import config from '../config'
import queryString from 'query-string'
import { isPreviewUrl } from './routeHelpers'
import DOMPurify from 'dompurify'

export const ancillaryExtensions = ['txt', 'doc', 'docx', 'pdf', 'jpg', 'jpeg', 'gif', 'png', 'csv', 'xlsx', 'zip']

/**
 * Get the origin url where assets (e.g., images, files) are fetched from;
 * allows for custom asset origin urls per environment, though most environments
 * will fallback to getOrigin() since assets are fetched from the same origin as the application
 */
export function getAssetOrigin () {
  return config.assetOrigin || getOrigin()
}

/**
 * Get the origin url for the frontend app using built-in window prop;
 * see https://developer.mozilla.org/en-US/docs/Web/API/Location/origin
 */
export function getOrigin () {
  return window.location.origin
}

export function getProtocol () {
  return window.location.protocol
}

export function getDomain () {
  return window.location.hostname
}

export function getPort () {
  return window.location.port
}

export function getRootUrl () {
  const port = getPort() ? `:${getPort()}` : ''
  const rootPath = process.env.PUBLIC_URL || ''
  return `${getDomain()}${port}${rootPath}`
}

export function extractRelativePath (url) {
  const domain = getDomain()
  const port = getPort()

  const urlPattern = isEmpty(port)
    ? new UrlPattern(`(:protocol\\://)${domain}(/*)`)
    : new UrlPattern(`(:protocol\\://)${domain}\\::${port}(/*)`)

  const match = urlPattern.match(url)

  return match
    ? match._ ? `/${match._}` : ''
    : url
}

/**
 * use drupal image scaler
 * @param {string} type - opts = 'thumbnail', 'medium', 'large', 'hero'
 * @param {string} imgUrl
 */

export const getScaledImgUrl = (type, itokImgUrls, imgUrl) => {
  const fileName = imgUrl && imgUrl.substring(imgUrl.lastIndexOf('/') + 1)
  if (!type || !fileName || !itokImgUrls[fileName]) return imgUrl
  return itokImgUrls[fileName][type] ? itokImgUrls[fileName][type].href : imgUrl
}

/**
 * Detect if url contains a BE ancillary file type
 * @param {string} url
 */
export const isFileLink = (url = '') => {
  if (!url) return false

  // regex for grabbing extension
  const hasExtension = url.match(/\.[0-9a-z]+$/i)
  if (!hasExtension) return false
  const extension = hasExtension[0].toLowerCase()

  return ancillaryExtensions.some(ancillaryExt => {
    if (extension.includes(ancillaryExt)) return true

    return false
  })
}

/**
 * Even though this is not the most proper way to check if a string is an email address,
 * it works for our purposes - can be enhanced with regex when needed
 * @param {string} url
 */
export const isEmailAddress = (url = '') => {
  return url.includes('@')
}

/**
 * Determine whether a link is directed to an external site (or handled as such)
 * @param {string} url
 * @returns {boolean}
 */
export const isExternalLink = (url = '') => {
  // file links, email links, and hosted apps are considered external
  if (isFileLink(url) || isEmailAddress(url) || isHostedAppLink(url)) {
    return true
  }

  // absolute urls (i.e., urls w/ domain + path) are considered external (except those pointed to the same domain)
  if (isAbsoluteUrl(url) && !isAbsoluteInternalUrl(url)) {
    return true
  }

  // anything else (e.g., empty urls) is not considered an external link
  return false
}

/**
 * Helper method for determining if a link is directed to a hosted non-wddse app under the same domain
 * @param {string} url
 * @returns {boolean}
 */
function isHostedAppLink (url) {
  const relativePath = extractRelativePath(url)
  // Provider Data is the only non-wddse app hosted under data.cms.gov at the moment
  if (relativePath.indexOf('/provider-data') > -1) {
    return true
  }
}

/**
 * Determine whether a url contains absolute url parts (e.g., protocol, domain)
 * @param {string} url
 * @returns {boolean}
 */
export const isAbsoluteUrl = (url = '') => {
  return url.includes('http') || url.includes('www')
}

/**
 * Determine whether a link is directed to an internal absolute url; based on presence of
 * config.domain in the url string (protocol agnostic); also assumes blank strings are internal
 * @param {string} url
 * @returns {boolean}
 */
export function isAbsoluteInternalUrl (url = '') {
  const rootUrl = getRootUrl()
  return url.includes(rootUrl)
}

/**
 * Query the DOM for any links inside param selector.
 *  If found, force any external links into new window/tab safely
 * @param {string} cssSelector
 */
export const searchAndHandleExternalLinks = (cssSelector) => {
  const links = document.querySelectorAll(`${cssSelector} a`)
  links.forEach(link => {
    if (!link.href.includes(getRootUrl())) {
      link.setAttribute('target', '_blank')
      link.setAttribute('rel', 'noopener noreferrer')
    }
  })
}

/**
 * Creates an email link w/ preset subject if conditions are met
 * @param {string} contactLink
 * @param {string} subjectLine
 * @returns {string}
 */
export const getContactHref = (contactLink = '', subjectLine = '') => {
  const isMailtoLink = isEmailAddress(contactLink)

  let contactHref = contactLink

  if (isMailtoLink) {
    contactHref = `mailto:${contactLink}`
  }

  if (isMailtoLink && !isEmpty(subjectLine)) {
    const bcc = 'data.support@cms.hhs.gov'
    const isBcc = contactLink !== bcc
    contactHref += `${isBcc ? `?bcc=${bcc}` : ''}${isBcc ? '&' : '?'}subject=${encodeURIComponent(`${subjectLine}`)}`
  }

  return contactHref
}

/**
 * Default email subject line for a piece of content (e.g., dataset name)
 * @param {string} url
 * @returns {string}
 */
export const getDefaultSubjectLineForContent = (contentName = '') => {
  if (isEmpty(contentName)) {
    return ''
  }

  return `Question on ${contentName}`
}

/**
 * Update URL to append ?accessToken={accessToken} and return a new url if
 * access token is already in the query string parameters
 * @param {string} originalUrl The original URL without accessToken
 */
export const retainAccessToken = (originalUrl) => {
  const searchParams = queryString.parse(window.location.search)
  return isPreviewUrl(originalUrl) ? DOMPurify.sanitize(`${originalUrl}?accessToken=${searchParams.accessToken}`) : originalUrl
}
