import React, { useState, Suspense, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Router, Route, Switch, useLocation } from 'react-router-dom'
import LoadingCover from '../common/LoadingCover/LoadingCover'
import history from '../../history'
import HomePage from '../pages/HomePage/HomePage'
import SearchPage from '../pages/SearchPage/SearchPage'
import InteractiveToolsPage from '../pages/InteractiveToolsPage/InteractiveToolsPage'
import DynamicRouteResolver, { routesStore } from '../router/DynamicRouteResolver/DynamicRouteResolver'
import ScrollToTop from './ScrollToTop'
import { DisplayContextProvider } from './DisplayContext'
import { scrollbarWidth } from '../../utilities/displayUtilities'
import config from '../../config'
import siteStore from '../../stores/siteStore'
import { getFrontendSettings } from '../../services/apiDomains/global'
import {
  getHomePagePath,
  getSearchPagePath,
  getToolsPagePath,
  removeQueryStringFromLocationPath,
  isPreviewUrl
} from '../../utilities/routeHelpers'
import DownloadStatusWindow from '../common/DownloadProgressBar/DownloadStatusWindow'

import 'pure-react-carousel/dist/react-carousel.es.css'
import 'swagger-ui-react/swagger-ui.css'
import 'pikaday/css/pikaday.css'

import './App.scss'

const {
  apiSite
} = config

function App () {
  const [isHeaderOpen, setHeaderOpen] = useState(false)
  const handleHeaderOpen = () => setHeaderOpen(true)
  const handleHeaderClose = () => setHeaderOpen(false)

  useEffect(() => {
    const checkFrontEndSettings = async () => {
      try {
      // Call API for front end settings only once.
        if (!siteStore?.currentState?.frontendSettings) {
          const frontEndSettings = await getFrontendSettings()
          siteStore.update(s => { s.frontendSettings = frontEndSettings })
        }
      } catch (e) {
        console.error('Error during checkFrontEndSettings', e)
      }
    }
    checkFrontEndSettings()
  }, [siteStore])

  return (
    <DisplayContextProvider>
      <DownloadStatusWindow />
      <Router history={history}>
        <ScrollToTop />
        <div
          className='App'
          data-api-site={apiSite}
          data-public-url={process.env.PUBLIC_URL}
          data-app-version={process.env.REACT_APP_APP_VERSION}
        >
          {/*
            redirect home path with extra trailing slash for alerts and any other
            components that depend on checking for home path
          */}
          <ContentArea
            isHeaderOpen={isHeaderOpen}
            handleHeaderOpen={handleHeaderOpen}
            handleHeaderClose={handleHeaderClose}
          />
        </div>
      </Router>
    </DisplayContextProvider>
  )
}

const ContentArea = ({ isHeaderOpen, handleHeaderOpen, handleHeaderClose }) => {
  let location = useLocation()
  location = isPreviewUrl(location?.pathName) ? removeQueryStringFromLocationPath(location) : location

  // routesStore is a key/value obj where key is route, value is corresponding component
  const dynamicRouteComponents = routesStore.useState(routesObj => routesObj)
  const dynamicRoutes = Object.keys(dynamicRouteComponents)

  const paddingRight = isHeaderOpen ? `${scrollbarWidth}px` : '0px'

  return (
    <div
      className='ContentArea'
      style={{ paddingRight }}
    >
      <Switch location={location}>
        <Route path={getHomePagePath()} exact render={renderPage({ Component: HomePage, headerProps: { handleHeaderOpen, handleHeaderClose } })} />
        <Route path={getSearchPagePath()} exact render={renderPage({ Component: SearchPage, headerProps: { handleHeaderOpen, handleHeaderClose } })} />
        <Route path={getToolsPagePath()} exact render={renderPage({ Component: InteractiveToolsPage, headerProps: { handleHeaderOpen, handleHeaderClose } })} />
        {dynamicRoutes.map(route => {
          const { component, routeProps } = dynamicRouteComponents[route]
          return (
            <Route
              key={route}
              path={route}
              render={renderPage({ Component: component, headerProps: { handleHeaderOpen, handleHeaderClose } })}
              {...routeProps}
            />
          )
        })}
        <Route render={renderPage({ Component: DynamicRouteResolver, headerProps: { handleHeaderOpen, handleHeaderClose } })} />
      </Switch>
    </div>
  )
}

ContentArea.propTypes = {
  isHeaderOpen: PropTypes.bool,
  handleHeaderOpen: PropTypes.func,
  handleHeaderClose: PropTypes.func
}

/**
 * Standard component for wrapping top level app pages
 */
const Page = ({ children }) => <div className='page'>{children}</div>

Page.propTypes = {
  children: PropTypes.any
}

/**
 * Helper function for route render prop to wrap in standard page component and provide option for lazy rendering
 * @param {object} Component - React component
 * @param {object} opts
 * @param {boolean} opts.isLazy - indicates whether the component is being lazy loaded via React.lazy()
 */
function renderPage (component, opts = {}) {
  const { isLazy } = opts
  const { Component, headerProps } = component

  return routerProps => (
    <Page>
      {isLazy && (
        <Suspense fallback={<LoadingCover />}>
          <Component {...routerProps} {...headerProps} />
        </Suspense>
      )}
      {!isLazy && <Component {...routerProps} {...headerProps} />}
    </Page>
  )
}

export default App
