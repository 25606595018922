/*
  The All Datasets endpoint @ /data-api/v1/dataset-type is a heavy processor
  and BE recommended we cache the results and re-use across app.
  This HOC implements the suggestion for Components.
*/
import React, { PureComponent } from 'react'
import { getDatasetsWithMetaData } from '../../services/api'

/**
 * @param {object} WrappedComponent 🤓
 * @param {object} opts
 * @param {string} opts.displayName - useful for maintaining dependent unit tests
 */
function withAllDatasets (WrappedComponent, opts = {}) {
  const { displayName } = opts

  class Enhanced extends PureComponent {
    constructor () {
      super()

      let resolvePromise

      this.promise = new Promise((resolve) => {
        resolvePromise = resolve
      })

      // assign resolve to class variable to enable resolving outside of original Promise scope
      this.resolvePromise = resolvePromise

      this.state = {
        allDatasets: [],
        isLoaded: false
      }
    }

    componentDidMount () {
      this.loadDatasets()
    }

    loadDatasets = async () => {
      const data = await getDatasetsWithMetaData()

      this.setState({
        allDatasets: data,
        isAllDatasetsLoaded: true
      }, () => {
        // resolve promise that is passed to child component to notify that
        // allDatasets are ready to be consumed
        this.resolvePromise()
      })
    }

    awaitAllDatasets = () => {
      return this.promise
    }

    render () {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          awaitAllDatasets={this.awaitAllDatasets}
        />
      )
    }
  }

  if (displayName) {
    Enhanced.displayName = displayName
  }

  return Enhanced
}

export default withAllDatasets
