import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as CalendarIcon } from '../../../assets/fontawesome/custom/calendar.svg'
import { ReactComponent as DoubleFileIcon } from '../../../assets/fontawesome/custom/double-file.svg'
import { ReactComponent as ZipFileIcon } from '../../../assets/fontawesome/custom/zip-file.svg'
import { ReactComponent as WarningIcon } from '../../../assets/fontawesome/solid/triangle-exclamation.svg'
import { getDisplayFileSize, resolvePlurals, displayDate } from '../../../utilities/displayUtilities'
import { NotificationBanner } from '../../common/NotificationBanner/NotificationBanner'

const DownloadModalReview = (props) => {
  const { customizedOptions, totalFileSize, totalFilesSelected, selectedStartDate, selectedEndDate, hasSingleDataset, resourcesByType, datasetFrequency, maxGB, goBackHandler } = props
  const hasAWarning = (maxGB > 0 && totalFileSize > (maxGB * 1000000000))
  return (
    <div className='modalTableContainer'>
      <div className='modalTableContainer__subtitle'>Review the files included in your download selection:</div>
      {hasAWarning ? <NotificationBanner actionItemHandler={goBackHandler} maxGBSetting={maxGB} /> : ''}
      <div className='modalTable__header'>
        <div>
          <div>{hasSingleDataset ? 'Date:' : 'Date range:'}</div>
          {hasSingleDataset
            ? (<div><CalendarIcon />{resourcesByType?.Primary ? resourcesByType?.Primary[0]?.datasetVersionLabel : ''}</div>)
            : (<div><CalendarIcon />{displayDate(selectedStartDate, datasetFrequency)} &ndash; {displayDate(selectedEndDate, datasetFrequency)}</div>)}
        </div>
        <div className='fileSize'>
          <div>Estimated file size:</div>
          <div>{hasAWarning ? <WarningIcon className='warningIcon' /> : <ZipFileIcon />}{getDisplayFileSize(totalFileSize)}</div>
        </div>
        <div>
          <div>Total files selected:</div>
          <div><DoubleFileIcon />{totalFilesSelected}</div>
        </div>
      </div>
      <div className='modalTable__body'>
        {customizedOptions.map((parentNode) => {
          const checkedChildren = parentNode?.children?.filter((child) => child.checked)
          return (
            checkedChildren?.length > 0 && (
              <div className='listHeading' key={parentNode?.value}>
                {resolvePlurals(parentNode?.label, checkedChildren?.length > 1)?.toUpperCase()}
                {` (${checkedChildren?.length}):`}
                {parentNode.children.map((child) => (
                  child.checked && (
                    <div className='listItem' key={child.value}>
                      <span className='largeBullet'>&bull;</span>&nbsp;{child.label}
                    </div>
                  )
                ))}
              </div>
            )
          )
        })}
      </div>

    </div>
  )
}

DownloadModalReview.propTypes = {
  customizedOptions: PropTypes.array,
  totalFileSize: PropTypes.number,
  totalFilesSelected: PropTypes.number,
  selectedStartDate: PropTypes.object,
  selectedEndDate: PropTypes.object,
  hasSingleDataset: PropTypes.bool,
  resourcesByType: PropTypes.object,
  datasetFrequency: PropTypes.string,
  maxGB: PropTypes.number,
  goBackHandler: PropTypes.func
}

export default DownloadModalReview
