import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { formatMetaTags } from './global'

/**
 * Transform API response to data expected by components
 * @param {*} data
 */
export function formatContactPageData (data) {
  return {
    title: get(data, 'title'),
    description: get(data, 'fieldContactShortDescription'),
    bodySections: get(data, 'fieldContactPageSection', []).map((section, i) => {
      const subscriptionService = get(section, 'fieldSubscriptionService')
      const isSubscription = !isEmpty(subscriptionService)
      const subscriptionLink = get(subscriptionService, 'fieldExternalSubscriptionLink.uri')
      const ctaLink = get(section, 'fieldRefCallToAction.fieldExternalLink.uri')
      const actionLink = isSubscription ? subscriptionLink : ctaLink

      return {
        title: get(section, 'fieldSectionHeader'),
        text: get(section, 'fieldWysiwygParagraph.value'),
        btnText: isSubscription ? 'Sign Up' : get(section, 'fieldRefCallToAction.fieldCallToActionText'),
        link: actionLink,
        isInverse: isSubscription
      }
    }),
    metaTags: formatMetaTags(get(data, 'metatagNormalized'))
  }
}
