import React, { Component, createRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { getCancelToken, getTypeahead } from '../../../services/api'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
import debounce from 'lodash/debounce'
import config from '../../../config'
import SearchIcon from '../../icons/SearchIcon'
import Text from '../Text/Text'
import log from '../../../log'
import useDisplayContext from '../../../hooks/useDisplayContext'
import { trackTypeaheadClicks } from '../../../utilities/analyticsEventHelpers'
import Analytics from '../../../utilities/analyticsUtilities'
import './Typeahead.scss'

const {
  mobileTypeaheadMaxRows,
  typeaheadMaxRows,
  searchIfGreaterThan
} = config

class Typeahead extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      isTypeaheadOpen: false,
      searchValue: props.initialSearchValue
    }

    this.inputRef = createRef()
  }

  componentDidMount () {
    const { initialSearchValue, autoFocus } = this.props

    // focus only if starting with an empty input
    if (isEmpty(initialSearchValue) && autoFocus) {
      // add a short timeout to compensate for any UI effects (modal animation), otherwise .focus() will not work
      setTimeout(() => {
        if (this.inputRef.current) {
          this.inputRef.current.focus()
        }
      }, 100)
    }
    // add listener for clicking off typeahead
    document.addEventListener('click', this.handleClickOutside, true)
  }

  // when typeahead is part of a form must allow input to update
  // without firing network calls or showing typeahead container
  componentDidUpdate (prevProps, prevState) {
    const { initialSearchValue: oldInitialValue } = prevProps
    const { searchValue: oldSearchValue } = prevState
    const { initialSearchValue } = this.props

    if ((oldInitialValue !== initialSearchValue) && (initialSearchValue !== oldSearchValue)) {
      this.handleSearchInputChange({ target: { value: initialSearchValue } })
    }
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  handleClickOutside = e => {
    const domNode = ReactDOM.findDOMNode(this)
    if (!domNode || !domNode.contains(e.target)) {
      this.setState({
        isTypeaheadOpen: false
      })
    }
  }

  handleSearchInputChange = ({ target: { value: searchValue } }) => {
    const { onChange: externalOnInputChange, name } = this.props

    const inputHasFocus = this.inputRef.current && document.activeElement === this.inputRef.current

    // save input value to typeahead state
    const newState = { searchValue }
    // update url query string
    if (searchValue !== '' && inputHasFocus) {
      newState.isTypeaheadOpen = true
    } else {
      newState.isTypeaheadOpen = false
    }
    // update state
    this.setState(newState)

    // fire optional input on change handler as a normal input with target
    if (externalOnInputChange) {
      externalOnInputChange({
        target: {
          name,
          value: searchValue
        }
      })
    }
  }

  handleSearchOnKeyDown = ({ key }) => {
    if (key === 'Enter') {
      this.handleSearchButtonClick()
    }

    // close result list when user leaves input field
    if (key === 'Escape' || key === 'Tab') {
      this.setState({ isTypeaheadOpen: false })
    }
  }

  handleTypeaheadClick = (typeaheadSearchValue) => {
    const { event } = this.props
    const { searchValue } = this.state
    this.requestSearch(typeaheadSearchValue)
    const searchData = { typeahead: typeaheadSearchValue, term: searchValue }
    trackTypeaheadClicks(event, searchData, false, window.utag)
    this.setState({ isTypeaheadOpen: false })
  }

  handleSearchButtonClick = () => {
    const { event } = this.props
    const { searchValue } = this.state
    this.requestSearch(searchValue)
    const searchData = { term: searchValue }
    trackTypeaheadClicks(event, searchData, true, window.utag)
  }

  // close typeahead and request search from parent
  requestSearch = (value) => {
    const {
      onRequestSearch
    } = this.props

    this.setState({
      isTypeaheadOpen: false
    }, () => {
      onRequestSearch(value)
    })
  }

  render () {
    const {
      className,
      getTypeaheadData,
      isMobile,
      label,
      name,
      onTypeaheadClick,
      placeholder,
      listComponent,
      renderOverlay,
      disabled,
      id
    } = this.props

    const {
      searchValue,
      isTypeaheadOpen
    } = this.state

    return (
      <Form.Group
        controlId={id}
        className={`input-label-container ${cx(className, {
          Typeahead: true,
          isOpen: isTypeaheadOpen
        })}`}
      >
        {label && <h1 className='Typeahead__input-label'><Form.Label>{label}</Form.Label></h1>}
        <Form.Control
          ref={this.inputRef}
          className='Typeahead__input'
          type='text'
          placeholder={placeholder}
          value={searchValue}
          autoComplete='off'
          autoCapitalize='off'
          spellCheck='false'
          onChange={this.handleSearchInputChange}
          onKeyDown={this.handleSearchOnKeyDown}
          name={name}
          disabled={disabled}
        />
        <span className='Typeahead__SearchIcon' onClick={this.handleSearchButtonClick}>
          <SearchIcon />
        </span>
        {isTypeaheadOpen &&
          <TypeaheadContainer
            getTypeaheadOverride={getTypeaheadData}
            isMobile={isMobile}
            onClick={onTypeaheadClick || this.handleTypeaheadClick}
            searchValue={searchValue}
            listComponent={listComponent}
          />}
        {renderOverlay && renderOverlay({ isTypeaheadOpen })}
      </Form.Group>
    )
  }
}

Typeahead.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  getTypeaheadData: PropTypes.func,
  initialSearchValue: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  // optional side effect(s) which need to occur when changes are made
  onChange: PropTypes.func,
  onRequestSearch: PropTypes.func,
  placeholder: PropTypes.string,
  listComponent: PropTypes.any,
  renderOverlay: PropTypes.func,
  onTypeaheadClick: PropTypes.func,
  event: PropTypes.object
}

Typeahead.defaultProps = {
  id: 'searchInput',
  onChange: null,
  initialSearchValue: '',
  listComponent: null
}

export const LoadingRow = () => (
  <Row className='LoadingRow animated fadeIn'>
    <Col>
      <div className='skeleton-box' />
    </Col>
    <Col>
      <div className='skeleton-box' />
    </Col>
  </Row>
)

export const LoadingHeadingRow = () => (
  <Row className='LoadingHeadingRow animated fadeIn'>
    <Col>
      <div className='skeleton-box' />
    </Col>
    <Col>
      <div className='skeleton-box' />
    </Col>
  </Row>
)

export const TypeaheadOverlay = ({ active }) => (
  <div className={`TypeaheadOverlay${active ? ' TypeaheadOverlay--active' : ''}`} />
)

TypeaheadOverlay.propTypes = {
  active: PropTypes.bool
}

export class TypeaheadContainer extends Component {
  state = {
    data: [],
    error: null,
    isLoading: false
  }

  componentDidMount () {
    const { searchValue } = this.props
    this._isMounted = true

    if (searchValue && this._isMounted && searchValue.length > searchIfGreaterThan) {
      this.fetchDebounced()
    }
  }

  componentDidUpdate (prevProps) {
    const { searchValue } = this.props
    if (searchValue !== prevProps.searchValue && this._isMounted && searchValue.length > searchIfGreaterThan) {
      this.fetchDebounced()
    }
  }

  componentWillUnmount () {
    this._isMounted = false
    this.fetchDebounced.cancel()
    this.cancelActiveRequest()
    Analytics.setResultsCount(null)
  }

  cancelActiveRequest () {
    const cancel = get(this.source, 'cancel')
    if (isFunction(cancel)) {
      log.debug('Typeahead: canceling request')
      cancel('Typeahead: canceled request')
    }
  }

  async fetch () {
    const { getTypeaheadOverride } = this.props
    const { isLoading } = this.state
    if (!isLoading) this.setState({ isLoading: true })

    this.cancelActiveRequest()

    const { searchValue, isMobile } = this.props

    this.source = getCancelToken()

    const fetchFunction = getTypeaheadOverride || getTypeahead

    try {
      const data = await fetchFunction(searchValue, isMobile, { cancelToken: this.source.token })
      this.source = null
      this.setState({ data, isLoading: false, error: null })
      Analytics.setResultsCount(data.length)
    } catch (error) {
      this.setState({ data: [], isLoading: false, error })
    }
  }

  fetchDebounced = debounce(this.fetch, 500, { maxWait: 1000 })

  render () {
    const { isMobile, searchValue, onClick, listComponent } = this.props
    const { data, isLoading, error } = this.state
    const loadingRowCount = isMobile ? mobileTypeaheadMaxRows : typeaheadMaxRows

    const renderProps = {
      data,
      error,
      isLoading,
      isMobile,
      loadingRowCount,
      onClick,
      searchValue
    }

    const ListComponent = listComponent || DefaultTypeaheadList

    return (
      <div className='TypeaheadContainer animated fadeIn'>
        <ListComponent {...renderProps} />
      </div>
    )
  }
}

TypeaheadContainer.propTypes = {
  getTypeaheadOverride: PropTypes.func,
  listComponent: PropTypes.func,
  isMobile: PropTypes.bool,
  searchValue: PropTypes.string,
  onClick: PropTypes.func.isRequired
}

TypeaheadContainer.defaultProps = {
  listComponent: null
}

const DefaultTypeaheadList = (props) => {
  const {
    data,
    error,
    isLoading,
    loadingRowCount,
    onClick,
    searchValue
  } = props

  return (
    <>
      <Container className='TypeaheadContainer__data-container' fluid>
        {/* Loading Header Row */}
        {isLoading && (
          <LoadingHeadingRow />
        )}
        {/* Header Row */}
        {!isLoading && (
          <Row className='TypeaheadContainer__data-header animated fadeIn'>
            <Col>
              <Text typeFace='Small Text/MedGray' domElement='span'>Suggested</Text>
            </Col>
          </Row>
        )}
        {/* Loading Rows */}
        {isLoading && Array(loadingRowCount).fill(null).map((v, i) => <LoadingRow key={i} />)}
        {/* Data Rows */}
        {!error && !isLoading && data.map(({ title }, i) => (
          <Row key={i} className='TypeaheadContainer__data-row animated fadeIn' onMouseDown={() => onClick(title)}>
            <Col>
              <Text typeFace='Body/18pt/Black/L' domElement='span'>{title}</Text>
            </Col>
          </Row>
        ))}
      </Container>
      <Text
        className='TypeaheadContainer__data-row-search'
        typeFace='Body/16pt/Blue/L'
        domElement='p'
        onClick={() => onClick(searchValue)}
      >Search "{searchValue}"
      </Text>
    </>
  )
}

DefaultTypeaheadList.propTypes = {
  data: PropTypes.array.isRequired,
  error: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadingRowCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired
}

export default (props) => {
  const { isMobile } = useDisplayContext()

  return (
    <Typeahead {...props} isMobile={isMobile} />
  )
}
