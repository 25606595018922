import React from 'react'
import PropTypes from 'prop-types'

import './CloseIcon.scss'
import { isEmpty } from 'lodash'

const CloseIcon = (props) => {
  const { size, strokeWidth, onClick, hoverCircleColor, xColor } = props
  const circleBuffer = 27
  const svgMarkup = (
    <svg className='closeImage' xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <line
        x1='1' y1={size - 1}
        x2={size - 1} y2='1'
        stroke={isEmpty(xColor) ? 'currentColor' : xColor}
        strokeWidth={strokeWidth}
      />
      <line
        x1='1' y1='1'
        x2={size - 1} y2={size - 1}
        stroke={isEmpty(xColor) ? 'currentColor' : xColor}
        strokeWidth={strokeWidth}
      />
    </svg>)

  if (hoverCircleColor) {
    return (
      <div aria-label='clear' onClick={onClick} className='CloseIcon SvgIcon'>
        <div className='backgroundCircle' style={{ backgroundColor: hoverCircleColor, height: size + circleBuffer, width: size + circleBuffer }}>
          {svgMarkup}
        </div>
      </div>
    )
  } else {
    return (
      <div aria-label='clear' onClick={onClick} className='CloseIcon SvgIcon'>
        {svgMarkup}
      </div>
    )
  }
}

CloseIcon.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
  hoverCircleColor: PropTypes.string,
  xColor: PropTypes.string
}

CloseIcon.defaultProps = {
  size: 13,
  strokeWidth: 2
}

export default CloseIcon
