import React from 'react'

const SearchFilterCloseIcon = () => (
  <span aria-label='remove' className='SearchFilterCloseIcon SvgIcon'>
    <svg width='10px' height='10px' viewBox='0 0 10 10' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='3.-Molecule/ISearch/Filter-Links' transform='translate(-187.000000, -5.000000)' fill='currentColor'>
          <g transform='translate(-2.000000, -1.000000)' id='2.-Atom/Icon/Close'>
            <g transform='translate(189.000000, 6.000000)'>
              <g id='baseline-close-24px' transform='translate(0.000000, 2.083333)'>
                <polygon id='Path' points='10 -1.07619048 8.99285714 -2.08333333 5 1.90952381 1.00714286 -2.08333333 0 -1.07619048 3.99285714 2.91666667 0 6.90952381 1.00714286 7.91666667 5 3.92380952 8.99285714 7.91666667 10 6.90952381 6.00714286 2.91666667' />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </span>
)

export default SearchFilterCloseIcon
