import axios from 'axios'
import { formatHomepageData } from '../formatters/homepage'
import log from '../../log'
import simpleCacheBust from '../../utilities/simpleCacheBust'
import config from '../../config'
const {
  apiSite,
  useCacheBuster
} = config

/**
 * Get data for homepage
 * @param {*} [opts.cancelToken] axios cancel token
 */
export const getHomepage = async ({ cancelToken } = {}) => {
  try {
    const res = await axios({
      operationId: 'getHomepage',
      method: 'GET',
      baseURL: apiSite,
      jsonAPI: true,
      url: '/jsonapi/node/home_page',
      params: {
        'fields[node--look_up_tool]': 'path,title,field_short_description,field_ref_lookup_tool_type',
        include: [
          'field_ref_home_page_section.field_home_page_component.field_media_image.field_media_image',
          'field_home_page_chart_section.field_ref_navigational_category',
          'field_home_page_chart_section.field_ref_chart.field_ref_chart_data',
          'field_home_page_chart_section.field_ref_chart.field_ref_topic',
          'field_home_page_chart_section.field_ref_chart.field_ref_dataset_type',
          'field_ref_featured_tools_section.field_home_page_featured_tools.field_card_icon.field_media_image_1',
          'field_ref_featured_tools_section.field_home_page_featured_tools.field_ref_call_to_action',
          'field_ref_featured_tools_section.field_home_page_featured_tools.field_ref_call_to_action.field_internal_page',
          'field_home_page_feat_content_sec.field_home_page_featured_content.field_internal_page.field_ref_content_type',
          'field_ref_home_page_section.field_home_page_component.field_ref_call_to_action.field_internal_page.field_ref_content_type',
          'field_home_page_feat_content_sec.field_home_page_featured_content.field_media_image.field_media_image',
          'field_home_page_feat_content_sec.field_home_page_featured_content.field_internal_page.field_ref_image.field_media_image',
          'field_home_page_feat_content_sec.field_home_page_featured_content.field_ancillary_file.field_media_file',
          'field_ref_youtube_video_section'
        ].join(','),
        ...(useCacheBuster.getDatasetById ? {
          cacheBuster: simpleCacheBust()
        } : {})
      },
      cancelToken: cancelToken
    })
    return formatHomepageData(res.data[0], res.rawData.included)
  } catch (error) {
    log.error('getHomepage: request error', error)
    throw (error)
  }
}
