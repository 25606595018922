import React from 'react'

const ModalCloseIcon = () => {
  return (
    <span className='ModalCloseIcon SvgIcon'>
      <svg width='22px' height='22px' viewBox='0 0 22 22' version='1.1' xmlns='http://www.w3.org/1999/xlink'>
        <g id='Data-Detail-Page' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g id='Data-Detail_XL_Download-Modal---Range-1' transform='translate(-933.000000, -142.000000)' fill='currentColor'>
            <g id='Download-Modal-Years-1' transform='translate(285.000000, 104.000000)'>
              <g id='2.-Atom/Icon/Close' transform='translate(648.000000, 38.000000)'>
                <g id='baseline-close-24px' transform='translate(0.000000, 4.583333)'>
                  <polygon id='Path' points='22 -2.36761905 19.7842857 -4.58333333 11 4.20095238 2.21571429 -4.58333333 0 -2.36761905 8.78428571 6.41666667 0 15.2009524 2.21571429 17.4166667 11 8.63238095 19.7842857 17.4166667 22 15.2009524 13.2157143 6.41666667' />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  )
}

export default ModalCloseIcon
