import React from 'react'
import PropTypes from 'prop-types'
import CaretIcon from '../../icons/CaretLeftRightIcon'
import Text from '../../common/Text/Text'
import SearchFiltersBadge from './SearchFiltersBadge'

import './SearchFiltersCategories.scss'

const SearchFiltersCategories = (props) => {
  const {
    categories,
    onCategoryClick,
    onClearCategoryFiltersClick,
    activeFilters
  } = props

  return (
    <ul className='SearchFiltersCategories'>
      {categories.map((category, idx) => {
        const numOfActiveFilters = activeFilters[category.label]
          ? activeFilters[category.label].length
          : 0
        return (
          <li
            className='SearchFiltersCategories__category'
            key={idx}
          >
            <div
              onClick={() => onCategoryClick(category)}
              className='SearchFiltersCategories__categoryText'
            >
              <Text
                domElement='span'
                typeFace='05 H5/Desktop/Regular/White'
                className='SearchFiltersTopics__topicHeaderText'
              >{category.label}&nbsp;
              </Text>
              {numOfActiveFilters > 0 && (
                <SearchFiltersBadge
                  onClick={(e) => {
                    // prevent triggering navigation action
                    e.stopPropagation()
                    onClearCategoryFiltersClick(category)
                  }}
                  text={numOfActiveFilters}
                />
              )}
            </div>
            <div className='SearchFiltersCategories__categoryIcon' onClick={() => onCategoryClick(category)}>
              <CaretIcon direction='right' color='#fff' />
            </div>
          </li>
        )
      })}
    </ul>
  )
}

SearchFiltersCategories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  onCategoryClick: PropTypes.func.isRequired,
  onClearCategoryFiltersClick: PropTypes.func.isRequired,
  activeFilters: PropTypes.object
}

export default SearchFiltersCategories
