import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Typeahead from '../../common/Typeahead/Typeahead'
import { encodeSearchPageQueryString } from '../../../utilities/searchPageHelpers'
import config from '../../../config'
import { getSearchPagePath } from '../../../utilities/routeHelpers'

import './HomepageHero.scss'

const { placeholders, defaultSearchSortValue } = config
const { searchTypeahead, searchTypeaheadMobile } = placeholders

const HomepageHeroLoading = () => (
  <div className='HomepageHeroLoading'>
    <div className='skeleton-box HomepageHeroLoading_header-row' />
    <div className='skeleton-box HomepageHeroLoading_header-row' />
    <div className='skeleton-box HomepageHeroLoading_search' />
  </div>
)

function HomepageHero ({ pageHeader, history, children, isMobile }) {
  const handleRequestSearch = (searchValue) => {
    const queryString = encodeSearchPageQueryString({
      sort: searchValue ? defaultSearchSortValue : '',
      keywords: searchValue
    })

    history.push({
      pathname: getSearchPagePath(),
      search: queryString
    })
  }

  const renderBody = () => {
    if (!pageHeader) {
      return <HomepageHeroLoading />
    }

    return (
      <>
        <h1>{pageHeader}</h1>
        <Typeahead
          autoFocus={false}
          onRequestSearch={handleRequestSearch}
          placeholder={isMobile ? searchTypeaheadMobile : searchTypeahead}
          event={{ grouping: 'Search', category: 'Homepage - Search Typeahead' }}
        />
      </>
    )
  }

  return (
    <>
      <div className='HomepageHero'>
        {renderBody()}
      </div>
      {children}
    </>
  )
}

HomepageHero.propTypes = {
  history: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.string]))
  ]),
  isMobile: PropTypes.bool.isRequired,
  pageHeader: PropTypes.string
}

export default withRouter(HomepageHero)

export { HomepageHero }
