import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import downloadIndicatorStore, { updateDownloadStore, NewQueueNumberIfApplicable, ProcessQueues, getQueueNumber } from '../../../stores/downloadIndicatorStore'
import cx from 'classnames'
import DOMPurify from 'dompurify'
import { ReactComponent as SpinnerCircle } from '../../../assets/fontawesome/custom/SpinnerCircle.svg'
import { ReactComponent as Warning } from '../../../assets/fontawesome/custom/Warning.svg'
import { ReactComponent as Error } from '../../../assets/fontawesome/custom/Error.svg'
import { ReactComponent as CircleCheck } from '../../../assets/fontawesome/solid/circle-check.svg'

import { downloadWithBrowser, SelectedOptionTypes } from '../../../components/common/DownloadModal/downloadModalHelpers'
import { getFeatureSetting } from '../../../stores/siteStore/siteStore'
import { downloadRequest, logDatasetDownloadError } from '../../../services/api'

import './DownloadProgressBar.scss'

export const CancelButton = ({ messageText, downloadZip, progress }) => {
  const shouldHide = messageText === 'Dismiss'
  const cancel = () => {
    updateDownloadStore(progress?.uuid, { active: !shouldHide, pendingCancel: true, status: 'canceled' })
    ProcessQueues()
  }
  return downloadZip ? <div className='cancel' onClick={cancel}>{messageText}</div> : <></>
}

export const DownloadProgressToast = ({ progress, downloadZip, subtitle, IconElement, classByType }) => {
  const messageText = classByType === 'preparing_download' ? 'Cancel' : 'Dismiss'
  return (
    <div className={cx(classByType, 'Toast')}>
      {IconElement}
      <div className='DownloadProgressBar__Column'>
        <div className='DownloadProgressBar__Title'>{progress?.datasetName}</div>
        <div className='DownloadProgressBar__Subtitle'>{subtitle}</div>
      </div>
      <CancelButton messageText={messageText} downloadZip={downloadZip} progress={progress} />
    </div>)
}

export const DownloadProgressBarContents = ({ progress, downloadZip }) => {
  const { status } = progress
  switch (status) {
    case 'preparing':
    case 'contactingServer':
      return <DownloadProgressToast progress={progress} downloadZip={downloadZip} subtitle='Preparing your download...' classByType='preparing_download' IconElement={<SpinnerCircle />} />
    case 'canceled':
      return <DownloadProgressToast progress={progress} downloadZip={downloadZip} subtitle='Download canceled.' classByType='canceled_download' IconElement={<Warning />} />
    case 'starting':
      return <DownloadProgressToast progress={progress} downloadZip={downloadZip} subtitle='Your download has started.' classByType='starting_download' IconElement={<CircleCheck />} />
    case 'completed':
      return <DownloadProgressToast progress={progress} downloadZip={downloadZip} subtitle='Your download is completed.' classByType='starting_download' IconElement={<CircleCheck />} />
    case 'error':
      return <DownloadProgressToast progress={progress} downloadZip={downloadZip} subtitle='There was an error with your download.' classByType='error_download' IconElement={<Error />} />
    case 'errorCatchall':
      return <DownloadProgressToast progress={progress} downloadZip={downloadZip} subtitle='Your selections have exceeded the download limit. Please unselect some options and try again.' classByType='error_download' IconElement={<Error />} />

    default:
      return ''
  }
}

export const prepareZip = async (datasetName, uuid, datasets, files, selectedOption, totalFileSize, firstCall = false) => {
  const thisProgress = downloadIndicatorStore.getRawState()?.progresses?.find(p => p.uuid === uuid)
  let pendingCancel = thisProgress?.pendingCancel

  const newQueueNumber = NewQueueNumberIfApplicable()

  // Return before even setting initial indicator state
  if (!firstCall && pendingCancel) {
    updateDownloadStore(uuid, { pendingCancel: false })
    return
  }

  // Start the UI for preparing since prepareZip shouldn't be called in other states
  if (firstCall) {
    updateDownloadStore(uuid, { pendingCancel: false })
    updateDownloadStore(uuid, { active: true, status: 'contactingServer', datasetName: datasetName, queueNumber: newQueueNumber })
  }

  ProcessQueues()

  try {
    let continuePolling = false
    let maxRetriesSetting = null
    let maxRetries = 3
    let currentRetries = 0
    let response = null

    if (!datasets || !files) {
      // Stop polling, we have empty datasets or files.
      continuePolling = false
      console.error('Received empty datasets or files, stopping the polling until a new user request is made.')
      updateDownloadStore(uuid, { active: false, status: 'error' })
      return
    }
    // If queued
    if (getQueueNumber(uuid) === 0) {
      response = await downloadRequest(datasets, files)
    } else { continuePolling = true }
    // After API call, check if cancel was clicked during call
    pendingCancel = thisProgress?.pendingCancel
    if (pendingCancel) {
      updateDownloadStore(uuid, { pendingCancel: false })
    }

    switch (response?.status) {
      case 'IN_PROGRESS':
        continuePolling = true
        updateDownloadStore(uuid, { active: true, status: 'preparing' })
        break
      case 'COMPLETE':
        continuePolling = false
        updateDownloadStore(uuid, { active: true, status: 'starting' })
        break
      case 'ERROR':
        maxRetriesSetting = getFeatureSetting('downloadModalV2', 'maxRetries')
        maxRetries = maxRetriesSetting || maxRetries
        currentRetries = response?.retries?.length || currentRetries
        if (currentRetries < maxRetries) {
          continuePolling = true
          updateDownloadStore(uuid, { active: true, status: 'preparing' })
        } else {
          continuePolling = false
          updateDownloadStore(uuid, { active: true, status: selectedOption === SelectedOptionTypes.Option3 ? 'errorCatchall' : 'error' })
          await logDatasetDownloadError(datasetName, 'backend', datasets, files, selectedOption, totalFileSize)
        }
        break

      default:
        break
    }

    if (continuePolling) {
      if (pendingCancel) {
      // Stopped recursion, now reset variable
        updateDownloadStore(uuid, { pendingCancel: false })
      } else {
        var pollingInterval = Math.floor(Math.random() * (5000 - 3000 + 1)) + 3000

        // Call recursively and poll
        setTimeout(() => prepareZip(datasetName, uuid, datasets, files, selectedOption, totalFileSize), pollingInterval)
      }
    } else {
      if (response?.download_path && !pendingCancel) {
        downloadWithBrowser(DOMPurify.sanitize(response?.download_path))
        continuePolling = false
        updateDownloadStore(uuid, { active: true, status: 'starting' })
        ProcessQueues()
      }
    }
  } catch (error) {
    console.error(error)
    const responseStatusCode = error?.response?.status
    ProcessQueues()
    if (!responseStatusCode) {
      updateDownloadStore(uuid, { active: true, status: 'error' })
      await logDatasetDownloadError(datasetName, 'frontend', datasets, files, selectedOption, totalFileSize)
    } else {
      if (selectedOption !== SelectedOptionTypes.Option3) {
        // User didn't have a selection.  Ensure they get generic error
        updateDownloadStore(uuid, { active: true, status: 'error' })
        await logDatasetDownloadError(datasetName, responseStatusCode, datasets, files, selectedOption, totalFileSize)
      }
      switch (responseStatusCode) {
        case 400:
          updateDownloadStore(uuid, { active: true, status: 'errorCatchall' })
          await logDatasetDownloadError(datasetName, 'length', datasets, files, selectedOption, totalFileSize)
          break
        case 503:
          updateDownloadStore(uuid, { active: true, status: 'error' })
          await logDatasetDownloadError(datasetName, responseStatusCode, datasets, files, selectedOption, totalFileSize)
          break
        default:
          updateDownloadStore(uuid, { active: true, status: 'errorCatchall' })
          await logDatasetDownloadError(datasetName, responseStatusCode, datasets, files, selectedOption, totalFileSize)
          break
      }
    }
  }
}

export const DownloadProgressBar = (props) => {
  const { progress, downloadZip, statusCollapsed } = props
  useEffect(() => {
    if (progress?.status === 'completed' || progress?.status === 'starting' || progress?.status === 'canceled') {
      setTimeout(() => {
        updateDownloadStore(progress?.uuid, { active: false })
      }, 5000)
    }
  }, [progress.status])
  return progress?.active && !statusCollapsed ? <div className='DownloadProgressBar'><DownloadProgressBarContents progress={progress} downloadZip={downloadZip} /></div> : ''
}

CancelButton.propTypes = {
  messageText: PropTypes.string,
  downloadZip: PropTypes.bool,
  progress: PropTypes.object

}

DownloadProgressToast.propTypes = {
  progress: PropTypes.object,
  downloadZip: PropTypes.bool,
  subtitle: PropTypes.string,
  IconElement: PropTypes.element,
  classByType: PropTypes.string
}

DownloadProgressBarContents.propTypes = {
  progress: PropTypes.object,
  downloadZip: PropTypes.bool

}

DownloadProgressBar.propTypes = {
  progress: PropTypes.object,
  downloadZip: PropTypes.bool,
  statusCollapsed: PropTypes.bool
}

export default DownloadProgressBar
