import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import './Text.scss'

// used as an export for testing
const typeFaces = [
  '01 H1/Desktop/Black',
  '01 H1/White',
  '02 H2/Black/L',
  '02 H2/White/L',
  '02 H2/Desktop/White',
  '02 H2/Homepage/Black',
  '02 H2/Homepage/White',
  '02 H2/Mobile/Black',
  '02 H2/Mobile/White',
  '05 H2/Regular/Dark Blue',
  '03 H3/Black/L',
  '03 H3/White/L',
  '03 H3/Mobile/Black',
  '03 H3/Mobile/White',
  '04 H3/Desktop/Regular/White',
  '05 H3/Regular/Dark Blue',
  '05 H3/Black/Regular/L',
  '05 H5/Black/Regular/L',
  '05 H3/Mobile/Regular/Black',
  '05 H5/Mobile/Regular/Black',
  '06 H3/Black',
  '06 H4/Blue/Link',
  'Body/18pt/Black/L',
  'Body/16pt/Blue/L',
  'Body/16pt/Light Blue',
  'Body/16pt/MedGray',
  'Small Text/MedGray',
  '00 Display/White',
  '05 H5/Blue/Regular/L',
  '05 H5/Desktop/Semibold/White',
  'Body/16pt/White/L',
  '00 Display/Black',
  '06 H3/White',
  '06 H6/Black',
  '06 H6/White',
  '06 H6/Blue/Link',
  'Body/16pt/Black/L',
  'Small Text/Black',
  'Body/18pt/White/L',
  '03 H3/Desktop/White',
  '05 H5/Desktop/Regular/White',
  '04 H4/Desktop/Regular/Black',
  'Small Text/White',
  '04 H4/Desktop/Regular/White',
  'Large Text/Callout/Black/Light',
  '05 H5/Regular/Dark Blue',
  '05 H5/Mobile/Regular/Dark Blue'
]

const defaultTextElement = 'p'

const Text = (props) => {
  const {
    typeFace,
    className: additionalClassName,
    children,
    domElement,
    ...additionalProps
  } = props

  let Element = defaultTextElement
  let className

  switch (typeFace) {
    case '01 H1/Desktop/Black':
      className = 'typeface-01-H1-Desktop-Black'
      Element = 'h1'
      break

    case '01 H1/White':
      className = 'typeface-01-H1-White'
      Element = 'h1'
      break

    case '02 H2/Black/L':
      className = 'typeface-02-H2-Black-L'
      Element = 'h2'
      break

    case '02 H2/Mobile/Black':
      className = 'typeface-02-H2-Mobile-Black'
      Element = 'h2'
      break

    case '02 H2/Mobile/White':
      className = 'typeface-02-H2-Mobile-White'
      Element = 'h2'
      break

    case '02 H2/White/L':
      className = 'typeface-02-H2-White-L'
      Element = 'h2'
      break

    case '02 H2/Homepage/Black':
      className = 'typeface-02-H2-Homepage-Black'
      Element = 'h2'
      break

    case '02 H2/Desktop/White':
      className = 'typeface-02-H2-Desktop-White'
      Element = 'h2'
      break

    case '02 H2/Homepage/White':
      className = 'typeface-02-H2-Homepage-White'
      Element = 'h2'
      break

    case '05 H5/Black/Regular/L':
      className = 'typeface-05-H5-Black-Regular-L'
      Element = 'h5'
      break

    case '05 H3/Black/Regular/L':
      className = 'typeface-05-H5-Black-Regular-L'
      Element = 'h3'
      break

    case '05 H3/Mobile/Regular/Black':
      className = 'typeface-05-H5-Mobile-Black-Regular'
      Element = 'h3'
      break

    case '05 H5/Mobile/Regular/Black':
      className = 'typeface-05-H5-Mobile-Black-Regular'
      Element = 'h5'
      break

    case '05 H5/Blue/Regular/L':
      className = 'typeface-05-H5-Blue-Regular-L'
      Element = 'h5'
      break

    case '05 H5/Desktop/Semibold/White':
      className = 'typeface-05-H5-Desktop-Semibold-White'
      Element = 'h5'
      break

    case 'Body/16pt/Light Blue':
      className = 'typeface-body-16pt-Light-Blue'
      break

    case 'Body/16pt/MedGray':
      className = 'typeface-body-16pt-MedGray'
      break

    case '05 H5/Black/Semibold/L':
      className = 'typeface-05-Black-Semibold-L'
      Element = 'h5'
      break

    case 'Body/16pt/Black/L':
      className = 'typeface-body-16pt-black-l'
      break

    case 'Body/18pt/Black/L':
      className = 'typeface-Body-18pt-Black-L'
      break

    case 'Body/16pt/Blue/L':
      className = 'typeface-Body-16pt-Blue-L'
      break

    case 'Small Text/MedGray':
      className = 'typeface-Small-Text-MedGray'
      break

    case '00 Display/White':
      className = 'typeface-00-Display-White'
      Element = 'h1'
      break

    case 'Small Text/LightGray':
      className = 'typeface-Small-Text-LightGray'
      break

    case 'Body/16pt/White/L':
      className = 'typeface-Body-16pt-White-L'
      break

    case 'Body/16pt/White/L/List':
      className = 'typeface-Body-16pt-White-L-List'
      break

    case 'Button/White/L':
      className = 'typeface-Button-White-L'
      Element = 'span'
      break

    case 'Rubik-Regular':
      className = 'typeface-Rubik-Regular'
      break

    case '03 H3/Black/L':
      className = 'typeface-03-H3-Black-L'
      Element = 'h3'
      break

    case '03 H3/White/L':
      className = 'typeface-03-H3-White-L'
      Element = 'h3'
      break

    case '03 H3/Mobile/Black':
      className = 'typeface-03-H3-Mobile-Black'
      Element = 'h3'
      break

    case '03 H3/Mobile/White':
      className = 'typeface-03-H3-Mobile-White'
      Element = 'h3'
      break

    case '00 Display/Black':
      className = 'typeface-00-Display-Black'
      Element = 'h1'
      break

    case '06 H6/Black':
      className = 'typeface-06-H6-Black'
      Element = 'h6'
      break

    case '06 H3/Black':
      className = 'typeface-06-H6-Black'
      Element = 'h3'
      break

    case '06 H6/White':
      className = 'typeface-06-H6-White'
      Element = 'h6'
      break

    case '06 H3/White':
      className = 'typeface-06-H6-White'
      Element = 'h3'
      break

    case '06 H6/Blue/Link':
      className = 'typeface-06-H6-Blue-Link'
      Element = 'h6'
      break

    case '06 H4/Blue/Link':
      className = 'typeface-06-H6-Blue-Link'
      Element = 'h4'
      break

    case 'Small Text/Black':
      className = 'typeface-Small-Text-Black'
      break

    case 'Body/18pt/White/L':
      className = 'typeface-body-18pt-white-l'
      break

    case '03 H3/Desktop/White':
      className = 'typeface-03-h3-desktop-white'
      Element = 'h3'
      break

    case '05 H5/Desktop/Regular/White':
      className = 'typeface-05-h5-desktop-regular-white'
      Element = 'h5'
      break

    case 'Small Text/White':
      className = 'typeface-small-text-white'
      break

    case '04 H4/Desktop/Regular/White':
      className = 'typeface-04-H4-Desktop-Regular-White'
      Element = 'h4'
      break

    case '04 H3/Desktop/Regular/White':
      className = 'typeface-04-H4-Desktop-Regular-White'
      Element = 'h3'
      break

    case '04 H4/Desktop/Regular/Black':
      className = 'typeface-04-H4-Desktop-Regular-Black'
      Element = 'h4'
      break

    case '04 H4/Mobile/Regular/Black':
      className = 'typeface-04-H4-Mobile-Regular-Black'
      Element = 'h4'
      break

    case 'Large Text/Callout/Black/Light':
      className = 'typeface-Large-Text-Callout-Black-Light'
      break

    case '05 H5/Regular/Dark Blue':
      className = 'typeface-05-H5-Regular-Dark-Blue'
      Element = 'h5'
      break

    case '05 H3/Regular/Dark Blue':
      className = 'typeface-05-H5-Regular-Dark-Blue'
      Element = 'h3'
      break

    case '05 H2/Regular/Dark Blue':
      className = 'typeface-05-H5-Regular-Dark-Blue'
      Element = 'h2'
      break

    case '05 H5/Mobile/Regular/Dark Blue':
      className = 'typeface-05-H5-Mobile-Regular-Dark-Blue'
      Element = 'h5'
      break

    case 'Montserrat':
      className = 'typeface-montserrat'
      break

    case 'Montserrat Header/Navy':
      className = 'typeface-Montserrat-Header-Navy'
      break

    default:
      throw new Error(`unrecognized or missing typeFace: ${typeFace}`)
  }

  if (domElement) {
    Element = domElement
  }

  return (
    <Element
      className={cx(className, additionalClassName)}
      {...additionalProps}
    >
      {children}
    </Element>
  )
}

Text.propTypes = {
  typeFace: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  // optionally select/override which dom element to use
  domElement: PropTypes.string
}

export default Text

export {
  typeFaces
}
