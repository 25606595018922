import get from 'lodash/get'
import { sortByWeight } from '../../utilities/displayUtilities'
import { extractAlertLinkData } from '../../utilities/apiHelpers'

/**
 * Extract meta tags from raw JSON API data. Note the attributes object only contains either
 * name OR property, not both.
 * @param {Object[]} tags
 * @param {Object} tags[].tagWrapper
 * @param {string} tags[].tagWrapper.tag the tag type
 * @param {Object} tags[].tagWrapper.attributes of the tag
 * @param {string} tags[].tagWrapper.attributes.name of the tag
 * @param {string} tags[].tagWrapper.attributes.property of the tag
 * @param {string} tags[].tagWrapper.attributes.content of the tag
 */
export const formatMetaTags = (tags = []) => {
  return tags.reduce((list, tagWrapper) => {
    const { tag, attributes } = tagWrapper
    if (tag === 'meta') {
      list.push(attributes)
    }

    return list
  }, [])
}

/**
 *
 * @param {object} data
 * @param {object} data.header_nav
 * @param {object} data.footer_nav
 */
export function formatNavigationMenus (data) {
  const rawHeader = data.header_nav || {}
  const primaryHeaderLinks = rawHeader.primary_links || {}
  const secondaryHeaderLinks = rawHeader.secondary_links || {}
  const rawFooter = data.footer_nav
  const rawSitemapLinks = get(data, 'sitemap.additional_links', {})
  const formattedData = {}

  // format header links obj to component-consumable format
  formattedData.headerMenuLinks = {
    primary: formatLinkObj(primaryHeaderLinks),
    secondary: formatLinkObj(secondaryHeaderLinks)
  }

  formattedData.sitemap = formatLinkObj(rawSitemapLinks)
  formattedData.footer = rawFooter

  return formattedData
}

function formatLinkObj (linkObj = {}) {
  const formattedLinkList = Object.keys(linkObj).map(headerItemKey => {
    const item = linkObj[headerItemKey]
    let subLinks = []

    if (item.hasChildren) {
      subLinks = Object.keys(item.subtree).map(subItemKey => {
        const subItem = item.subtree[subItemKey]
        return {
          id: subItem.id,
          name: subItem.title,
          url: subItem.url,
          weight: Number(subItem.weight)
        }
      })
    }

    return {
      id: item.id,
      name: item.title,
      url: item.url,
      weight: Number(item.weight),
      subLinks: subLinks
    }
  })

  formattedLinkList.sort(sortByWeight)

  formattedLinkList.forEach(link => {
    link.subLinks.sort(sortByWeight)
  })

  return formattedLinkList
}

// ref: https://stackoverflow.com/questions/822452/strip-html-from-text-javascript/47140708#47140708
/**
 * Temp (maybe) function to strip HTML from category description...I can't see why we'd allow
 * users to put links and bulleted lists here, so using this until BE field is updated
 * @param {*} html
 */
function stripHTML (html) {
  var doc = new window.DOMParser().parseFromString(html, 'text/html')
  return doc.body.textContent || ''
}

/**
 *
 * @param {object[]} allTopics
 */
export function formatAllNavigationalTopicsForNavMenu (allTopics) {
  const categoryObj = allTopics.reduce((obj, rawTopic) => {
    // skip over topics that are not under a category
    if (!rawTopic.fieldRefNavigationalCategory) {
      return obj
    }

    const categoryId = get(rawTopic, 'fieldRefNavigationalCategory.id')

    const formattedTopic = {
      id: get(rawTopic, 'id'),
      name: get(rawTopic, 'name'),
      url: get(rawTopic, 'path.alias'),
      weight: Number(get(rawTopic, 'weight'))
    }

    if (obj[categoryId]) {
      obj[categoryId].subCategories.push(formattedTopic)
    } else {
      obj[categoryId] = {
        id: categoryId,
        name: get(rawTopic, 'fieldRefNavigationalCategory.name'),
        description: stripHTML(get(rawTopic, 'fieldRefNavigationalCategory.description.processed', '')),
        weight: Number(get(rawTopic, 'fieldRefNavigationalCategory.weight')),
        subCategories: [formattedTopic]
      }
    }

    return obj
  }, {})

  // consuming component expects a categories array
  const formattedCategories = Object.keys(categoryObj).map(categoryId => {
    // sor the child topics by weight
    categoryObj[categoryId].subCategories.sort(sortByWeight)
    return categoryObj[categoryId]
  })

  // sort top level categories by weight
  formattedCategories.sort(sortByWeight)

  return formattedCategories
}

export function getIncludedUrls (included = []) {
  const imageUrls = {}
  included.forEach((image) => {
    if (get(image, 'type') === 'file--file') {
      const url = image.attributes.uri.url
      const filename = url.substring(url.lastIndexOf('/') + 1)
      imageUrls[filename] = image.links
    }
  })
  return imageUrls
}

/**
 * This is a helper used to extract the alt text from an image.  The JSON API deserializer should be picking
 * it up, but it's not so we manually find it via the 'included' prop in the JSON API response.
 * Not sure if it is a Drupal thing related to this open issue: https://www.drupal.org/project/drupal/issues/3056921
 *
 * @param {object} imageComponent standard media-image component/content type from deserialized Drupal JSON API
 * @param {object[]} included array of included objects in the JSON API response
 */
export function getMediaImageAltText (imageComponent = {}, included = []) {
  const mediaImageFileId = get(imageComponent, 'id')
  const fileInclude = included.find(include => include.id === mediaImageFileId)
  return get(fileInclude, 'relationships.thumbnail.data.meta.alt', ' ')
}

export function formatAlert (data) {
  const { uri, title: linkTitle } = extractAlertLinkData(get(data[0], 'fieldAlertCta', {}), true)
  const title = get(data[0], 'fieldAlertHeading', '')
  const description = get(data[0], 'fieldAlertDescription.processed', '')
  if (!title && !description && !linkTitle) {
    return {}
  }
  return {
    title,
    description,
    linkTitle,
    uri
  }
}
