import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import MetaHead from '../../layout/MetaHead/MetaHead'
// import LoadingCover from '../../common/LoadingCover/LoadingCover'
import compose from '../../../hocs/compose'
import withBoundary from '../../../hocs/withBoundary/withBoundary'
import Footer from '../../layout/Footer/Footer'
import config from '../../../config'
import ToolsList from '../../interactiveTools/ToolsList/ToolsList'
import VisualizationToolsList from '../../interactiveTools/VisualizationToolsList/VisualizationToolsList'
import InteractiveToolsLandingHero from '../../interactiveTools/InteractiveToolsLandingHero/InteractiveToolsLandingHero'
import ErrorPage from '../ErrorPage/ErrorPage'
import { getLookupToolsGlobalListing } from '../../../services/api'
import LoadingCover from '../../common/LoadingCover/LoadingCover'
import GovBanner from '../../common/GovBanner/GovBanner'
import Header from '../../../components/layout/Header/Header.js'
import history from '../../../history'

const { metaOrganizationName } = config

const InteractiveToolsPage = (props) => {
  const { handleHeaderOpen, handleHeaderClose } = props

  const [globalTools, setGlobalTools] = useState({
    hero: { description: '', title: '' },
    mapTools: [],
    lookupTools: [],
    visualizationTools: [],
    isLoading: true,
    error: null
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {
          hero,
          lookupTools,
          mapTools,
          visualizationTools,
          metaTags
        } = await getLookupToolsGlobalListing()

        setGlobalTools(prevState => (
          {
            hero,
            mapTools,
            lookupTools,
            visualizationTools,
            isLoading: false,
            error: null,
            metaTags
          }
        ))
      } catch (error) {
        setGlobalTools(prevState => (
          {
            ...prevState,
            error
          }
        ))
      }
    }

    fetchData()
  }, [])

  const { hero, lookupTools, mapTools, visualizationTools, isLoading, error, metaTags } = globalTools

  return (
    <div>
      <GovBanner id='QA_govBanner' />
      <Header
        history={history}
        onOpen={handleHeaderOpen}
        onClose={handleHeaderClose}
      />
      {(() => {
        if (error) {
          return <ErrorPage error={error} />
        }
        if (isLoading) {
          return <LoadingCover />
        }
        return (
          <div className='InteractiveToolsPage pageAnimation'>
            <MetaHead metaTags={metaTags}>
              <title>{`Interactive Tools Page ${metaOrganizationName}`}</title>
            </MetaHead>
            <InteractiveToolsLandingHero {...hero} />
            <ToolsList {...lookupTools} {...mapTools} />
            <VisualizationToolsList {...visualizationTools} />
            <Footer />
          </div>
        )
      })()}
    </div>
  )
}

InteractiveToolsPage.propTypes = {
  handleHeaderOpen: PropTypes.func,
  handleHeaderClose: PropTypes.func
}

export default compose(withBoundary)(InteractiveToolsPage)

export { InteractiveToolsPage }
