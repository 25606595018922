import React from 'react'
import PropTypes from 'prop-types'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './InteractiveToolsLandingHero.scss'

const InteractiveToolsLandingHero = ({
  description,
  title
}) => {
  return (
    <div className='InteractiveToolsLandingHero jumbotron' fluid='true'>
      <Container>
        <Row className='InteractiveToolsLandingHero__container-inner'>
          <Col lg={8} className='InteractiveToolsLandingHero__title-container'>
            <h1>{title}</h1>
            <p>{description}</p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

InteractiveToolsLandingHero.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default InteractiveToolsLandingHero
