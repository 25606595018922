import axios from 'axios'
import config from '../../config'
import log from '../../log'

const {
  apiSite
} = config

const baseFields = [
  'id',
  'title',
  'field_data_source_json',
  'field_module_config_json',
  'machine_name'
]

// /jsonapi/dynamic_module/number_callout
export const getNumberCalloutModule = async (id) => {
  try {
    const res = await axios({
      operationId: 'getNumberCalloutModule',
      jsonAPI: true,
      method: 'get',
      baseURL: apiSite,
      url: `/jsonapi/dynamic_module/number_callout/${id}`
    })

    return res.data
  } catch (error) {
    log.error('getNumberCalloutModule: request error', error)
    throw (error)
  }
}

function formatMapModule (data) {
  return {
    id: data.id,
    moduleIdentifier: data.machineName,
    title: data.title,
    dataSelectionId: '68141f73-64d5-46cc-9637-7104ac99c2aa',
    config: JSON.parse(data.fieldModuleConfigJson)
  }
}

// /jsonapi/dynamic_module/map
export const getMapModule = async (id) => {
  try {
    const res = await axios({
      operationId: 'getMapModule',
      jsonAPI: true,
      method: 'get',
      baseURL: apiSite,
      url: `/jsonapi/dynamic_module/map/${id}`,
      params: {
        'fields[dynamic_module--map]': baseFields.join(',')
      }
    })
    log.debug('getMapModule response', res.data)
    const formatted = formatMapModule(res.data)
    log.debug('getMapModule formatted', formatted)
    return formatted
  } catch (error) {
    log.error('getMapModule: request error', error)
    throw (error)
  }
}

// dynamic_module/bar_graph
export const getBarChartModule = async (id) => {
  try {
    const res = await axios({
      operationId: 'getBarChartModule',
      jsonAPI: true,
      method: 'get',
      baseURL: apiSite,
      url: `/jsonapi/dynamic_module/bar_graph/${id}`
    })

    return res.data
  } catch (error) {
    log.error('getBarChartModule: request error', error)
    throw (error)
  }
}

// /jsonapi/dynamic_module/line_graph
export const getLineChartModule = async (id) => {
  try {
    const res = await axios({
      operationId: 'getLineChartModule',
      jsonAPI: true,
      method: 'get',
      baseURL: apiSite,
      url: `/jsonapi/dynamic_module/line_graph/${id}`
    })

    return res.data
  } catch (error) {
    log.error('getLineChartModule: request error', error)
    throw (error)
  }
}
