import React, { PureComponent, Suspense } from 'react'
import PropTypes from 'prop-types'
import { Store, registerInDevtools } from 'pullstate'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
import { getCancelToken, getSlug } from '../../../services/api'
import { getFormattedPathname, isPreviewUrl } from '../../../utilities/routeHelpers'
import LoadingCover from '../../common/LoadingCover/LoadingCover'
import ErrorPage, { MESSAGE_NOT_FOUND } from '../../pages/ErrorPage/ErrorPage'
import config from '../../../config'
import log from '../../../log'
import GovBanner from '../../common/GovBanner/GovBanner'
import Header from '../../../components/layout/Header/Header.js'
import history from '../../../history'

const DatasetPageContainer = React.lazy(() => import('../../pages/DatasetPage/DatasetPageContainer'))
const ArticlePageContainer = React.lazy(() => import('../../pages/ArticlePage/ArticlePageContainer'))
const NavigationalTopicContainer = React.lazy(() => import('../../pages/NavigationalTopicPage/NavigationalTopicContainer'))
const ContactPage = React.lazy(() => import('../../pages/ContactPage/ContactPage'))
const CategoriesPage = React.lazy(() => import('../../pages/CategoriesPage/CategoriesPage'))
const ApiDocsPage = React.lazy(() => import('../../pages/ApiDocsPage/ApiDocsPage'))
const SiteMapPage = React.lazy(() => import('../../pages/SiteMapPage/SiteMapPage'))
const InteractiveToolPageContainer = React.lazy(() => import('../../pages/InteractiveToolsPage/InteractiveToolPageContainer'))

const {
  bundleTypes,
  previewParam
} = config

export const routesStore = new Store({})

/**
 * Given slug response bundle type, return corresponding component
 * @param {object} data
 * @param {string} data.bundle
 */
export function getPageComponent (data = {}, handleHeaderOpen, handleHeaderClose) {
  const { bundle } = data
  let Component = null

  switch (bundle) {
    case bundleTypes.DATASET:
      log.debug('DynamicRouteResolver: Dataset page resolved.', bundle)
      Component = DatasetPageContainer
      break
    case bundleTypes.DATA_DICTIONARY:
    case bundleTypes.STORY:
    case bundleTypes.DATA_RESOURCE:
      log.debug('DynamicRouteResolver: Article page resolved.', bundle)
      Component = ArticlePageContainer
      break
    case bundleTypes.NAVIGATIONAL_TOPIC:
      log.debug('DynamicRouteResolver: Navigational topic page resolved.', bundle)
      Component = NavigationalTopicContainer
      break
    case bundleTypes.CONTACT_PAGE:
      log.debug('DynamicRouteResolver: Contact page resolved.', bundle)
      Component = ContactPage
      break
    case bundleTypes.CATEGORIES_PAGE:
      log.debug('DynamicRouteResolver: Categories page resolved.', bundle)
      Component = CategoriesPage
      break
    case bundleTypes.SITEMAP_PAGE:
      log.debug('DynamicRouteResolver: Sitemap page resolved.', bundle)
      Component = SiteMapPage
      break
    case bundleTypes.LOOKUP_TOOL_PAGE:
      log.debug('DynamicRouteResolver: Lookup tool page resolved.', bundle)
      Component = InteractiveToolPageContainer
      break
    case bundleTypes.API_DOCS_PAGE:
      log.debug('DynamicRouteResolver: ApiDocs page resolved.', bundle)
      Component = ApiDocsPage
      break
    default:
      log.debug('DynamicRouteResolver: Error page resolved.', bundle)
      Component = ErrorPage
      break
  }

  // must return in functional form with slugResolveData for proper rendering in App
  return function DynamicRouteResolverPage (props) {
    return (
      <div>
        <GovBanner id='QA_govBanner' />
        <Header
          history={history}
          onOpen={handleHeaderOpen}
          onClose={handleHeaderClose}
        />
        <Suspense fallback={<LoadingCover />}>
          <Component {...props} slugResolveData={data} />
        </Suspense>
      </div>
    )
  }
}

/**
 * Given bundle type, determine any necessary additional router props
 * @param {string} bundle
 */
function getRouteProps (bundle) {
  switch (bundle) {
    case bundleTypes.DATASET:
    case bundleTypes.LOOKUP_TOOL_PAGE:
      // dataset + tool should not be exact since we want both '/{tool/dataset path}' and '/{tool/dataset path}/*'
      // to render the same containing component
      return { exact: false }
    case bundleTypes.DATA_DICTIONARY:
    case bundleTypes.STORY:
    case bundleTypes.DATA_RESOURCE:
    case bundleTypes.NAVIGATIONAL_TOPIC:
    case bundleTypes.API_DOCS_PAGE:
    default:
      // all other types are a single page without any subroutes; therefore, they should have exact routes
      return { exact: true }
  }
}

class DynamicRouteResolver extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: true,
      data: {},
      error: null,
      notFound: false
    }
  }

  componentDidMount () {
    this.getSlugInfo()
  }

  componentWillUnmount () {
    this.cancelActiveRequest()
  }

  componentDidUpdate (prevProps) {
    const { location: { pathname: previousPath } } = prevProps
    const { location: { pathname: currentPath } } = this.props

    if (previousPath !== currentPath) {
      this.getSlugInfo()
    }
  }

  cancelActiveRequest () {
    const cancel = get(this.source, 'cancel')
    if (isFunction(cancel)) {
      log.debug('DynamicRouteResolver: canceling request')
      cancel('DynamicRouteResolver: canceled request')
    }
  }

  async getSlugInfo () {
    const { location: { pathname } } = this.props

    this.cancelActiveRequest()
    this.source = getCancelToken()

    // getFormattedPathname will remove any frontend-managed parts of the route (e.g., '/data' from the IDS dataset page);
    // if we don't remove these parts, the BE /slug endpoint will return a 404 since these are entirely frontend managed
    const formattedPathname = getFormattedPathname(pathname)

    log.debug('DynamicRouteResolver:getSlugInfo', formattedPathname)

    try {
      const { data } = await getSlug(formattedPathname, { cancelToken: this.source.token })
      this.source = null

      this.setState({
        isLoading: false,
        error: null,
        notFound: false
      })

      const storePath = isPreviewUrl() ? '/' + previewParam + formattedPathname : formattedPathname
      // store the route/component relationship to persist the routes and prevent unecessary mounting/unmounting
      const pathToStore = process.env.PUBLIC_URL
        ? `${process.env.PUBLIC_URL}${storePath}`
        : storePath

      routesStore.update(state => {
        state[pathToStore] = {
          component: getPageComponent(data, this.props.handleHeaderOpen, this.props.handleHeaderClose),
          routeProps: getRouteProps(data.bundle)
        }
      })
    } catch (error) {
      if (error.response) {
        const dataMsg = get(error.response, 'data.meta.message', '')
        const notFound = (
          error.response.status === 404 ||
          dataMsg.indexOf('The requested path is invalid') > -1
        )
        this.setState({
          notFound: notFound,
          error: error,
          isLoading: false
        })
      }
      this.setState({
        notFound: true,
        error: error,
        isLoading: false
      })
    }
  }

  render () {
    const {
      isLoading,
      error,
      notFound
    } = this.state

    const { handleHeaderOpen, handleHeaderClose } = this.props

    return (
      <div>
        <GovBanner id='QA_govBanner' />
        <Header
          history={history}
          onOpen={handleHeaderOpen}
          onClose={handleHeaderClose}
        />
        {(() => {
          if (isLoading) {
            return <LoadingCover />
          }

          if (notFound) return <ErrorPage message={MESSAGE_NOT_FOUND} />

          if (error) {
            log.error('There was an error loading the page.', error)
            return <ErrorPage error={error} />
          }

          return null
        })()}
      </div>
    )
  }
}

DynamicRouteResolver.propTypes = {
  location: PropTypes.object,
  handleHeaderOpen: PropTypes.func,
  handleHeaderClose: PropTypes.func
}

registerInDevtools({ routesStore })

export default DynamicRouteResolver
