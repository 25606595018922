import get from 'lodash/get'
import pick from 'lodash/pick'
import { extractCtaData, getContactEmail } from '../../utilities/apiHelpers'
import { getDefaultSubjectLineForContent, getScaledImgUrl } from '../../utilities/linkHelpers'
import { buildCaseStudies, buildTextSections } from '../../utilities/topicSubtopicPageHelpers'
import { formatMetaTags, getIncludedUrls, getMediaImageAltText } from '../formatters/global'
import { formatDynamicModule } from '../formatters/dynamicModule'
import cloneDeep from 'lodash/cloneDeep'

/**
 * Formats raw API data for main subtopic page call
 * @param {*} data
 */
export const formatGetSubtopicData = (data, included) => {
  const pageTitle = get(data, 'title')
  const parentName = get(data, 'fieldRefTopic.parent[0].name', '')
  const caseStudies = get(data, 'fieldRelatedCaseStudies', [])

  return {
    pageTitle,
    currentTopicId: get(data, 'fieldRefTopic.id'),
    currentTopicName: get(data, 'fieldRefTopic.name'),
    parentId: get(data, 'fieldRefTopic.parent[0].id', ''),
    header: {
      heading: get(data, 'fieldHeroHeader', ''),
      parents: get(data, 'fieldRefTopic.parent', []),
      description: get(data, 'fieldDescription')
    },
    relatedLinks: {
      header: `Related ${parentName} Datasets`,
      linkGroups: []
    },
    contact: {
      ...getContactEmail(data),
      subjectLine: getDefaultSubjectLineForContent(pageTitle)
    },
    textSections: buildTextSections(data),
    caseStudies,
    caseStudyData: buildCaseStudies(caseStudies, included),
    metaTags: formatMetaTags(get(data, 'metatagNormalized'))
  }
}

/**
 * Formats raw API data for main topic page call
 * @param {*} data
 */
export const formatGetTopicData = (data, included) => {
  const pageTitle = get(data, 'title')
  const formattedVisualizations = formatStaticVisualizations(get(data, 'fieldRefTopicPgVisSection.fieldTopicPgVisComponent'), included)

  const formatted = {
    pageTitle,
    currentTopicId: get(data, 'fieldRefTopic.id'),
    currentTopicName: get(data, 'fieldRefTopic.name'),
    categoryId: get(data, 'fieldRefTopic.fieldRefNavigationalCategory.id', ''),
    header: {
      heading: get(data, 'fieldHeroHeader', ''),
      desc: get(data, 'fieldDescription'),
      imgSrc: getScaledImgUrl('hero', getIncludedUrls(included), get(data, 'fieldTopicHeroImage.thumbnail.uri.url', '')),
      imgAlt: getMediaImageAltText(get(data, 'fieldTopicHeroImage'), included),
      crumb: get(data, 'fieldRefTopic.fieldRefNavigationalCategory.name', '')
    },
    visualizationList: {
      header: get(data, 'fieldRefTopicPgVisSection.fieldSectionHeader', ''),
      subheader: get(data, 'fieldRefTopicPgVisSection.fieldTopicPgVisDesc', ''),
      visualizations: formattedVisualizations
    },
    caseStudies: get(data, 'fieldRelatedCaseStudies', []),
    included,
    textSections: get(data, 'fieldRefTopicPageSection'),
    contact: {
      ...getContactEmail(data),
      subjectLine: getDefaultSubjectLineForContent(pageTitle)
    },
    metaTags: formatMetaTags(get(data, 'metatagNormalized'))
  }

  return formatted
}

/**
 * Format array of raw visualization objects for the VisualizationList component
 * @param {object[]} rawVisualizations
 */
export const formatStaticVisualizations = (rawVisualizations = [], included) => {
  return rawVisualizations.map(rawVisualization => {
    const rawVisualizationLinks = get(rawVisualization, 'fieldAdditionalVisualization', [])
    const formattedLinks = rawVisualizationLinks.map(viz => {
      const rawLicenseAgreement = get(viz, 'fieldRefLicenseAgreement')
      const formattedLicenceAgreement = rawLicenseAgreement
        ? pick(rawLicenseAgreement, ['id', 'title', 'body'])
        : null

      return {
        licenseAgreement: formattedLicenceAgreement,
        ...extractCtaData(viz)
      }
    })

    const visualizationImage = getScaledImgUrl('large', getIncludedUrls(included), get(rawVisualization, 'fieldVisualizationImage.thumbnail.uri.url', ''))

    const visMediaImage = get(rawVisualization, 'fieldVisualizationImage')
    const visualizationImageAltText = getMediaImageAltText(visMediaImage, included)

    return {
      header: get(rawVisualization, 'fieldVisualizationHeader1', rawVisualization.fieldVisualizationHeader2 ? rawVisualization.fieldVisualizationHeader2 : ''),
      subheader: get(rawVisualization, 'fieldVisualizationDesc1', rawVisualization.fieldVisualizationDesc2 ? rawVisualization.fieldVisualizationDesc2 : ''),
      visualizationImage,
      visualizationImageAltText,
      links: formattedLinks,
      linksEyebrow: get(rawVisualization, 'fieldVisualizationHeaderAddl', 'Visualizations'),
      hasImage: !!(visualizationImage)
    }
  })
}

export const formatDynamicVisualizations = (rawVisualizations = [], included) => {
  const formatted = rawVisualizations
    .filter(viz => !!viz.fieldRefDynamicModule)
    .map(viz => {
      const { fieldRefDynamicModule } = viz
      const moduleInclude = included.find(incl => incl.id === fieldRefDynamicModule.id)
      return {
        id: viz.id,
        title: viz.fieldVisualizationHeader2,
        description: viz.fieldVisualizationDesc2,
        module: formatDynamicModule(moduleInclude, cloneDeep(fieldRefDynamicModule.fieldPgDataSource))
      }
    })

  return formatted
}
