import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Text from '../../common/Text/Text'
import { ReactComponent as FacebookIcon } from '../../../assets/fontawesome/solid/facebook-f.svg'
import { ReactComponent as TwitterIcon } from '../../../assets/fontawesome/solid/twitter.svg'
import { ReactComponent as LinkedInIcon } from '../../../assets/fontawesome/solid/linkedin-in.svg'
import { ReactComponent as YouTubeIcon } from '../../../assets/fontawesome/solid/youtube.svg'
import hhsLogo from '../../../assets/hhsLogo.png'
import dataCmsLogoWhite from '../../../assets/dataCmsLogoWhite.png'
import withGlobalMenus, { globalMenuProps } from '../../../hocs/withGlobalMenus/withGlobalMenus'
import LinkHandler from '../../common/LinkHandler/LinkHandler'

import './Footer.scss'

const { REACT_APP_APP_VERSION } = process.env

// create a plain text link from data sources
function generateTextLink (key, data) {
  const linkObject = get(data, key)
  const title = get(linkObject, 'title')
  const url = get(linkObject, 'url')

  return (
    <LinkHandler href={url} label={title} />
  )
}

// global page footer
function Footer ({ footer }) {
  const description = get(footer, 'short_description')
  const column1Label = get(footer, 'column_1_label')
  const column2Label = get(footer, 'column_2_label')
  const column3Label = get(footer, 'column_3_label')
  const hqAddress = get(footer, 'hq_address')
  const facebook = get(footer, 'social_facebook_link')
  const twitter = get(footer, 'social_twitter_link')
  const linkedIn = get(footer, 'social_linkedin_link')
  const youtube = get(footer, 'social_youtube_link')
  // dynamic columns
  const column2Links = get(footer, 'column_2_links')
  const column2Keys = column2Links ? Object.keys(column2Links) : []
  const column3Links = get(footer, 'column_3_links')
  const column3Keys = column3Links ? Object.keys(column3Links) : []
  const bottomLinks = get(footer, 'bottom_row_links')
  const bottomLinkKeys = bottomLinks ? Object.keys(bottomLinks) : []

  // REACT_APP_APP_VERSION is set via Jenkins staging & production build process with git tag value;
  // Jenkinsfile CI injects built-in Jenkins env variable GIT_COMMIT with git commit value for develop builds
  const version = REACT_APP_APP_VERSION || ''

  return (
    <div className='Footer'>
      <div className='Footer__Upper__wrapper'>
        <Container>
          <Col>
            <Row>
              <div className='Footer__Upper'>
                <div className='Footer__Logo'>
                  <LinkHandler noBs href='https://www.hhs.gov' ariaLabel='U.S. Department of Health & Human Services'>
                    <img
                      className='Top__logo'
                      src={hhsLogo}
                      alt='U.S. Department of Health & Human Services'
                    />
                  </LinkHandler>
                </div>
                <div className='Footer__Columns'>
                  <div className='Column__Info'>
                    <img
                      className='Footer__branding'
                      src={dataCmsLogoWhite}
                      alt='Centers for Medicare & Medicaid Services Data logo'
                    />
                    <Text typeFace='Small Text/White'>{description}</Text>
                  </div>
                  <div className='Column__One'>
                    {column1Label && <Text typeFace='06 H3/White' className='Column__header'>{column1Label}</Text>}
                    <p>{hqAddress}</p>

                    <LinkHandler className='first-link' href={facebook} ariaLabel='Facebook'>
                      <FacebookIcon />
                    </LinkHandler>

                    <LinkHandler href={twitter} ariaLabel='Twitter'>
                      <TwitterIcon />
                    </LinkHandler>

                    <LinkHandler href={linkedIn} ariaLabel='LinkedIn'>
                      <LinkedInIcon />
                    </LinkHandler>

                    <LinkHandler href={youtube} ariaLabel='Youtube'>
                      <YouTubeIcon />
                    </LinkHandler>
                  </div>
                  <div className='Column__Two'>
                    {column2Label && <Text typeFace='06 H3/White' className='Column__header'>{column2Label}</Text>}
                    <ul>
                      {
                        column2Keys.map((linkKey, i) => (
                          <li key={i}>
                            {generateTextLink(linkKey, column2Links)}
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                  <div className='Column__Three'>
                    {column3Label && <Text typeFace='06 H3/White' className='Column__header'>{column3Label}</Text>}
                    <ul>
                      {
                        column3Keys.map((linkKey, i) => (
                          <li key={i}>
                            {generateTextLink(linkKey, column3Links)}
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </Row>
          </Col>
        </Container>
      </div>
      <div className='Footer__Lower__wrapper'>
        <Container>
          <div className='Footer__Lower'>
            {
              bottomLinkKeys.map((linkId, i) => {
                return (
                  <LinkHandler key={i} href={bottomLinks[linkId].url}>
                    <Text typeFace='Small Text/White'>{bottomLinks[linkId].title}</Text>
                  </LinkHandler>
                )
              })
            }
          </div>
        </Container>
        <div className='Footer__version'>
          <Text typeFace='Small Text/White'>{version}</Text>
        </div>
      </div>
    </div>
  )
}

Footer.propTypes = {
  footer: PropTypes.shape({
    bottom_row_links: PropTypes.object.isRequired,
    column_1_label: PropTypes.string.isRequired,
    column_2_label: PropTypes.string.isRequired,
    column_3_label: PropTypes.string.isRequired,
    hq_address: PropTypes.string.isRequired,
    short_description: PropTypes.string.isRequired,
    social_facebook_link: PropTypes.string.isRequired,
    social_linkedin_link: PropTypes.string.isRequired,
    social_twitter_link: PropTypes.string.isRequired,
    social_youtube_link: PropTypes.string.isRequired
  }).isRequired
}

export default withGlobalMenus([globalMenuProps.footer])(Footer)

export { Footer }
