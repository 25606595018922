import { toolTypes } from './toolConfigs'
import * as inpatientTool from './inpatientTool'
import * as prescriberTool from './prescriberTool'
import * as physicianTool from './physicianTool'
import * as optoutTool from './optoutTool'
import * as revalidationTool from './revalidationTool'

export const DETAIL_DISPLAY_TYPE_LIST = 'list'
export const DETAIL_DISPLAY_TYPE_TABLE = 'table'

export function getToolDetailDisplayType (toolType) {
  switch (toolType) {
    case toolTypes.revalidation:
      return DETAIL_DISPLAY_TYPE_LIST
    default:
      return DETAIL_DISPLAY_TYPE_TABLE
  }
}

export const identifierColumns = {
  [toolTypes.inpatient]: inpatientTool.CCN_COLUMN,
  [toolTypes.prescriber]: prescriberTool.NPI_COLUMN,
  [toolTypes.physician]: physicianTool.NPI_COLUMN,
  [toolTypes.optout]: optoutTool.NPI_COLUMN,
  [toolTypes.revalidation]: revalidationTool.ENROLLMENT_ID_COLUMN
}

/**
 *
 * @param {object} data
 * @param {string} data.provider
 * @param {string} data.size
 * @param {string} data.offset
 * @param {string} toolType
 */
export async function getToolDetailDataApiParams (id, data = {}, toolType) {
  const {
    provider,
    offset,
    size
  } = data

  const filter = { conditions: [] }
  let column

  switch (toolType) {
    case toolTypes.revalidation:
      return revalidationTool.getToolDetailDataApiParams(id, data)
    default:
      column = identifierColumns[toolType]
  }

  filter.conditions = [{
    filterName: 'provider',
    column,
    operator: '=',
    value: provider
  }]

  const detailParams = {
    filter,
    offset,
    size
  }

  return detailParams
}

/**
 *
 * @param {object} data
 * @param {string} data.provider
 * @param {string} toolType
 */
export function getToolDetailHeroApiParams (data, toolType) {
  const { provider } = data

  const defaultParams = {
    filter: {
      conditions: [
        { filterName: 'hero', column: identifierColumns[toolType], operator: '=', value: provider }
      ]
    },
    size: 1,
    offset: 0
  }

  let params

  switch (toolType) {
    case toolTypes.revalidation:
      params = {
        ...defaultParams,
        table: revalidationTool.DUE_DATE_LIST_SUBTABLE
      }
      break
    default:
      params = { ...defaultParams }
  }

  return params
}

/**
 *
 * @param {string} id - lookup tool id
 * @param {object} data
 * @param {string} data.provider
 * @param {string} toolType
 */
export async function getToolDetailStatsApiParams (data = {}, toolType) {
  const {
    provider
  } = data

  const filter = { conditions: [] }
  let column

  switch (toolType) {
    case toolTypes.revalidation:
      return revalidationTool.getToolDetailStatsApiParams(data)
    default:
      column = identifierColumns[toolType]
  }

  filter.conditions = [{
    filterName: 'provider',
    column,
    operator: '=',
    value: provider
  }]

  const detailParams = {
    filter
  }

  return detailParams
}

export function getToolDetailResultsTitle (params, toolType) {
  const { provider } = params

  switch (toolType) {
    case toolTypes.revalidation:
      return revalidationTool.isIndividualId(provider)
        ? 'Organizations this individual belongs to'
        : 'Individuals in this organization'
    case toolTypes.inpatient:
      return 'Inpatient discharges by this provider'
    case toolTypes.prescriber:
      return 'Medications prescribed by this provider'
    case toolTypes.physician:
      return 'Services provided by this provider'
    default:
      return ''
  }
}

export const formatToolDetailCells = (column, format) => {
  if (!column.length) {
    return '-'
  }

  let formattedColumn
  let number
  switch (format) {
    case 'number':
      number = typeof column === 'number' ? column : column.replace(/[^0-9.]/g, '') * 1
      formattedColumn = Math.round(number).toLocaleString()
      break
    case 'currency':
      // using regex to strip string values except decimals and numbers
      formattedColumn = column.indexOf('$') > -1 ? `$${Math.round(column.replace(/[^0-9.]/g, '') * 1).toLocaleString()}` : `$${Math.round(column * 1).toLocaleString()}`
      break
    case 'place':
      formattedColumn = column === 'O' ? 'Office' : 'Facility'
      break
    case 'yesno':
      formattedColumn = column === 'O' ? 'Yes' : 'No'
      break
    default:
      formattedColumn = column
  }

  return formattedColumn
}
