import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import BasicSearchResult from './BasicSearchResult'

const StoryResult = ({ data }) => {
  const {
    description,
    image,
    slug,
    title,
    type
  } = data

  const imgSrc = get(image, 'medium.href')
  const imgAlt = get(image, 'alt')

  const resultProps = {
    description,
    imgSrc,
    imgAlt,
    slug,
    title,
    type,
    btnText: 'Read More'
  }

  return <BasicSearchResult {...resultProps} />
}

StoryResult.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    readMore: PropTypes.func,
    image: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string
    }),
    slug: PropTypes.string.isRequired,
    type: PropTypes.string
  })
}

export default StoryResult
