import React from 'react'
import PropTypes from 'prop-types'
import Container from 'react-bootstrap/Container'
import MetaHead from '../../layout/MetaHead/MetaHead'
import config from '../../../config'
import Footer from '../../layout/Footer/Footer'
import LinkHandler from '../../common/LinkHandler/LinkHandler'
import { getHomePagePath, getSearchPagePath } from '../../../utilities/routeHelpers'
import { getContactHref } from '../../../utilities/linkHelpers'

import './ErrorPage.scss'

const { metaOrganizationName, apiDocsPath } = config

export const MESSAGE_NOT_FOUND = {
  metaHead: `Page Not Found ${metaOrganizationName}`,
  messageTitle: 'Sorry, we couldn\'t find that page',
  messageDescription: (
    <ul>
      <li>The page may be missing or the link misspelled/incorrect.</li>
      <li>In July 2021, we launched our redesigned website. As a result, your bookmarked URL may have changed. Please use the <LinkHandler noBs href={getSearchPagePath()}>new site search</LinkHandler> to find your dataset and update your bookmarks.</li>
      <li>API Users: please refer to the <LinkHandler noBs href={apiDocsPath}>API Docs page</LinkHandler> for instructions on updating your applications.</li>
    </ul>
  )
}

export const MESSAGE_SERVER_ERROR = {
  metaHead: `Page Unavailable ${metaOrganizationName}`,
  messageTitle: 'This page is temporarily unavailable',
  messageDescription: 'Try refreshing or come back in a few minutes. If the problem persists, contact us.'
}

const ErrorPage = (props) => {
  const {
    error,
    message
  } = props

  // Using a function because we may handle more specific error statuses in future.
  function getErrorMessageContent () {
    if (message) {
      return message
    }
    /*
     The error object will contain both a response and the request in a 404, so
     we put this check first.  If we add more handling for specific errors, this method
     will be expanded.
    */
    if (error.response && error.response.status === 404) {
      return MESSAGE_NOT_FOUND
    } else {
      // our default error message
      return MESSAGE_SERVER_ERROR
    }
  }

  const { metaHead, messageTitle, messageDescription } = getErrorMessageContent()

  return (
    <div className='ErrorPage'>
      <MetaHead error={metaHead}>
        <title>{metaHead}</title>
      </MetaHead>
      <Container className='ErrorPage__Body'>
        <div className='ErrorPage__Message'>
          <h1>{messageTitle}</h1>
          {messageDescription}
        </div>
        <div className='ErrorPage__Navigation'>
          <LinkHandler href={getHomePagePath()} variant='primary' className='ErrorPage__HomeButton'>
            <span className='Error__button-text'>Back to Home</span>
          </LinkHandler>
          <a
            href={getContactHref('data.support@cms.hhs.gov', 'Support Question')}
            target='_blank'
            rel='noopener noreferrer'
            className='ErrorPage__ContactButton'
          >
            <span className='Error__button-text'>Contact Support</span>
          </a>
        </div>
      </Container>
      <div className='ErrorPage__Footer'>
        <Footer />
      </div>
    </div>
  )
}

// Message allows us to override the message title and description
// for any given page.
ErrorPage.propTypes = {
  error: PropTypes.object,
  message: PropTypes.shape({
    metaHead: PropTypes.string,
    messageTitle: PropTypes.string,
    messageDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
  })
}

ErrorPage.defaultProps = {
  error: {}
}

export default ErrorPage
