import React from 'react'
import PropTypes from 'prop-types'
import Tool from './Tool'
import Container from 'react-bootstrap/Container'

import './ToolsList.scss'

function ToolsList ({ lookupTools, mapTools }) {
  return (
    <div className='ToolsList'>
      <Container>
        <h2>Look-Up Tools</h2>
        <ul className='ToolsList__LookupTools'>
          {
            lookupTools.map((tool, i) => (
              <Tool key={i} tool={tool} />
            ))
          }
        </ul>
      </Container>
      <div className='ToolsList__divider' />
      <div className='ToolsList__MapTools-wrapper'>
        <Container>
          <h2>Interactive Map Tools</h2>
          <ul className='ToolsList__MapTools'>
            {
              mapTools.map((tool, i) => (
                <Tool key={i} tool={tool} />
              ))
            }
          </ul>
        </Container>
      </div>
    </div>
  )
}

ToolsList.propTypes = {
  lookupTools: PropTypes.array,
  mapTools: PropTypes.array
}

export default ToolsList
