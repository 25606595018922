import interactiveToolsStore from './interactiveToolsStore'
import { getLookupToolData } from '../../services/api'
import { formatLookupToolHero } from '../../services/formatters/lookupTool'
import { toolTypes, getToolDetailHeroApiParams } from '../../utilities/lookupToolUtilities'

/**
 *
 * @param {object} payload
 * @param {string} payload.provider
 */
async function loadToolDetailsHero (payload = {}) {
  const {
    data: {
      id,
      toolType,
      dataSources
    }
  } = interactiveToolsStore.getRawState()

  try {
    let additionalData
    const params = getToolDetailHeroApiParams(payload, toolType)
    const response = await getLookupToolData(id, params)

    // gather additional data from alternative sources for proper formatting/display
    switch (toolType) {
      case toolTypes.revalidation:
        additionalData = { lastUpdated: dataSources.default.lastUpdated }
        break
      default:
        additionalData = {}
    }

    const data = formatLookupToolHero(response, toolType, additionalData)

    interactiveToolsStore.update(state => {
      state.detail = data
    })
  } catch (e) {
    interactiveToolsStore.update(state => {
      state.detail.error = e
      state.detail.isLoading = false
    })
  }
}

export default loadToolDetailsHero
