import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as InfoIconOutline } from '../../../assets/fontawesome/custom/Circle-Info-Outline.svg'
import { ReactComponent as InfoIconFilledIn } from '../../../assets/fontawesome/custom/circle-info-filled-in.svg'

import './DatasetVersionIcon.scss'

const DatasetVersionIcon = ({ tooltip, icon }) => {
  return (
    <div className='datasetVersionIcon__TooltipMouseOver'>
      {icon === 'filledIn' && <InfoIconFilledIn />}
      {icon === 'outline' && <InfoIconOutline />}
      <div className='Tooltip'>
        {tooltip}
      </div>
    </div>
  )
}

export default DatasetVersionIcon

DatasetVersionIcon.propTypes = {
  tooltip: PropTypes.string,
  icon: PropTypes.string
}
