import axios from 'axios'
import log from '../../log'
import {
  formatCommonLookupToolData,
  formatGlobalListingToolsData,
  formatFeaturedTools,
  formatToolSlug
} from '../formatters/lookupTool'
import config from '../../config'
import { generateDataApiQueryString } from '../../utilities/lookupToolUtilities'
import { getInteractiveDataApiPath } from './dataset'

const {
  apiSite
} = config

/**
 * getLookupToolById
 * API call to get data about a single lookup tool; used to populate the lookup tool search page,
 * search page landing, and some parts of the lookup tool detail/result page
 * @param {string} lookupToolId - uuid of lookup tool
 */
export const getLookupToolById = async (lookupToolId) => {
  try {
    const { data } = await axios({
      operationId: 'getLookupToolById',
      jsonAPI: true,
      method: 'GET',
      baseURL: apiSite,
      url: `/jsonapi/node/look_up_tool/${lookupToolId}`,
      params: {
        'fields[paragraph--dataset_type_reference]': 'field_ref_dataset_type',
        'fields[paragraph--data_source_instance]': 'field_data_source_identifier,field_pg_data_source',
        'fields[taxonomy_term--dataset]': 'name,field_update_frequency,path,field_current_dataset_version',
        'fields[node--dataset]': 'title,field_last_updated_date',
        'fields[paragraph--look_up_tool_section]': 'id,field_in_page_navigation_link,field_section_header,field_wysiwyg_paragraph',
        'fields[node--contact]': 'id,field_contact_email,title',
        include: [
          'field_look_up_tool_section',
          'field_look_up_tool_resource_sec',
          'field_look_up_tool_resource_sec.field_lookup_tool_feat_resources',
          'field_look_up_tool_resource_sec.field_lookup_tool_feat_resources.field_internal_page.field_ref_content_type',
          'field_look_up_tool_resource_sec.field_lookup_tool_feat_resources.field_ancillary_file.field_media_file',
          'field_look_up_tool_resource_sec.field_lookup_tool_feat_resources.field_ancillary_file.field_ref_content_type',
          'field_look_up_tool_resource_sec.field_lookup_tool_addl_resources.field_internal_page.field_ref_content_type',
          'field_look_up_tool_resource_sec.field_lookup_tool_addl_resources.field_ancillary_file.field_media_file',
          'field_look_up_tool_resource_sec.field_lookup_tool_addl_resources.field_ancillary_file.field_ref_content_type',
          'field_lookup_tool_faq_sec.field_lookup_tool_ref_faq_list.field_ref_faq',
          'field_lookup_tool_datasets_sec.field_lookup_tool_rel_datasets.field_ref_dataset_type',
          'field_lookup_tool_datasets_sec.field_lookup_tool_rel_datasets.field_ref_dataset_type.field_current_dataset_version',
          'field_iframe_section',
          'field_ref_contact_email',
          'field_ref_license_agreement',
          'field_ref_lookup_tool_type',
          'field_pg_lookup_tool_datasource.field_pg_data_source.field_ref_dataset_type.field_current_dataset_version',
          'field_pg_lookup_tool_datasource.field_pg_data_source.field_ref_dataset_version',
          'field_pg_lookup_tool_datasource.field_pg_data_source.field_ref_media_interactive',
          'field_ref_icon_image',
          'field_ref_background_image'
        ].join(',')
      }
    })

    return formatCommonLookupToolData(data)
  } catch (error) {
    log.error('getLookupToolById: request error', error)
    throw error
  }
}

export const getLookupToolBackground = async (assetId) => {
  if (!assetId) {
    return {}
  }
  try {
    const { data } = await axios({
      operationId: 'getLookupToolAssets',
      jsonAPI: true,
      method: 'GET',
      baseURL: apiSite,
      url: `/jsonapi/media/background_image/${assetId}/`,
      params: {
        include: [
          'field_callout_image',
          'field_media_image_2',
          'field_mobile_image'
        ].join(',')
      }
    })
    return data
  } catch (error) {
    log.error('getLookupToolAssets: request error', error)
    throw error
  }
}

export const getLookupToolIcon = async (assetId) => {
  if (!assetId) {
    return {}
  }
  try {
    const response = await axios({
      operationId: 'getLookupToolAssets',
      jsonAPI: true,
      method: 'GET',
      baseURL: apiSite,
      url: `/jsonapi/media/icon_image/${assetId}/`,
      params: {
        include: [
          'field_media_image_1',
          'field_icon_image_inverted'
        ].join(',')
      }
    })
    return {
      ...response?.data,
      iconAltText: response?.rawData?.data?.relationships?.field_media_image_1?.data?.meta?.alt,
      iconInvertedAltText: response?.rawData?.data?.relationships?.field_icon_image_inverted?.data?.meta?.alt
    }
  } catch (error) {
    log.error('getLookupToolIcon: request error', error)
    throw error
  }
}

/**
 * getFeaturedLookupTools
 * API call to get the featured tools for a given lookup tool
 * @param {string} lookupToolId - uuid of lookup tool
 */
export const getFeaturedLookupTools = async (lookupToolId) => {
  try {
    const { data } = await axios({
      operationId: 'getFeaturedLookupTools',
      jsonAPI: true,
      method: 'GET',
      baseURL: apiSite,
      url: `/jsonapi/node/look_up_tool/${lookupToolId}/field_ref_featured_tools`,
      params: {
        'fields[node--look_up_tool]': 'id,title,field_short_description,field_description,path,field_ref_lookup_tool_type,field_ref_icon_image',
        'fields[media--icon_image]': 'field_media_image_1',
        include: [
          'field_ref_lookup_tool_type',
          'field_ref_icon_image.field_media_image_1'
        ].join(',')
      }
    })
    return formatFeaturedTools(data)
  } catch (error) {
    log.error('getFeaturedLookupTools: request error', error)
    throw error
  }
}

/**
 * getLookupToolSlugByToolType
 * API call to get the URL path for every look-up tool.
 * @param {string} lookupToolId - uuid of lookup tool
 */
export const getLookupToolSlugByToolType = async (toolType) => {
  try {
    const { data } = await axios({
      operationId: 'getLookupToolSlugByToolType',
      jsonAPI: true,
      method: 'GET',
      baseURL: apiSite,
      url: '/jsonapi/node/look_up_tool',
      params: {
        'fields[node--look_up_tool]': 'path',
        'filter[field_ref_lookup_tool_type.field_tool_type_identifier]': toolType
      }
    })
    return formatToolSlug(data)
  } catch (error) {
    log.error('getLookupToolSlugByToolType: request error', error)
    throw error
  }
}

/*
 * A more generic version of the below methods that allows for more flexible filtering
 * @param {string} uuid
 * @param {object} params
 * @param {object} params.filter
 * @param {object} params.filter.groups
 * @param {object} params.filter.conditions
 * @param {number} params.offset
 * @param {number} params.size
 * @param {string[]} params.sort
 * @param {string} params.table
 * @param {string} params.groupBy
 * @param {string[]} params.columns
 * @param {object} token
 */
export const getLookupToolData = (uuid, params, token = {}) => {
  const queryString = generateDataApiQueryString(params)
  return new Promise((resolve, reject) => {
    axios({
      operationId: 'getLookupToolData',
      method: 'GET',
      baseURL: apiSite,
      url: `${getInteractiveDataApiPath(uuid)}?${queryString}`,
      cancelToken: token.cancelToken
    })
      .then(response => {
        resolve(response.data)
      })
      .catch((error) => {
        log.error('getLookupToolData: request error', error)
        reject(error)
      })
  })
}

/**
 * A more generic version of the below methods that allows for more flexible filtering
 * @param {string} uuid
 * @param {object} params
 * @param {object} params.filter
 * @param {object} params.filter.groups
 * @param {object} params.filter.conditions
 * @param {string} params.table
 * @param {object} token
 */
export const getLookupToolDataStats = (uuid, params, token = {}) => {
  const queryString = generateDataApiQueryString(params)
  return new Promise((resolve, reject) => {
    axios({
      operationId: 'getLookupToolDataStats',
      method: 'GET',
      baseURL: apiSite,
      url: `${getInteractiveDataApiPath(uuid)}/stats?${queryString}`,
      cancelToken: token.cancelToken
    })
      .then(response => {
        resolve(response.data.data)
      })
      .catch((error) => {
        log.error('getLookupToolDataStats: request error', error)
        reject(error)
      })
  })
}

/**
 * getLookupToolsGlobalListing
 * API call to get listing page details
 */
export const getLookupToolsGlobalListing = async () => {
  try {
    const response = await axios({
      operationId: 'getLookupToolsGlobalListing',
      jsonAPI: true,
      method: 'GET',
      baseURL: apiSite,
      url: '/jsonapi/node/tool_listing_page',
      params: {
        'fields[node--look_up_tool]': 'path,title,field_short_description,field_ref_lookup_tool_type,field_ref_icon_image',
        'fields[taxonomy_term--look_up_tool_type]': 'name,field_tool_type_identifier',
        'fields[paragraph--tools_list_section]': 'field_section_header,field_ref_global_tool',
        'fields[field_ref_global_tool]': 'paragraph--external_tool,paragraph--tools_visualization,paragraph--internal_page',
        'fields[paragraph--external_tool]': 'field_component_title,field_external_link,field_media_image,field_wysiwyg_paragraph',
        'fields[paragraph--tools_visualization]': 'field_component_title,field_tools_visualization_url,field_wysiwyg_paragraph',
        'fields[paragraph--internal_page]': 'field_internal_page',
        'fields[paragraph--external_link]': 'field_component_title,field_call_to_action_text,field_external_link',
        'fields[media--icon_image]': 'field_media_image_1,field_media_image_2',
        'fields[file--file]': 'uri',
        include: [
          'field_ref_tools_list_section.field_ref_global_tool.field_tools_visualization_url',
          'field_ref_tools_list_section.field_ref_global_tool.field_internal_page.field_ref_icon_image.field_media_image_1',
          'field_ref_tools_list_section.field_ref_global_tool.field_internal_page.field_ref_image.field_media_image',
          'field_ref_tools_list_section.field_ref_global_tool.field_internal_page.field_ref_lookup_tool_type',
          'field_ref_tools_list_section.field_ref_global_tool.field_media_image.field_media_image_1'
        ].join(',')
      }
    })
    return formatGlobalListingToolsData(response?.data[0], response?.rawData?.included)
  } catch (error) {
    log.error('getLookupToolsGlobalListing: request error', error)
    throw error
  }
}
