import React from 'react'

import './SearchPageResultLoading.scss'

const SearchPageResultLoading = () => (
  <div className='SearchPageResultLoading'>
    <div className='SearchPageResultLoading__left'>
      <div className='SearchPageResultLoading__left-heading skeleton-box' />
      <div className='SearchPageResultLoading__left-parg-full skeleton-box' />
      <div className='SearchPageResultLoading__left-parg skeleton-box' />
    </div>
    <div className='SearchPageResultLoading__mid'>
      <div className='SearchPageResultLoading__mid-parg-full skeleton-box' />
      <div className='SearchPageResultLoading__mid-parg-full skeleton-box' />
      <div className='SearchPageResultLoading__mid-parg skeleton-box' />
    </div>
    <div className='SearchPageResultLoading__end'>
      <div className='SearchPageResultLoading__end-heading skeleton-box' />
      <div className='SearchPageResultLoading__end-link skeleton-box' />
      <div className='SearchPageResultLoading__end-link skeleton-box' />
      <div className='SearchPageResultLoading__end-link skeleton-box' />
    </div>
  </div>
)

export default SearchPageResultLoading
