// these tool types are created via the backend taxonomy manager (current path /admin/structure/taxonomy/manage/look_up_tool_type/overview);
// upon creation, they are given a machine-readable id and cannot be edited after that, so FE can depend on them staying the same over time
export const toolTypes = {
  inpatient: 'medicare_inpatient_hospital',
  prescriber: 'medicare_part_d_prescriber',
  physician: 'medicare_physician_other_practitioner',
  optout: 'medicare_provider_opt_out_affidavits',
  revalidation: 'medicare_revalidation_list',
  mmdHospital: 'mapping_medicare_disparities_by_hospital',
  mmdPopulation: 'mapping_medicare_disparities_by_population',
  marketSaturationStateCounty: 'market_saturation_state_county',
  marketSaturationCBSA: 'market_saturation_cbsa',
  iframe: 'i_frame'
}

export const customToolsList = [
  toolTypes.mmdPopulation,
  toolTypes.mmdHospital
]

export const toolIcons = {
  [toolTypes.inpatient]: true,
  [toolTypes.physician]: true,
  [toolTypes.prescriber]: true,
  [toolTypes.optout]: true,
  [toolTypes.revalidation]: true,
  [toolTypes.mmdHospital]: true,
  [toolTypes.mmdPopulation]: true,
  [toolTypes.marketSaturationStateCounty]: true,
  [toolTypes.marketSaturationCBSA]: true
}

export const toolDetails = {
  [toolTypes.inpatient]: {
    detailColumns: {
      DRG_Cd: {
        label: 'DRG Code',
        headerClasses: 'col-lg-1 leftHeader',
        classes: 'leftSmall'
      },
      DRG_Desc: {
        label: 'DRG Description',
        headerClasses: 'col-lg-3 leftHeader',
        classes: 'leftSmall'
      },
      Tot_Dschrgs: {
        label: 'Total Discharges',
        headerClasses: 'col-lg-2 centerHeader',
        classes: 'centerLarge',
        format: 'number'
      },
      Avg_Submtd_Cvrd_Chrg: {
        label: 'Average Covered Charges',
        headerClasses: 'col-lg-2 centerHeader',
        classes: 'centerLarge',
        format: 'currency'
      },
      Avg_Tot_Pymt_Amt: {
        label: 'Average Total Payments',
        headerClasses: 'col-lg-2 centerHeader',
        classes: 'centerLarge',
        format: 'currency'
      },
      Avg_Mdcr_Pymt_Amt: {
        label: 'Average Medicare Payments',
        headerClasses: 'col-lg-2 centerHeader',
        classes: 'centerLarge',
        format: 'currency'
      }
    }
  },
  [toolTypes.prescriber]: {
    detailColumns: {
      Brnd_Name: {
        label: 'Drug Name',
        headerClasses: 'col-lg-2 leftHeader',
        classes: 'leftSmall'
      },
      Gnrc_Name: {
        label: 'Generic Name',
        headerClasses: 'col-lg-2 leftHeader',
        classes: 'leftSmall'
      },
      Tot_Clms: {
        label: 'Number of Prescriptions',
        headerClasses: 'col-lg-2 centerHeader',
        classes: 'centerLarge',
        format: 'number'
      },
      Tot_Benes: {
        label: 'Number of Beneficiaries',
        headerClasses: 'col-lg-2 centerHeader',
        classes: 'centerLarge',
        format: 'number'
      },
      Tot_Day_Suply: {
        label: 'Number of Days Supplies',
        headerClasses: 'col-lg-2 centerHeader',
        classes: 'centerLarge',
        format: 'number'
      },
      Tot_Drug_Cst: {
        label: 'Total Drug Cost',
        headerClasses: 'col-lg-2 centerHeader',
        classes: 'centerLarge',
        format: 'currency'
      }
    }
  },
  [toolTypes.physician]: {
    detailColumns: {
      HCPCS_Cd: {
        label: 'HCPCS Code',
        headerClasses: 'leftHeader codePercent',
        classes: 'leftSmall'
      },
      HCPCS_Desc: {
        label: 'Description',
        headerClasses: 'leftHeader descPercent',
        classes: 'leftSmall'
      },
      HCPCS_Drug_Ind: {
        label: 'Drug',
        headerClasses: 'leftHeader drugPercent',
        classes: 'leftSmall',
        format: 'yesno'
      },
      Place_Of_Srvc: {
        label: 'Place of Service',
        headerClasses: 'leftHeader',
        classes: 'leftSmall',
        format: 'place'
      },
      Tot_Srvcs: {
        label: 'Number of Services',
        headerClasses: 'centerHeader',
        classes: 'centerSmall',
        format: 'number'
      },
      Tot_Benes: {
        label: 'Number of Beneficiaries',
        headerClasses: 'centerHeader colPercent',
        classes: 'centerSmall',
        format: 'number'
      },
      Avg_Sbmtd_Chrg: {
        label: 'Average Submitted Charge',
        headerClasses: 'centerHeader colPercent',
        classes: 'centerSmall',
        format: 'currency'
      },
      Avg_Mdcr_Alowd_Amt: {
        label: 'Average Medicare Allowed Amount',
        headerClasses: 'centerHeader colPercent',
        classes: 'centerSmall',
        format: 'currency'
      },
      Avg_Mdcr_Pymt_Amt: {
        label: 'Average Medicare Payment',
        headerClasses: 'centerHeader colPercent',
        classes: 'centerSmall',
        format: 'currency'
      }
    }
  }
}
