import { Store } from 'pullstate'
import { getFeatureSetting } from '../../stores/siteStore/siteStore'

const downloadIndicatorStore = new Store({ progresses: [] })
const MAX_DOWNLOAD_QUEUES_DEFAULT = 3

export const removeFromDownloadStore = (uuid) => {
  const stateArray = downloadIndicatorStore.getRawState()
  stateArray?.filter(s => s.uuid !== uuid)
  downloadIndicatorStore.update(stateArray)
}

export const addToDownloadStore = (state) => {
  const stateArray = downloadIndicatorStore.getRawState()
  // Don't allow duplicates for the dataset type uuid
  if (stateArray?.progresses?.find(s => s.uuid === state.uuid)) {
    return
  }
  downloadIndicatorStore.update((s) => { s?.progresses?.push(state) })
}

export const getQueueNumber = (uuid) => {
  const stateArray = downloadIndicatorStore.getRawState()
  return stateArray?.progresses?.find(s => s.uuid === uuid)?.queueNumber
}

export const updateDownloadStore = (uuid, updatedProps) => {
  const existingEntry = downloadIndicatorStore?.getRawState()?.progresses?.find(p => p.uuid === uuid)
  if (existingEntry) {
    downloadIndicatorStore.update((s) => {
      s.progresses = s.progresses.map((progress) => {
        if (progress.uuid === uuid) {
          return { ...progress, ...updatedProps }
        }
        return progress
      })
    })
  } else {
    addToDownloadStore({ uuid: uuid, ...updatedProps })
  }
}

export const getNumberOfDownloadsWithNoQueue = () => {
  const stateArray = downloadIndicatorStore.getRawState()
  return stateArray?.progresses?.filter(progress => (progress?.status === 'contactingServer' || progress?.status === 'preparing') && (!progress.queueNumber || progress.queueNumber > 0))?.length
}

export const getHighestQueue = () => {
  const stateArray = downloadIndicatorStore.getRawState()
  return stateArray?.progresses?.reduce((maxQueueNumber, obj) => {
    if (obj.queueNumber > maxQueueNumber && obj.queueNumber !== 0) {
      return obj.queueNumber
    } else {
      return maxQueueNumber
    }
  }, 0)
}

export const MoveQueuesDown = () => {
  const stateArray = downloadIndicatorStore.getRawState()

  const updatedProgresses = stateArray.progresses.map(progress => ({
    ...progress,
    queueNumber: Math.max(progress.queueNumber - 1, 0)
  }))

  downloadIndicatorStore.update((s) => {
    s.progresses = updatedProgresses
  })
}

export const ProcessQueues = () => {
  const maxDownloads = getFeatureSetting('downloadModalV2', 'maxActiveDownloads') || MAX_DOWNLOAD_QUEUES_DEFAULT
  if (getNumberOfDownloadsWithNoQueue() <= maxDownloads) {
    MoveQueuesDown()
  }
}

export const NewQueueNumberIfApplicable = () => {
  const maxDownloads = getFeatureSetting('downloadModalV2', 'maxActiveDownloads') || MAX_DOWNLOAD_QUEUES_DEFAULT
  if (getNumberOfDownloadsWithNoQueue() >= maxDownloads) {
    return getHighestQueue() + 1
  }
  return 0
}
export default downloadIndicatorStore
