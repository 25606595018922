import axios from 'axios'
import { deserialize } from 'jsonapi-deserializer'
import mapKeysDeep from 'map-keys-deep'
import camelcase from 'lodash/camelCase'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import cloneDeep from 'lodash/cloneDeep'
import log from './log'
import config from './config'
import { isPreviewUrl } from './utilities/routeHelpers'

const {
  requestLogging,
  responseLogging,
  artilleryScenarioLogging,
  artilleryScenarioLoggingThinkThreshold,
  apiSite
} = config

//
// IMPORTANT - ORDER MATTERS LEAVE THIS AT THE TOP
//
// Helps build load test for dev
if (artilleryScenarioLogging) {
  const artilleryScenario = {
    name: 'default',
    flow: []
  }

  axios.interceptors.request.use(request => {
    const method = (get(request, 'method', 'get')).toLowerCase()
    let url = get(request, 'url', null)
    const qs = get(request, 'params', {})
    const startTime = get(request, 'metadata.startTime')

    if (url.charAt(0) !== '/') url = `/${url}`

    const lastRequestStartTime = get(last(artilleryScenario.flow), 'startTime')

    if (lastRequestStartTime) {
      const dif = startTime - lastRequestStartTime

      // push think to flow
      if (dif > artilleryScenarioLoggingThinkThreshold) {
        artilleryScenario.flow.push({
          think: round(dif / 1000, 1) // convert ms to seconds
        })
      }
    }

    const config = {
      url,
      ...(isEmpty(qs) ? {} : { qs })
    }

    artilleryScenario.flow.push({
      [method]: config,
      startTime
    })

    log.debug('ARTILLERY SCENARIO LOGGING', JSON.stringify(
      sanitizeArtilleryScenarioConfig(
        cloneDeep(artilleryScenario)
      )
    ))

    return request
  })
}

axios.interceptors.request.use(request => {
  // Add access token in header.
  request.headers = {
    ...request.headers,
    ...addAccessTokenHeader()
  }

  // Get Request Start Time
  request.metadata = { startTime: new Date() }

  // Log Request Data
  if (requestLogging) {
    const url = get(request, 'url', '/')
    const operationId = get(request, 'operationId', url)
    const params = get(request, 'params', {})
    const method = (get(request, 'method', 'GET')).toUpperCase()
    log.debug(`REQUEST - ${method}:${operationId}`, {
      url,
      params,
      method
    })
  }

  return request
})

axios.interceptors.response.use(async (response) => {
  // Set End Time / Duration
  response.config.metadata.endTime = new Date()
  response.duration = response.config.metadata.endTime - response.config.metadata.startTime

  // Deserialize / Log Data
  const jsonAPI = get(response, 'config.jsonAPI', false)

  if (jsonAPI) {
    response.rawData = { ...response.data }
    const data = get(response, 'data', {})
    const deserializedData = deserialize(data)
    const deserializedDataCamelCaseKeys = mapKeysDeep(deserializedData, key => camelcase(key))
    response.data = deserializedDataCamelCaseKeys
  }

  if (responseLogging) {
    const url = get(response, 'config.url', '/')
    const operationId = get(response, 'config.operationId', url)
    const method = (get(response, 'config.method', 'GET')).toUpperCase()
    const duration = response.duration
    log.debug(`RESPONSE - [${duration}ms] ${method}:${operationId}`, response.data)
  }

  return response
}, (error) => {
  const url = get(error, 'response.config.url', '/')
  const operationId = get(error, 'response.config.operationId', url)
  const method = (get(error, 'response.config.method', 'GET')).toUpperCase()

  if (axios.isCancel(error)) {
    log.warn(`RESPONSE ERROR - ${method}:${operationId} request canceled`, error.message)
  } else {
    log.warn(`RESPONSE ERROR - ${method}:${operationId} request error`, error)
  }

  return Promise.reject(error)
})

function sanitizeArtilleryScenarioConfig (artilleryScenario) {
  artilleryScenario.flow = artilleryScenario.flow.map((flowArray) => {
    delete flowArray.startTime
    return flowArray
  })

  return {
    config: {
      target: apiSite,
      phases: [{
        duration: 60,
        arrivalCount: 10
      }]
    },
    scenarios: [artilleryScenario]
  }
}

function round (value, precision) {
  var multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}

const getAccessTokenFromURL = () => {
  return (new URLSearchParams(window.location.search)).get('accessToken')
}

const addAccessTokenHeader = () => {
  const isPreview = isPreviewUrl()
  const token = getAccessTokenFromURL()
  return (isPreview && token) ? { 'X-AUTH-TOKEN': token } : {}
}
