import interactiveToolsStore from './interactiveToolsStore'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
import omit from 'lodash/omit'
import isEqual from 'lodash/isEqual'
import {
  getLookupToolData,
  getLookupToolDataStats,
  getCancelToken,
  isCancellationError
} from '../../services/api'
import * as revalidationTool from '../../utilities/lookupToolUtilities/revalidationTool'
import * as optoutTool from '../../utilities/lookupToolUtilities/optoutTool'
import * as prescriberTool from '../../utilities/lookupToolUtilities/prescriberTool'
import * as inpatientTool from '../../utilities/lookupToolUtilities/inpatientTool'
import * as physicianTool from '../../utilities/lookupToolUtilities/physicianTool'
import * as msMarketSaturationTool from '../../utilities/lookupToolUtilities/msStateCountyTool'
import { toolTypes } from '../../utilities/lookupToolUtilities'
import { convertDataToObjectArray } from '../../services/formatters/lookupTool'

let activeResultsRequest = {}
let activeStatsRequest = {}

function cancelRequest (requestObj = {}, name) {
  const cancel = get(requestObj, 'cancel')
  if (isFunction(cancel)) {
    cancel(`${name}: canceled request`)
  }
}

export function cancelActiveSearchRequests () {
  cancelRequest(activeResultsRequest, 'getLookupToolSearchResults')
  cancelRequest(activeStatsRequest, 'getLookupToolSearchResultsStats')
}

/**
 * @param {object} payload
 * @param {object[]} payload.results
 * @param {object} payload.previousFormValues
 */
export function searchResultsLoadSuccess (payload) {
  const {
    results,
    previousFormValues
  } = payload

  return state => {
    state.search.isLoading = false
    state.search.isInitialLoad = false
    state.search.error = null
    state.search.results = results
    state.search.cachedFormValues = previousFormValues
  }
}

/**
 * @param {object} payload
 * @param {object} payload.stats
 * @param {number} payload.stats.found_rows
 * @param {number} payload.stats.total_rows
 */
export function searchStatsLoadSuccess (payload) {
  const {
    stats
  } = payload

  return state => {
    state.search.isLoadingStats = false
    state.search.isInitialLoad = false
    state.search.error = null
    state.search.numOfFoundResults = stats.found_rows
  }
}

/**
 * @param {any} e
 */
export function searchResultsLoadFailure (e) {
  return state => {
    state.search.isLoading = false
    state.search.isLoadingStats = false
    state.search.error = e
  }
}

/**
 *
 * @param {string} id
 * @param {number} params.offset
 * @param {number} params.size
 * @param {object} params.formValues
 * @param {string} toolType
 */
export async function refreshResults (id, params, toolType) {
  const { formValues, offset, size } = params
  activeResultsRequest = getCancelToken()

  interactiveToolsStore.update(state => {
    state.search.isLoading = true
    state.search.error = false
  })

  let apiParams

  switch (toolType) {
    case toolTypes.physician:
      apiParams = physicianTool.getToolSearchApiParams({ formValues })
      break
    case toolTypes.inpatient:
      apiParams = inpatientTool.getToolSearchApiParams({ formValues })
      break
    case toolTypes.optout:
      apiParams = optoutTool.getToolSearchApiParams({ formValues })
      break
    case toolTypes.revalidation:
      apiParams = revalidationTool.getToolSearchApiParams({ formValues })
      break
    case toolTypes.prescriber:
      apiParams = prescriberTool.getToolSearchApiParams({ formValues })
      break
    case toolTypes.marketSaturationStateCounty:
      apiParams = msMarketSaturationTool.getToolSearchApiParams({ formValues })
      break
    default:
      apiParams = {}
      break
  }

  getLookupToolData(id, { ...apiParams, offset, size }, { cancelToken: activeResultsRequest.token })
    .then(data => {
      const results = convertDataToObjectArray(data)
      interactiveToolsStore.update(searchResultsLoadSuccess({
        results,
        previousFormValues: params.formValues
      }))
    })
    .catch(e => {
      console.error('error fetching tool search results', e)
      if (!isCancellationError(e)) {
        interactiveToolsStore.update(searchResultsLoadFailure(e))
      }
    })
}

/**
 *
 * @param {string} id
 * @param {object} params.formValues
 * @param {string} toolType
 */
export async function refreshStats (id, params, toolType) {
  const { formValues } = params
  activeStatsRequest = getCancelToken()

  interactiveToolsStore.update(state => {
    state.search.isLoadingStats = true
    state.search.error = false
  })

  let apiParams

  switch (toolType) {
    case toolTypes.physician:
      apiParams = physicianTool.getToolSearchApiParams({ formValues })
      break
    case toolTypes.inpatient:
      apiParams = inpatientTool.getToolSearchApiParams({ formValues })
      break
    case toolTypes.optout:
      apiParams = optoutTool.getToolSearchApiParams({ formValues })
      break
    case toolTypes.revalidation:
      apiParams = revalidationTool.getToolSearchApiParams({ formValues })
      break
    case toolTypes.prescriber:
      apiParams = prescriberTool.getToolSearchApiParams({ formValues })
      break
    case toolTypes.marketSaturationStateCounty:
      apiParams = msMarketSaturationTool.getToolSearchApiParams({ formValues })
      break
    default:
      apiParams = {}
      break
  }

  getLookupToolDataStats(id, apiParams, { cancelToken: activeResultsRequest.token })
    .then(stats => {
      interactiveToolsStore.update(searchStatsLoadSuccess({
        stats
      }))
    })
    .catch(e => {
      console.error('error fetching tool search stats', e)
      if (!isCancellationError(e)) {
        interactiveToolsStore.update(searchResultsLoadFailure(e))
      }
    })
}

/**
 * Opportunity to make any calculations/requests before executing the actual results+stats requests;
 * currently only revalidation tool needs this
 * @param {string} id - lookup tool id
 * @param {object} payload - formValues, offset, size
 * @param {string} toolType
 * @returns {object} modified payload
 */
async function preparePayload (id, payload, toolType) {
  let prepared

  switch (toolType) {
    case toolTypes.revalidation:
      prepared = await revalidationTool.preparePayload(id, payload)
      break
    default:
      prepared = payload
  }

  return prepared
}

/**
 * Main call for reloading search results & stats (conditionally)
 * @param {string} id
 * @param {object} payload
 * @param {number} payload.offset
 * @param {number} payload.size
 * @param {object} payload.formValues
 */
async function refreshLookupToolSearchResults (id, payload = {}) {
  const {
    offset,
    size,
    formValues: updatedFormValues = {}
  } = payload

  const {
    data: {
      toolType
    },
    search: {
      cachedFormValues,
      isInitialLoad
    }
  } = interactiveToolsStore.getRawState()

  const shouldRefreshStats = (
    isInitialLoad ||
    !isEqual(cachedFormValues, updatedFormValues)
  )

  // store updated params
  interactiveToolsStore.update(state => {
    state.search.offset = offset
    state.search.size = size
    state.search.formValues = updatedFormValues
  })

  const preparedPayload = await preparePayload(id, payload, toolType)

  refreshResults(id, preparedPayload, toolType)

  if (shouldRefreshStats) {
    const statsPayload = omit(preparedPayload, 'size', 'offset', 'sort')
    refreshStats(id, statsPayload, toolType)
  }
}

export default refreshLookupToolSearchResults
