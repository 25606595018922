import some from 'lodash/some'
import cx from 'classnames'
import React from 'react'
import CloseIcon from '../../src/components/icons/CloseIcon'
import { ReactComponent as TriangleExclamationIcon } from '../assets/fontawesome/solid/triangle-exclamation.svg'
import { ReactComponent as InfoCircleIcon } from '../assets/fontawesome/solid/circle-info.svg'
import { ReactComponent as CircleExclamationIcon } from '../assets/fontawesome/solid/circle-exclamation.svg'
import { ReactComponent as CircleCheckIcon } from '../assets/fontawesome/solid/circle-check.svg'
import { ReactComponent as ExpandIcon } from '../assets/fontawesome/regular/expand.svg'
import { ReactComponent as CompressIcon } from '../assets/fontawesome/regular/compress.svg'
import { LoadingSpinner } from '../components/common/LoadingCover/LoadingCover'

import './displayUtilities.scss'

let scrollbarWidth = 0

window.addEventListener('DOMContentLoaded', (event) => {
  measureScrollBarWidth()
})

// scrollbar width varies by device + browser; measure width at startup
// to use it upon opening modals to prevent page shifting in various components
function measureScrollBarWidth () {
  // Create the measurement node
  const scrollDiv = document.createElement('div')
  scrollDiv.setAttribute('style', 'width: 100px; height: 100px; overflow: scroll; position: absolute; top: -9999px;')

  document.body.appendChild(scrollDiv)

  // Get the scrollbar width
  scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth

  // Delete the DIV
  document.body.removeChild(scrollDiv)
}

export {
  measureScrollBarWidth,
  scrollbarWidth
}

/**
 * Determine if userAgent is a mobile device; this is not ideal to have on
 * the frontend, but this method can be updated with more regex as needed
 * @param {string} userAgent
 */
export const isMobileDevice = (userAgent = '') => {
  const match = !!(
    userAgent.match(/Android/i) ||
    userAgent.match(/webOS/i) ||
    userAgent.match(/iPhone/i) ||
    userAgent.match(/iPad/i) ||
    userAgent.match(/iPod/i) ||
    userAgent.match(/BlackBerry/i)
  )

  return match
}

const internetExplorerUserAgentStrings = [
  'Mozilla/5.0 (Windows NT 6.1; WOW64; Trident/7.0;',
  'Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0;',
  'Mozilla/5.0 (compatible, MSIE 11, Windows NT 6.3; Trident/7.0;'
]

/**
 * Determine if userAgent is an IE browser; probably not 100% accurate, but it's IE, so.... ¯\_(ツ)_/¯
 * @param {string} userAgent
 */
export const isIeUserAgent = (userAgent) => {
  return some(internetExplorerUserAgentStrings, ieUaString => userAgent.indexOf(ieUaString) > -1)
}

/**
 * Calculate slider props for carousel components
 *
 * @param {object} payload
 * @param {number} payload.availableWidth - available width for the visible portion of the slider
 * @param {number} payload.totalWidthPerSlide - width per slide, including L/R margin
 * @param {number} payload.numOfSlides - total number of slides
 */
export const calculateSliderProps = (payload) => {
  const {
    availableWidth,
    totalWidthPerSlide,
    numOfSlides
  } = payload

  // describes how many slides are visible within the viewport
  const numOfVisibleSlides = Math.floor(availableWidth / totalWidthPerSlide)
  // describes the width of the visible portion of the slider
  const visibleSliderWidth = numOfVisibleSlides * totalWidthPerSlide
  // describes the combined slide width (including overflow outside of the viewport) of the slider
  const overallSliderWidth = numOfSlides * totalWidthPerSlide

  return {
    areAllSlidesVisible: availableWidth >= overallSliderWidth,
    numOfVisibleSlides: numOfVisibleSlides,
    sliderStyle: {
      width: `${visibleSliderWidth}px`
    }
  }
}

export function sortByWeight (a, b) {
  if (a.weight > b.weight) {
    return 1
  } else if (a.weight < b.weight) {
    return -1
  } else {
    return 0
  }
}

export function combineClassObjects (baseClassObj, classObj) {
  const combinedClasses = Object.keys(baseClassObj).reduce((combined, classKey) => ({
    ...combined,
    [classKey]: cx(baseClassObj[classKey], classObj[classKey])
  }), {})

  return combinedClasses
}

export function removeEmptyHtmlElement (html, element) {
  if (!html || typeof (html) !== 'string') { return html }

  const htmlElement = document.createElement('html')
  htmlElement.innerHTML = html

  const elements = htmlElement.getElementsByTagName(element)

  if (!elements) { return html }

  const elementsArray = [...elements]
  elementsArray.forEach(e => {
    if (e.innerHTML === '' || e.innerHTML === '&nbsp;' || e.innerHTML === ' ') {
      e.parentNode.removeChild(e)
    }
  })

  return htmlElement.innerHTML
}

export const getAlertBannerIcon = (type) => {
  switch (type) {
    case 'warning':
      return (
        <TriangleExclamationIcon className='alert-icon' />
      )
    case 'info':
      return (
        <InfoCircleIcon className='alert-icon' />
      )
    case 'error':
      return (
        <CircleExclamationIcon className='alert-icon' />
      )
    case 'success':
      return (
        <CircleCheckIcon className='alert-icon' />
      )
    default:
      break
  }
}

export const getAlertBanner = (data, OnBannerClear) => {
  return (
    <div className={`alert-banner  ${data.type}`}>
      <div className='accent-bar' />
      <div className='alertIcon'>
        {getAlertBannerIcon(data.type)}
      </div>
      <div className='content'>
        <div className='title'>{data.title}</div>
        <div className='body'>{data.body}</div>
      </div>
      <div className='closeIcon'>
        <CloseIcon size={14} onClick={OnBannerClear} hoverCircleColor='#FEEABC' xColor='#212121' />
      </div>
    </div>
  )
}

export const cantLoadBanner = () => { return { type: 'warning', title: 'Sorry, we are unable to load the visual. Check back shortly.' } }

export const getLoadingSection = () => {
  return <LoadingSpinner statusText='Loading data...' />
}

/**
 * Truncate a string down to the maxChars limit and add ... at the end.
 * @param {string} str The string you wish to truncate
 * @param {string} maxChars The maximum number of characters before truncating.
 * @param {string} allowSplittingWord (Default false) When set to true, this will truncate exactly at maxChars.  Otherwise, it will truncate at previous word.
 */
export const truncateWithElipses = (str, maxChars, allowSplittingWord = false) => {
  if (str?.length <= maxChars) { return str }

  let truncatedStr = str?.slice(0, maxChars - 3) + '...'
  if (!allowSplittingWord) {
    truncatedStr = truncatedStr?.slice(0, truncatedStr.lastIndexOf(' ')) + '...'
  }
  return truncatedStr
}

export const expandMapToggle = (isMapExpanded, isMapExtendedCallback) => {
  return (
    <div className='ExpandIcon__wrapper' title={`${isMapExpanded ? 'Close' : 'Expand'}`} aria-label={`${isMapExpanded ? 'Close' : 'Expand'}`}>
      {isMapExpanded ? <CompressIcon onClick={() => isMapExtendedCallback()} />
        : <ExpandIcon onClick={() => isMapExtendedCallback()} />}
    </div>
  )
}

/**
 * Toggle all of the children of nodes used in filepicker / download modal to be all checked or unchecked
 * @param {Array} nodes
 * @param {Boolean} check
 */
export const toggleAllChildrenOfNodes = (nodes, check) => {
  for (let j = 0; j < nodes?.length; j++) {
    for (let i = 0; i < nodes[j]?.children?.length; i++) {
      nodes[j].children[i] = { ...nodes[j].children[i], checked: check }
    }
  }
  return false
}

export const getDisplayFileSize = (sizeBytes) => {
  if (sizeBytes <= 999) {
    return `${sizeBytes} B`
  }
  if (sizeBytes <= 999999) {
    return `${Math.round(+sizeBytes / 1000)} KB`
  }
  if (sizeBytes <= 999999999) {
    return `${Math.round(+sizeBytes / 1000000)} MB`
  }

  if (sizeBytes <= 999999999999) {
    return `${Math.round(+sizeBytes / 1000000000)} GB`
  }
  return `${sizeBytes}`
}

// Function to rename plural str. For now Dataset, but may need for 'Methodologies' vs 'Methodology' for e.g.
export const resolvePlurals = (str, shouldBePlural) => {
  switch (str) {
    case 'Dataset':
    case 'Datasets':
      return shouldBePlural ? 'Datasets' : 'Dataset'
    case 'file':
    case 'files':
      return shouldBePlural ? 'files' : 'file'
    case '':
    default:
      return str
  }
}

export const displayDate = (date, frequency) => {
  switch (frequency) {
    case 'Annually':
      return date?.toLocaleDateString('en-US', { year: 'numeric' })
    case 'Quarterly':
      return `Q${Math.floor((date.getMonth() + 3) / 3)} ${date.getFullYear()}`
    case 'Monthly':
      return date?.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit' })
    default:
      return date?.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
  }
}
