import React from 'react'
import PropTypes from 'prop-types'
import DatasetResult from './DatasetResult'
import StoryResult from './StoryResult'
import LookupToolResult from './LookupToolResult'
import TopicResult from './TopicResult'
import config from '../../../config.js'
import log from '../../../log.js'

// results types will expand post MVP
function getSearchComponentType (type) {
  const { bundleTypes } = config

  // swap to null return to accomodate circumstances BE sends broken data
  switch (type) {
    case bundleTypes.DATASET:
      return DatasetResult
    case bundleTypes.STORY:
      return StoryResult
    case bundleTypes.LOOKUP_TOOL:
      return LookupToolResult
    case bundleTypes.TOPIC_PAGE:
      return TopicResult
    default:
      log.warn('SearchPageResult: invalid search result type submitted')
      return null
  }
}

function SearchPageResult ({ result }) {
  const {
    bundle
  } = result

  const ResultComponent = getSearchComponentType(bundle)

  return (
    ResultComponent && <ResultComponent data={result} />
  )
}

SearchPageResult.propTypes = {
  result: PropTypes.shape({
    bundle: PropTypes.string.isRequired,
    summary: PropTypes.string,
    icon_inverted: PropTypes.string
  }).isRequired
}

export default SearchPageResult
