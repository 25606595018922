import axios from 'axios'
import config from '../../config'
import simpleCacheBust from '../../utilities/simpleCacheBust'
import { formatContactPageData } from '../formatters/contact'
import log from '../../log'
const {
  apiSite,
  useCacheBuster
} = config

const getContactPageDataIncludes = [
  'field_ref_content_type',
  'field_contact_page_section.field_subscription_service',
  'field_contact_page_section.field_ref_call_to_action'
]

/**
 * Get data for the contact page
 */
export const getContactPageData = async () => {
  try {
    const { data } = await axios({
      operationId: 'getContactPageData',
      method: 'get',
      jsonAPI: true,
      baseURL: apiSite,
      url: '/jsonapi/node/contact_page',
      params: {
        include: getContactPageDataIncludes.join(','),
        ...(useCacheBuster.getContactPageData ? {
          cacheBuster: simpleCacheBust()
        } : {})
      }
    })
    return formatContactPageData(data[0])
  } catch (error) {
    log.error('getContactPageData: request error', error)
    throw (error)
  }
}
