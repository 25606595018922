import { getLookupToolData } from '../../services/api'
import { convertDataToObjectArray } from '../../services/formatters/lookupTool'

// column names
export const FIRST_NAME_COLUMN = 'First Name'
export const LAST_NAME_COLUMN = 'Last Name'
export const SPECIALTY_COLUMN = 'Specialty'
export const NPI_COLUMN = 'NPI'
export const ZIP_CODE_COLUMN = 'Zip code'

// input name values
export const OPTOUT_FIRST_NAME = 'firstName'
export const OPTOUT_LAST_NAME = 'lastName'
export const OPTOUT_SPECIALTY = 'specialty'
export const OPTOUT_NPI = 'npi'
export const OPTOUT_ZIP = 'zipCode'

const searchConfig = {
  [OPTOUT_FIRST_NAME]: {
    filterName: OPTOUT_FIRST_NAME,
    operator: 'STARTS_WITH',
    column: FIRST_NAME_COLUMN
  },
  [OPTOUT_LAST_NAME]: {
    filterName: OPTOUT_LAST_NAME,
    operator: 'STARTS_WITH',
    column: LAST_NAME_COLUMN
  },
  [OPTOUT_SPECIALTY]: {
    filterName: OPTOUT_SPECIALTY,
    operator: 'CONTAINS',
    column: SPECIALTY_COLUMN
  },
  [OPTOUT_NPI]: {
    filterName: OPTOUT_NPI,
    operator: '=',
    column: NPI_COLUMN
  },
  [OPTOUT_ZIP]: {
    filterName: OPTOUT_ZIP,
    operator: 'STARTS_WITH',
    column: ZIP_CODE_COLUMN
  }
}

/**
 *
 * @param {object} data
 * @param {object} data.formValues
 */
export function getToolSearchApiParams (data) {
  const { formValues } = data

  const filter = {
    conditions: []
  }

  Object.keys(formValues).forEach(formKey => {
    const baseCondition = { ...searchConfig[formKey] }
    filter.conditions.push({
      ...baseCondition,
      value: formValues[formKey]
    })
  })

  return {
    filter,
    // here
    sort: [LAST_NAME_COLUMN, FIRST_NAME_COLUMN]
  }
}

export async function getSpecialtyOptions (uuid) {
  const params = {
    sort: [SPECIALTY_COLUMN],
    size: 1000,
    groupBy: SPECIALTY_COLUMN,
    columns: [SPECIALTY_COLUMN]
  }

  try {
    const data = await getLookupToolData(uuid, params)
    const results = convertDataToObjectArray(data)
    const specialtyOptions = results.map(row => {
      const specialty = row[SPECIALTY_COLUMN]
      return { label: specialty, value: specialty }
    })

    return specialtyOptions
  } catch (err) {
    throw new Error(err)
  }
}
