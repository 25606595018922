import axios from 'axios'
import get from 'lodash/get'
import config from '../../config'
import { formatGetSubtopicData, formatGetTopicData } from '../formatters/subtopicTopic'
import { getScaledImgUrl } from '../../utilities/linkHelpers'
import { getIncludedUrls } from '../formatters/global'
import log from '../../log'
import simpleCacheBust from '../../utilities/simpleCacheBust'

const {
  apiSite,
  useCacheBuster,
  topicTaxonomyUrl,
  topicMainDataUrl,
  subtopicMainDataUrl
} = config

/**
 * Retrieve the main data body for topic param
 * @param {string} topicUuid - taxonomy topic uuid that is related to the topic page
 */
export const getTopicData = async (topicUuid) => {
  try {
    const { data, rawData } = await axios(
      {
        method: 'GET',
        baseURL: apiSite,
        jsonAPI: true,
        operationId: 'getTopicData',
        url: `${topicMainDataUrl}?filter[field_ref_topic.id]=${topicUuid}`,
        params: {
          include: [
            'field_ref_topic',
            'field_ref_topic.field_ref_navigational_category',
            'field_topic_hero_image.field_media_image',
            'field_ref_topic_page_section.field_ref_featured_datasets',
            'field_ref_topic_page_section.field_ref_subtopic',
            'field_related_case_studies.field_ref_image.field_media_image',
            'field_ref_topic_pg_vis_section.field_topic_pg_vis_component.field_visualization_image.field_media_image',
            'field_ref_topic_pg_vis_section.field_topic_pg_vis_component.field_additional_visualization.field_ancillary_file.field_media_file',
            'field_ref_topic_pg_vis_section.field_topic_pg_vis_component.field_additional_visualization.field_ref_license_agreement',
            'field_ref_contact_email'
          ].join(','),
          ...(useCacheBuster.getTopicData ? {
            cacheBuster: simpleCacheBust()
          } : {})
        }
      }
    )

    if (!data[0]) {
      throw new Error('Empty data')
    }

    return formatGetTopicData(data[0], rawData.included)
  } catch (error) {
    log.error('getTopicData: request error', error)
    throw error
  }
}

/**
 * Retrieve the main data body for subtopic param
 * @param {string} subtopicUuid
 */
export const getSubtopicData = async (subtopicUuid) => {
  try {
    const { data, rawData } = await axios(
      {
        method: 'GET',
        jsonAPI: true,
        operationId: 'getSubtopicData',
        baseURL: apiSite,
        url: `${subtopicMainDataUrl}?filter[field_ref_topic.id]=${subtopicUuid}`,
        params: {
          include: [
            'field_ref_topic.parent.field_ref_navigational_category',
            'field_subtopic_page_section.field_subtopic_page_component',
            'field_related_case_studies.field_ref_image.field_media_image',
            'field_ref_contact_email'
          ].join(','),
          ...(useCacheBuster.getSubtopicData ? {
            cacheBuster: simpleCacheBust()
          } : {})
        }
      }
    )

    if (!data[0]) {
      throw new Error('Empty data')
    }

    return formatGetSubtopicData(data[0], rawData.included)
  } catch (error) {
    log.error('getSubtopicData: request error', error)
    throw error
  }
}

/**
 * Retrieve all direct-descendant L1 topics of a category; option avaialble to exclude a certain topic
 * @param {string} categoryUuid
 * @param {object} options
 * @param {string} options.excludeTopicId - topic to exclude
 * @param {string[]} options.fields - fields to include for each topic
 */
export const getChildTopicsOfCategory = async (categoryUuid, options = {}) => {
  const {
    excludeTopicId,
    fields = ['name', 'description', 'path', 'weight']
  } = options

  try {
    const { data: rawTopics } = await axios({
      method: 'GET',
      jsonAPI: true,
      operationId: 'getChildTopicsOfCategory',
      baseURL: apiSite,
      url: topicTaxonomyUrl,
      params: {
        'fields[taxonomy_term--navigational_topics]': fields.join(','),
        'filter[field_ref_navigational_category.id]': categoryUuid,
        'filter[id][operator]': '<>',
        'filter[id][value]': excludeTopicId,
        // per BE suggestion, make taxonomy term does not have a parent to ensure it is L1; author
        // has the ability to select a category for L2 terms, even though they are advised not to;
        // this 'undefined' solution is not ideal, but it works to get around a Drupal IS NULL filter
        // bug on parent relationships
        'filter[parent.0]': 'undefined'
      }
    })

    return rawTopics
  } catch (error) {
    log.error('getChildTopicsOfCategory: request error', error)
    throw error
  }
}

/**
 * Retrieve all direct-descendant L2 topics (subtopics) of a topic; option avaialble to exclude a certain topic
 * @param {string} topicUuid
 * @param {object} options
 * @param {string} options.excludeTopicId - topic to exclude
 * @param {string[]} options.fields - fields to include for each topic
 */
export const getSubtopicsOfTopic = async (topicUuid, options = {}) => {
  const {
    excludeTopicId,
    fields = ['name', 'description', 'path', 'weight']
  } = options

  try {
    const { data: rawTopics } = await axios({
      method: 'GET',
      jsonAPI: true,
      operationId: 'getSubtopicsOfTopic',
      baseURL: apiSite,
      url: topicTaxonomyUrl,
      params: {
        'fields[taxonomy_term--navigational_topics]': fields.join(','),
        'filter[parent.id]': topicUuid,
        'filter[id][operator]': '<>',
        'filter[id][value]': excludeTopicId
      }
    })

    return rawTopics
  } catch (error) {
    log.error('getChildTopicsOfCategory: request error', error)
    throw error
  }
}

export const getTopicPages = async (topicIds) => {
  const topicIdsFilterParams = topicIds.reduce((existingParams, topicId) => {
    return [
      ...existingParams,
      `filter[topics-filter][condition][value][]=${topicId}`
    ]
  }, [
    'filter[topics-filter][condition][path]=field_ref_topic.id',
    'filter[topics-filter][condition][operator]=IN'
  ])

  const topicPageFields = ['id', 'title', 'field_hero_header', 'field_topic_hero_image', 'field_ref_topic']
  const navTopicFields = ['id', 'name', 'parent', 'path']

  const params = [
    ...topicIdsFilterParams,
    `fields[node--topic_page]=${topicPageFields.join(',')}`,
    `fields[taxonomy_term--navigational_topics]=${navTopicFields.join(',')}`,
    'include=field_topic_hero_image.field_media_image,field_ref_topic'
  ]

  try {
    const response = await axios({
      method: 'GET',
      baseURL: apiSite,
      jsonAPI: true,
      operationId: 'getTopicPages',
      url: `${topicMainDataUrl}?${params.join('&')}`
    })

    return response.data.map((topicPage, i) => {
      const rawImage = get(topicPage, 'fieldTopicHeroImage.fieldMediaImage.uri.url')
      const included = get(response, 'rawData.included')

      return {
        ...topicPage,
        // append custom-picked image style
        imageSource: rawImage && getScaledImgUrl('large', getIncludedUrls(included), `${apiSite}${rawImage}`)
      }
    })
  } catch (error) {
    log.error('getTopicPages: request error', error)
    throw error
  }
}
