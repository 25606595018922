import PropTypes from 'prop-types'

const linkPropTypes = PropTypes.arrayOf(PropTypes.shape({
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  weight: PropTypes.number,
  subLinks: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    weight: PropTypes.number
  }))
}))

export const propTypeLinks = PropTypes.shape({
  primary: linkPropTypes,
  secondary: linkPropTypes
})

export const propTypeCategories = PropTypes.arrayOf(PropTypes.shape({
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  weight: PropTypes.number,
  subCategories: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string, // .isRequired,
    weight: PropTypes.number
  }))
}))
