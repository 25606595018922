import axios from 'axios'
import get from 'lodash/get'
import log from '../../log'
import { formatNavigationMenus, formatMetaTags, formatAlert } from '../formatters/global'
import config from '../../config'
import simpleCacheBust from '../../utilities/simpleCacheBust'

const {
  apiSite,
  useCacheBuster
} = config

/**
 * Get ApiDocs Contact Info for ApiDocsPage
 * @param {*} [opts.cancelToken] axios cancel token
 */

export const getApiPageMetaData = async () => {
  try {
    const res = await axios({
      operationId: 'getApiPageMetaData',
      jsonAPI: true,
      method: 'get',
      baseURL: apiSite,
      url: '/jsonapi/node/api_page?include=field_ref_contact_email'
    })

    return {
      header: get(res, 'data[0].fieldContactHeader'),
      descriptionLong: get(res, 'data[0].fieldApiLongDescription.processed'),
      descriptionShort: get(res, 'data[0].fieldApiShortDescription.processed'),
      description: get(res, 'data[0].fieldContactShortDescription'),
      contactLink: get(res, 'data[0].fieldRefContactEmail.fieldContactEmail'),
      metaTags: formatMetaTags(get(res, 'data[0].metatagNormalized'))
    }
  } catch (error) {
    log.error('getApiPageMetaData: request error', error)
    throw (error)
  }
}

/**
 * Get ApiDocsInfo for ApiDocsPage
 * @param {*} [opts.cancelToken] axios cancel token
 */

export const getApiDocsInfo = async () => {
  try {
    const res = await axios({
      operationId: 'getApiDocsInfo',
      method: 'get',
      baseURL: apiSite,
      url: '/data-api/v1?_format=json'
    })

    const formattedData = get(res, 'data', [])
    return formattedData
  } catch (error) {
    log.error('getApiDocsInfo: request error', error)
    throw (error)
  }
}

/**
 * Fetch the global nav header menu & footer data
 * @param {object} options
 * @param {*} options.cancelToken optional axios cancellation token
 */
export const getNavigationMenus = async ({
  cancelToken
} = {}) => {
  try {
    const res = await axios({
      operationId: 'getNavigationMenus',
      method: 'get',
      baseURL: apiSite,
      url: '/data-api/v1/frontend/navigation-menu',
      params: {
        ...(useCacheBuster.getNavigationMenus ? {
          cacheBuster: simpleCacheBust()
        } : {})
      },
      cancelToken: cancelToken
    })

    const body = res.data || { data: {} }
    const data = body.data
    const formattedData = formatNavigationMenus(data)
    return formattedData
  } catch (error) {
    log.error('getNavigationMenus: request error', error)
    throw (error)
  }
}

/**
 * Fetch all topics with included navigational categories
 * @param {object} options
 * @param {*} options.cancelToken optional axios cancellation token
 */
export const getAllNavigationalTopics = async ({
  cancelToken
} = {}) => {
  try {
    const params = [
      'include=field_ref_navigational_category,parent'
      // 10/24/2019 - the below Drupal filtering is not working as expected, so we will get all navigational topics and filter the ones
      // without a parent - only the ones with a parent (ie, navigational category) are supposed to be diplayed in the nav menu
      // 'filter=[parent-filter][condition][path]=parent.id',
      // `[parent-filter][condition][operator]=IS%20NULL`
    ].join('&')

    const { data } = await axios({
      operationId: 'getAllNavigationalTopics',
      jsonAPI: true,
      method: 'get',
      baseURL: apiSite,
      url: `/jsonapi/taxonomy_term/navigational_topics?${params}`,
      params: {
        ...(useCacheBuster.getAllNavigationalTopics ? {
          cacheBuster: simpleCacheBust()
        } : {})
      },
      cancelToken: cancelToken
    })
    return data
  } catch (error) {
    log.error('getAllNavigationalTopics request error', error)
    throw (error)
  }
}

export const getSitemapPageData = async ({
  cancelToken
} = {}) => {
  try {
    const { data } = await axios({
      operationId: 'getSitemapPageData',
      method: 'get',
      jsonAPI: true,
      baseURL: apiSite,
      url: '/jsonapi/node/sitemap_page?include=field_ref_contact_email',
      cancelToken: cancelToken
    })
    // extract meta data and send along
    return data
  } catch (error) {
    log.error('getSitemapPageData: request error', error)
    throw (error)
  }
}

export const getAlert = async () => {
  try {
    const res = await axios({
      operationId: 'getAlert',
      method: 'get',
      jsonAPI: true,
      baseURL: apiSite,
      url: '/jsonapi/node/alert?include=field_alert_cta.field_internal_page,field_alert_cta.field_ref_dataset_type,field_alert_cta.field_ancillary_file.field_media_file'
    })
    const formattedData = formatAlert(get(res, 'data', []))
    return formattedData
  } catch (error) {
    log.error('getAlert: request error', error)
    throw (error)
  }
}

export const getFrontendSettings = async ({
  cancelToken
} = {}) => {
  try {
    const res = await axios({
      operationId: 'getFrontendSettings',
      method: 'get',
      baseURL: apiSite,
      url: '/data-api/v1/frontend/config',
      params: {},
      cancelToken: cancelToken
    })

    const body = res.data || { data: {} }
    const data = body.data
    const featureFlags = data?.general
    // Returning an empty array so that we know we tried to hit API and got nothing back vs. the default state of undefined in SiteStore
    return featureFlags || []
  } catch (error) {
    log.error('getFrontendSettings: request error', error)
    throw (error)
  }
}
