import React from 'react'

const DataDictionaryIcon = () => (
  <div className='DataDictionaryIcon SvgIcon'>
    <svg xmlns='http://www.w3.org/2000/svg' fill='currentColor' height='100%' width='100%' version='1' viewBox='0 0 20 24'>
      <path fill='currentColor' fillRule='nonzero' d='M0 0h20v24H0V0zm5 5v3h10V5H5zm0 6v3h10v-3H5zm0 6v3h6v-3H5z' />
    </svg>
  </div>
)

export default DataDictionaryIcon
