import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as ChevronRight } from '../../../assets/fontawesome/custom/chevron-right.svg'
import { ReactComponent as ChevronDown } from '../../../assets/fontawesome/custom/chevron-down.svg'
import { ReactComponent as BoxEmpty } from '../../../assets/fontawesome/custom/box-empty.svg'
import { ReactComponent as CheckedBox } from '../../../assets/fontawesome/custom/box-checked.svg'
import { ReactComponent as BoxMinus } from '../../../assets/fontawesome/custom/box-minus.svg'
import { ReactComponent as CollapseIcon } from '../../../assets/fontawesome/custom/collapseIcon.svg'
import { ReactComponent as ExpandIcon } from '../../../assets/fontawesome/custom/expandIcon.svg'
import { getDisplayFileSize, resolvePlurals, toggleAllChildrenOfNodes } from '../../../utilities/displayUtilities'
import { NotificationBanner } from '../../common/NotificationBanner/NotificationBanner'

import './FilePicker.scss'

const FilePicker = (props) => {
  const { nodes, itemsUpdated, maxGB, setCheckboxesDirty } = props
  const [checkboxNodes, setCheckboxNodes] = useState(nodes)
  const [headerNode, setHeaderNode] = useState({})
  const [totalOverallFileSize, setTotalOverallFileSize] = useState(0)

  useEffect(() => {
    updateNodeStates()
    toggleChildrenVisibility(checkboxNodes, true)
  }, [])

  useEffect(() => {
    itemsUpdated(checkboxNodes, totalOverallFileSize, getSelectedNodes()?.length)
  }, [checkboxNodes])

  const checkboxClickedHandler = (node, check, isParent) => {
    if (isParent) {
      toggleAllChildrenOfNodes([node], check)
    }
    checkClicked(node)
    updateNodeStates()
  }

  const headerCheckboxClickedHandler = (check) => {
    toggleAllChildrenOfNodes(checkboxNodes, check)
    updateNodeStates()
  }

  const headerExpandClickedHandler = (check) => {
    toggleChildrenVisibility(checkboxNodes, check)
    updateNodeStates()
  }

  const renderNode = (node, isParent) => {
    const someChildrenChecked = areSomeChildrenChecked(node)
    const childCount = node?.children?.filter(child => child.checked).length
    const allChildrenHidden = areChildrenHidden(node)
    const childLength = node.children?.length
    const hasOnlySingleChild = childLength === 1
    return (
      !node.hidden &&
        <div className={(isParent ? 'parentContainer' : 'childContainer')} key={node.value}>
          <div className={'selectionRow ' + (isParent ? 'parentRow' : 'childRow')}>
            {!isParent &&
              <div className='childRowDottedContainer'>
                <div className='childRowDottedTop' />
                <div className='childRowDottedBottom' />
              </div>}

            {!isParent && <div className='horizontalLine' />}
            {node.children &&
              <div className='expandCollapseWrapper'>
                {allChildrenHidden && <button onClick={() => { toggleChildrenVisibility([node], false) }}><ChevronRight className='chevron' /></button>}
                {!allChildrenHidden && <button onClick={() => { toggleChildrenVisibility([node], true) }}><ChevronDown className='chevron' /></button>}
              </div>}
            <div className='checkboxWrapper'>
              {!someChildrenChecked && !node.checked && <button onClick={() => { checkboxClickedHandler(node, true, isParent) }}><BoxEmpty className='uncheckedBox' /></button>}
              {!someChildrenChecked && node.checked && <button onClick={() => { checkboxClickedHandler(node, false, isParent) }}><CheckedBox className='checkedCheckbox' /></button>}
              {someChildrenChecked && <button onClick={() => { checkboxClickedHandler(node, true, isParent) }}><BoxMinus className='minusCheckbox' /></button>}
            </div>
            <div className='rowNameAndDetails'>
              <span className='fileName'>{resolvePlurals(node.label, !hasOnlySingleChild)}</span>

              {node?.fileType && node?.fileSize && (
                <span className='rowInfo'>
                  {node.fileType} &bull; {getDisplayFileSize(node.fileSize)}
                </span>)}
              {isParent && <div className={'rowInfo parentRowInfo' + (allChildrenHidden ? ' noLeftBorder' : '')}>{childLength} {resolvePlurals('files', !hasOnlySingleChild)} available</div>}
            </div>
            {isParent && childCount > 0 && (
              <div className='selectionPillWrapper'>
                <div className='selectionPill'>{childCount}</div>
              </div>
            )}
          </div>

          {renderChildrenNodes(node.children)}
        </div>

    )
  }
  const renderChildrenNodes = (childrenNodes) => {
    if (!childrenNodes) { return }
    return (childrenNodes.map((node) => { return renderNode(node, false) }))
  }

  const toggleChildrenVisibility = (nodes, shouldHide) => {
    for (let j = 0; j < nodes.length; j++) {
      const updatedNodes = checkboxNodes.map((n) => {
        if (n === nodes[j]) {
          for (let i = 0; i < n?.children?.length; i++) {
            n.children[i] = { ...nodes[j].children[i], hidden: shouldHide }
          }
        }
        return n
      })
      setCheckboxNodes(updatedNodes)
    }
  }

  const updateNodeStates = () => {
    let totalChildrenCount = 0
    let totalCheckedCount = 0
    let totalHiddenCount = 0
    let totalUncheckedCount = 0
    let totalChildFileSize = 0
    let totalOverallFileSize = 0

    const updatedNodes = checkboxNodes?.map((n) => {
      let emptyChildrenCount = 0
      let checkedChildrenCount = 0
      for (let i = 0; i < n?.children?.length; i++) {
        if (n.children[i].checked) {
          checkedChildrenCount++
          totalChildFileSize += n.children[i]?.fileSize
        } else {
          emptyChildrenCount++
        }
        totalChildrenCount++
        if (n?.children[i]?.hidden || n?.children[i]?.hidden === undefined) {
          totalHiddenCount++
        }
        if (n.children[i].fileSize) {

        }
      }
      if (n?.children?.length === emptyChildrenCount) {
        n = { ...n, checked: false }
      }
      if (n?.children?.length === checkedChildrenCount) {
        n = { ...n, checked: true }
      }
      n = { ...n, fileSize: totalChildFileSize }
      totalOverallFileSize += totalChildFileSize
      totalChildFileSize = 0
      totalCheckedCount += checkedChildrenCount
      totalUncheckedCount += emptyChildrenCount

      if (totalCheckedCount > 0) {
        setCheckboxesDirty(true)
      }

      return n
    })

    if (totalCheckedCount > 0 && totalUncheckedCount > 0 && totalCheckedCount !== totalChildrenCount && totalUncheckedCount !== totalChildrenCount) {
      setHeaderNode({ partialChecked: true, allHidden: totalHiddenCount === totalChildrenCount, fileSize: totalChildFileSize })
    } else {
      setHeaderNode({
        checked: totalCheckedCount === totalChildrenCount,
        partialChecked: false,
        allHidden: totalHiddenCount === totalChildrenCount
      })
    }

    setTotalOverallFileSize(totalOverallFileSize)

    setCheckboxNodes(updatedNodes)
  }

  const areSomeChildrenChecked = (node) => {
    if (!node.children) { return false }
    if (node.children.length < 2) { return false }
    let atLeastOneCheck = false
    let foundUnchecked = false
    for (let i = 0; i < node.children.length; i++) {
      atLeastOneCheck = atLeastOneCheck || node.children[i].checked
      if (!node.children[i].checked) {
        foundUnchecked = true
      }
    }
    return atLeastOneCheck && foundUnchecked
  }

  const areChildrenHidden = (node) => {
    if (!node.children) { return false }
    for (let i = 0; i < node.children.length; i++) {
      if (node.children[i].hidden) { return true }
    }
  }

  const checkClicked = (node) => {
    const updatedNodes = checkboxNodes.map((n) => {
      if (n === node) {
        return { ...n, checked: !n.checked }
      }
      // Check children
      for (let i = 0; i < n?.children?.length; i++) {
        if (n.children[i] === node) {
          n.children[i] = { ...n.children[i], checked: !n.children[i].checked }
        }
      }

      return n
    })
    setCheckboxNodes(updatedNodes)
  }

  const getSelectedNodes = () => {
    const retNodes = []
    for (let i = 0; i < checkboxNodes?.length; i++) {
      for (let j = 0; j < checkboxNodes[i]?.children?.length; j++) {
        const childNode = checkboxNodes[i]?.children[j]
        if (childNode?.checked) {
          retNodes.push(childNode)
        }
      }
    }
    return retNodes
  }

  const checkedBoxesCount = getSelectedNodes()?.length
  return (
    <div className='FilePickerWindow'>
      <div className='header'>
        <div className='fileName'>
          <div className='expandCollapseHeaderWrapper'>
            {headerNode?.allHidden && <button onClick={() => { headerExpandClickedHandler(false) }}><ExpandIcon className='expandIcon' /></button>}
            {!headerNode?.allHidden && <button onClick={() => { headerExpandClickedHandler(true) }}><CollapseIcon className='collapseIcon' /></button>}
          </div>
          <div className='checkboxHeaderWrapper'>
            {headerNode?.partialChecked && <button onClick={() => { headerCheckboxClickedHandler(true) }}><BoxMinus className='minusCheckbox' /></button>}
            {!headerNode?.partialChecked && !headerNode?.checked && <button onClick={() => { headerCheckboxClickedHandler(true) }}><BoxEmpty className='uncheckedBox' /></button>}
            {!headerNode?.partialChecked && headerNode?.checked && <button onClick={() => { headerCheckboxClickedHandler(false) }}><CheckedBox className='checkedCheckbox' /></button>}
          </div>
          <span>Select all</span>
        </div>
        {checkedBoxesCount > 0 &&
          <div className='selections'>
            <span>Selections</span>
            <div className='selectionPill'>{checkedBoxesCount}</div>
          </div>}
      </div>
      <div className='FilePicker'>
        {checkboxNodes.map((node) => { return renderNode(node, true) })}
      </div>
      {checkedBoxesCount > 0
        ? (maxGB > 0 && totalOverallFileSize > (maxGB * 1000000000))
          ? <NotificationBanner estimatedFileSize={getDisplayFileSize(totalOverallFileSize)} maxGBSetting={maxGB} />
          : <div className='estimatedFileSize'><span>Estimated file size: {totalOverallFileSize > 0 ? getDisplayFileSize(totalOverallFileSize) : '0 KB'}</span></div>
        : <div className='estimatedFileSize'><span className='MakeASelection'>Make a file selection to continue</span></div>}
    </div>

  )
}

FilePicker.propTypes = {
  nodes: PropTypes.array,
  itemsUpdated: PropTypes.func,
  maxGB: PropTypes.number,
  setCheckboxesDirty: PropTypes.func
}

export default FilePicker
