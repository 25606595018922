export const footerDefaults = {
  bottom_row_links: {},
  column_1_label: '',
  column_2_label: '',
  column_3_label: '',
  hq_address: '',
  link_contact: '',
  link_freedom_of_information_act: '',
  link_get_email_updates: '',
  link_no_fear_act: '',
  link_privacy_policy: '',
  link_rss_feed: '',
  short_description: '',
  social_facebook_link: '',
  social_linkedin_link: '',
  social_twitter_link: '',
  social_youtube_link: ''
}
