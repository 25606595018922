/*
  Helpers to remove JSON API jank for UI consumption
*/
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { formatMetaTags, getIncludedUrls, getMediaImageAltText } from './global'
import { getScaledImgUrl, getAssetOrigin } from '../../utilities/linkHelpers'
import { extractCtaData } from '../../utilities/apiHelpers'

// helpers to manage the content type of featured content
const INTERNAL = 'fieldInternalPage'
const EXTERNAL = 'fieldExternalLink'
const FILE = 'fieldAncillaryFile'
const INTERNAL_TYPE = 'internal'
const EXTERNAL_TYPE = 'external'
const FILE_TYPE = 'file'

/*
 * High level function to convert raw JSON:API homepage data to POJO
 */
export const formatHomepageData = (data, included) => {
  const {
    title = '',
    fieldHomePageHeader = '',
    fieldRefFeaturedToolsSection = {},
    fieldHomePageChartSection = [],
    fieldRefHomePageSection = [],
    fieldHomePageFeatContentSec = {},
    metatagNormalized = [],
    fieldRefYoutubeVideoSection = []
  } = data

  return {
    pageTitle: title,
    pageHeader: fieldHomePageHeader,
    pageChartSections: formatChartSections(fieldHomePageChartSection),
    mission: formatMissionSection(fieldRefHomePageSection, included),
    featuredTools: formatFeaturedTools(fieldRefFeaturedToolsSection),
    featuredContent: formatFeaturedContent(fieldHomePageFeatContentSec, included),
    videos: formatVideoSection(fieldRefYoutubeVideoSection),
    metaTags: formatMetaTags(metatagNormalized)
  }
}

function formatChartSections (sections) {
  return sections.map((section, i) => (
    {
      description: get(section, 'fieldChartSectionDescription'),
      chart: {
        title: get(section, 'fieldRefChart.fieldChartTitle'),
        topic: {
          name: get(section, 'fieldRefChart.fieldRefTopic.name'),
          slug: get(section, 'fieldRefChart.fieldRefTopic.path.alias')
        },
        dataset: {
          name: get(section, 'fieldRefChart.fieldRefDatasetType.name'),
          slug: get(section, 'fieldRefChart.fieldRefDatasetType.path.alias')
        },
        data: JSON.parse(get(section, 'fieldRefChart.fieldRefChartData[0].fieldChartDataValue'))
      },
      header: get(section, 'fieldRefNavigationalCategory.name', '')
    }
  ))
}

/*
  The mission statement portion of the homepage is a one-time component
  even though the section is labeled as if it is a geenral purpose mechanism.
*/
function formatMissionSection (sections, included) {
  const missionSection = sections[0]

  return {
    header: get(missionSection, 'fieldSectionHeader'),
    card: {
      header: get(missionSection, 'fieldHomePageComponent[0].fieldComponentSubtitle'),
      description: get(missionSection, 'fieldHomePageComponent[0].fieldWysiwygParagraph.value'),
      cta: {
        label: get(missionSection, 'fieldHomePageComponent[0].fieldRefCallToAction.fieldCallToActionText'),
        slug: get(missionSection, 'fieldHomePageComponent[0].fieldRefCallToAction.fieldExternalLink.uri') || get(missionSection, 'fieldHomePageComponent[0].fieldRefCallToAction.fieldInternalPage.path.alias')
      }
    },
    image: getScaledImgUrl('large', getIncludedUrls(included), `${getAssetOrigin()}${get(missionSection, 'fieldHomePageComponent[0].fieldMediaImage.fieldMediaImage.uri.url')}`),
    imageAlt: getMediaImageAltText(get(missionSection, 'fieldHomePageComponent[0].fieldMediaImage'), included)
  }
}

function formatFeaturedTools (section) {
  const toolList = get(section, 'fieldHomePageFeaturedTools', [])

  return {
    header: get(section, 'fieldSectionHeader'),
    description: get(section, 'fieldFeaturedToolShortDesc'),
    tools: toolList.map(tool => {
      const rawImageUrl = get(tool, 'fieldCardIcon.fieldMediaImage1.uri.url')
      const imageUrl = rawImageUrl ? `${getAssetOrigin()}${rawImageUrl}` : null
      const ctaData = extractCtaData(get(tool, 'fieldRefCallToAction'))

      return {
        icon: imageUrl,
        name: get(tool, 'fieldComponentTitle'),
        description: get(tool, 'fieldFeaturedToolShortDesc'),
        cta: {
          label: ctaData.title,
          uri: ctaData.uri
        }
      }
    })
  }
}

/**
 * format the featured content page section
 * Homepage featured content passes data through to FeaturedContentList component
 * this function returns the API to that component
 * @param {object} section
 * @param {object[]} included
 */
function formatFeaturedContent (section, included) {
  const contentItems = get(section, 'fieldHomePageFeaturedContent', [])

  return {
    header: get(section, 'fieldSectionHeader'),
    ...extractContentItems(contentItems, included)
  }
}

/**
 * Format the video section.
 *
 * @param {object[]} items A list of video sections returned by the BE.
 * @returns {object[]} Formatted list of video sections.
 */
function formatVideoSection (items) {
  return items.map((item) => {
    return {
      header: item.fieldSectionHeader,
      description: item.fieldWysiwygParagraph.processed,
      embedCode: item.fieldEmbedCode
    }
  })
}

/**
 * Content items must be parsed according to object properties
 * In addition, the end consumer expects a specific data structure; do so here
 * @param {object[]} items
 * @param {object[]} included
 */
function extractContentItems (items, included) {
  const SPOTLIGHT_INDEX = 3
  const formattedItems = {
    featuredContentItems: [],
    spotlightCard: {}
  }

  items.forEach((item, index) => {
    const type = determineContentType(item)

    // the last item in the list is the
    if (index === SPOTLIGHT_INDEX) {
      const spotlight = buildSpotlightCard(item, type)
      formattedItems.spotlightCard = spotlight

      return
    }

    // all others are normal
    const normal = buildNormalCard(type, item, included)
    formattedItems.featuredContentItems.push(normal)
  })

  return formattedItems
}

// returns a normal featured content item card
export function buildNormalCard (type, data, included) {
  const ctaOverrideText = get(data, 'fieldCallToActionText')

  // ancillary file
  if (type === FILE_TYPE) {
    const rawImgObj = get(data, 'fieldMediaImage')
    const rawImageUrl = get(rawImgObj, 'thumbnail.uri.url') || get(rawImgObj, 'fieldMediaImage.uri.url')

    return {
      title: get(data, 'fieldComponentTitle') || get(data, 'fieldAncillaryFile.name'),
      description: get(data, 'fieldHomePageFeatContentDes', ''),
      link: {
        label: ctaOverrideText || `Read ${get(data, 'fieldAncillaryFile.name')}`,
        url: `${getAssetOrigin()}${get(data, 'fieldAncillaryFile.fieldMediaFile.uri.url')}`,
        imgSrc: `${getAssetOrigin()}${rawImageUrl}`,
        imgAlt: getMediaImageAltText(rawImgObj, included)
      }
    }
  }

  // link to an external page
  if (type === INTERNAL_TYPE) {
    const rawImgObj = get(data, 'fieldInternalPage.fieldRefImage')
    const rawImageUrl = get(data, 'fieldInternalPage.fieldRefImage.fieldMediaImage.uri.url')

    return {
      title: get(data, 'fieldComponentTitle') || get(data, 'fieldInternalPage.title'),
      description: get(data, 'fieldHomePageFeatContentDes') || get(data, 'fieldInternalPage.fieldDescription'),
      link: {
        label: !isEmpty(ctaOverrideText)
          ? ctaOverrideText
          : `Read ${get(data, 'fieldInternalPage.fieldRefContentType.name')}`,
        url: get(data, 'fieldInternalPage.path.alias'),
        imgSrc: rawImageUrl ? `${getAssetOrigin()}${rawImageUrl}` : null,
        imgAlt: getMediaImageAltText(rawImgObj, included)
      }
    }
  }

  // link to an external resource
  if (type === EXTERNAL_TYPE) {
    const rawImgObj = get(data, 'fieldMediaImage')
    const rawImageUrl = get(rawImgObj, 'thumbnail.uri.url') || get(rawImgObj, 'fieldMediaImage.uri.url')

    return {
      title: get(data, 'fieldComponentTitle'),
      description: get(data, 'fieldHomePageFeatContentDes'),
      link: {
        url: get(data, 'fieldExternalLink.uri'),
        label: get(data, 'fieldExternalLink.title'),
        imgSrc: rawImageUrl ? `${getAssetOrigin()}${rawImageUrl}` : null,
        imgAlt: getMediaImageAltText(rawImgObj, included)
      }
    }
  }
}

/**
 * Extract the call-to-action data of the spotlight card based on content type
 * @param {object} data
 * @param {string} type
 */
export function getSpotlightCardCta (data, type) {
  let label, url
  const ctaOverride = get(data, 'fieldCallToActionText')

  switch (type) {
    case INTERNAL_TYPE:
      label = ctaOverride || `Read ${get(data, 'fieldInternalPage.fieldRefContentType.name')}`
      url = get(data, 'fieldInternalPage.path.alias')
      break
    case EXTERNAL_TYPE:
      label = get(data, 'fieldExternalLink.title')
      url = get(data, 'fieldExternalLink.uri')
      break
    case FILE_TYPE:
      label = ctaOverride || get(data, 'fieldAncillaryFile.name')
      url = `${getAssetOrigin()}${get(data, 'fieldAncillaryFile.fieldMediaFile.uri.url')}`
      break
    default:
      break
  }

  return {
    label,
    url
  }
}

/**
 * Extract the header & description of the spotlight card based on content type
 * @param {object} data
 * @param {string} type
 */
export function getSpotlightCardCopy (data, type) {
  const isInternalPage = type === INTERNAL_TYPE
  const internalTitleOverride = get(data, 'fieldComponentTitle')
  const internalDescOverride = get(data, 'fieldHomePageFeatContentDes')

  return {
    header: isInternalPage ? internalTitleOverride || get(data, 'fieldInternalPage.title') : get(data, 'fieldComponentTitle'),
    description: isInternalPage ? internalDescOverride || get(data, 'fieldInternalPage.fieldDescription') : get(data, 'fieldHomePageFeatContentDes')
  }
}

// returns the 'spotlight' featured card w/no image and light background
// NOTE: Editorially, the spotlight card is the 4th item in the list
export function buildSpotlightCard (data, type) {
  return {
    ...(getSpotlightCardCopy(data, type)),
    cta: getSpotlightCardCta(data, type)
  }
}

// check data fields for content type
function determineContentType (data) {
  if (data.hasOwnProperty(INTERNAL)) return INTERNAL_TYPE
  if (data.hasOwnProperty(EXTERNAL)) return EXTERNAL_TYPE
  if (data.hasOwnProperty(FILE)) return FILE_TYPE
}
