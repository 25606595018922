import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { searchAndHandleExternalLinks } from '../../../utilities/linkHelpers'

import './RichTextField.scss'

const uuid = require('uuid/v4')

function RichTextField (props) {
  const [instanceId] = useState(uuid())
  const {
    className: additionalClassName,
    content,
    ...otherProps
  } = props

  /*
    id this instance for efficient link parsing
    CSS will not accept a number as first char so prepend component className
  */
  const componentClassName = 'RichTextField'
  const uniqueKey = `${componentClassName}-${instanceId}`
  const querySelector = `#${uniqueKey}`

  useEffect(() => {
    searchAndHandleExternalLinks(querySelector)
  }, [instanceId, querySelector])

  return (
    <div
      id={uniqueKey}
      className={cx(componentClassName, additionalClassName)}
      dangerouslySetInnerHTML={{ __html: content }}
      {...otherProps}
    />
  )
}

RichTextField.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string.isRequired
}

export default RichTextField
